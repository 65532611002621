/*
ImportUtils.js
*/

import {
  apirequest,
  getAuthData
} from '../js/Utilities';

import {
  API_IMPORT_GSHEET_TO_PLAYLIST
} from '../js/Configuration';

export const setStateImportOnChange = (state, target) => {
  const field = target.name.indexOf('prepend') === 0 ? 'prepend' : target.name;

  return {
    importGSheet: {
      ...state.importGSheet,
      [field]: target.value
    }
  }
}

export const setStateImportOnSubmit = (state) => {
  return {
    importGSheet: {
      ...state.importGSheet,
      loading: true
    }
  }
}

export const setStateImportOnOpen = (state) => {
  return {
    importGSheet: {
      ...state.importGSheet,
      open: true,
    }
  }
}

export const setStateImportOnClose = (state, showloader=false) => {
  return {
    ...state,
    importGSheet: {
      ...state.importGSheet,
      loading: false,
      open: false,
      url: '',
      sheet: 'Sheet1'
    },
    loading: showloader
  }
}

const setStateImportOnError = (state) => {
  return {
    importGSheet: {
      ...state.importGSheet,
      loading: false
    }
  }
}

export const importSpreadSheet = (state, callback) => {
  const fd = new FormData();
  fd.set('url', state.importGSheet.url);
  fd.set('sheet', state.importGSheet.sheet);
  fd.set('playlist', state.currentVideoBlock.vsb_id);
  fd.set('prepend', state.importGSheet.prepend);
  
  fd.set('userUID', getAuthData('uuid'));
  fd.set('userID', getAuthData('uid'));
  fd.set('userName', getAuthData('user'));
  fd.set('token', getAuthData('token'));

  apirequest(API_IMPORT_GSHEET_TO_PLAYLIST, { body: fd }, callback);
}


export const importSpreadSheetCallback = (response, state) => {
  let result;
  if(response) {
    result = setStateImportOnClose(state, true);
  } else {
    result = setStateImportOnError(state);
  }
  return result;
}
