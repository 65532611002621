/**
 * EpisodeView.js
 * View a single episode. Page page-level component
 */
import React, { createRef } from 'react';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';

import AddVideosByURL from '../Destinations/AddVideosByURL';
import Breadcrumb from '../subcomponents/Breadcrumb';
import CheckForDuplicatesModal from '../Destinations/CheckForDuplicatesModal';
import { CheckForUpdates } from '../Destinations/CheckForUpdates';
import CheckForUpdatesProgress from '../Destinations/CheckForUpdatesProgress';
import CRTStatus from '../Destinations/CRTStatus';

import DragSortList from '../DragSortList';

import { DestinationsToggleView } from '../Destinations/DestinationsToggleView';
import EpisodeViewTitle from './EpisodeViewTitle';
import EpisodeMoveModal from './EpisodeMoveModal';
import ExportCSV from '../Destinations/ExportCSV';
import RuntimeRatings from '../Destinations/RuntimeRatings';
import RunDate from '../Stations/RunDate';
import SeasonsEpsDisplay from './SeasonsEpsDisplay';
import VideoDragItem from '../subcomponents/VideoDragItem';
import VideoModal from '../subcomponents/Videos/VideoModal';
import VideoSetEdit from '../Destinations/VideoSetEdit';
import VideosList from '../VideosList';

import {
  API_CRTAPPROVALS_GETVIDSETSTATUS,
  API_DESTINATIONS_ASSIGNMENTS,
  API_DESTINATIONS_CHECKFORUPDATES,
  API_DESTINATIONS_CHECKFORUPDATES_CSV,
  API_VIDEO_REMOVEFROMSET,
  API_VIDEOSETBLOCK_RATING,
  API_VIDEOSETBLOCK_UPDATE,
  API_VIDEO_GETBATCH,
  API_VIDEO_ADDTOSET,
  API_GROUPS_ALLGROUPS,
  API_RATINGS_ALL,
  API_RATINGS_VIDEOUPDATE,
  API_VIDEO_INFO,
  API_STATIONS_EPISODE_EDIT,
  API_VIDEOSETBLOCK_REORDERED,
  API_VIDEOSETBLOCK_INFO,
  API_VIDEOSETBLOCK_INFO_CSV,
  NULL_SET_TITLE,
  NULL_EPISODE_TITLE
} from '../../js/Configuration';

import {
  apirequest,
  debounce,
  getAuthData,
  isAdmin,
  totalRunTime,
  formatTime
} from '../../js/Utilities';

import * as StaUtils from '../../actions/StationsUtils';

import {
  setStateSetDupes,
  setStateOpenCheckDupes,
  setStateOnDupeExclude,
  setStateOnRemoveInstance,
  setStateCloseCheckDupes,
  setStateCancelCheckDupes,
  setStateOnAddByUrlChange,
  setStateOnAddByUrlClose,
  setStateOnAddByUrlSubmit,
  checkUrlListForDupes,
  setStateFoundDupes,
  setStateOnDupesMessageClose
} from '../../actions/DestinationsPlaylistUtils';

import {
  exportVideoData,
  playAllVideos
} from '../../actions/DestinationsUtils';

import './css/EpisodeView.css';
import '../Destinations/css/VideoDisplay.css';

class EpisodeView extends React.Component {
  constructor(props) {
    super(props);

    this.fetchCancel = new AbortController();
    this.episodeDeleteDialog = createRef();
    this.episodeDeleteDialog = createRef()
  
    this.state = {
      series: 0,
      list: [],
      breadcrumbs: [{path: null, label: ''}],
      title: '',
      edittitle: {
        meta: {},
        list: [],
        open: false,
        value: '',
        error: null,
        id: 0
      },
      addByURL: {
        open: false,
        value: '',
        maxIds: false,
        working: false
      },
      videostoadd: {
        open: false,
        isloading: true,
        available: [],
        page: 1,
        pageCount: 1,
        results: 0,
        perPage: 10,
        sortBy: 'video_addeddate',
        ascending: false,
        filterKeyword: '',
        filterOn: '',
        selected: [],
        savestatus: {
          hidden: true
        }
      },
      videopreview: {
        open: false,
        current: {},
        groups: [],
        ratings: [],
        currentrating: null,
        selectedSet: {}
      },
      crtstatus: {
        approved: false,
        loading: false,
        status: 'Not Submitted'
      },
      meta: {
        se: {
          season: 0,
          episode: 0
        },
        vsb_datetimetorun: null
      }, // Stores the entire video set block response from the server
      runtime: 0,
      checkForUpdates: {
        open: false,
        updates: [],
        loading: true
      },
      moveep: {
        open: false,
        value: '',
        series: []
      },
      deleteep: {
        open: false
      },
      useGrid: true,
      checkForDupes: {
        open: false,
        loading: true,
        dupes: [],
        mode: 'adding', // Can be 'adding', 'checking', or 'checked'
        working: false,
        response: {},
        message: ''
      }
    };

    this.makeBreadcrumb = this.makeBreadcrumb.bind(this);
    this.makeEpisodeEditModal = this.makeEpisodeEditModal.bind(this);
    this.makeVideos = this.makeVideos.bind(this);
    this.makeAddVidsByURL = this.makeAddVidsByURL.bind(this);
    this.makeVideoList = this.makeVideoList.bind(this); // Shows/hides the VideoList component

    this.playVideo = this.playVideo.bind(this);
    this.gridUpdated = this.gridUpdated.bind(this);

    // TODO: Consolidate this with DestinationsPage functions somehow.
    this.getGroups = this.getGroups.bind(this);
    this.getRatings = this.getRatings.bind(this);
    this.deleteVideo = this.deleteVideo.bind(this);
    this.getVideosForBlock = this.getVideosForBlock.bind(this);

    this.getEpisodeCRTStatus = this.getEpisodeCRTStatus.bind(this);
    // Gets rating for a particular episode
    this.getEpisodeRating = this.getEpisodeRating.bind(this);

    this.onEpisodeEdit = this.onEpisodeEdit.bind(this);
    this.onEpisodeEditSave = this.onEpisodeEditSave.bind(this);
    this.onEditEpisodeInput = this.onEditEpisodeInput.bind(this);
    this.onEpisodeEditClose = this.onEpisodeEditClose.bind(this);
    this.onEpisodeEditReset = this.onEpisodeEditReset.bind(this);

    this.onEpisodeMove = this.onEpisodeMove.bind(this);
    this.onEpisodeMoveSave = this.onEpisodeMoveSave.bind(this);
    this.onEditEpisodeInput = this.onEditEpisodeInput.bind(this);
    this.onEpisodeMoveClose = this.onEpisodeMoveClose.bind(this);
    this.onEpisodeMoveChange = this.onEpisodeMoveChange.bind(this);
    this.onEpisodeDelete = this.onEpisodeDelete.bind(this);
    this.onEpisodeDeleteClose = this.onEpisodeDeleteClose.bind(this);
    this.onEpisodeDeleteConfirm = this.onEpisodeDeleteConfirm.bind(this);

    this.onAddVideoByURL = this.onAddVideoByURL.bind(this);
    this.onAddVideoByUrlSubmit = this.onAddVideoByUrlSubmit.bind(this);
    this.onAddVideoByURLClose = this.onAddVideoByURLClose.bind(this);
    this.onAddVideoByUrlChange = this.onAddVideoByUrlChange.bind(this);
    this.onAddVideoByURLResponse = this.onAddVideoByURLResponse.bind(this);
    this.onSubmitUrlPostConfirmation = this.onSubmitUrlPostConfirmation.bind(this);

    this.onAddVideoFromAva = this.onAddVideoFromAva.bind(this);
    this.onAddVideoFromAvaClose = this.onAddVideoFromAvaClose.bind(this);

    /* For VideosList component, mostly duplicates of what's in DestinationsPage */
    this.onVideoSelected = this.onVideoSelected.bind(this);
    this.onPaginationClick = this.onPaginationClick.bind(this);
    this.onSearchFilterChangeHandler = this.onSearchFilterChangeHandler.bind(this);
    this.debouncedFilterChangeHandler = debounce(200, this.debouncedFilterChangeHandler.bind(this));
    this.changePerPageHandler = this.changePerPageHandler.bind(this);
    this.onSearchFilterClearHandler = this.onSearchFilterClearHandler.bind(this);
    this.onSearchSortChangeHandler = this.onSearchSortChangeHandler.bind(this);
    this.onSearchDirectionChangeHandler = this.onSearchDirectionChangeHandler.bind(this);

    this.getVideoListBatch = this.getVideoListBatch.bind(this);
    this.getVideoListBatchSortDir = this.getVideoListBatchSortDir.bind(this);
    this.getVideoListBatchRequest = this.getVideoListBatchRequest.bind(this);

    this.onRatingsChange = this.onRatingsChange.bind(this);
    this.onRatingsSubmit =  this.onRatingsSubmit.bind(this);

    this.onVideoInfoClose = this.onVideoInfoClose.bind(this);
    this.onDeleteFromSystem = this.onDeleteFromSystem.bind(this);

    this.saveNewOrder = this.saveNewOrder.bind(this);
    this.indicateSaved = this.indicateSaved.bind(this);
    this.manageSaveAlert = this.manageSaveAlert.bind(this);
    this.onSeasonEpChange = this.onSeasonEpChange.bind(this);
    this.onSeasonEpSave = this.onSeasonEpSave.bind(this);
    this.onRunDateChange = this.onRunDateChange.bind(this);
    this.onRunDateChangeSave = this.onRunDateChangeSave.bind(this);

    this.onCheckForUpdates = this.onCheckForUpdates.bind(this);
    this.onCheckForUpdatesClose = this.onCheckForUpdatesClose.bind(this);

    this.requestVideoUpdates = this.requestVideoUpdates.bind(this);

    this.makeCheckForUpdatesModal = this.makeCheckForUpdatesModal.bind(this);
    this.makeMoveEpisodeModal = this.makeMoveEpisodeModal.bind(this);
    this.makeDeleteEpisodeModal = this.makeDeleteEpisodeModal.bind(this);

    this.onMeowSave = this.onMeowSave.bind(this);
    this.onCRTsubmit = this.onCRTsubmit.bind(this);
    this.addOneVideoToAnotherSet = this.addOneVideoToAnotherSet.bind(this);

    this.onGridToggleChange = this.onGridToggleChange.bind(this);
    this.gridOrList = this.gridOrList.bind(this);

    this.onCheckForDuplicates = this.onCheckForDuplicates.bind(this);
    this.onCloseCheckForDupes = this.onCloseCheckForDupes.bind(this);
    this.onRemoveInstance = this.onRemoveInstance.bind(this);
    this.onRemoveInstanceSubmit = this.onRemoveInstanceSubmit.bind(this);
    this.onCheckDupesChange = this.onCheckDupesChange.bind(this);
    this.onCheckDupesErrorClose = this.onCheckDupesErrorClose.bind(this);

    this.isAdmin = isAdmin(this.props.project);
    this.makeSeriesOptions = this.makeSeriesOptions.bind(this);
 
  }

  // Get series for this station
  componentDidMount() {
    this.getVideosForBlock();
  }

  closeModal() {
    this.episodeDeleteDialog.current.close();
  }

  changePerPageHandler(domEvent) {
    domEvent.persist();
    this.setState((state) => StaUtils.setStateOnPerPage(state, domEvent.target.value), this.getVideoListBatch);
  }

  getVideosForBlock() {
    const fd = new FormData();
    if(!this.props.params.episodeID) {
      alert('ERROR: Try refreshing the page.');
      return;
    }

    fd.set('id', this.props.params.episodeID);
    fd.set('token', getAuthData('token'));

    // Fetch the block's videos.
    apirequest(API_VIDEOSETBLOCK_INFO, {body: fd}, (response) => {
      if(!response.vsb_setID) return;
      this.setState((state) => StaUtils.setStateGetVideosForBlock(state, response), this.getEpisodeCRTStatus);
    });
  }

  getEpisodeCRTStatus() {
    const fd = new FormData();
    fd.set('videoset', this.state.meta.vsb_id || 0);
    fd.set('token', getAuthData('token'));

    apirequest(API_CRTAPPROVALS_GETVIDSETSTATUS, {body: fd}, (data) => {
      const crt = {
        approved: data.approved,
        status: data.status,
        loading: false
      };

      this.setState((state) => StaUtils.setStateOnCrtChange(state, data), this.getEpisodeRating);
    });
  }

  getEpisodeRating() {
    const fd = new FormData();
    fd.set('videosetblockid', +this.state.meta.vsb_id);
    fd.set('token', getAuthData('token'));

    // Make the network request here.
    apirequest(API_VIDEOSETBLOCK_RATING, { body: fd }, (data) => {
      if(data.message === 'success') {
        this.setState((state) => StaUtils.setStateRating(state, data));
      }
    });
  }

  makeBreadcrumb() {
    return <Breadcrumb items={this.state.breadcrumbs} />;
  }

  makeAddVidsByURL() {
    return (
      <AddVideosByURL
        {...this.state.addByURL}
        block={this.state.meta}
        onSubmit={this.onAddVideoByUrlSubmit}
        onClose={this.onAddVideoByURLClose}
        onChange={this.onAddVideoByUrlChange} />
    )
  }

  onVideoSelected(domEvent) {

    const picked = domEvent.target.value.toString();
    let nselected;
    let toAdd;

    if(this.state.addByURL.value) {
      toAdd = this.state.addByURL.value.split(/[,\s]/);
    } else {
      toAdd = [];
    }

    // If the box is checked and it's not already in the list...
    if(domEvent.target.checked) {
      // Find the item in the videosAvailable list
      const addThis = this.state.videostoadd.available.find((item) => item.video_youtubeID === picked);
      nselected = [...this.state.videostoadd.selected, addThis];
      toAdd.push(picked);
    } else {
      nselected = this.state.videostoadd.selected.filter((item) => item.video_youtubeID !== picked);
      toAdd = toAdd.filter((item) => item !== picked);
    }

    this.setState((state) => StaUtils.setStateOnVideoSelected(state, nselected, toAdd));
  }

  onPaginationClick(domEvent){
    domEvent.persist();
    this.setState((state) => StaUtils.setStateOnPaginationClick(state, domEvent.target.value), this.getVideoListBatch);
  }

  addOneVideoToAnotherSet(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();

    const fd = new FormData();
    fd.set('videoid', this.state.videopreview.current.video_ava_id);
    fd.set('ytid', this.state.videopreview.current.video_yt_id);
    fd.set('setid', 0);
    fd.set('blockid', this.state.videopreview.selectedSet.videoblockid);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEO_ADDTOSET, {body: fd}, (data) => {
      // TODO: Should probably handle errors too.
      this.setState(StaUtils.setStateCloseVideo);
    });
  }

  getVideoListBatch() {
    this.setState(StaUtils.setStateGetVidBatch, this.getVideoListBatchRequest);
  }

  getVideoListBatchRequest() {
    const fd = new FormData();
    fd.set('perpage', this.state.videostoadd.perPage);
    fd.set('page', this.state.videostoadd.page);
    fd.set('sort_by', this.state.videostoadd.sortBy);
    fd.set('filter_by', this.state.videostoadd.filterKeyword);
    fd.set('filter_on', this.state.videostoadd.filterOn);
    fd.set('order', this.getVideoListBatchSortDir());
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEO_GETBATCH, {body: fd}, (data) => {
      this.setState((state) => StaUtils.setStateAfterVidBatch(state, data));
    });
  }

  getVideoListBatchSortDir() {
    const direction = ['descending', 'ascending'];
    const dirKey = +this.state.videostoadd.ascending;
    return direction[dirKey];
  }

  debouncedFilterChangeHandler(domEvent) {
    const keyword = domEvent.target.form.elements.searchValue.value;
    const criterion = domEvent.target.form.elements.filterOn.value.split('|')[0];

    this.setState((state) => StaUtils.setStateOnFilterChange(state, keyword, criterion), this.getVideoListBatch);
  }

  onSearchFilterChangeHandler(domEvent) {
    domEvent.persist(); // Necessary for React to persist the event.
    this.debouncedFilterChangeHandler(domEvent, this);
  }

  onSearchSortChangeHandler(domEvent) {
    const sort_criteria = domEvent.target.value.split('|')[0];
    this.setState((state) => StaUtils.setStateOnSortChange(state, sort_criteria), this.getVideoListBatch);
  }

  onSearchDirectionChangeHandler(domEvent) {
    this.setState(StaUtils.setStateOnDirectionChange, this.getVideoListBatch);
  }

  onSearchFilterClearHandler(domEvent) {
    this.setState(StaUtils.setStateOnFilterClear, this.getVideoListBatch);
  }

  onVideoInfoClose(dialogRef = null) {
    this.setState(StaUtils.setStateOnInfoClose, () => {
      if(dialogRef && Object.hasOwn(dialogRef, 'current')) {
        dialogRef.current.close();
      }
    });
  }

  onDeleteFromSystem(response) {
    if(!response.videosDeleted.length) return;
    this.setState((state) => StaUtils.setStateOnDeleteFromSystem(state, response));
  }

  onSeasonEpChange(domEvent) {
    const property = domEvent.target.id;
    const value    = domEvent.target.value;

    this.setState((state) => StaUtils.setStateOnSeasonEpChange(state, property, value));
  }

  onSeasonEpSave() {
    StaUtils.saveSeasonEp(this.state.meta.vsb_id, this.state.meta.season, this.state.meta.episode, (data) => {
      this.setState((state) => StaUtils.setStateOnSESave(state, data), this.manageSaveAlert);
    });
  }

  onRunDateChange(domEvent) {
    const newdate = domEvent.target.value
    this.setState((state) => StaUtils.setStateOnRunDateChange(state, newdate), this.onRunDateChangeSave);
  }

  onRunDateChangeSave() {
    const fd = new FormData();
    fd.set('id', this.state.meta.vsb_id);
    fd.set('rundate', this.state.meta.vsb_datetimetorun);
    fd.set('token', getAuthData('token'));
    apirequest(API_VIDEOSETBLOCK_UPDATE, {body: fd}, this.indicateSaved);
  }

  /*
    Duplicates DestinationPlaylist methods. Need to normalize the names that the
    API returns. Then we can consolidate these into a Utility module.
  */
  onCRTsubmit(data){
    if(data.status !== 'success') return;

    const vl = [...this.state.list];

    const whichVideo = vl.findIndex((item) => {
      return item.ytID === data.video_ytid;
    });

    vl[whichVideo].crt = data.crt;

    this.setState({videoList: vl});
  }

  onMeowSave(data) {
    if(data.status !== 'success') return;

    const vl = [...this.state.list];
    const whichVideo = vl.findIndex((item) => {
      return item.ytID === data.video_ytid;
    });

    vl[whichVideo].meow = data.meow;
    this.setState({videoList: vl});
  }

  makeSeriesOptions(item) {
    return {
      label: item.setName,
      value: item.setID
    }
  }

  makeVideoList() {
    if(this.state.videostoadd.open) {
      return (
        <VideosList
          ascending={this.state.videostoadd.ascending}
          open={this.state.videostoadd.open}
          currentSet={this.state.list}
          selected={this.state.videostoadd.selected}
          list={this.state.videostoadd.available}
          videosSelectedHandler={this.onVideoSelected}
          closeHandler={this.onAddVideoFromAvaClose}
          currentPage={this.state.videostoadd.page}
          loading={this.state.videostoadd.isloading}
          totalPages={this.state.videostoadd.pageCount}
          perPage={this.state.videostoadd.perPage}
          perPageChangeHandler={this.changePerPageHandler}
          videos={this.state.videostoadd.available}
          onSubmit={this.onAddVideoByUrlSubmit}
          onPaginationClick={this.onPaginationClick}
          videoSearchSubmitHandler={this.videoSearchSubmitHandler}
          videoSearchInputHandler={this.videoSearchInputHandler}
          showPrevious={this.state.videostoadd.page > 1}
          showNext={this.state.videostoadd.page < this.state.videostoadd.pageCount}
          onSortDirectionChange={this.videoListSortHandler}
          onSearchSortChange={this.onSearchSortChangeHandler}
          onSearchFilterChange={this.onSearchFilterChangeHandler}
          onSearchFilterClear={this.onSearchFilterClearHandler}
          onSearchDirectionChange={this.onSearchDirectionChangeHandler}
       />);
    } else {
      return null;
    }
  }

  // Shows the video modal
  onAddVideoFromAva() {
    this.setState({
      addByURL: {...this.state.addByURL, open: false},
      videostoadd: {...this.state.videostoadd, open: true}
    }, this.getVideoListBatch);
  }

  onAddVideoFromAvaClose() {
    const oldstate = {...this.state.videostoadd};
    const newstate = {
      open: false,
      page: 1,
      pageCount: 1,
      numResults: 0,
      sortBy: 'video_addeddate',
      ascending: false,
      filterKeyword: '',
      filterOn: ''
    };

    this.setState({videostoadd: Object.assign(oldstate, newstate) });
  };

  onAddVideoByURLClose(dialogRef=null) {
    this.setState(
      setStateOnAddByUrlClose,
      () => { if(dialogRef && dialogRef.hasOwnProperty('current')) dialogRef.current.close(); }
    );
  }

  onAddVideoByUrlChange(domEvent){
    const value = domEvent.target.value;
    this.setState((state) => setStateOnAddByUrlChange(state, value));
  }

  onAddVideoByURLResponse(response) {
    this.setState({
      list: StaUtils.formatVideoList(response.videoList.returnData),
      videostoadd: {...this.state.videostoadd, selected: response.videoList.returnData, open: false},
      addByURL: {...this.state.addByURL, open: false, working: false, value: ''},
      runtime: totalRunTime(response.videoList.returnData),
    });
  }

  makeVideos() {
    if( !this.state.list || this.state.list.length === 0 ) {
      return null;
    }

    const items = this.state.list.map(( item, index ) => {
      return (
        <VideoDragItem
          key={uniqueId(`${item.ytID}_`)}
          deleteText="Delete From Episode"
          interstitial={ item.is_interstitial }
          data={ item }
          playVideo={ this.playVideo }
          disabled={false}
          deleteVideo={this.deleteVideo}
          className='no-drag'
          ordinal={item.ordinal}
          position={item.position} />
      );
    });

    const cssClass = classNames({
      'video__display': true,
      'video__display--grid': this.state.useGrid,
      'video__display--listview': !this.state.useGrid
    });

    const grid = (
      <section className="draggable__grid episode__videolist">
        <header>
          <h4>Drag videos to reorder. Click thumbnail to view video information.</h4>
          <div className="episode__savestatus" hidden={this.state.videostoadd.savestatus.hidden}>Saved</div>
        </header>

        <DestinationsToggleView useGrid={this.state.useGrid} onChange={this.onGridToggleChange} />

        <div className={cssClass}>
          <DragSortList
            items={this.state.list}
            onPlayVideo={this.playVideo}
            onDeleteVideo={this.deleteVideo}
            onReSort={(newOrder) => {
            this.setState((state) => {

              return {
                ...state,
                list: newOrder
              }
            }, () => this.gridUpdated(this.state.list) )

          }} />
        </div>
      </section>
    );

    return grid;
  }

  gridUpdated(videos) {
    let ordered;
    if(videos.length) {
      ordered = videos.map((vid) => {
        return vid.ytID;
      });
      // Saves the new order to the server.
      this.saveNewOrder(ordered);
    }
  }

  saveNewOrder(order) {
    const fd = new FormData();
    const id = this.state.meta.vsb_id;

    fd.set('id', id);
    fd.set('videos', order.join(','));
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSETBLOCK_REORDERED, {body: fd}, (data) => {
      this.setState((state) => StaUtils.setStateOnReOrder(state, data));
    });
  }

  indicateSaved() {
    this.setState(StaUtils.setStateIndicateSaved, this.manageSaveAlert);
  }

  playVideo(video) {
    const fd = new FormData();
    fd.set('id', video);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEO_INFO, {body: fd}, (data) => {
      this.getGroups();
      this.getRatings();

      this.setState({
        videopreview: Object.assign(
          {...this.state.videopreview},
          {
            current: data,
            open: true
          }
        )
      });
    });
  }

  getGroups() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUPS_ALLGROUPS, {body: fd}, (data) => {
      this.setState({videoGroups: data});
    });
  }

  getRatings() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_RATINGS_ALL, {body: fd}, (data) => {
      const ratingsData = data.map((item, index) => {
        return {
          value: index,
          label: item.label
        };
      });

      this.setState({
        videopreview: Object.assign(
          {...this.state.videopreview},
          {ratings: ratingsData}
        )
      });
    });
  }

  onEpisodeEdit() {
    const etstate = {...this.state.edittitle};
    this.setState({edittitle: Object.assign(etstate, {open: true}) });
  }

  onEpisodeEditClose(dialogRef) {
    this.setState((state) => {
      return {
        edittitle: {
          ...this.state.edittitle,
          open: false
        }
      }
    }, () => { dialogRef.current.close() });
  }

  onEpisodeEditSave(dialogRef) {

    const fd = new FormData();
    fd.set('id', this.props.params.episodeID);
    fd.set('title', this.state.edittitle.value);
    fd.set('token', getAuthData('token'));

    apirequest(API_STATIONS_EPISODE_EDIT, {body: fd}, (data) => {
      let curstate = {...this.state};
      let cb = ()=>{};

      if(data.result === 'success') {
        let breadcrumbs = [...this.state.breadcrumbs];
        breadcrumbs[breadcrumbs.length - 1] = Object.assign({...breadcrumbs[breadcrumbs.length - 1]}, {label: data.title});

        curstate.title = data.title;
        curstate.edittitle = Object.assign({...this.state.edititle}, {open: false});
        curstate.breadcrumbs = breadcrumbs;
        
        // Close dialog
        cb = () => { dialogRef.current.close() };
      } else {
        curstate.edittitle = Object.assign({...this.state.edititle}, {error: data.message});
      }

      this.setState(Object.assign({...this.state}, curstate), cb);
    });
  }

  onEditEpisodeInput(domEvent) {
    this.setState((state) => {
      return {
        edittitle: {
          ...this.state.edittitle,
          value: domEvent.target.value
        }
      }
    });
  }

  onEpisodeEditReset(dialogRef) {
     this.setState((state) => {
      return {
        edititle: {
          ...this.state.edittitle,
          value: ''
        }
      }
    }, () => { dialogRef.current.close(); });
  } 
  
  onEpisodeMove() {
    const fd = new FormData();
    fd.set('id', +this.state.meta.station_id);
    fd.set('token', getAuthData('token'));
    apirequest(API_DESTINATIONS_ASSIGNMENTS, {body: fd}, (response) => {
      const options = response.map(this.makeSeriesOptions);
      this.setState(
        {
          moveep: {
            ...this.state.moveep,
            series: options,
            open: true
          }
        });
    });
  }

  onEpisodeMoveClose(dialogRef) {
    
    this.setState((state) => {
      return {
        ...state,
        moveep: {
          ...state.moveep,
          open: false,
        }
      } 
    }, () => { dialogRef.current.close() })
  }

  onEpisodeMoveSave(domEvent = null) {
    if(domEvent) {
      domEvent.preventDefault();
    }

    const fd = new FormData();
    fd.set('id', this.props.params.episodeID);
    fd.set('series', this.state.moveep.value);
    fd.set('token', getAuthData('token'));

    const callback = (response) => {
      if(response.error) {
        window.alert(response.error);
      }
      if(!response.series) return;

      const loaf = [...this.state.breadcrumbs];
      const sliced = {
        label: response.series.Video_Set,
        path: `/stations/series/${response.series.vs_id}`
      };

      // Replaces previous breadcrumb with new one.
      loaf.splice(1, 1, sliced);
      this.setState({
        breadcrumbs: loaf,
        moveep: {
          open: false,
          value: ''
        }
      });
    };

    apirequest(API_STATIONS_EPISODE_EDIT, {body: fd}, callback);
  }

  onEpisodeMoveChange(newValue) {
    this.setState({moveep: Object.assign({...this.state.moveep}, {value: newValue}) });
  }


  onEpisodeDelete() {
    this.setState({ deleteep: {open: true}}, () => { this.episodeDeleteDialog.current.showModal(); });
  }

  onEpisodeDeleteClose() {
    this.setState({ deleteep: {open: false}}, () => { this.episodeDeleteDialog.current.close(); });
  }

  onEpisodeDeleteConfirm() {
    const updatedstate = {
      moveep: {open: false, value: -1},
      deleteep: {open: false}
    };
    const callback = () => {
      this.onEpisodeMoveSave();
      window.location = `/stations/series/${this.state.series}`;
    }

    this.setState(updatedstate, callback);
  }

  onCheckForUpdates(domEvent) {
    const newState = {...this.state.checkForUpdates};
    newState.open = true;
    newState.loading = true;

    this.setState({checkForUpdates: newState}, this.requestVideoUpdates);
  }

  onCheckForUpdatesClose(dialogRef=null) {
    const newState = {...this.state.checkForUpdates};
    newState.open = false;
    newState.loading = true;
    newState.updates = [];

    this.setState({checkForUpdates: newState}, () => {
      if(dialogRef && Object.hasOwn('current')) {
        dialogRef.current.close();
      }
    });
  }

  requestVideoUpdates() {
    const fd = new FormData();
    fd.set('id', this.state.meta.vsb_id);
    fd.set('token', getAuthData('token'));

    apirequest(API_DESTINATIONS_CHECKFORUPDATES, {body: fd}, (response) => {
      let currentState = Object.assign({}, {...this.state.checkForUpdates});
      let newCheckForUpdates;

      if(!response.changed.length) {

        newCheckForUpdates = Object.assign(currentState, {loading: false});
        this.setState({ checkForUpdates: newCheckForUpdates});

      } else {

        const currentlist = [...this.state.list];

        // Update the current state for each changed item in the list.
        response.changed.forEach((item) => {
          const updateIt = currentlist.findIndex((vid, index) => {
            return vid.ytID === item.YouTube_ID;
          });

          currentlist[updateIt].name = item.Current_Title;
        });

        const cfu = {...this.state.checkForUpdates};

        this.setState({
          list: currentlist,
          checkForUpdates: Object.assign(cfu, {updates: response.changed, loading: false})
        });
      }
    });
  }

  makeEpisodeEditModal() {
    return (
      <VideoSetEdit
        labelText="Enter a new name for this episode"
        title="Edit episode title"
        open={this.state.edittitle.open}
        value={this.state.edittitle.value}
        onInput={this.onEditEpisodeInput}
        onSubmit={this.onEpisodeEditSave}
        onClose={this.onEpisodeEditClose}
        onReset={this.onEpisodeEditReset}
        error={this.state.edittitle.error} />
    );
  }

  // TODO: Make this a utility method. Repeated in DestinationPlaylist.
  deleteVideo( video ) {
    const yes = window.confirm('Are you sure that you want to delete this video?');

    if(yes) {
      const fd = new FormData();
      fd.set('playlist', this.props.params.episodeID);
      fd.set('video', video.id);
      fd.set('order', video.position);
      fd.set('token', getAuthData('token'));

      apirequest(API_VIDEO_REMOVEFROMSET, {body: fd}, this.getVideosForBlock); 
    }
  }

  onAddVideoByURL() {
    this.setState(StaUtils.setStateAddByURL);
  }

  onAddVideoByUrlSubmit(domEvent = null, dialogRef = null) {
    let postdupecheck = false;
    if(domEvent) {
      domEvent.preventDefault();
      postdupecheck = domEvent.target.elements.hasOwnProperty('PostDupeCheck');
    }
    

    this.setState(setStateOnAddByUrlSubmit, () => {
      const dupes = checkUrlListForDupes(this.state.addByURL.value, this.state.list);
      if(dupes.length && !postdupecheck) {
        setTimeout(
          () => {
            this.setState(
              (state) => setStateFoundDupes(state, dupes),
              () => { if(dialogRef && dialogRef.hasOwnProperty('current')) dialogRef.current.close(); })
          }, 1500);
      } else {
        this.onSubmitUrlPostConfirmation(dialogRef);
      }
    });
  }

  onSubmitUrlPostConfirmation(dialogRef) {
    this.setState(StaUtils.setStateSavePostDupeCheck, () => {
      const fd = new FormData();
      fd.set('blockid', this.state.meta.vsb_id);
      fd.set('userID', getAuthData('uuid') );
      fd.set('videoids', this.state.addByURL.value);
      StaUtils.saveURLsToBlock(fd, this.state.addByURL.value, () => this.onCloseCheckForDupes(dialogRef));
    });
  }

  onRatingsChange(domEvent) {
    const value = domEvent.target.value;
    this.setState((state) => StaUtils.setStateOnRatingChange(state, value));
  }

  onRatingsSubmit(domEvent) {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('userid', getAuthData('uid'));
    fd.set('videoid', this.state.videopreview.current.video_ava_id);
    fd.set('ytid', this.state.videopreview.current.video_yt_id);
    fd.set('rating', this.state.videopreview.currentrating);
    fd.set('token', getAuthData('token'));

    apirequest(API_RATINGS_VIDEOUPDATE, {body: fd}, (data) => {
      this.setState(StaUtils.setStateAfterRating);
    });
  }

  manageSaveAlert() {
    const disappear = () => {
      this.setState(StaUtils.setStateHideSaved);
    };
    setTimeout(disappear, 3000);
  }

  makeCheckForUpdatesModal() {
    let cfum;
    if(this.state.checkForUpdates.open) {
      cfum = (
        <CheckForUpdatesProgress
            apiurl={API_DESTINATIONS_CHECKFORUPDATES_CSV}
            open={this.state.checkForUpdates.open}
            videoset={this.state.meta.vsb_id}
            videos={this.state.checkForUpdates.updates}
            loading={this.state.checkForUpdates.loading}
            onClose={this.onCheckForUpdatesClose} />
      );
    } else {
      cfum = null;
    }
    return cfum;
  }

  makeMoveEpisodeModal() {
    return (
      <EpisodeMoveModal
        {...this.state.moveep}
        station={this.state.meta.station_id}
        onChange={this.onEpisodeMoveChange}
        onSubmit={this.onEpisodeMoveSave}
        onClose={this.onEpisodeMoveClose} />
    )
  }

  makeDeleteEpisodeModal() {
    const clickHandler = (domEvent) => {
      switch(domEvent.target.value) {
        case 'Yes':
          this.onEpisodeDeleteConfirm();
          break;
        default:
          this.onEpisodeDeleteClose();
      }
    }

    const confirm = (
      <form method="dialog" onClick={clickHandler}>
        <h2>Delete <em>{this.state.title}</em> from <em>{this.state.meta.Video_Set}</em></h2>
        <h3>Are you sure?</h3>
        <p>
          <button type="button" className="btn btn--action" name="confirm" value="Yes">Yes</button>
          <button type="button" className="btn btn--action" name="confirm" value="No">No</button>
        </p>
      </form>
    );

    return (
      <dialog
        ref={this.episodeDeleteDialog}
        id="EpisodeViewDeleteModal"
        onClose={this.onEpisodeDeleteClose}>
        {confirm}
      </dialog>
    );
  }

  onGridToggleChange(domEvent) {
    /*
    `+domEvent.target.value` Converts the value to an integer
    !! converts it to a boolean
    */
    const value = !!(+domEvent.target.value);
    this.setState((state) => StaUtils.setStateGridToggle(state, value));
  }

  gridOrList() {
    const options = ['vertical', 'grid'];
    const toNum = +this.state.useGrid;
    return options[toNum];
  }

  onCheckForDuplicates(domEvent) {
    const callback = () => { setTimeout(() => { this.setState(setStateSetDupes); }, 1500); }
    this.setState(setStateOpenCheckDupes, callback);
  }

  onCloseCheckForDupes(dialogRef = null) {
    this.setState(
      StaUtils.setStateOnCloseCheckForDupes, 
      () => {
        if(dialogRef && dialogRef.hasOwnProperty('current')) {
          dialogRef.current.close();
        }
      }
    );
  }

  onCheckDupesChange(domEvent) {
    const value = domEvent.target.value;
    const isChecked = domEvent.target.checked
    this.setState((state) => setStateOnDupeExclude(state, {id: value, checked: isChecked}));
  }

  onCheckDupesErrorClose(domEvent) {
    this.setState(setStateOnDupesMessageClose)
  }

  onRemoveInstance(domEvent) {
    domEvent.preventDefault();
    let confirm = false;
    let video = false;

    const video_id = domEvent.target.elements['video'].value;
    if(video_id) {
      video = this.state.list.find((item) => item.ytID === video_id);
    }
    if(!video) return;

    confirm = window.confirm(`Are you sure you want to delete this instance of "${video.name}"?`);
    if(confirm) {
      const form = domEvent.target;
      this.setState((state) => setStateOnRemoveInstance(state, video.name), () => this.onRemoveInstanceSubmit(form));
    }
  }

  onRemoveInstanceSubmit(form) {
    const fd = new FormData(form);
    fd.set('token', getAuthData('token'));
    const signal = this.fetchCancel.signal;
    apirequest(API_VIDEO_REMOVEFROMSET, {body: fd, signal}, (data) => {
      this.setState((state) => StaUtils.setStateAfterRemoveInstance(state, data), this.getVideosForBlock)
    });
  }

  render() {
    let disableButtons;
    if(this.state.list) {
      disableButtons = this.state.list.length === 0;
    } else {
      disableButtons = true;
    }

    return (
      <div>
        {this.makeBreadcrumb()}
        <div className="should__be__acollapsible__panel">
          <EpisodeViewTitle
            title={this.state.title}
            id={this.state.list.vsb_id}
            onEpisodeEdit={this.onEpisodeEdit}
            onEpisodeMove={this.onEpisodeMove}
            onEpisodeDelete={this.onEpisodeDelete}
            isAdmin={this.isAdmin} />
          <SeasonsEpsDisplay season={this.state.meta.se.season || '—'} episode={this.state.meta.se.episode || '—'} onSave={this.onSeasonEpSave} onChange={this.onSeasonEpChange} isAdmin={this.isAdmin} />
          <div id="episode__view__ratingcrt">
            <RuntimeRatings runtime={formatTime(this.state.runtime)} rating={this.state.rating} videos={this.state.list.length} />
            <CRTStatus approved={this.state.crtstatus.approved} status={this.state.crtstatus.status} loading={this.state.crtstatus.loading} />
          </div>
          <RunDate date={this.state.meta.vsb_datetimetorun || ''} onInputChange={this.onRunDateChange} />
          <div className="episode__view__controls">
            <span>
              <button type="button" className="btn btn-sm btn--action" onClick={this.onAddVideoByURL}>Add Videos by URL</button>
              <button type="button" className="btn btn-sm btn--action" onClick={this.onAddVideoFromAva}>Add Videos from AVA</button>
            </span>

            <div>
              <button disabled={disableButtons} type='button' className='btn btn-sm btn--action playAllBtn playListBtn' onClick={() => {playAllVideos(this.state.list)} }>Play All Videos</button>
              <button disabled={disableButtons} type='button' className='btn btn-sm btn--action exportDataBtn playListBtn' onClick={() => {exportVideoData(this.state.list)}}>Export IDs</button>

              <ExportCSV actionURL={API_VIDEOSETBLOCK_INFO_CSV} videosetID={+this.state.meta.vsb_id} disabled={!this.state.list.length}/>

              <CheckForUpdates onClick={this.onCheckForUpdates} disabled={disableButtons} />
              <button disabled={disableButtons} type="button" className="btn btn--action btn-sm" onClick={this.onCheckForDuplicates}>Check for Duplicate Videos</button>

            </div>
          </div>
        </div>
        {this.makeVideos()}
        {this.makeEpisodeEditModal()}
        {this.makeAddVidsByURL()}
        {this.makeVideoList()}

        <VideoModal
          mode="videos_in_system"
          open={this.state.videopreview.open}
          onCloseHandler={this.onVideoInfoClose}
          videoData={this.state.videopreview.current}
          groups={this.state.videopreview.groups}
          onAddVideoToDestinationSubmit={this.addOneVideoToAnotherSet}
          onRatingsChange={this.onRatingsChange}
          onRatingsSubmit={this.onRatingsSubmit}
          onVideoBlockSelect={(data) => {
            this.setState((state) => StaUtils.setStateOnVideoBlockSelect(state, data));
          }}
          onCRTSave={this.onCRTsubmit}
          onMeowSave={this.onMeowSave}
          ratings={this.state.videopreview.ratings}
          onDeleteVideo={this.onDeleteFromSystem}
          selectedRating={this.state.videopreview.currentrating} />
        {this.makeCheckForUpdatesModal()}
        {this.makeMoveEpisodeModal()}
        {this.makeDeleteEpisodeModal()}

        <CheckForDuplicatesModal
          onClose={this.onCloseCheckForDupes}
          onCancel={(state) => this.setState(setStateCancelCheckDupes)}
          inSet={this.state.list}
          playlist={this.props.params.episodeID}
          onRemove={this.onRemoveInstance}
          onSubmit={this.onAddVideoByUrlSubmit}
          onChange={this.onCheckDupesChange}
          onErrorClose={this.onCheckDupesErrorClose}
          {...this.state.checkForDupes} />
      </div>
    );
  }
}

export default (props) => (
    <EpisodeView
        {...props}
        params={useParams()}
    />
);