/**
 * AmzClustersSearch.js
 */
import React from 'react';
import PropTypes from 'prop-types';

import './css/AmzClustersSearch.css';

const AmzClustersSearch = (props) => {
  return (
    <form
      method="dialog"
      id="AmzClustersSearch"
      onSubmit={props.onSubmit}
      onReset={props.onReset}
    >
      <label htmlFor="q">Filter Clusters</label>
      <div className="form__input_button_group">
        <span>
          <input
            type="search"
            id="q"
            name="q"
            defaultValue={props.value}
            onChange={props.onChange}
            autoComplete="off"
            onFocus={props.onFocus}
          />
          <button type="reset" className="btn btn--action">Reset</button>
        </span>
      </div>
    </form>
  )
}

AmzClustersSearch.defaultProps = {
  value: '',
}

AmzClustersSearch.propTypes = {
  value: PropTypes.string
};

export default AmzClustersSearch;
