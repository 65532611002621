/**
 * ChannelsRemoveFromGroup.js
 * /channel/:channelID single channel, page-level component
 */
import React from 'react';

import {
  API_CHANNEL_INFO,
  MESSAGE_NO_TAGS
} from '../../js/Configuration';

import { ChannelGroupDelete } from './ChannelGroupDelete';

import './css/ChannelsRemoveFromGroup.css';

import {
  apirequest,
  debounce,
  getAuthData,
  numberFormat
} from '../../js/Utilities';

export default class ChannelsRemoveFromGroup extends React.Component {
  constructor(props) {
    super(props);
    this.makeBadges = this.makeBadges.bind(this);
  }

  makeBadges() {
    if(!this.props.inGroups || !this.props.inGroups.length) return;
    const badges = this.props.inGroups.map((g) => {
      return (
        <ChannelGroupDelete
          key={g.group_id}
          onSubmit={this.props.onGroupDelete}
          group={g.group_id}
          channel={this.props.channel}
          name={g.group_name} />
      );
    });
    
    return badges;
  }

  render() {
    return (
      <section id="ChannelsManageGroups__remove">
        <h5>Remove Channel From Groups</h5>
        <div>
          {this.makeBadges()}
        </div>
      </section>
    );
  }
}
