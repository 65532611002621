/**
 * SeasonsEpsInput.js
 * Simple number input component
 */
import React from 'react';
import PropTypes from 'prop-types';

import './css/SeasonsEpsInput.css';

export default class SeasonsEpsInput extends React.Component {
  render() {
    return (
      <p className="seasons__ep__input">
        <label htmlFor={this.props.id} className="form__access__label">{this.props.label}</label>
        <input type="number"
          name={this.props.id}
          id={this.props.id}
          onChange={this.props.onChange}
          form={this.props.form}
          value={this.props.value}
          step="1"
          min="0"
          size="3" />       
      </p>
    );
  }
}

SeasonsEpsInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
}
