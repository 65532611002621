/**
 * ExportCSV.js
 * Check for updates button.
 */
import React from 'react';
import PropTypes from 'prop-types';

import './css/ExportCSV.css';

import LoaderSimple from '../subcomponents/LoaderSimple';

import {
  csvrequest,
  getAuthData,
  forceDownload
} from '../../js/Utilities';

import {
  API_VIDEOSETBLOCK_INFO_CSV
} from '../../js/Configuration';

export default class ExportCSV extends React.Component {
  constructor( _props ) {
    super( _props );
    this.state = {
      working: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(domEvent) {
    domEvent.preventDefault();
    
    this.setState({working: true}, () => {
      const fd = new FormData();
      fd.set('id', this.props.videosetID);
      fd.set('token', getAuthData('token'));
      csvrequest(API_VIDEOSETBLOCK_INFO_CSV, {body: fd}, (response) => {
        forceDownload(response);
        this.setState({working: false});
      });
    });
  }
  
  render() { 
    return (
      <form action={this.props.actionURL} method="POST" target="_blank" className="export__csv" onSubmit={this.onSubmit}>
        <input type="hidden" id="id" name="id" value={`${this.props.videosetID}`} onChange={()=>{}} />
        <input type="hidden" id="token" name="token" value={getAuthData('token')} onChange={()=>{}} />
        <button disabled={this.props.disabled || this.state.working} type="submit" className="btn btn-sm btn--action saveChangesBtn playListBtn">
          Export CSV
          <LoaderSimple cssClass="export__csv__loader" open={this.state.working} />
        </button>       
      </form>
    );
  }
}

ExportCSV.defaultProps = {
  disabled: false
}

ExportCSV.propTypes = {
  actionURL: PropTypes.string.isRequired,
  videosetID: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired
};
