/**
 * ChannelsList.js
 * Displays the channel search modal.
 */

import React, { createRef } from 'react';
import PropTypes from 'prop-types';

// import DialogModal from './subcomponents/DialogModal';
import LoaderSimple from './subcomponents/LoaderSimple';
import ChannelListTable from './subcomponents/ChannelListTable';
import ChannelListSearch from './subcomponents/ChannelListSearch';
import Pagination from './subcomponents/Pagination';
import SelectPerPage from './subcomponents/SelectPerPage';
import { CloseButton } from './subcomponents/CloseButton';

export default class ChannelsList extends React.Component {
  constructor(props) {
    super(props);

    this.submitChannels = this.submitChannels.bind(this);
    this.makeTableData = this.makeTableData.bind(this);
    this.getModalBody = this.getModalBody.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.onClose = this.onClose.bind(this);

    this.channelsModal = createRef();
    this.onEscapeDown = this.onEscapeDown.bind(this);

  }

  componentDidUpdate() {
    if(this.props.open && this.channelsModal.hasOwnProperty('current')) {
      this.channelsModal.current.showModal();
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onEscapeDown);
  }


  componentWillUnmount() {
    window.removeEventListener('keydown', this.onEscapeDown);
  }
  

  submitChannels(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();
    this.props.onSubmit(this.channelsModal);
  }

  onClose() {
    if(this.props.closeHandler) {
      this.props.closeHandler(this.channelsModal)
    }
  }

  onEscapeDown(domEvent) {
    const keyVal = Object.hasOwn(domEvent, 'key') ? domEvent.key : domEvent.keyCode;

    switch(keyVal) {
      case 27:
      case 'Escpae':
        this.onClose(this.channelsModal);
      default:
        return;
    }
  }

  makeTableData() {
    let data = [];
    let curSet = this.props.currentSet || [];

    if(this.props.channels.length) {
      data = this.props.channels.map((item, index) => {
        const d = {};
        
        d.channel_id = item.channel_id;
        d.channel_title = item.channel_title;
        d.video_count = item.channel_videocount;
        d.channel = item.channel_dateaddedtosystem;
        d.channel_youtubeID = item.channel_youtubechannelid;

        d.channel_selected = !!curSet.find((cs) => {
          return cs.channel_youtubechannelid === item.channel_youtubechannelid;
        });

        return d;
      });
    } 
    return data; 
  }

  changeHandler(domEvent) {
    domEvent.persist();
    if(this.props.onSelectChange) {
      this.props.onSelectChange(domEvent);
    }
  }

  getModalBody() {
    if( this.props.loading ) {
      return <LoaderSimple open={true} />;
    }
        
    return (
      <ChannelListTable
        cssClass="channel__list__table"
        headers={[null, 'Channel Title', 'Video count']}
        rows={this.makeTableData()}
        checkBoxChangeHandler={this.changeHandler} />  
    );
  }

  render() {
    return ( 
      <dialog ref={this.channelsModal} id="SearchChannelsList">
          <div className="modalHeaderBtns button__group">
            <button type="submit" className='btn btn-sm btn--action' form="add_to_videoset">Submit</button>
            <button type="button" className="btn btn-sm btn--action" onClick={this.onClose}>Close</button>
          </div>
          <h2>Select Channels</h2>
          <div className="table__list__controls">
            <SelectPerPage name="video_list_pages" cssClass="table__list__perpage" value={this.props.perPage} onChangeHandler={this.props.onPerPageChange || this.props.perPageChangeHandler} />
            <Pagination
              currentPage={+this.props.currentPage}
              totalPages={this.props.totalPages}
              onClickHandler={this.props.onPaginationClick}
              disablePrevious={this.props.currentPage <= 1}
              disableNext={this.props.channels.length < this.props.perPage} />
          </div>

          <ChannelListSearch
            apiURL="api/getchannelbatch"
            cssClass="search__channel__list__searchform"
            datalist={null}
            isRequired={false}
            onInputHandler={this.props.onChannelFilterChange}
            labelText="Filter channels"
            value={this.props.filterKeyword} />

          <form action="api/getchannelbatch" method="post" onSubmit={this.submitChannels} id="add_to_videoset">
           { this.getModalBody() }
            <div className="table__list__controls">
              <SelectPerPage name="video_list_pages" cssClass="table__list__perpage" value={this.props.perPage} onChangeHandler={this.props.onPerPageChange || this.props.perPageChangeHandler} />
              <Pagination
                currentPage={+this.props.currentPage}
                totalPages={this.props.totalPages}
                onClickHandler={this.props.onPaginationClick}
                disablePrevious={this.props.currentPage <= 1}
                disableNext={this.props.channels.length < this.props.perPage} />
            </div>

            <div className="button__group">
              <button type="submit" className='btn btn-sm btn--action'>Submit</button>
              <button type="button" className='btn btn-sm btn--action' onClick={this.onClose}>Close</button>
            </div>       
          </form>
      </dialog>
    )
  }
}

ChannelsList.defaultProps = {
  open: false,
  onPerPageChange: function(){},
  onChannelFilterChange: function(){},
};

ChannelsList.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  channels: PropTypes.array.isRequired,
  onPaginationClick: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onPerPageChange: PropTypes.func.isRequired,
  onChannelFilterChange: PropTypes.func.isRequired
};
