/**
 * ChannelsManageGroups.js
 * Panel for managing the channel-group relationship.
 */
import React from 'react';

import {
  API_CHANNEL_INFO,
  MESSAGE_NO_TAGS,
  API_CHANNEL_DELETE
} from '../../js/Configuration';

import SelectMenu from '../subcomponents/SelectMenu';
import ChannelsRemoveFromGroup from './ChannelsRemoveFromGroup';

import './css/ChannelsManageGroups.css';

import {
  apirequest,
  debounce,
  getAuthData,
  numberFormat
} from '../../js/Utilities';

export default class ChannelsManageGroups extends React.Component {
  constructor(props) {
    super(props);

    this.makeMenu = this.makeMenu.bind(this);
  }

  makeMenu() {
    if(!this.props.groups.length) return;
    let groups = [{value: '', label: 'Select Group'}];
    const grps = this.props.groups.map((item) => {
      return {
        value: item.group_id,
        label: item.group_name
      };
    });
    return groups.concat(grps);
  }
  
  render() {
    return (
        <details id="ChannelsManageGroups" className="channel__singlepg__panel" open>
          <summary>
            <h2>Manage Channel Groups</h2>
          </summary>
          <div id="ChannelsManageGroups__body">
            <form id="ChannelsManageGroups__addtogroup" onSubmit={this.props.onSubmit}>
              <input type="hidden" id="channelID" name="channelID" value={this.props.channel} />
              <SelectMenu labelText="Add Channel To Group" options={this.makeMenu()} onChangeHandler={()=>{}} name="groupID" firstEmpty={false} required />
              <button type="submit" className="btn btn--action">Save</button>
            </form>
            
            <ChannelsRemoveFromGroup channel={this.props.channel} onDelete={this.props.onGroupDelete} onGroupDelete={this.props.onGroupDelete} inGroups={this.props.inGroups} />

            <p>
              <button type="button" className="btn btn--destructive" onClick={this.props.onChannelDelete}>Delete Channel From System</button>
            </p>        
          </div>
        </details>
    );
  }
}
