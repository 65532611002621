import {
  apirequest,
  getAuthData,
} from '../js/Utilities';

import {
  tooManyURLs,
} from './DestinationsUtils';

import {
  API_CLUSTERS_GENREREMOVE,
} from '../js/Configuration';

export const amzUrl = (asin) => `https://amazon.com/dp/${asin}`

export const setStateOnSearchChange = (state, newval) => ({
  ...state,
  search: {
    value: newval,
    loading: true,
  },
});

export const setStateOnSearchReset = (state) => ({
  ...state,
  search: {
    value: '',
  },
  genres: [],
});

export const setStateOnFieldChange = (state, value) => {
  let asc = true;

  if (state.sortfield === value) {
    asc = !state.ascending;
  }

  return {
    ...state,
    sortfield: value,
    ascending: +asc,
    search: {
      ...state.search,
      loading: true,
    },
  }
}

export const setStateOnClusterTitle = (state, response) => {
  let rmeta;

  if (Object.hasOwn(response, 'id')) {
    rmeta = {
      meta: response,
    };
  } else {
    rmeta = {
      meta: {
        error: true,
      },
    };
  }
  return { ...state, ...rmeta };
}

export const setStateOnGetPage = (state, value) => {
  let pg;

  switch (value) {
    case 'last':
      pg = state.pagination.pages - 1;
      break;
    case 'next':
      pg = state.pagination.page + 1;
      break;
    case 'previous':
      pg = state.pagination.page - 1;
      break;
    case null:
    case 'first':
      pg = 1;
      break;
    default:
      pg = +value;
  }

  return {
    ...state,
    loading: true,
    pagination: {
      ...state.pagination,
      page: pg,
    },
  }
}

export const setStateOnAddSongsRequest = (state) => ({
  ...state,
  addSongs: {
    ...state.addSongs,
    open: true,
  },
});

export const setStateOnAddSongsClose = (state) => ({
  ...state,
  addSongs: {
    ...state.addSongs,
    open: false,
    data: [],
    mode: 'add',
    working: false,
    value: '',
  },
});

export const setStateOnAddSongsChange = (state, value) => ({
  ...state,
  addSongs: {
    ...state.addSongs,
    open: true,
    value,
    maxIds: tooManyURLs(value, 10),
  },
});

export const setStateOnAddSongsSubmit = (state) => ({
  ...state,
  addSongs: {
    ...state.addSongs,
    working: true,
  },
});

export const setStateOnAddSongsPostSubmit = (state, response) => ({
  ...state,
  addSongs: {
    ...state.addSongs,
    working: false,
    data: response,
    mode: 'save',
  },
});

export const setStateOnExclude = (state, asin, isChecked) => {
  const songs = [...state.addSongs.data];

  // Find which item to exclude
  const exclude = songs.findIndex((song) => asin === song.asin);

  // Change its value
  songs[exclude].include = isChecked;

  return {
    ...state,
    addSongs: {
      ...state.addSongs,
      data: songs,
    },
  }
}

export const setStateOnOrderChangeAdding = (state, asin, ordinal) => {
  const songs = [...state.addSongs.data];

  // Find which item to exclude
  const exclude = songs.findIndex((song) => asin === song.asin);

  // Change its value
  songs[exclude].ordinal = ordinal;

  return {
    ...state,
    addSongs: {
      ...state.addSongs,
      data: songs,
    },
  }
};

export const setStateOnAddSongsSave = (state) => ({
  ...state,
  addSongs: {
    ...state.addSongs,
    working: true,
  },
});

export const makeSaveRequest = (cluster, data, callback) => {
  const fd = new FormData();
  fd.set('token', getAuthData('token'));
  fd.set('uuid', getAuthData('uuid'));
  fd.set('cluster', cluster);
  fd.set('data', JSON.stringify(data));
  apirequest('api/importasin', { body: fd }, callback)
}

export const setStateAfterSave = (state) => ({
  ...state,
  loading: true,
  addSongs: {
    open: false,
    value: '',
    maxIds: false,
    working: false,
    data: [],
    mode: 'add', // Should be one of ['add','save']
  },
});

export const setStateAfterSongs = (state, response) => ({
  ...state,
  loading: false,
  songs: response.results,
  count: response.count,
  search: {
    ...state.search,
    loading: false,
  },
  mode: 'display',
});

export const setStateOnAddNew = (state, response) => ({
  ...state,
  addcluster: {
    ...state.addcluster,
    open: true,
    menu: response,
  },
});

export const setStateOnAddNewClose = (state) => ({
  ...state,
  addcluster: {
    ...state.addcluster,
    open: false,
  },
});

export const setStateOnAddNewChange = (state, field, value) => {
  const setError = (field === 'size') ? value === '0' : state.addcluster.error;

  return {
    ...state,
    addcluster: {
      ...state.addcluster,
      [field]: value,
      error: setError,
    },
  }
};

export const setStateOnAddNewSubmit = (state, field, value) => ({
  addcluster: {
    ...state.addcluster,
    [field]: value,
  },
});

export const setStateOnAddNewSubmitResponse = (state, response) => {
  const hasError = Object.hasOwn(response, 'error');
  return {
    ...state,
    addcluster: {
      ...state.addcluster,
      open: hasError,
      error: hasError ? response.error : '',
      message: hasError ? response.message : '',
    },
    genres: Array.from(response).concat([...state.genres]),
  }
}

export const setStateOnReportResponse = (state, response) => ({
  ...state,
  loading: false,
  results: response.results,
});

export const setStateOnModeChange = (state) => {
  const modes = ['reorder', 'display']

  // Find the index of the current value.
  const current = modes.findIndex((m) => m === state.mode);

  return {
    ...state,
    mode: modes[+(!current)], // Make this a boolean, invert it, convert to an integer
  }
}

export const setStateOnReorder = (state, asin, ordinal) => {
  const currentSongs = [...state.songs];

  // Find which item to exclude
  const update = currentSongs.findIndex((song) => asin === song.ASIN);

  // Change its value
  currentSongs[update].ordinal = ordinal;

  return {
    ...state,
    songs: currentSongs,
  };
}

export const setStateAddGenreOpen = (state) => ({
  ...state,
  addNew: {
    ...state.addNew,
    open: true,
  },
});

export const setStateAddGenreClose = (state) => ({
  ...state,
  addNew: {
    ...state.addNew,
    open: false,
  },
});

export const setStateAddGenreChange = (state, field, value) => ({
  ...state,
  addNew: {
    ...state.addNew,
    [field]: value,
  },
});

export const setStateAddGenrePostSubmit = (state, response) => {
  const updated = [...state.genres];
  updated.unshift(response);

  return {
    ...state,
    genres: updated,
    addNew: {
      ...state.addNew,
      open: false,
    },
  }
}

export const reSetStateOnSongTitleDupes = (state, response) => ({
  ...state,
  loading: false,
  meta: { ...state.meta, name: response.title },
  results: response.genres,
});

export const setStateOnSongTitleDupes = (state, response) => ({
  ...state,
  loading: false,
  meta: { name: response.title },
  results: response.genres,
});

export const setStateOnArtistNameDupes = (state, response) => ({
  ...state,
  loading: false,
  meta: { name: response.name },
  results: response.genres,
});

export const setStateAfterSongRemoval = (state, response) => {
  const currentsongs = [...state.songs];
  const newsongs = currentsongs.filter((s) => response.asin !== s.ASIN);
  return {
    ...state,
    songs: newsongs,
    loading: false,
  }
}

export const genreRemove = (form, responseCallback = null) => {
  const fd = new FormData(form);
  fd.set('token', getAuthData('token'));
  apirequest(API_CLUSTERS_GENREREMOVE, { body: fd }, responseCallback);
}

export const setStateAfterGenreRemoval = (state, response) => {
  const currentgenres = [...state.genres];
  const newgenres = currentgenres.filter((g) => +response.genre !== +g.id);

  return {
    ...state,
    genres: newgenres,
    loading: false,
  }
}

export const setStateAfterCatalogRequest = (state, response) => ({
  ...state,
  catalogs: response,
});

export const setStateCatalogRename = (state, selected) => ({
  ...state,
  rename: {
    ...state.rename,
    open: true,
    catalog: selected,
  },
});

export const stateCloseRename = (state) => ({
  ...state,
  rename: {
    ...state.rename,
    open: false,
    catalog: {},
    value: '',
  },
});

export const setStateOnRenameChange = (state, input) => ({
  ...state,
  rename: {
    ...state.rename,
    value: input,
  },
});

const updateCatalogName = (catalogs, newname, uuid) => {
  const index = catalogs.findIndex((cat) => cat.uuid === uuid);
  const cat = [...catalogs];
  cat[index].alias = newname;
  return cat;
}

export const setStateAfterCatalogRename = (state, response) => ({
  ...state,
  catalogs: updateCatalogName([...state.catalogs], response.alias, response.id),
  rename: {
    ...state.rename,
    error: {
      ...state.rename.error,
      hidden: response.success,
    },
    open: response.error,
  },
});

export const setStateOnCatalogHeaders = (state, response) => ({
  ...state,
  columns: new Map(Object.entries(response.columns)),
  name: response.name,
});

export const setStateOnCatalogBody = (state, response) => ({
  ...state,
  loading: false,
  ...response,
});

export const setStateOnCatalogSearch = (state, value) => ({
  ...state,
  search: {
    ...state.search,
    keyword: value,
  },
});

export const setStateResetPagination = (state) => ({
  ...state,
  pagination: {
    ...state.pagination,
    page: 1,
  },
});

export const setStateResetSearch = (state) => {
  const pg = setStateResetPagination(state);
  const srch = {
    search: {
      keyword: '',
      orderBy: '',
      ascending: 1,
    },
  }
  return Object.assign(state, pg, srch);
}

export const setStateOnEmail = (state, response) => ({
  ...state,
  import: {
    ...state.import,
    email: response.email,
  },
});

export const setStateOnImportOpen = (state) => ({
  ...state,
  import: {
    ...state.import,
    open: true,
  },
});

export const setStateOnImportChange = (state, value) => ({
  ...state,
  import: {
    ...state.import,
    url: value,
  },
})

export const setStateOnImportSubmit = (state) => ({
  ...state,
  import: {
    ...state.import,
    loading: true,
  },
});

export const setStateOnImportResponse = (state, response) => ({
  ...state,
  import: {
    ...state.import,
    loading: false,
    completed: response,
  },
});

export const setStateOnImportClose = (state) => ({
  ...state,
  import: {
    ...state.import,
    loading: false,
    open: false,
    url: '',
    completed: [],
  },
  loading: true,
});

export const filterGenres = (query, genres) => {
  if (!query) return genres;

  const regexp = new RegExp(query, 'i');

  return genres.filter((g) => regexp.test(g.name));
}
