import React from 'react';
import { Link } from 'react-router-dom';

import {
  removeAuthData,
  apirequest
} from '../js/Utilities';

import '../css/logoutPage.css';

export default class LogoutPage extends React.Component {
  componentDidMount() {
    apirequest('api/logout', {method: 'GET'}, (data) => {
      removeAuthData();
      this.setState({user: null});
    });
  }

  render() {
    return (
      <div id="content" className="logout__page">
        <div>
          <h1>You're now logged out.</h1>
          <Link to="/">Log in again</Link>
        </div>
      </div>
    );
  }
}
