/**
 * ChannelItem.js
 * Displays individual channels. Used on Channels page.
 */

import React from 'react';
import { format } from 'date-fns';

import { Link } from 'react-router-dom';

import {
  truncateString,
  numberFormat
} from '../../js/Utilities';

import { getGroupNamesFromData } from '../../actions/ChannelsUtils';

import './css/ChannelItem.css';

export default class ChannelItem extends React.Component {
  constructor( _props ) {
    super( _props );
    this.makeMenuItems = this.makeMenuItems.bind(this);
  }

  makeMenuItems(arrayOfItems=[]) {
    let menu = null;

    if(arrayOfItems.length) {
      menu = arrayOfItems.map((item, index) => {
        return <option key={index.toString()} value={item.value}>{item.label}</option>
      });
    }
    return menu;
  }

  render() {
    let title = truncateString(this.props.channelData.channel_title);
    const group_select_id = `${this.props.channelData.channel_youtubechannelid}_group_select`;

    return (
        <Link to={{ pathname: `/channels/${this.props.channelData.channel_youtubechannelid}`}} className="channel__list__item results__list__item">
          <h5 className="results__list__item__title videoResultItemHeader divTransition">{title}</h5>
          <img src={ this.props.channelData.channel_thumbnail} alt={`View ${this.props.channelData.channel_title}`} className="channel__thumb" />
          <ul>
            <li><span className="listText">Added to System:</span><span className="itemsBadge">{ format(new Date(this.props.channelData.channel_dateaddedtosystem), 'PP' ) }</span></li>
            <li><span className="listText">Date Updated in System:</span><span className="itemsBadge">{ format(new Date(this.props.channelData.channel_datelastupdated), 'PP' ) }</span></li>
            <li><span className="listText">Publication Date:</span><span className="itemsBadge">{ format(new Date(this.props.channelData.channel_dateadded), 'PP' ) }</span></li>
            <li><span className="listText">Videos In AVA:</span><span className="itemsBadge">{numberFormat( this.props.channelData.videos_in_ava )}</span></li>
            <li><span className="listText">Subscriber Count:</span><span className="itemsBadge">{numberFormat( this.props.channelData.channel_subscribercount )}</span></li>
            <li><span className="listText">Video Count:</span><span className="itemsBadge">{numberFormat( this.props.channelData.channel_videocount )}</span></li>
            <li className="nogrid"><span className="listText">Groups:</span><span className="itemsBadge textNormal listItemScrollingTextBox">{ getGroupNamesFromData(this.props.channelData) }</span></li>
            <li className="nogrid"><span className="listText">Tags:</span><span className="itemsBadge textNormal listItemScrollingTextBox">{ this.props.channelData.channel_customtags }</span></li>
          </ul>
      </Link>
    )
  }
};
