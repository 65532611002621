/*
ChannelsUtils.js

Functions that are shared between multiple views in Channels and Groups
*/
import {
  MESSAGE_ERROR_NOTACHANNEL,
  MESSAGE_ERROR_ADDCHANNELTOSYSTEM
} from '../js/Configuration';


import {
  tooManyURLs,
} from './DestinationsUtils';

export const stringToArray = ( string ) => {
  return string.split(/[,\s]/);
}

export const getGroupNamesFromData = (channelData) => {
  /* If there's no property, return an empty array */
  if(!channelData.hasOwnProperty('groupList')) return [];

  const getGroupName = (item) => item.group_name;

  let gl = [];
  if(channelData.groupList && channelData.groupList.length) {
    gl = channelData.groupList.map(getGroupName)
  }

  return gl.join(', ');
}

export const stateOnPerPageSelect = (state, value) => {
  // Calculate a new page count
  const newpagecount = Math.ceil(state.videos.total / value);
  let pg = state.videos.page;

  // If the current page value is greater than the new page count, reset the
  // new page count too. Otherwise, we'll use the existing count.
  if(state.videos.page > newpagecount) { 
    pg = newpagecount;
  }

  return { videos: {...state.videos, perPage: +value, page: pg }};
}

export const stateSetVideos = (state, response) => {
  return { videos: Object.assign({...state.videos}, {loading: false}, response)};
}

export const stateSetVideosPage = (state, value) => {
  let pg;
  switch(value) {
    case 'first':
      pg = 1;
      break;
    case 'last':
      pg = state.videos.pages;
      break;
    case 'next':
      pg = +state.videos.page + 1;
      break;
    case 'previous':
      pg = +state.videos.page - 1;
      break;
    default:
      pg = +value;
  } 
  return { videos: {...state.videos, page: pg, loading: true } }
}

export const setStateOnGetBatch = (state, data) => {
  return {
    channelList: data.channels,
    page: data.current_page,
    pageCount: data.pages,
    numResults: data.num_results,
    isLoading: false,
  }
}

export const setStateOnGroupListUpdate = (state, response) => {
  const channelID = Object.keys(response.list)[0];
  return {
    data: {
      ...state.data, 
      groupList: response.list[channelID]
    }
  }
}

export const setStateOnSortDirection = ( state ) => {
  return {
    ascending: !state.ascending
  }
}

export const setStateOnFilterClear = ( state ) => {
  return {
    filter: {
      ...state.filter,
      filterOn: 'channel_title',
      searchValue: ''
    },
    page: 1,
    pageCount: 1
  }
}

export const setStateOnFilterChange = ( state, target ) => {
  return {
    filter: {
      ...state.filter,
      [target.name]: target.value,
    },
    page: 1,
    pageCount: 1
  }
}

export const setStateOnShowAddChannel = ( state ) => {
  return {
    addChannels: {
      ...state.addChannels,
      open: true,
      channels: '',
      group: ''
    }
  }
}

export const setStateOnSubmitAddChannel = ( state ) => {
  return {
    addChannels: {
      ...state.addChannels,
      mode: 'working',
    }
  }
}

export const setStateOnCloseAddChannel = ( state ) => {
  return {
    addChannels: {
      ...state.addChannels,
      open: false,
    }
  }
}

export const setStateOnAddChannelResponse = ( state, response ) => {
  if( !response.channelsAdded ) {
    window.alert( MESSAGE_ERROR_ADDCHANNELTOSYSTEM );
    return;
  }
  const status =  new Map( state.addChannels.response );

  /*
   Creates a map of items in the form { <KEY:CHANNEL_ID>: { status: <object> }}
   where <object> contains { message: '', error: true/false, title: ''}
  */
  response.channelsAdded.forEach(( ch ) => {
    status.set(
      ch.channel_youtubechannelid,
      {
        ...ch.status,
        title: ch.channel_title
      }
    );
  });

  return {
    addChannels: {
      ...state.addChannels,
      response: status,
      mode: 'response'
    }
  }
}

export const setStateOnAddChannelChange = (state, name, value) => {
  return {
    addChannels: {
      ...state.addChannels,
      [name]: value,
      max: tooManyURLs( value )
    }
  }
}

export const setStateOnGroupsResponse = ( state, response ) => {
  // Construct options array of objects.
  const grps = response.map( (item) => {
    return {
      value: item.group_id,
      label: item.group_name
    }
  });

  return {
    addChannels: {
      ...state.addChannels,
      options: [...state.addChannels.options].concat(grps)
    }
  }
}
