import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './css/NextPrevious.css';

export default class NextPrevious extends React.Component {
  constructor(props){
    super(props);
      this.next = this.next.bind(this);
      this.prev = this.prev.bind(this);
    }

    next() {
      this.props.next(this.props);
    }

    prev() {
      this.props.prev(this.props);
    }

    render() {
      const cssClass = classNames(this.props.className, {
        'pagination__component': true
      });

      return(
        <div className={cssClass}>
          <button disabled={!this.props.hasOwnProperty('prev_page')} type="button" className="pagination__btn btn btn-light pagination__previous" onClick={this.prev}>Previous</button>
          <button disabled={this.props.result_count < this.props.per_page} type="button" className="pagination__btn btn btn-light pagination__next" onClick={this.next}>Next</button>
        </div>
      );
    }
}

NextPrevious.propTypes = {
  next: PropTypes.func,
  prev: PropTypes.func,
  per_page: PropTypes.number,
  result_count: PropTypes.number,
  prev_page: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  next_page: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
