/**
 * DestinationsIncludeInactive.js
 */

import React from 'react';
import './css/DestinationsIncludeInactive.css';

export const DestinationsIncludeInactive = (props) => {
  let dii = null;
  dii = (
    <span id="DestinationsIncludeInactive">
      <input type="checkbox" checked={props.checked} name="DestinationsIncludeInactive__toggle" id="DestinationsIncludeInactive__toggle" value="1" onChange={props.onChange} />
      <label htmlFor="DestinationsIncludeInactive__toggle">Include deleted video sets?</label>
    </span>
  )
  return dii;
}
