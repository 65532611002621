/**
 * Search.js
 * Search form for Top Channels by Destination, Top Videos by Destination
 * Author: Webinista, Inc 3/24/18.
 */
  
import React from 'react';
import DateInput from '../subcomponents/DateInput';
import SelectMenu from '../subcomponents/SelectMenu';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ReportSearch extends React.Component
{
  constructor(props) {
    super(props);
    this.makeOptions = this.makeOptions.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.defaultDate = this.defaultDate.bind(this);
  }

  submitHandler(domEvent) {
    this.props.onSubmitHandler(domEvent);
  }

  /*
    Prevents makeOptions from throwing an error 
    on re-render by preventing the re-rendering of this component.
  */
  shouldComponentUpdate(nextProps, nextState) {
    return !!nextProps.destinations;
  }

  defaultDate() {
    const theDate = new Date();
    const month = (theDate.getMonth().toString().length < 2) ? `0${theDate.getMonth() + 1}` : theDate.getMonth();
    const date = (theDate.getDate().toString().length < 2) ? `0${theDate.getDate()}` : theDate.getDate();
    return `${theDate.getFullYear()}-${month}-${date}`;
  }

  makeOptions(inputArray) {
    return inputArray.map((item, index) => {
      return {
        label: item.dName,
        value: `${item.dID}`
      };
    });
  }

  render() {
    const cssClass = classNames(
      this.props.cssClass,
      {'reports__destination__search': true}
    );

    return (
      <div>
        <form id="reports_destination_search" action={this.props.apiURL} method="POST" className={cssClass} onSubmit={this.submitHandler}>
          <input type="hidden" id="limit" name="limit" value={this.props.limit} />
          <SelectMenu
            labelText={this.props.labelText}
            name={this.props.name}
            onChange={this.props.onChangeHandler}
            options={this.makeOptions(this.props.destinations)}
            isRequired={true}
            cssClass="destination-select" />
          <DateInput name="start" labelText="Start of date range:" isRequired={false} />
          <DateInput name="end" labelText="End of date range:" isRequired={false} />
          <button type="submit" className="btn btn--action">Search</button>
        </form>
      </div>
    )
  }
}

ReportSearch.defaultProps = {
  apiURL: null,
  id: null,
  onChangeHandler: null,
  onModalClickHandler: null,
  options: [],
  value: ''
};

ReportSearch.propTypes = {
  apiURL: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  onChangeHandler: PropTypes.func,
  onSubmitHandler: PropTypes.func,
  destinations: PropTypes.arrayOf(PropTypes.shape({
    dID: PropTypes.string,
    dName: PropTypes.string
  })),
  value: PropTypes.string.isRequired
};

export default ReportSearch;
