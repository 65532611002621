/**
 * LoaderSimple.js
 * A simple loader for loading search results without a modal.
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../../css/LoaderSimple.css';

const LoaderSimple = (props) => {
  const cssClasses = classNames('LoaderSimple', props.cssClass);

  const title = props.title ? (<title>{props.title}</title>) : null;
  return (
    <div hidden={!props.open} className={cssClasses}>
      <svg width="312" height="312" viewBox="0 0 312 312" version="1.1" xmlns="http://www.w3.org/2000/svg">
        {title}
        <g id="loading-group" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(156.000000, 156.000000) rotate(45.000000) translate(-156.000000, -156.000000) translate(46.000000, 46.000000)">
          <rect id="top" x="0" y="0" width="103" height="103" />
          <rect id="left" x="0" y="117" width="103" height="103" />
          <rect id="right" x="117" y="0" width="103" height="103" />
          <rect id="bottom" x="117" y="117" width="103" height="103" />
        </g>
      </svg>
    </div>
  );
}

LoaderSimple.defaultProps = {
  open: true,
  cssClass: ''
};

LoaderSimple.propTypes = {
  open: PropTypes.bool.isRequired,
  cssClass: PropTypes.string
};

export default LoaderSimple;
