import React from 'react';
import { AmazonLogo } from '../components/subcomponents/AmazonLogo';
import YTPlayIcon from '../components/subcomponents/YTPlayIcon';
import { AppleLogo } from '../components/subcomponents/AppleLogo';

export const ProjectIcons = {
  'AmazonLogo': <AmazonLogo cssClass="project__icon" />,
  'YTPlayIcon': <YTPlayIcon cssClass="project__icon" />,
  'AppleLogo': <AppleLogo cssClass="project__icon" />
};
