import React from 'react';
import PropTypes from 'prop-types';

import '../../css/SelectMenu.css';

export default class SearchDropDown extends React.Component {
  constructor( _props ) {
    super( _props );

    this.state = {}

    this.onChange = this.onChange.bind(this);
    this.makeMenuItems = this.makeMenuItems.bind(this);
  }

  makeMenuItems(arrayOfItems=[]) {
    let menu;

    if(arrayOfItems.length) {
      menu = arrayOfItems.map((item, index) => {
        return <option key={index.toString()} value={item.value}>{item.label}</option>
      });
    } else {
      menu = null;
    }

    return menu;
  }
  
  onChange(domEvent) {  
    domEvent.persist();
    domEvent.preventDefault();
    this.props.onChange(domEvent);
  }

  render() {
    const note = this.props.note ? <div>{this.props.note}</div> : null;

    return (
      <div className="search__form__item ava-input-select">
        <label htmlFor={this.props.id}>{this.props.label}</label>
        <select id={this.props.id} name={this.props.id} onChange={this.onChange} value={this.props.value} defaultValue={this.props.defaultValue}>
          { this.makeMenuItems(this.props.options) }
        </select>
        {note}
      </div>
    )
  }
}



SearchDropDown.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any
  })),
  note: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.any
};
