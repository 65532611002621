/**
 * AddVideosField.js
 * Add videos to AVA. Used on the Videos page
 */

import React from 'react';
import PropTypes from 'prop-types';

import './css/AddVideosField.css';


export default class AddVideosField extends React.Component {
  render() {
    return (
      <form method="post" action={this.props.action} id="video_add_form" onSubmit={this.props.onSubmit}>
        <div className="form__input_button_group">
          <label htmlFor="video_ids">Add Videos:</label>
          <span>
              <input className='bigSearchInputBox' type='text' name='video_ids' id='video_ids' required autoComplete="off" />
              <button type='submit' className='btn btn-sm btn--action videosPageAddVideosBtn'>Add Videos</button>
          </span>
          <span className="form__instructions">Use valid YouTube URLs or video IDs.</span>
        </div>
      </form>
    );
  }
}

AddVideosField.propTypes = {
  action: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};
