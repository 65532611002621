/**
 * ToolsSheetLoading.js
 * 
 */

import React from 'react';
import LoaderSimple from '../subcomponents/LoaderSimple';

export const ToolsSheetLoading = () => {
  return (
    <div className="tools__importing">
      <h1>Now crunching those numbers</h1>
      <p>This could take a few minutes.</p>
      <LoaderSimple open={true} />
    </div>
  )
}

