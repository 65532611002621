import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom'

import './css/Breadcrumb.css';

export default class Breadcrumb extends React.Component {
  constructor(props) {
    super(props);
    this.makeCrumb = this.makeCrumb.bind(this);
  }

  makeCrumb(item, index) {
    let link;
    let linkState = {};

    const defaultObj = {
      pathname: null,
      state: {}
    };

    if(!item.hasOwnProperty('path') || !item.path) {
      link = <b>{item.label}</b>;
    } else {

      if( item.hasOwnProperty('state') ) {
        linkState = { state: item.state};
      }

      link = (
        <NavLink to={item.path} state={linkState} className={ ({isActive}) => isActive ? 'active' : ''} end>
          {item.label}
        </NavLink>
      );
    }

    return <li key={index.toString()}>{link}</li>;
  }

  render() {
    const links = this.props.items.map(this.makeCrumb);

    return  (
      <nav id={this.props.id} aria-label={this.props.ariaLabel}>
        <ol className={`breadcrumb__menu ${this.props.cssClass}`}>
          {links}
        </ol>
      </nav>
    )
  }
}


Breadcrumb.defaultProps = {
  items: [{
    path: PropTypes.string,
    label: PropTypes.string
  }],
  id: 'breadcrumb-navigation',
  cssClass: '',
  ariaLabel: 'Breadcrumb'
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string.isRequired,
      state: PropTypes.object
    }
  )).isRequired,
  cssClass: PropTypes.string,
  id: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired
};
