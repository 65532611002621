import React from 'react';
import logo from '../media/AVALogoREV.png';
import { Navigate } from 'react-router-dom'

import '../css/LoginPage.css';

import {
  getAuthData,
  apirequest
} from '../js/Utilities';

import {
  login,
  requestPassword,
  isCapsLocked,
  clearIsCapsLocked,
  setStatePostLogin
} from '../actions/LoginUtils';


import {
  ERROR_LOGIN_FAILED,
  ERROR_LOGIN_CAPSLOCK
} from '../js/Configuration';

export default class LoginPage extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      reqtok: '',
      error: null,
      user: {},
      capslock: false
    };

    this.abortcontroller = new AbortController();
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
    this.authenticate = this.authenticate.bind(this);
  }

  componentDidMount() {
    const signal = this.abortcontroller.signal;
    apirequest('api/requesttoken', {signal}, (response) => {
      this.setState({reqtok: response.request_token});
    });
  }

  onFocusHandler(domEvent) {
    if(this.state.error) {
      this.setState({error: null});
    }
  }

  redirectTo() {
    let redirect = '';

    const user = getAuthData();

    if(Object.hasOwn(user, 'permissions')) {
      const projectCount = Object.keys(user.permissions);

      switch(projectCount.length) {
        case 0:
          redirect = `/notassigned`;
          break;
        case 1:
          redirect = `/${user.permissions[projectCount[0]].path}`;
          break;
        default:
          redirect = `/projects`;
      }
    }
    
    if(window.location.search) {
      const params = new URLSearchParams(window.location.search);
      redirect = params.get('redirect');
    }

    return <Navigate to={redirect} />;
  }

  authenticate(domEvent) {
    domEvent.preventDefault();
    const fd = new FormData(domEvent.target);
    const callback = (response) => { 
      this.setState((state) => setStatePostLogin(state, response));
    }
    login(fd, callback);
  }

  render() {
    if(getAuthData('token')) {
      return this.redirectTo();
    }

    return(
      <form className="login" action="/ava/api/api/api/login" method="post" onSubmit={this.authenticate} onReset={requestPassword}>
        <p id="form_error" className="alert alert-danger" hidden={!this.state.error}>{ERROR_LOGIN_FAILED}</p>
        <p>
          <input type="hidden" id="tok" name="tok" value={this.state.reqtok} />
          <label htmlFor="login_user_name">User Name:</label>
          <input type="text" id="login_user_name" name="uname" required onFocus={this.onFocusHandler} autoComplete="username" />
        </p>

        <p>
          <label htmlFor="login_user_pass">Password:</label>
          <input
            onKeyDown={(domEvent) => {isCapsLocked(domEvent, this)}}
            id="login_user_pass"
            type="password"
            name="pword"
            aria-labelledby="login_user_pass_error"
            onFocus={this.onFocusHandler}
            onChange={ (domEvent) => { clearIsCapsLocked(domEvent, this)} }
            autoComplete="current-password"
            />
          <span id="login_user_pass_error" className="alert alert-warning" hidden={!this.state.capslock}>{ERROR_LOGIN_CAPSLOCK}</span>
        </p>

        <p className="login__buttons">
          <button type="submit" className="btn btn-success login-btn">Login</button>
          <button type="reset" className="btn">Forgot Password</button>
        </p>
        <img src={logo} className="login-logo" alt="logo" />
      </form>
    )
  }
}
