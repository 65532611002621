/**
 * Reviewed.js
 * Component used for Proofed, Curated, and Approved features.
 * Author: Webinista, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './css/Reviewed.css';

export default class Reviewed extends React.Component {
  constructor( _props ) {
    super( _props );
    this.onChange = this.onChange.bind(this);
    this.state = {
      checked: this.props.checked
    }
  }

  onChange(domEvent) {
    domEvent.persist();
    this.props.onChange(domEvent);  
  }
  
  render() {
    const cssClass = classNames(
      this.props.cssClass,
      {'destinations__reviewed': true}
    );
    
    const fieldName = this.props.name || this.props.id;
    
    return (
      <div className={cssClass}>
          <div>
            <label htmlFor={this.props.id}>{this.props.labelText} </label>
            <input type="checkbox"
              onChange={this.onChange}
              name={fieldName}
              id={this.props.id}
              checked={this.props.checked} />
          </div>
          <div><i>by:</i> {this.props.by}</div>
          <div><i>date:</i> {this.props.date}</div>
      </div>
    )
  }
}

Reviewed.defaultProps = {
  labelText: 'YOU FORGOT YOUR LABEL!',
  by: '\u0020'
};

Reviewed.propTypes = {
  cssClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  by: PropTypes.string,
  date: PropTypes.string,
  labelText: PropTypes.string.isRequired
};
