import React from 'react';
import PropTypes from 'prop-types';

import PaginationBar from '../subcomponents/PaginationBar';
import SelectPerPage from '../subcomponents/SelectPerPage';
import LoaderSimple from '../subcomponents/LoaderSimple';
import ToggleSwitch from '../subcomponents/ToggleSwitch';

import './css/ReportsChannelUsageResultPanel.css';

import {
  youtubeURL,
  youtubeThumbnailURL
} from '../../js/Utilities';

class ReportsChannelUsageResultPanel extends React.Component {
  constructor(props) {
    super(props);

    this.makePagingComp = this.makePagingComp.bind(this);
    this.makeToggle = this.makeToggle.bind(this);
    this.singles = this.singles.bind(this);
    this.singlerows = this.singlerows.bind(this);

    this.grouped = this.grouped.bind(this);
    this.groupedrows = this.groupedrows.bind(this);
  }

  makePagingComp() {
    let props = {};
    if(!Object.hasOwn(this.props, 'pages')) return;

    return (
      <PaginationBar
        className="reports__channel_use--pag"
        data={JSON.stringify({'destination': this.props.destination})}
        pages={+this.props.pages}
        page={this.props.page}
        onClickHandler={this.props.onPageClick}
        navSize={5} />
    );
  }

  makeToggle() {
    let toggle = null;
    let supplementalData = JSON.stringify({destination: +this.props.destination});

    if(Object.hasOwn(this.props, 'toggle')) {
      toggle = (
        <ToggleSwitch
          onChange={this.props.onToggleChange}
          data={supplementalData}
          label="Show:"
          className="reports__channel_use"
          options={['List Each Occurrence','List Videos by Frequency']}
          name="view"
          selectedIndex={this.props.toggle.selected} />
      );
    }
    return toggle;
  }

  singlerows() {
    let body = null;
    if(!Object.hasOwn(this.props, 'results') ) {
      body = <h3>No results found.</h3>;
    }

    body = this.props.results.map((item, index) => {
      return (
        <tr key={`ReportsChannelUsageResultPanel__tbl_row_${item.ytid}_${index}`}>
          <td>{item['Series or Video Set']}</td>
          <td>{item['Episode Title']}</td>
          <td>{item['Run Date']}</td>
          <td><a href={youtubeURL(item.ytid)} target="_blank">{item.video_title}</a></td>
        </tr>
      );
    });
    return body;
  }

  singles() {
    if(!Object.hasOwn(this.props, 'results')) return;
    let body;
    if(this.props.loading) {
      body = <LoaderSimple open={true} />
    } else {
      body = (
        <div id="ReportsChannelUsageResultPanel">
          <div className="l__flex__sb">
            {this.makeToggle()}
            {this.makePagingComp()}
          </div>
          <table className="ReportsChannelUsageResultPanel__tbl">
            <thead>
              <tr>
                <td>Series or Video Set</td>
                <td>Episode Title</td>
                <td>Run Date</td>
                <td>Video</td>
              </tr>
            </thead>

            <tbody>
              {this.singlerows()}
            </tbody>
          </table>
        </div>
      );
    }
    return body;
  }

  groupedrows() {
    let body = null;
    if(!this.props.hasOwnProperty('detail')) {
      body = <h3>No results found.</h3>;
    }

    body = this.props.results.map((item, index) => {
      return (
        <tr key={`ReportsChannelUsageResultPanel__tbl_row_${item.ytid}_${index}`}>
          <td>
            <a href={youtubeURL(item.ytid)} target="_blank" className="vidthumb__and__title">
              <img src={youtubeThumbnailURL(item.ytid)} className="ReportsChannelUsageResultPanel__thumb" alt="" width="75" />
              {item.video_title}
            </a>
          </td>
          <td>{item['Frequency']}</td>
        </tr>
      );
    });
    return body;
  }

  grouped() {
    if(!Object.hasOwn(this.props, 'results')) return;

    let body;
    if(this.props.loading) {
      body = <LoaderSimple open={true} />
    } else {
      body = (
        <div id="ReportsChannelUsageResultPanel">
          <div className="l__flex__sb">
            {this.makeToggle()}
            {this.makePagingComp()}
          </div>
          <table className="ReportsChannelUsageResultPanel__tbl">
            <thead>
              <tr>
                <td>Video</td>
                <td>Frequency</td>
              </tr>
            </thead>

            <tbody>
              {this.groupedrows()}
            </tbody>
          </table>
        </div>
      );
    }
    return body;
  }

  render() {
    let body = null;
    if(!!this.props.toggle.selected) {
      body = this.grouped();
    } else {
      body = this.singles();
    }

    return body;
  }
}

export default ReportsChannelUsageResultPanel;