/**
 * AmzGenreAddNew.js
 */
import React, { forwardRef } from 'react';
import DialogModal from '../subcomponents/DialogModal';
import ActionMessageDisplay from '../subcomponents/ActionMessageDisplay';
import uniqueId from 'lodash.uniqueid';
import { getAuthData } from '../../js/Utilities';
import { CloseButton } from '../subcomponents/CloseButton';

import './css/AmzGenreAddNew.css';


export const AmzGenreAddNew = forwardRef(function AmzGenreAddNew(props, ref) {
  const openModal = () => {
    if(Object.hasOwn(ref, 'current')) {
      ref.current.showModal();
    }
  }
  
  const closeModal = () => {
    if(props.onClose) {
      props.onClose(ref)
    }
    /* If we don't have a prop.onClose */
    if(Object.hasOwn(ref, 'current') && ref.current.open) {
      ref.current.close();
    }
  }

  return (
    <div>
      <div style={{'textAlign':'right'}}>
        <button type="button" onClick={openModal} className="btn btn--action">Add New Genre</button>
      </div>
      <dialog ref={ref} id="AmzGenreAddNewModal">
        <CloseButton color="#CBCBCB" onClick={closeModal} className="dialog__body__close" />
        <h2>Add New Genre</h2>

        <ActionMessageDisplay mode="failed" hidden={!props.error} message={props.message} onClose={props.onErrorClose} />
        <form id="AmzGenreAddNewForm" style={{'margin': '3rem 0'}} onSubmit={props.onSubmit}>
          <input type="hidden" id="parent" name="parent" onChange={props.onChange} value="0" />
          <p>
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" className="form-control" required value={props.name || ''} onChange={props.onChange} />
          </p>
  
          <p>
            <label htmlFor="notes">Notes</label> <span className="form-note">(optional)</span>
            <textarea id="notes" name="notes" className="form-control" value={props.notes || ''} onChange={props.onChange}></textarea>
          </p>
  
          <p className="button__group" style={{'textAlign':'right'}}>
            <button type="submit" className="btn btn--action">Save</button>
            <button type="button" className="btn" onClick={closeModal}>Cancel</button>
          </p>
        </form>
      </dialog>
    </div>
  );
});
