/**
 * ToolsAverages.js
 * Calculate and display averages for a list of videos.
 */

import React, { useParams } from 'react';
import ToggleSwitch from '../subcomponents/ToggleSwitch';
import { ToolsAddToGoogleSheet } from './ToolsAddToGoogleSheet';
import { ToolsImportAnother } from './ToolsImportAnother';
import { ToolsSpreadsheetUpdated } from './ToolsSpreadsheetUpdated';
import { ToolsSpreadsheetFailed } from './ToolsSpreadsheetFailed';
import { ToolsSheetLoading } from './ToolsSheetLoading';

import {
  API_IMPORT_SPREADSHEET,
  MESSAGE_ERROR_GENERAL
} from '../../js/Configuration';

import {
  getAuthData,
  apirequest,
  numberFormat,
  formatTime  
}  from '../../js/Utilities';

import {
  download,
  setSpreadsheetData
}  from '../../actions/ToolsUtils';

import '../subcomponents/css/fancy-radiobuttons.css';

class ToolsAverages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      calcs: {
        total: 0,
        median: 0,
        mean: 0,
        mode: [],
        min: 0,
        max: 0
      },
      mode: 'importing', // 'importing', 'imported', or 'pushed'
      timedisplay: {
        options: ['Hours','Days'],
        useDays: true // Convert to Boolean.
      },
      exporting: {
        loading: false
      }
    }

    this.fetchCancel = new AbortController();
    this.onToggleTimeDisplay = this.onToggleTimeDisplay.bind(this);
    this.ddownload = this.ddownload.bind(this);
    this.setData = this.setData.bind(this);
    this.makeAverages = this.makeAverages.bind(this);

    this.fetchCancel = new AbortController();

    this.requestModeRanges = this.requestModeRanges.bind(this);
  }

  componentDidMount() {
    this.setState({loading: true, mode: 'importing'}, this.requestModeRanges);
  }

  ddownload() {
    const dta = this.prepareData();
    download(dta, this.props.params.spreadsheetID, this.props.params.sheetName);
  }

  onToggleTimeDisplay(domEvent) {
    const td = {...this.state.timedisplay};
    td.useDays = !(+domEvent.target.value); // + converts to integer / boolean. ! inverts it.
    this.setState({timedisplay: td});
  }

  prepareData() {
    const data = [
      [`Statistics for ${this.props.params.spreadsheetID} (${this.props.params.sheetName})`],
      ['Number of Videos', +this.state.calcs.count],
      ['Total Duration', `${formatTime(this.state.calcs.total, false)} (${formatTime(this.state.calcs.total, true)})`],
      ['Average Length', formatTime(this.state.calcs.mean)],
      ['Shortest', formatTime(this.state.calcs.min)],
      ['Longest', formatTime(this.state.calcs.max)],
    ];
    return data;
  }

  setData() {
    const data = this.prepareData();

    const cb = (response) => {
      if(response.spreadsheetId) {
        this.setState({exporting: {loading: false}, mode: 'pushed'});
      } else {
        alert(MESSAGE_ERROR_GENERAL);
      }
    };
    setSpreadsheetData(data, cb, this.props.params.spreadsheetID, this.props.params.sheetName, null, 'durations');
  }

  makeAverages() {
    return (
      <div className="tools__imported">
        <h1>Statistics for {this.props.params.sheetName}</h1>
        <ul>
          <li><b>Videos in sheet:</b> {numberFormat(this.state.calcs.count)}</li>
          <li>
            <b>Total:</b> {formatTime(this.state.calcs.total, !this.state.timedisplay.useDays)}
            <ToggleSwitch onChange={this.onToggleTimeDisplay} label="Show in:" className="tools--toggle" options={this.state.timedisplay.options} name="PlaylistCalcTimeDisplay" selectedIndex={+(!this.state.timedisplay.useDays)} />
          </li>
          <li><b>Average Length:</b> {formatTime(this.state.calcs.mean)}</li>
          <li><b>Shortest:</b> {formatTime(this.state.calcs.min)}</li>
          <li><b>Longest:</b> {formatTime(this.state.calcs.max)}</li>
        </ul>
        <p className="tools__imported__controls">
          <button type="button" onClick={this.ddownload} className="btn btn--action">Download Statistics</button>
          <ToolsAddToGoogleSheet onClick={this.setData} loading={this.state.exporting.loading} />
          <ToolsImportAnother />
        </p>
      </div>
    );
  }

  requestModeRanges() {
    const signal = this.fetchCancel.signal;

    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('url', `https://docs.google.com/spreadsheets/d/${this.props.params.spreadsheetID}/`);
    fd.set('sheet', this.props.params.sheetName);

    apirequest(API_IMPORT_SPREADSHEET, {body: fd, signal}, (response) => {
      if(response.status != 200) { 
        this.setState({mode: 'failed'});
        return;
      }

      const updatestate = {
        calcs: response.results,
        mode: 'imported',
        loading: false
      }
      this.setState(updatestate);
    });
  }

  render() {
    let body;

    switch(this.state.mode) {
      case 'importing':
        body = <ToolsSheetLoading />;
        break;
      case 'failed':
        body = <ToolsSpreadsheetFailed />;
        break;
      case 'imported':
        body = this.makeAverages();
        break;
      case 'pushed':
        body = <ToolsSpreadsheetUpdated spreadsheetID={this.props.params.spreadsheetID} />;
        break;
     }
    return body;
  }
}

export default (props) => (
    <ToolsAverages
        {...props}
        params={useParams()}
    />
);
