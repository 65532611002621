/**
 * EditModal.js
 * Allows for renaming of series. Mostly the same as VideoSetEdit.
 */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CloseButton } from './CloseButton';

import '../../css/VideoSetEdit.css';
import './css/EditModal.css';

export default class EditModal extends React.Component {
  constructor( _props ) {
    super( _props );

    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onClose = this.onClose.bind(this);
    
    this.editNameDialog = createRef();
  }

  componentDidUpdate() {
    if( this.props.open ) {
      this.editNameDialog.current.showModal()
    }
  }

  onSubmit(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();
    this.props.onSubmit(domEvent);
  }
  
  onClose() {
    this.props.onClose(this.editNameDialog);
  }

  onReset(domEvent) {
    this.props.onReset(domEvent);
    this.onClose();
  }

  render() {

    const cssClass = classNames({
      'edit__modal': true,
    }, this.props.cssClass);
        
    return (
      <dialog ref={this.editNameDialog} id="edit_modal_dialog" className={cssClass}>
        <CloseButton longForm={true} title="Close" onClick={this.onClose} />
        <h2>{this.props.title}</h2>
        <form action={this.props.apiURL} method="dialog" onSubmit={this.onSubmit} onReset={this.onReset} className="edit__modal__form">
          <div>
            <label htmlFor="edit__modal__title">{this.props.labelText}</label>
            <div>
              <input type="text" onChange={this.props.onInput} name={this.props.id} id={this.props.id} value={this.props.value} required aria-describedby="edit_modal_title_error" />
              <button type="submit" className="btn btn--action">Save</button>
              <button type="reset" className='btn'>Cancel</button>
            </div>
            <p id="edit_modal_title_error" className="edit_modal__set__edit__error" hidden={!this.props.error}>{this.props.error}</p>
          </div>
        </form>
      </dialog>
    );
  }
}

EditModal.defaultProps = {
  onInput:  ()=>{},
  onSubmit: ()=>{},
  onReset:  ()=>{},
  onClose:  ()=>{},
};

EditModal.propTypes = {
  onInput: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  apiURL: PropTypes.string.isRequired,
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired
};
