import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import PropTypes from 'prop-types';

import uniqueId from 'lodash.uniqueid';


import { API_CHANNEL_USAGE } from '../../js/Configuration';

import {
  apirequest,
  csvrequest,
  getAuthData
} from '../../js/Utilities';

import { DownloadCSV } from '../subcomponents/DownloadCSV';
import ReportsChannelUsageResultPanel from './ReportsChannelUsageResultPanel';

import './css/ReportsChannelUsageResults.css';

const ReportsChannelUsageResults = (props) => {
  const abortcontroller = new AbortController();

  const [isDownloading, setDownloading] = useState(false);
  const [usageResults, setUsageResults] = useState(new Map());
  const [loading, setLoading] = useState(false);
  const [currentPanel, setCurrentPanel] = useState(null);

  /**
   props.results is an array of objects that matches the following structure.

  [{
    "destination": "62",
    "Destination or Station": "YouTube Music - Moods",
    "Frequency": 2,
    "detail": {
      "loading": true,
      "open": false,
      "page": 1,
      "perPage": 10,
      "results": [],
      "toggle": {
        "options": [
          "List Each Occurrence",
          "List by Frequency"
        ],
        "selected": 0,
        "ascending": 1
      }
    }
  }]
  **/

  const makeFormData = (args = {}) => {
    const signal = abortcontroller.signal;
    const fd = new FormData();
    fd.set('channel', args.channel);
    fd.set('destination', args.destination);
    fd.set('token', getAuthData('token'));
    fd.set('group_by', args.groupBy);
    fd.set('ascending', args.ascending);
    fd.set('page', 1);
    fd.set('perPage', 10);

    apirequest(API_CHANNEL_USAGE, {body: fd, signal}, args.callback);
  }

  const onPanelToggle = (domEvent) => {
    if(props.onPanelToggle) {
      props.onPanelToggle(domEvent)
    }
  }

  const onViewToggle = (domEvent) => {
    if(props.onViewToggle) {
      props.onViewToggle(domEvent)
    }
  }

  const makePanel = (result, index) => {
    return (
      <details open={false} onToggle={onPanelToggle} className="reports__channel_use_item" key={`${result.channel}_${index}`} data-destination-id={result.destination}>
        <summary>
          <b>{result['Destination or Station']}</b>
          <span className="reports__channel_use_item_freq">{result.Frequency}</span>
        </summary>
        <ReportsChannelUsageResultPanel {...result.detail} onToggleChange={onViewToggle} onPageClick={props.onPageClick} />
      </details>
    );
  }

  const makeResults = (results) => {
    if( !results ) {
      return null
    } else {
      return results.map(makePanel)
    }
  }

  if(props.count === null) return null;

  return (
    <div>
      <DownloadCSV count={props.count} onSubmit={props.onDownload} working={isDownloading} disabled={!Object.keys(props.csv).length} />
      <div>
        {makeResults(props.results)}
      </div>
    </div>
  );
}

ReportsChannelUsageResults.defaultProps = {
  results: {},
  destinations: [],
  cssClass: '',
  count: null
};

ReportsChannelUsageResults.propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.object),
  results: PropTypes.array,
  count: PropTypes.number,
  cssClass: PropTypes.string
};

export default ReportsChannelUsageResults;
