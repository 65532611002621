/**
 * PasswordResetShowHide.js
 * Toggle between type="password" and type="text"
 */
import React from 'react';
import './css/PasswordResetShowHide.css';

const PasswordResetShowHide = (props) => {
  const {
    id,
    type,
    onClick,
  } = props;

  return (
    <span className="PasswordResetShowHide__item">
      <input {...props} />
      <button
        data-controls={id}
        className="btn btn--action"
        type="button"
        hidden={type === 'text'}
        onClick={onClick}
      >
        Show?
      </button>
      <button
        data-controls={id}
        className="btn btn--action"
        type="button"
        hidden={type === 'password'}
        onClick={onClick}
      >
        Hide
      </button>
    </span>
  );
}

export default PasswordResetShowHide;
