import React from 'react';
import { Project } from './Login/Project';

import {
  getAuthData,
  apirequest
} from '../js/Utilities';

import {
  setStateOnProjects
} from '../actions/UserUtils';

import '../css/UserProjects.css';

export default class UserProjects extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      projects: [],
      icons: {
        
      }    
    }
  }

  render() {  
    return (
      <div className="UserNoProjects">
        <h1>No projects 😞</h1>
        <p>You haven't been assigned to any projects yet. If this sounds wrong, talk to your project lead.</p>
      </div>
    );
  }
}

