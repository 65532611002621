/**
 * DashboardCounts.js
 * Displays dashboard videos, channels, and destinations counts.
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';
import { numberFormat } from '../../js/Utilities';

class DashboardCounts extends React.Component {
  render() {
    return (
      <p className="reports__dashboard__count">
        Cataloguing <span className="reports__dashboard__count__num">{numberFormat(this.props.videos)}</span> videos
        and <span className="reports__dashboard__count__num">{numberFormat(this.props.channels)}</span> channels for 
          <span className="reports__dashboard__count__num">{numberFormat(this.props.destinations)}</span> destinations
          and <span className="reports__dashboard__count__num">{numberFormat(this.props.stations)}</span> stations.
      </p>
    );
  }
}

DashboardCounts.defaultProps = {
  videos: 0,
  destinations: 0,
  channels: 0
};

DashboardCounts.propTypes = {
  videos: PropTypes.number.isRequired,
  destinations: PropTypes.number.isRequired,
  channels: PropTypes.number.isRequired
};

export default DashboardCounts;
