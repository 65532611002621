/**
 * VideoListTable.js
 * A no-framework table component, specifically for the VideoListComponent
 * display. Used on the DestinationsPage.
 * See PropTypes definitions at the end of this file for parameters
 * TODO: See if can consolidate this with ChannelListTable.js
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { format } from 'date-fns';
import uniqueId from 'lodash.uniqueid';

import { getDuration } from '../../js/Utilities.js';

import '../../css/VideoListTable.css';

const VideoListTable = (props) => {
  const {
    cssClass,
    headers,
    id,
    rows,
    checkBoxChangeHandler,
  } = props;

  function makeHeaders() {
    let retval;

    if (headers) {
      let hdrs = [];

      hdrs = headers.map((h, idx) => <th key={idx.toString()}>{ h.name }</th>);

      retval = (
        <thead>
          <tr>
            {hdrs}
          </tr>
        </thead>
      );
    } else {
      retval = null
    }
    return retval;
  }

  function makeRows() {
    let retval;

    if (rows) {
      let rws = [];

      rws = rows.map(makeOneRow);

      retval = (
        <tbody>
          {rws}
        </tbody>
      );
    } else {
      retval = null
    }
    return retval;
  }

  function makeOneRow(obj) {
    const tds = [];
    const {
      checked,
      video_youtubeID,
    } = obj;

    tds[0] = (
      <td key={video_youtubeID}>
        <input
          name="videos_to_add"
          type="checkbox"
          id={video_youtubeID}
          value={video_youtubeID}
          checked={checked}
          onChange={checkBoxChangeHandler}
        />
      </td>
    );

    tds[1] = (
      <td key={uniqueId()} className="video__list__table_occurr">
        { obj.occurrences > 0 ? obj.occurrences : '' }
      </td>
    );
    tds[2] = (
      <td key={uniqueId()}>
        <label htmlFor={obj.video_youtubeID}>
          {obj.video_title}
        </label>
      </td>
    );

    tds[3] = <td key={uniqueId()}>{obj.video_channel}</td>;
    tds[4] = <td key={uniqueId()}>{getDuration(obj.video_duration)}</td>;
    tds[5] = <td key={uniqueId()}>{format(obj.video_pubdate * 1000, 'd MMM yyyy')}</td>;

    const tr = (
      <tr key={uniqueId()}>
        {tds}
      </tr>
    );

    return tr;
  }
  const cssClasses = classNames('table-list', cssClass);
  return (
    <table className={cssClasses} id={id}>
      {makeHeaders()}
      {makeRows()}
    </table>
  );
}

VideoListTable.propTypes = {
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  cssClass: PropTypes.string,
  headers: PropTypes.array,
  rows: PropTypes.array,
  onSortDirectionChange: PropTypes.func,
};

export default VideoListTable;
