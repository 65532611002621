/**
 * SearchResultItem.js
 * An item in the search page results.
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import Logo1021 from '../subcomponents/Logo1021';
import YTPlayIcon from '../subcomponents/YTPlayIcon';

import './css/SearchResultItem.css';

import {
  truncateString,
  numberFormat,
  formatTime
} from '../../js/Utilities';

class SearchResultItem extends React.Component {
  constructor( _props ) {
    super( _props );
    this.makeInSystemIndicator = this.makeInSystemIndicator.bind(this);
  }

  /*
  * Displays 'Add Video To System' if it's not already there, or
  * 'Video Added to System' if it is
  */
  makeInSystemIndicator() {
    let status;

    if(this.props.video_alreadyprocessed === true) {
      status = <div className="result__item__is-in-system">Video Added to System</div>;
    } else {
      status = (
        <button
          type="button"
          className="result__item__add_to_system btn"
          onClick={ this.props.addVideoHandler.bind( null, this.props.id ) }>Add to System</button>
      );
    }
    return status;
  }

  render() {
    let channelTitle = truncateString(this.props.video_channel_title, 25);

    return (
        <div className="results__list__item">
            <div className="searchListItem">
                <button type="button" className="show__video__title results__list__item__title" onClick={ this.props.playClickHandler.bind( null, this.props) } ><h5 className="videoResultItemHeader divTransition">{this.props.video_title}</h5></button>

                <button type="button" className="show__video__trigger videoThumb" onClick={ this.props.playClickHandler.bind( null, this.props ) } >
                  <img src={this.props.video_thumb} alt={`Watch ${this.props.video_title}`} />
                  <YTPlayIcon />
                </button>

                <div className="centerTxt">{ this.makeInSystemIndicator() }</div>
                {this.props.video_alreadyprocessed ? <Logo1021 className="video__in__system__icon" /> : null }
                <ul>
                  <li><span className="listText">Channel Title:</span> <span className="itemsBadge">{channelTitle}</span></li>
                  <li><span className="listText">Views:</span><span className="itemsBadge">{numberFormat(this.props.video_viewcount)}</span></li>
                  <li><span className="listText">Video Date:</span><span className="itemsBadge">{ format( this.props.video_publishdate * 1000, 'PP') }</span></li>
                  <li><span className="listText">Duration:</span><span className="itemsBadge">{formatTime(this.props.video_duration)}</span></li>
                  <li><span className="listText">Likes:</span><span className="itemsBadge">{numberFormat(this.props.video_likecount)}</span></li>
                  <li><span className="listText">Dislikes:</span><span className="itemsBadge">{numberFormat(this.props.video_dislikecount)}</span></li>
                </ul>
            </div>
        </div>
    )
  }
}

SearchResultItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  playClickHandler: PropTypes.func,
  addVideoHandler: PropTypes.func
};

export default SearchResultItem;
