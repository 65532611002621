/**
 * CheckForUpdates.js
 * Check for updates button.
 */
import React from 'react';
import PropTypes from 'prop-types';

export const CheckForUpdates = (props) => {
  return <button type="submit" {...props} onClick={props.onClick} className="btn btn--action btn-sm destinations__check_for_updates">Check for Updates</button>;
}

CheckForUpdates.propTypes = {
  onClick: PropTypes.func.isRequired,
};
