/*
 eslint "react/react/destructuring-assignment": 0
*/

/**
 * DestinationPlaylist.js
 */
import React, { createRef } from 'react';
import { useParams } from 'react-router-dom';

import {
  format,
  isValid,
} from 'date-fns';

import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';
import { CloseButton } from '../subcomponents/CloseButton';

import { DestinationsToggleView } from './DestinationsToggleView';
import DragSortList from '../DragSortList';

import Breadcrumb from '../subcomponents/Breadcrumb';
import VideosList from '../VideosList';
import VideoModal from '../subcomponents/Videos/VideoModal';
import AddVideosByURL from './AddVideosByURL';

import { CheckForUpdates } from './CheckForUpdates';
import CheckForUpdatesProgress from './CheckForUpdatesProgress';
import CheckForDuplicatesModal from './CheckForDuplicatesModal';

import RuntimeRatings from './RuntimeRatings';

import ExportCSV from './ExportCSV';
import VideoDragItem from '../subcomponents/VideoDragItem';
import Reviewed from './Reviewed';
import PlaylistMeta from './PlaylistMeta';
import CRTStatus from './CRTStatus';

import { ShuffleVideos } from './ShuffleVideos';

import LoaderSimple from '../subcomponents/LoaderSimple';
import RunDate from '../Stations/RunDate';

import ImportSpreadsheetToPlaylist from '../Import/ImportSpreadsheetToPlaylist';

import {
  API_CRTAPPROVALS_GETVIDSETSTATUS,
  API_DESTINATIONS_CHECKFORUPDATES,
  API_GROUPS_ALLGROUPS,
  API_RATINGS_ALL,
  API_RATINGS_VIDEOUPDATE,
  API_STATIONS_EPISODE_EDIT,
  API_VIDEO_ADDTOSET,
  API_VIDEO_GETBATCH,
  API_VIDEO_INFO,
  API_VIDEO_REMOVEFROMSET,
  API_VIDEOSET_ISDUPLICATE,
  API_VIDEOSETBLOCK_INFO,
  API_VIDEOSETBLOCK_INFO_CSV,
  API_VIDEOSETBLOCK_RATING,
  API_VIDEOSETBLOCK_REORDERED,
  API_VIDEOSETBLOCK_REVIEWED,
  API_VIDEOSETBLOCK_UPDATE,
  DESTINATIONS_VIDEOS_PER_PAGE,
  INTERSTITIAL_INDICATOR,
  NULL_DATE_TEXT_STATIONS,
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData,
  debounce,
  totalRunTime,
  formatTime,
  isAdmin,
  shuffle,
  expandShortYouTubeUrl,
  extractYouTubeIdFromUrl,
  getUTCNormalizedDate,
} from '../../js/Utilities';

import {
  tooManyURLs,
  exportVideoData,
  playAllVideos,
  duplicatePlaylist,
  setStateOnDateChange,
} from '../../actions/DestinationsUtils';

import {
  importSpreadSheet,
  importSpreadSheetCallback,
  setStateImportOnChange,
  setStateImportOnSubmit,
  setStateImportOnClose,
  setStateImportOnOpen,
} from '../../actions/ImportUtils';

import * as DPU from '../../actions/DestinationsPlaylistUtils';

import {
  saveURLsToBlock,
  setStateOnVideoBlockSelect,
  setStateAfterRemoveInstance,
  setStateSavePostDupeCheck,
} from '../../actions/StationsUtils';

import '../../css/destinationsPage.css';
import './css/VideoDisplay.css';

class DestinationPlaylist extends React.Component {
  constructor(_props) {
    super(_props);

    this.fetchCancel = new AbortController();

    this.state = {
      list: [], // List of videos in the set
      runTime: '00:00:00',
      videosInSetCount: 0,
      // Partly initialize this object so that we can use componentDidUpdate
      // without errors.
      currentVideoBlock: {
        Station: '',
        Video_Set: '',
        vsb_datetimetorun: '',
        vsb_tags: '',
        vsb_proofCopy: '',
        vsb_playlistURL: '',
        title: '',
        vsb_id: 0,
      },
      currentVideoData: {},
      showVideo: false,
      videoGroups: [], // Groups the channel is in
      addBySearch: {
        open: false,
        loading: true,
        currentPage: 1, // old this.state.page
        perPage: DESTINATIONS_VIDEOS_PER_PAGE,
        totalPages: 0,
        numResults: 0,
        sortBy: 'video_addeddate',
        ascending: false,
        filterKeyword: '',
        filterOn: '',
        list: [],
        selected: [], // Videos that are checked
        maxIds: false,
      },
      ratings: [], // Ratings options
      currentRating: '\u2014',
      selectedSet: {},
      checkForUpdates: {
        open: false,
        updates: [],
        loading: true,
        dupes: [],
      },
      approved: {
        isOkayed: false,
        by: '\u0020',
        date: '',
      },
      proofed: {
        isOkayed: false,
        by: '\u0020',
        date: '',
      },
      curated: {
        isOkayed: false,
        by: '\u0020',
        date: '',
      },
      crtstatus: {
        approved: false,
        loading: false,
        status: 'Not Submitted',
      },
      addByURL: {
        open: false,
        value: '',
        maxIds: false,
        working: false,
        toggle: {
          options: ['Yes', 'No'],
          selected: 1,
        },
      },
      checkForDupes: {
        loading: true,
        dupes: [],
        mode: 'adding', // Can be 'adding' or 'checking'
        working: false,
        response: {},
        message: '',
        open: false,
      },
      loading: true,
      breadcrumbs: [],
      duplicated: false,
      duplicate: {
        open: false,
        rundate: '',
      },
      deleteset: {
        open: false,
      },
      useGrid: true,
      importGSheet: {
        open: false,
        url: '',
        sheet: 'Sheet1',
        loading: false,
        prepend: 0,
      },
    };

    this.deleteSetModal = createRef();
    this.dupeModal = createRef();

    this.normalizeVideos = this.normalizeVideos.bind(this);
    this.buildVideoGrid = this.buildVideoGrid.bind(this);

    this.buildBreadcrumbs = this.buildBreadcrumbs.bind(this);

    this.deleteVideo = this.deleteVideo.bind(this);
    this.gridUpdated = this.gridUpdated.bind(this);

    this.setVideoState = this.setVideoState.bind(this);

    /* New functions that undo old Models setup. */
    this.getGroups = this.getGroups.bind(this);
    this.getVideoListBatch = this.getVideoListBatch.bind(this);
    this.getVideoListBatchSortDir = this.getVideoListBatchSortDir.bind(this);
    this.requestVideoListBatch = this.requestVideoListBatch.bind(this);
    this.getVideosForBlock = this.getVideosForBlock.bind(this);

    this.closeModal = this.closeModal.bind(this);
    this.closeVideoList = this.closeVideoList.bind(this);

    this.onShowVideos = this.onShowVideos.bind(this);

    this.playVideo = this.playVideo.bind(this);
    this.onChangePerPage = this.onChangePerPage.bind(this);
    this.onPaginationRequest = this.onPaginationRequest.bind(this);
    this.videoSearchSubmitHandler = this.videoSearchSubmitHandler.bind(this);
    this.videoSearchInputHandler = this.videoSearchInputHandler.bind(this);

    this.onVideoSelected = this.onVideoSelected.bind(this);

    this.addOneVideoToAnotherSet = this.addOneVideoToAnotherSet.bind(this);

    // Get ratings. Strictly for video info display.
    this.getRatings = this.getRatings.bind(this);
    this.ratingsChangeHandler = this.ratingsChangeHandler.bind(this);
    this.ratingsSubmitHandler = this.ratingsSubmitHandler.bind(this);

    // Gets rating for a particular video set block
    this.getVideoSetRating = this.getVideoSetRating.bind(this);

    // Gets the CRT status for a particular video set
    this.getVideoSetCRTStatus = this.getVideoSetCRTStatus.bind(this);

    // Check whether this is a duplicate playlist.
    this.getDupeInfo = this.getDupeInfo.bind(this);

    // Clears runtime, video count, rating for current video set block
    this.clearMetaData = this.clearMetaData.bind(this);

    // Updates this component's state with video set, date block values from
    // DestinationsSelect.
    this.setCurrentVideoBlock = this.setCurrentVideoBlock.bind(this);

    // Update current video block list with response from AddVideosByURL
    this.onAddVideoByUrlSubmit = this.onAddVideoByUrlSubmit.bind(this);
    this.onAddByUrlChange = this.onAddByUrlChange.bind(this);
    this.onSubmitUrlPostConfirmation = this.onSubmitUrlPostConfirmation.bind(this);

    this.videoListSortHandler = this.videoListSortHandler.bind(this);
    this.videoListSearchInChange = this.videoListSearchInChange.bind(this);

    this.onCheckForUpdates = this.onCheckForUpdates.bind(this);
    this.onCheckForUpdatesClose = this.onCheckForUpdatesClose.bind(this);
    this.requestVideoUpdates = this.requestVideoUpdates.bind(this);

    this.onApprovedProofedCuratedChange = this.onApprovedProofedCuratedChange.bind(this);
    this.onPlaylistMetaChange = this.onPlaylistMetaChange.bind(this);
    this.onPlaylistSave = this.onPlaylistSave.bind(this);
    this.onCommentsChange = this.onCommentsChange.bind(this);
    this.onCRTsubmit = this.onCRTsubmit.bind(this);
    this.onDeleteFromSystem = this.onDeleteFromSystem.bind(this);

    this.makeVideos = this.makeVideos.bind(this);
    this.makeTitle = this.makeTitle.bind(this);
    this.makeImportModalTitle = this.makeImportModalTitle.bind(this);
    this.makeDateControl = this.makeDateControl.bind(this);

    this.onSearchFilterChangeHandler = this.onSearchFilterChangeHandler.bind(this);
    this.onSearchSortChangeHandler = this.onSearchSortChangeHandler.bind(this);
    this.onSearchFilterClearHandler = this.onSearchFilterClearHandler.bind(this);
    this.onSearchDirectionChangeHandler = this.onSearchDirectionChangeHandler.bind(this);

    this.debouncedSearchFilterChangeHandler = debounce(200, this.debouncedSearchFilterChangeHandler);

    this.onShowAddByUrlModal = this.onShowAddByUrlModal.bind(this);
    this.onAddVideosByUrlClose = this.onAddVideosByUrlClose.bind(this);

    this.saveNewOrder = this.saveNewOrder.bind(this);
    this.duplicate = this.duplicate.bind(this);
    this.duplicateClose = this.duplicateClose.bind(this);
    this.duplicateDateSet = this.duplicateDateSet.bind(this);
    this.duplicateDateSetSave = this.duplicateDateSetSave.bind(this);
    this.indicateDuplicate = this.indicateDuplicate.bind(this);
    this.onDuplicateSkip = this.onDuplicateSkip.bind(this);

    this.onSetDeleteConfirm = this.onSetDeleteConfirm.bind(this);
    this.onSetDeleteClose = this.onSetDeleteClose.bind(this);
    this.onSetDelete = this.onSetDelete.bind(this);

    this.onRunDateChange = this.onRunDateChange.bind(this);
    this.onRunDateChangeSave = this.onRunDateChangeSave.bind(this);

    this.toNextPage = this.toNextPage.bind(this);
    this.makeRunDateModal = this.makeRunDateModal.bind(this);
    this.makeConfirmDeleteModal = this.makeConfirmDeleteModal.bind(this);

    this.onMeowSave = this.onMeowSave.bind(this);
    this.gridOrList = this.gridOrList.bind(this);
    this.onGridToggleChange = this.onGridToggleChange.bind(this);

    this.onPrependChange = this.onPrependChange.bind(this);
    this.onShuffle = this.onShuffle.bind(this);
    this.onCheckForDuplicates = this.onCheckForDuplicates.bind(this);
    this.onRemoveInstance = this.onRemoveInstance.bind(this);
    this.onRemoveInstanceSubmit = this.onRemoveInstanceSubmit.bind(this);
    this.onCheckDupesChange = this.onCheckDupesChange.bind(this);
    this.onCheckDupesClose = this.onCheckDupesClose.bind(this);
    this.onCheckDupesErrorClose = this.onCheckDupesErrorClose.bind(this);

    this.onImportGSheetChange = this.onImportGSheetChange.bind(this);
    this.onImportGSheetSubmit = this.onImportGSheetSubmit.bind(this);

    this.isAdmin = isAdmin(this.props.project);
  }

  componentDidMount() {
    /*
      If this property is available, we probably came here from
      DestinationsPage. So we'll update state.
    */
    const hist = this.props.history;

    if (hist && Object.hasOwn(hist, 'location')) {
      if (hist.location.state && Object.hasOwn(hist.location.state, 'is_dupe')) {
        this.getDupeInfo();
      }
    }

    this.getVideosForBlock(true);
    this.getRatings();
    this.buildBreadcrumbs();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If the ID has changed ...
    if (prevProps.params.vsbID !== this.props.params.vsbID) {
      this.getDupeInfo();
    }

    if (prevState.duplicated) {
      setTimeout(this.indicateDuplicate, 5000);
    }

    // Update the breadcrumbs if the station has changed.
    if (prevState.currentVideoBlock.Station !== this.state.currentVideoBlock.Station) {
      this.buildBreadcrumbs();
    }
  }

  onPaginationRequest(domEvent) {
    domEvent.persist();
    this.setState((state) => DPU.setStateOnPageRequest(state, domEvent.target.value), this.getVideoListBatch);
  }

  onChangePerPage(domEvent) {
    domEvent.persist();
    this.setState((state) => DPU.setStateOnChangePerPage(state, domEvent.target.value), this.getVideoListBatch);
  }

  getGroups() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    const { signal } = this.fetchCancel;

    apirequest(API_GROUPS_ALLGROUPS, { body: fd, signal }, (data) => {
      this.setState((state) => DPU.setStateGroups(state, data));
    });
  }

  getVideosForBlock(closeAfter = true) {
    const fd = new FormData();
    fd.set('id', this.props.params.vsbID);
    fd.set('token', getAuthData('token'));

    const { signal } = this.fetchCancel;

    apirequest(API_VIDEOSETBLOCK_INFO, { body: fd, signal }, (data) => {
      this.buildVideoGrid(data, closeAfter);
    });
  }

  requestVideoListBatch() {
    const fd = new FormData();
    fd.set('perpage', this.state.addBySearch.perPage);
    fd.set('page', this.state.addBySearch.currentPage);
    fd.set('sort_by', this.state.addBySearch.sortBy);
    fd.set('filter_by', this.state.addBySearch.filterKeyword);
    fd.set('filter_on', this.state.addBySearch.filterOn);
    fd.set('order', this.getVideoListBatchSortDir());
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEO_GETBATCH, { body: fd }, (data) => {
      this.setState((state) => DPU.setStatePostVideoListBatch(state, data));
    });
  }

  getVideoListBatch() {
    this.setState(DPU.setStateOnPreGetVideoListBatch, this.requestVideoListBatch);
  }

  getVideoListBatchSortDir() {
    const direction = ['descending', 'ascending'];
    const dirKey = +this.state.addBySearch.ascending;
    return direction[dirKey];
  }

  getRatings() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_RATINGS_ALL, { body: fd }, (data) => {
      this.setState((state) => DPU.setStatePostGetRatings(state, data));
    });
  }

  getVideoSetRating() {
    const fd = new FormData();
    fd.set('videosetblockid', +this.state.currentVideoBlock.vsb_id);
    fd.set('token', getAuthData('token'));

    // Make the network request here.
    apirequest(API_VIDEOSETBLOCK_RATING, { body: fd }, (data) => {
      if (data.message === 'success') {
        this.setState({ currentRating: data.rating });
      }
    });
  }

  ratingsChangeHandler(domEvent) {
    const { value } = domEvent.target;
    this.setState((state) => DPU.setStateOnRatingsChange(state, value));
  }

  ratingsSubmitHandler(domEvent) {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('userid', getAuthData('uid'));
    fd.set('videoid', this.state.currentVideoData.video_ava_id);
    fd.set('ytid', this.state.currentVideoData.video_yt_id);
    fd.set('rating', this.state.selectedRating);
    fd.set('token', getAuthData('token'));

    apirequest(API_RATINGS_VIDEOUPDATE, { body: fd }, (data) => {
      this.setState((state) => DPU.setStatePostRatingsSubmit(state, data));
    })
  }

  getDupeInfo() {
    const fd = new FormData();
    fd.set('id', this.props.params.vsbID);
    fd.set('token', getAuthData('token'));

    // Make the network request here.
    apirequest(API_VIDEOSET_ISDUPLICATE, { body: fd }, (data) => {
      this.setState((state) => DPU.setStateIsDupe(state, data));
    });
  }

  closeModal(dialogRef = null) {
    this.setState(DPU.setStateCloseVideo, () => {
      if (dialogRef && Object.hasOwn(dialogRef, 'current')) {
        dialogRef.current.close();
      }
    });
  }

  closeVideoList(dialogRef = null) {
    this.setState(DPU.setStateCloseVideoList, () => {
      if (dialogRef && Object.hasOwn(dialogRef, 'current')) {
        dialogRef.current.close()
      }
    });
  }

  saveNewOrder(ordered) {
    const fd = new FormData();
    const id = this.state.currentVideoBlock.vsb_id;
    fd.set('id', id);
    fd.set('videos', ordered.join(','));
    fd.set('token', getAuthData('token'));
    fd.set('prepend', +!this.state.addByURL.toggle.selected);

    apirequest(API_VIDEOSETBLOCK_REORDERED, { body: fd }, (data) => {
      this.setState((state) => DPU.setStatePostSaveNewOrder(state, data));
    });
  }

  addOneVideoToAnotherSet(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();

    const fd = new FormData();
    fd.set('videoid', this.state.currentVideoData.video_ava_id);
    fd.set('ytid', this.state.currentVideoData.video_yt_id);
    fd.set('setid', this.state.selectedSet.videosetid);
    fd.set('blockid', this.state.selectedSet.videoblockid);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEO_ADDTOSET, { body: fd }, (data) => {
      // TODO: Should probably handle errors too.
      this.setState(DPU.setStateCloseVideo);
    });
  }

  // Shows the video modal
  onShowVideos() {
    this.setState(DPU.setStateOnShowVids, this.getVideoListBatch);
  }

  onShowAddByUrlModal() {
    this.setState(DPU.setStateShowAddByURLModal);
  }

  onAddVideosByUrlClose(dialogRef = null) {
    this.setState(
      DPU.setStateOnAddByUrlClose,
      () => {
        if (dialogRef && Object.hasOwn(dialogRef, 'current')) {
          dialogRef.current.close()
        }
      },
    );
  }

  // videos selected from modal
  onVideoSelected(domEvent) {
    const { target } = domEvent;
    this.setState((state) => DPU.setStateOnVideoSelect(state, target));
  }

  deleteVideo(data) {
    const yes = window.confirm('Are you sure that you want to delete this video?');
    if (yes) {
      const fd = new FormData();
      fd.set('playlist', this.props.params.vsbID);
      fd.set('video', data.id);
      fd.set('order', data.position);
      fd.set('token', getAuthData('token'));
      apirequest(API_VIDEO_REMOVEFROMSET, { body: fd }, this.getVideosForBlock);
    }
  }

  videoSearchSubmitHandler(domEvent) {
    domEvent.preventDefault();
  }

  videoSearchInputHandler(domEvent) {
    domEvent.persist(); // Necessary for React to persist the event.
    const updated = { ...this.state.addBySearch, open: true, currentPage: 0 }
    this.setState({ addBySearch: updated }, this.getVideoListBatch)
  }

  onApprovedProofedCuratedChange(domEvent) {
    const fd = new FormData();
    fd.set('field', domEvent.target.name);
    fd.set('by', getAuthData('uuid'));
    fd.set('id', this.state.currentVideoBlock.vsb_id);
    fd.set('isOkayed', +domEvent.target.checked); // The + converts the true/false to an integer
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSETBLOCK_REVIEWED, { body: fd }, (data) => {
      const newState = {
        by: (data.by ? data.by.split('|')[0] : ''),
        date: data.date,
        isOkayed: data.okayed === 'y',
      }

      this.setState({
        [domEvent.target.name]: newState,
      });
    });
  }

  getVideoSetCRTStatus() {
    this.setState({ crtstatus: { loading: true } });

    const fd = new FormData();
    fd.set('videoset', this.state.currentVideoBlock.vsb_id || 0);
    fd.set('token', getAuthData('token'));

    apirequest(API_CRTAPPROVALS_GETVIDSETSTATUS, { body: fd }, (data) => {
      const crt = {
        approved: data.approved,
        status: data.status,
        loading: false,
      };

      this.setState({ crtstatus: crt });
    });
  }

  playVideo(videoId) {
    if (videoId) {
      const fd = new FormData();
      fd.set('id', videoId);
      fd.set('token', getAuthData('token'));

      apirequest(API_VIDEO_INFO, { body: fd }, (data) => {
        this.setState({ showVideo: true, currentVideoData: data });
        this.getGroups();
      });
    }
  }

  makeVideos() {
    if (this.state.loading) {
      return <LoaderSimple open className="playlist__global" />;
    }

    let grid;

    if (this.state.approved.isOkayed) {
      grid = (
        <div className="List destinations__disabled__list">
          {
        this.state.list.map((i) => (
          <VideoDragItem
            key={uniqueId(`${i.ytID}_`)}
            interstitial={i.is_interstitial}
            data={i}
            playVideo={this.playVideo}
            disabled
            deleteVideo={() => {}}
            position={i.position}
            ordinal={i.ordinal}
          />
        ))
      }
        </div>
      );
    } else {
      grid = (
        <DragSortList
          items={this.state.list}
          onReSort={(newOrder) => {
            this.setState((state) => ({
              ...state,
              list: newOrder,
            }), () => this.gridUpdated(this.state.list))
          }}
          onPlayVideo={this.playVideo}
          onDeleteVideo={this.deleteVideo}
        />
      )
    }
    return grid;
  }

  /*
    TODO: Normalize the VideoItem setup so that we don't have to worry
    about reformatting it into an acceptable version to pass to addVideosSet
    here.
  */
  gridUpdated(videos) {
    let ordered;
    if (videos.length) {
      ordered = videos.map((vid) => vid.ytID);

      // Saves the new order to the server.
      this.saveNewOrder(ordered);
    }
  }

  setCurrentVideoBlock(videoSetAndDateBlock) {
    if (!videoSetAndDateBlock) return;

    const newCurrentVideoBlock = {}

    if (Object.hasOwn(videoSetAndDateBlock, 'videosetblocks')) {
      newCurrentVideoBlock.vsb_id = videoSetAndDateBlock.videosetblocks.value;
    }

    if (Object.hasOwn(videoSetAndDateBlock, 'videosets')) {
      newCurrentVideoBlock.vsb_setID = videoSetAndDateBlock.videosets.value;
    }

    this.setState({ currentVideoBlock: newCurrentVideoBlock });
  }

  normalizeVideos(videolist) {
    return DPU.normalizeVids(videolist);
  }

  buildVideoGrid(videoBlockData, closeAfter) {
    if (!videoBlockData) return;
    const newState = (state) => DPU.setStateAfterGrid(state, videoBlockData, closeAfter);

    this.setState(newState, () => {
      this.getVideoSetRating();
      this.getVideoSetCRTStatus();
    });
  }

  buildBreadcrumbs() {
    if (this.state.breadcrumbs.length > 1) return;
    let crumbs = [];
    const data = this.state.currentVideoBlock;

    const loaf = [{
      label: 'Destinations',
      path: '/destinations',
    }];

    if (data.Station) {
      crumbs = [
        {
          path: `/destinations/videosets/${data.station_id}`,
          label: data.Station,
          state: {
            destination: data.Station,
          },
        },
        {
          path: `/destinations/rundates/${data.vsb_setID}`,
          label: data.Video_Set,
        },
        {
          label: isValid(data.vsb_datetimetorun) ? format(data.vsb_datetimetorun, 'PP') : NULL_DATE_TEXT_STATIONS,
        },
      ];
    }
    this.setState({ breadcrumbs: loaf.concat(crumbs) });
  }

  // adds video time up.
  setVideoState(videos, callBack = null) {
    const totalSeconds = totalRunTime(videos);

    this.setState({
      runTime: formatTime(totalSeconds),
      videosInSetCount: videos.length,
    }, callBack);
  }

  clearMetaData() {
    const resetCAP = {
      by: '',
      date: '',
      isOkayed: false,
    }

    this.setState({
      runTime: '00:00:00',
      videosInSetCount: 0,
      currentRating: '\u2014',
      list: [],
      videoGroups: [],
      addBySearch: { ...this.state.addBySearch, selected: [] },
      curated: resetCAP,
      approved: resetCAP,
      proofed: resetCAP,
      crtstatus: {
        approved: false,
        status: '\u2014',
      },
    });
  }

  videoListSortHandler(field = null, ascending = true) {
    const updated = {
      ...this.state.addBySearch,
      sortBy: field,
      ascending,
    };

    this.setState({ addBySearch: updated }, this.getVideoListBatch);
  }

  videoListSearchInChange(value) {
    this.setState({ addBySearch: { ...this.state.addBySearch, filterOn: value } }, this.getVideoListBatch);
  }

  onCheckForUpdates(domEvent) {
    const newState = { ...this.state.checkForUpdates, open: true, loading: true };
    this.setState({ checkForUpdates: newState }, this.requestVideoUpdates);
  }

  onCheckForUpdatesClose(dialogRef = null) {
    const newState = {
      ...this.state.checkForUpdates,
      open: false,
      loading: true,
      updates: [],
    };

    this.setState({ checkForUpdates: newState }, () => {
      if (dialogRef && Object.hasOwn(dialogRef, 'current')) {
        dialogRef.current.close();
      }
    });
  }

  requestVideoUpdates() {
    const fd = new FormData();
    fd.set('id', this.state.currentVideoBlock.vsb_id);
    fd.set('token', getAuthData('token'));

    apirequest(API_DESTINATIONS_CHECKFORUPDATES, { body: fd }, (response) => {
      const currentState = { ...this.state.checkForUpdates };

      if (!response.changed.length) {
        const updatedState = {
          ...this.state.checkForUpdates,
          loading: false,
        };
        this.setState({ checkForUpdates: updatedState });
      } else {
        const toupdate = [...this.state.list];

        response.changed.forEach((vid) => {
          const idx = toupdate.findIndex((t) => t.ytID === vid.YouTube_ID);

          let upv;
          if (vid.Current_Title) {
            upv = { ...toupdate[idx], name: vid.Current_Title };
          }

          toupdate[idx] = upv;
        });

        this.setState({
          list: toupdate,
          checkForUpdates: Object.assign(currentState, { loading: false, updates: response.changed }),
        }, (data) => { this.buildVideoGrid(data, true); });
      }
    });
  }

  onPlaylistMetaChange(domEvent) {
    const newCurrentVideoBlock = {
      ...this.state.currentVideoBlock,
      [domEvent.target.name]: domEvent.target.value,
    };
    this.setState({ currentVideoBlock: newCurrentVideoBlock });
  }

  onPlaylistSave(domEvent) {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('id', this.state.currentVideoBlock.vsb_id);
    fd.set('playlisturl', this.state.currentVideoBlock.vsb_playlistURL);
    fd.set('proofcopy', this.state.currentVideoBlock.vsb_proofCopy);
    fd.set('tags', this.state.currentVideoBlock.vsb_tags);
    fd.set('title', this.state.currentVideoBlock.title);
    fd.set('rundate', this.state.currentVideoBlock.vsb_datetimetorun);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSETBLOCK_UPDATE, { body: fd }, (data) => {
      alert(data.message);
    })
  }

  onCommentsChange(domEvent) {
    const newCurrentVideoBlock = { ...this.state.currentVideoBlock };
    newCurrentVideoBlock.vsb_tags = domEvent.target.value;
    this.setState({ currentVideoBlock: newCurrentVideoBlock });
  }

  onCRTsubmit(data) {
    if (data.status !== 'success') return;

    const vl = [...this.state.list];

    const whichVideo = vl.findIndex((item) => item.ytID === data.video_ytid);

    vl[whichVideo].crt = data.video_crt;

    this.setState({ list: vl }, this.getVideoSetCRTStatus());
  }

  onMeowSave(data) {
    if (data.status !== 'success') return;

    const vl = [...this.state.list];
    const whichVideo = vl.findIndex((item) => item.ytID === data.video_ytid);

    vl[whichVideo].meow = data.meow;
    this.setState({ list: vl });
  }

  onPrependChange(domEvent) {
    const abu = { ...this.state.addByURL };
    abu.toggle = { ...this.state.addByURL.toggle, selected: +domEvent.target.value }
    this.setState({ addByURL: abu });
  }

  // Need to pass `this` as an argument so that we can set state.
  debouncedFilterChangeHandler(domEvent, that) {
    const updated = {
      ...this.state.addBySearch,
      filterKeyword: domEvent.target.form.elements.searchValue.value,
      filterOn: domEvent.target.form.elements.filterOn.value.split('|')[0],
      currentPage: 1,
      totalPages: 1,
    };
    that.setState({ addBySearch: updated }, that.getVideoListBatch);
  }

  onSearchFilterChangeHandler(domEvent) {
    domEvent.persist(); // Necessary for React to persist the event.
    this.debouncedFilterChangeHandler(domEvent, this);
  }

  onSearchSortChangeHandler(domEvent) {
    const sort_criteria = domEvent.target.value.split('|')[0];
    const updated = {
      ...this.state.addBySearch,
      sortBy: sort_criteria,
    };
    this.setState({ addBySearch: updated }, this.getVideoListBatch);
  }

  onSearchDirectionChangeHandler() {
    this.setState(DPU.setStateOnSearchDirectionChange, this.getVideoListBatch);
  }

  onSearchFilterClearHandler() {
    this.setState(DPU.setStateOnSearchFilterClear, this.getVideoListBatch);
  }

  onAddVideoByUrlSubmit(domEvent = null, dialogRef = null) {
    let postdupecheck = false;

    if (domEvent) {
      postdupecheck = Object.hasOwn(domEvent.target.elements, 'PostDupeCheck');
    }

    this.setState(DPU.setStateOnAddByUrlSubmit, () => {
      const dupes = DPU.checkUrlListForDupes(this.state.addByURL.value, this.state.list);

      if (dupes.length && !postdupecheck) {
        setTimeout(() => {
          this.setState(
            (state) => DPU.setStateFoundDupes(state, dupes),
          )
        }, 1500);
      } else {
        this.onSubmitUrlPostConfirmation(dialogRef);
      }
    });
  }

  onSubmitUrlPostConfirmation(dialogRef = null) {
    this.setState(setStateSavePostDupeCheck, () => {
      const fd = new FormData();
      fd.set('blockid', this.state.currentVideoBlock.vsb_id);
      fd.set('userID', getAuthData('uuid'));
      fd.set('videoids', this.state.addByURL.value);
      fd.set('prepend', +!this.state.addByURL.toggle.selected);
      saveURLsToBlock(fd, this.state.addByURL.value, (data) => {
        if (!data || (Object.hasOwn(data, 'error') && data.error)) {
          window.alert(`ERROR: ${data.message}`);
          this.setState(DPU.setStateResetModal);
        } else {
          this.buildVideoGrid(data, true);
        }
      }, +!this.state.addByURL.toggle.selected);

      if (dialogRef && Object.hasOwn(dialogRef, 'current')) {
        dialogRef.current.close();
      }
    });
  }

  onAddByUrlChange(domEvent) {
    const { value } = domEvent.target;
    this.setState((state) => DPU.setStateOnAddByUrlChange(state, value));
  }

  onImportGSheetChange(domEvent) {
    const tgt = domEvent.target;
    this.setState((state) => setStateImportOnChange(state, tgt));
  }

  onImportGSheetSubmit(domEvent, dialogRef = null) {
    const cb = (response) => {
      this.setState(importSpreadSheetCallback(response, this.state), this.getVideosForBlock);
    }
    this.setState(setStateImportOnSubmit, () => {
      importSpreadSheet(this.state, cb);
      if (dialogRef && Object.hasOwn(dialogRef, 'current')) {
        dialogRef.current.close()
      }
    });
  }

  getBlockInfo() {
    if (!this.state.currentVideoBlock || this.state.currentVideoBlock === null) return null;

    let disableButtons;
    if (this.state.list) {
      disableButtons = this.state.list.length === 0;
    } else {
      disableButtons = true;
    }

    const proofedDate = isValid(this.state.proofed.date) ? format(new Date(this.state.proofed.date), 'PP') : null;
    const curatedDate = isValid(this.state.curated.date) ? format(new Date(this.state.curated.date), 'PP') : null;
    const approveDate = isValid(this.state.approved.date) ? format(new Date(this.state.approved.date), 'PP') : null;

    return (
      <div id="blockInfo">
        <div className="destinations__metadata_and_status">
          <div className="destinations__meta_controls">
            <RuntimeRatings
              runtime={this.state.runTime}
              rating={this.state.currentRating}
              videos={this.state.videosInSetCount}
            />

            <CRTStatus
              approved={this.state.crtstatus.approved}
              status={this.state.crtstatus.status}
              loading={this.state.crtstatus.loading}
            />

            <div className="destinations__meta_controls__controls">
              <button
                disabled={disableButtons}
                type="button"
                className="btn btn-sm btn--action playAllBtn playListBtn"
                onClick={() => { playAllVideos(this.state.list) }}
              >
                Play All Videos
              </button>

              <button
                disabled={disableButtons}
                type="button"
                className="btn btn-sm btn--action exportDataBtn playListBtn"
                onClick={() => { exportVideoData(this.state.list) }}
              >
                Export IDs
              </button>

              <ExportCSV
                actionURL={API_VIDEOSETBLOCK_INFO_CSV}
                videosetID={+this.state.currentVideoBlock.vsb_id}
                disabled={false}
              />

              <form className="destinations__save" id="destinations_save_playlist" name="destinations_save_playlist" method="POST" action={API_VIDEOSETBLOCK_UPDATE} onSubmit={this.onPlaylistSave} onChange={() => {}}>
                <button disabled={false} type="submit" className="btn btn-sm btn--action saveChangesBtn playListBtn">Save Playlist Changes</button>
              </form>

              <CheckForUpdates onClick={this.onCheckForUpdates} disabled={disableButtons} />
              <ShuffleVideos onClick={this.onShuffle} disabled={disableButtons} />

            </div>
          </div>

          <PlaylistMeta
            url={this.state.currentVideoBlock.vsb_playlistURL || ''}
            copy={this.state.currentVideoBlock.vsb_proofCopy || ''}
            onChange={this.onPlaylistMetaChange}
            disabled={this.state.proofed.isOkayed}
            form="destinations_save_playlist"
          />

          <Reviewed
            checked={this.state.proofed.isOkayed}
            cssClass="destinations__proofed"
            labelText="PROOFED: "
            id="proofed"
            by={this.state.proofed.by || '\u0020'}
            date={proofedDate}
            onChange={this.onApprovedProofedCuratedChange}
          />
        </div>

        <div className="destinations__comments_reviewed">
          <Reviewed
            checked={this.state.curated.isOkayed}
            cssClass="destinations__curated"
            labelText="CURATED: "
            id="curated"
            by={this.state.curated.by || '\u0020'}
            date={curatedDate}
            onChange={this.onApprovedProofedCuratedChange}
          />

          <div className="destinations__comments">
            <label htmlFor="playlist_comments">Enter Playlist Comments</label>
            <textarea
              className="commentsTxt form-control"
              type="textarea"
              name="playlist_comments"
              id="playlist_comments"
              value={this.state.currentVideoBlock.vsb_tags || ''}
              onChange={this.onCommentsChange}
            />
            <div className="destinations__add__video__controls">
              <button type="button" className="btn btn--action" onClick={(domEvent) => this.setState(setStateImportOnOpen)} disabled={this.state.approved.isOkayed}>Import Videos from Google Sheet</button>
              <button type="button" className="btn btn--action" onClick={this.onShowVideos} disabled={this.state.approved.isOkayed}>Add Videos by Search</button>
              <button type="button" className="btn btn--action" onClick={this.onShowAddByUrlModal} disabled={this.state.approved.isOkayed}>Add Videos by URL</button>
              <button type="button" className="btn btn--action" onClick={this.onCheckForDuplicates}>Check for Duplicate Videos</button>
            </div>
          </div>

          <Reviewed
            checked={this.state.approved.isOkayed}
            cssClass="destinations__approved"
            labelText="APPROVED: "
            id="approved"
            by={this.state.approved.by || '\u0020'}
            date={approveDate}
            onChange={this.onApprovedProofedCuratedChange}
          />
        </div>
      </div>
    )
  }

  makeTitle() {
    if (!this.state.currentVideoBlock) return null;
    const interstit = this.state.currentVideoBlock.contains_interstitial ? <b className="icon" title="">{INTERSTITIAL_INDICATOR}</b> : null;
    const dte = isValid(this.state.currentVideoBlock.vsb_datetimetorun) ? format(new Date(this.state.currentVideoBlock.vsb_datetimetorun), 'PP') : NULL_DATE_TEXT_STATIONS;

    let deletebutton = null;
    if (this.isAdmin) {
      deletebutton = (
        <div>
          <button type="button" className="btn btn--action" onClick={this.onSetDelete}>Delete this playlist</button>
        </div>
      );
    }

    return (
      <div className="playlist__title__bar">
        <h1 className="playlist__title">
          {this.state.currentVideoBlock.Video_Set}
          {' '}
          (
          {dte.replace(/[[|\]]/g, '')}
          )
          {interstit}
          { this.state.duplicated ? ' [copy] ' : '' }
        </h1>
        <div style={{ display: 'inline-flex', gap: '1rem' }}>
          <div>
            <button type="button" className="btn btn--action" onClick={this.duplicate}>Duplicate this playlist</button>
            <b hidden={!this.state.duplicated} className="action--alert">Playlist duplicated</b>
          </div>
          {deletebutton}
        </div>
      </div>
    );
  }

  makeImportModalTitle() {
    if (!this.state.currentVideoBlock) return null;
    const dte = isValid(this.state.currentVideoBlock.vsb_datetimetorun) ? format(new Date(this.state.currentVideoBlock.vsb_datetimetorun), 'PP') : NULL_DATE_TEXT_STATIONS;
    return `${this.state.currentVideoBlock.Video_Set} (${dte.replace(/[[|\]]/g, '')})`;
  }

  makeDateControl() {
    return (
      <RunDate date={this.state.currentVideoBlock.vsb_datetimetorun || ''} onInputChange={this.onRunDateChange} className="playlist__rundate" />
    );
  }

  indicateDuplicate() {
    this.setState({ duplicated: false });
  }

  duplicateClose() {
    this.setState({
      duplicate: {
        ...this.state.duplicate,
        open: false,
      },
    }, () => {
      if (this.dupeModal && Object.hasOwn(this.dupeModal, 'current')) {
        this.dupeModal.current.close()
      }
    });
  }

  duplicateDateSet(domEvent) {
    this.setState({
      duplicate: { ...this.state.duplicate, rundate: domEvent.target.value },
    });
  }

  toNextPage() {
    window.location = `/destinations/playlist/${this.state.currentVideoBlock.vsb_id}`;
  }

  duplicateDateSetSave(domEvent) {
    domEvent.preventDefault();
    duplicatePlaylist(this.props.params.vsbID, (data) => {
      const block = {
        ...this.state.currentVideoBlock,
        vsb_id: data,
        vsb_datetimetorun: this.state.duplicate.rundate,
      };

      this.setState(() => ({
        currentVideoBlock: block,
        duplicate: { rundate: '', open: false },
      }), () => {
        this.onRunDateChangeSave(this.toNextPage);
      });
    });
  }

  duplicate() {
    this.setState({
      duplicate: {
        ...this.state.duplicate,
        open: true,
      },
    });
  }

  onDuplicateSkip() {
    duplicatePlaylist(this.props.params.vsbID, (data) => {
      const block = {
        ...this.state.currentVideoBlock,
        vsb_id: data,
        vsb_datetimetorun: '0000-00-00',
      }

      const slices = [...this.state.breadcrumbs];

      // Mutate the array by replacing the last item.
      slices.splice(-1, 1, { label: NULL_DATE_TEXT_STATIONS });

      this.setState({
        currentVideoBlock: block,
        duplicate: { open: false },
        breadcrumbs: slices,
      }, this.toNextPage);
    });
  }

  onRunDateChange(domEvent) {
    const mmddyy = domEvent.target.value;
    this.setState((state) => setStateOnDateChange(state, mmddyy), this.onRunDateChangeSave);
  }

  onRunDateChangeSave(callback = null) {
    const fd = new FormData();
    fd.set('id', this.state.currentVideoBlock.vsb_id);
    fd.set('rundate', this.state.currentVideoBlock.vsb_datetimetorun);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSETBLOCK_UPDATE, { body: fd }, () => {
      const slices = [...this.state.breadcrumbs];

      const runtime = this.state.currentVideoBlock.vsb_datetimetorun;

      const newcrumb = {
        label: runtime && runtime !== '0000-00-00' ? format(getUTCNormalizedDate(runtime), 'PP') : NULL_DATE_TEXT_STATIONS,
      };

      // Mutate the array by replacing the last item.
      slices.splice(-1, 1, newcrumb);

      this.setState({
        breadcrumbs: slices,
      }, callback);
    });
  }

  onDeleteFromSystem() {
    // Not in use.
  }

  makeRunDateModal() {
    if (this.state.duplicate.open) {
      if (this.dupeModal.current) {
        this.dupeModal.current.showModal()
      }
    }

    return (
      <dialog ref={this.dupeModal} id="playlist__dupemod">
        <CloseButton onClick={this.duplicateClose} longForm />
        <h2>Duplicate this list</h2>
        <form action="api/videosetblockupdate" onSubmit={this.duplicateDateSetSave}>
          <h3>Add a run date?</h3>
          <p>
            <label htmlFor="destination_rundate">Set run date: </label>
            <span className="playlist__dupemod_btns">
              <input type="date" id="destination_rundate" name="destination_rundate" value={this.state.duplicate.rundate} onChange={this.duplicateDateSet} />
              <button type="submit" className="btn btn--action" disabled={!this.state.duplicate.rundate}>Save</button>
            </span>
          </p>
          <p><button type="button" className="btn btn-light" onClick={this.onDuplicateSkip}>Skip</button></p>
        </form>
      </dialog>
    );
  }

  onSetDeleteConfirm(domEvent = null) {
    if (domEvent) {
      domEvent.preventDefault();
    }

    const fd = new FormData();
    fd.set('id', this.props.params.vsbID);
    fd.set('series', -1);
    fd.set('token', getAuthData('token'));

    const callback = (response) => {
      if (response.result === 'success') {
        this.onSetDeleteClose();

        /* Not sure that we still need to set/send the station property value. */
        const station_and_set = {
          station: this.state.currentVideoBlock.station_id,
          videoset: this.state.currentVideoBlock.vsb_setID,
        };
        const params = new URLSearchParams(station_and_set);

        window.location = `/destinations/rundates/${params.get('videoset')}`;
      }
    };

    apirequest(API_STATIONS_EPISODE_EDIT, { body: fd }, callback);
  }

  onSetDeleteClose() {
    this.setState(
      { deleteset: { open: false } },
      () => this.deleteSetModal.current.close(),
    );
  }

  onSetDelete() {
    this.setState({ deleteset: { open: true } });
  }

  makeConfirmDeleteModal() {
    const title = (
      <div>
        Delete
        <em>{this.state.currentVideoBlock.Video_Set}</em>
      </div>
    );

    const clickHandler = (domEvent) => {
      switch (domEvent.target.value) {
        case 'Yes':
          this.onSetDeleteConfirm();
          break;
        default:
          this.onSetDeleteClose();
      }
    }

    if (this.state.deleteset.open) {
      if (this.deleteSetModal.current) {
        this.deleteSetModal.current.showModal();
      }
    }

    return (
      <dialog id="EpisodeViewDeleteModal" ref={this.deleteSetModal}>
        <CloseButton onClick={this.onSetDeleteClose} longForm />
        <h2>{title}</h2>
        <form method="dialog" onClick={clickHandler}>
          <h3>Are you sure?</h3>
          <p>
            <button type="button" className="btn btn--action" name="confirm" value="Yes">Yes</button>
            <button type="button" className="btn btn--action" name="confirm" value="No">No</button>
          </p>
        </form>
      </dialog>
    );
  }

  gridOrList() {
    const options = ['vertical', 'grid'];
    const toNum = +this.state.useGrid;
    return options[toNum];
  }

  onGridToggleChange(domEvent) {
    /*
    `+domEvent.target.value` Converts the value to an integer
    !! converts it to a boolean
    */
    this.setState({ useGrid: !!(+domEvent.target.value) })
  }

  onShuffle() {
    this.setState(DPU.setStateOnShuffle, () => {
      const ordered = this.state.list.map((vid) => vid.ytID)
      this.saveNewOrder(ordered)
    });
  }

  onCheckForDuplicates() {
    const callback = () => { setTimeout(() => { this.setState(DPU.setStateSetDupes); }, 1500); }
    this.setState(DPU.setStateOpenCheckDupes, callback);
  }

  onCheckDupesChange(domEvent) {
    const { value } = domEvent.target;
    const isChecked = domEvent.target.checked
    this.setState((state) => DPU.setStateOnDupeExclude(state, { id: value, checked: isChecked }));
  }

  onCheckDupesClose(dialogRef) {
    this.setState(
      DPU.setStateCloseCheckDupes,
      () => {
        if (dialogRef && Object.hasOwn(dialogRef, 'current')) {
          dialogRef.current.close();
        }
      },
    );
  }

  onRemoveInstance(domEvent) {
    domEvent.preventDefault();
    let confirm = false;
    let video = false;

    const video_id = domEvent.target.elements.video.value;
    if (video_id) {
      video = this.state.list.find((item) => item.ytID === video_id);
    }
    if (!video) return;

    // eslint-disable-next-line no-alert
    confirm = window.confirm(`Are you sure you want to delete this instance of "${video.name}"?`);
    if (confirm) {
      const form = domEvent.target;
      this.setState((state) => DPU.setStateOnRemoveInstance(state, video.name), () => this.onRemoveInstanceSubmit(form));
    }
  }

  onRemoveInstanceSubmit(form) {
    const fd = new FormData(form);
    fd.set('token', getAuthData('token'));
    const { signal } = this.fetchCancel;
    apirequest(API_VIDEO_REMOVEFROMSET, { body: fd, signal }, (data) => {
      this.setState(
        (state) => setStateAfterRemoveInstance(state, data),
        () => this.getVideosForBlock(false),
      )
    });
  }

  onCheckDupesErrorClose() {
    this.setState(DPU.setStateOnDupesMessageClose)
  }

  render() {
    const cssClass = classNames({
      video__display: true,
      'video__display--grid': this.state.useGrid,
      'video__display--listview': !this.state.useGrid,
    });

    const importModalTitle = this.makeImportModalTitle();

    const body = (
      <div id="destinations__view">
        <Breadcrumb items={this.state.breadcrumbs} />
        { this.makeTitle() }
        { this.makeDateControl() }
        { this.getBlockInfo() }

        <DestinationsToggleView
          useGrid={this.state.useGrid}
          onChange={this.onGridToggleChange}
          disabled={this.state.approved.isOkayed}
        />

        <div className={cssClass}>
          { this.makeVideos() }
        </div>

        <CheckForUpdatesProgress
          open={this.state.checkForUpdates.open}
          videoset={this.props.params.vsbID}
          videos={this.state.checkForUpdates.updates}
          loading={this.state.checkForUpdates.loading}
          onClose={this.onCheckForUpdatesClose}
        />

        <AddVideosByURL
          {...this.state.addByURL}
          block={this.state.currentVideoBlock}
          onClose={this.onAddVideosByUrlClose}
          onSubmit={this.onAddVideoByUrlSubmit}
          onChange={this.onAddByUrlChange}
          onPrependChange={this.onPrependChange}
        />

        <VideosList
          {...this.state.addBySearch}
          ascending={this.state.addBySearch.ascending}
          currentSet={this.state.list}
          videosSelectedHandler={this.onVideoSelected}
          closeHandler={this.closeVideoList}
          perPageChangeHandler={this.onChangePerPage}
          onSubmit={this.onAddVideoByUrlSubmit}
          onPaginationClick={this.onPaginationRequest}
          videoSearchSubmitHandler={this.videoSearchSubmitHandler}
          videoSearchInputHandler={this.videoSearchInputHandler}
          showPrevious={this.state.addBySearch.currentPage > 1}
          showNext={this.state.addBySearch.currentPage < this.state.addBySearch.totalPages}
          onSortDirectionChange={this.videoListSortHandler}
          onSearchSortChange={this.onSearchSortChangeHandler}
          onSearchFilterChange={this.onSearchFilterChangeHandler}
          onSearchFilterClear={this.onSearchFilterClearHandler}
          onSearchDirectionChange={this.onSearchDirectionChangeHandler}
          toggle={{ ...this.state.addByURL.toggle }}
          onPrependChange={this.onPrependChange}
        />

        <VideoModal
          mode="videos_in_system"
          open={this.state.showVideo}
          onCloseHandler={this.closeModal}
          videoData={this.state.currentVideoData}
          groups={this.state.videoGroups}
          onAddVideoToDestinationSubmit={this.addOneVideoToAnotherSet}
          onRatingsChange={this.ratingsChangeHandler}
          onRatingsSubmit={this.ratingsSubmitHandler}
          onVideoBlockSelect={(data) => {
            this.setState((state) => setStateOnVideoBlockSelect(state, data));
          }}
          onCRTSave={this.onCRTsubmit}
          onMeowSave={this.onMeowSave}
          onDeleteVideo={this.onDeleteFromSystem}
          ratings={this.state.ratings}
          selectedRating={this.state.selectedRating}
        />

        <CheckForDuplicatesModal
          open={this.state.checkForDupes.open}
          onClose={this.onCheckDupesClose}
          onCancel={() => this.setState(DPU.setStateCancelCheckDupes)}
          inSet={this.state.list}
          playlist={this.props.params.vsbID}
          onRemove={this.onRemoveInstance}
          onSubmit={this.onAddVideoByUrlSubmit}
          onChange={this.onCheckDupesChange}
          onErrorClose={this.onCheckDupesErrorClose}
          {...this.state.checkForDupes}
        />

        <ImportSpreadsheetToPlaylist
          {...this.state.currentVideoBlock}
          open={this.state.importGSheet.open}
          title={importModalTitle}
          onChange={this.onImportGSheetChange}
          onSubmit={this.onImportGSheetSubmit}
          onClose={(domEvent) => {
            domEvent.preventDefault();
            this.setState((state) => setStateImportOnClose(state));
          }}
        />

        {this.makeRunDateModal()}
        {this.makeConfirmDeleteModal()}
      </div>
    );

    return body;
  }
}

export default (props) => (
  <DestinationPlaylist
    {...props}
    params={useParams()}
  />
);
