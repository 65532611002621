/**
 * ToolsImportAnother.js
 * 
 */

import React from 'react';
import { Link } from 'react-router-dom';

export const ToolsImportAnother = () => {
  return <Link className='btn btn--action' to="/tools" key="tools">Import another sheet?</Link>
}

