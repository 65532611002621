/**
 * VideoModal.js
 * Shows data in a modal when requested.
 */
import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';

import {
  format,
  isValid,
} from 'date-fns';

import {
  apirequest,
  numberFormat,
  getAuthData,
  formatTime,
} from '../../../js/Utilities';

import DestinationsSelect from '../../Destinations/DestinationsSelect';
import RatingsMenu from '../RatingsMenu';
import CRTApprovalsMenu from './CRTApprovalsMenu';
import MeowMenu from './MeowMenu';
import { VideoNote } from './VideoNote';
import { BadgeMEOWCRT } from '../../Videos/BadgeMEOWCRT';
import { PleaseAddToAva } from '../../Videos/PleaseAddToAva';
import { CloseButton } from '../CloseButton';

import {
  VIDEO_OPTIONS,
  NULL_DATE_TEXT,
  API_VIDEO_UPDATETAGS,
  API_CRTAPPROVALS_OPTIONS,
  API_CRTAPPROVALS_SETSTATUS,
  API_VIDEO_DELETE,
  API_VIDEO_ADDNOTE,
  API_GROUPS_ALLGROUPS,
  API_RATINGS_ALL,
  API_GROUPS_ADD_CHANNEL_TO_GROUP,
  API_MEOW_OPTIONS,
  API_MEOW_SETSTATUS,
  API_RATINGS_VIDEOUPDATE,
  MESSAGE_NOT_SUBMITTED,
  MESSAGE_VIDEO_NOTE_SAVED,
  MESSAGE_VIDEO_NOTRATED,
  API_VIDEO_REMOVEFROMSET,
} from '../../../js/Configuration';

import './css/VideoModal.css';

export default class VideoModal extends React.Component {
  constructor(props) {
    super(props);

    /* Video data is passed in as a prop */
    this.state = {
      channelAdded: false,
      videoAdded: false,
      addVideoButtonDisabled: true,
      chanGroups: {
        options: [],
        selected: null,
      },
      ratings: {
        options: [],
        selected: null,
      },
      meow: {
        options: [],
        selected: null,
      },
      crt: {
        options: [],
        selected: null,
      },
      note: {
        text: '',
      },
    };

    this.destComponent = null;
    this.videoAddedToBlock = false;

    this.addChannelToGroup = this.addChannelToGroup.bind(this);
    this.addedToGroup = this.addedToGroup.bind(this);

    this.addVideoToVideoBlock = this.addVideoToVideoBlock.bind(this);
    this.deleteVideo = this.deleteVideo.bind(this);
    this.addTagsToVideo = this.addTagsToVideo.bind(this);
    this.videoAdded = this.videoAdded.bind(this);
    this.addVideo = this.addVideo.bind(this);
    this.makeVideoSets = this.makeVideoSets.bind(this);
    this.getRatingsMenu = this.getRatingsMenu.bind(this);
    this.getCrtMenu = this.getCrtMenu.bind(this);
    this.onCRTChange = this.onCRTChange.bind(this);
    this.onCRTSubmit = this.onCRTSubmit.bind(this);
    this.getCrtOptions = this.getCrtOptions.bind(this);
    this.getGroupOptions = this.getGroupOptions.bind(this);
    this.getRatings = this.getRatings.bind(this);

    this.getMeowOptions = this.getMeowOptions.bind(this);
    this.getMeowMenu = this.getMeowMenu.bind(this);
    this.onMeowChange = this.onMeowChange.bind(this);
    this.onMeowSubmit = this.onMeowSubmit.bind(this);
    this.onRatingsChange = this.onRatingsChange.bind(this);
    this.onRatingsSubmit = this.onRatingsSubmit.bind(this);

    this.getDeleteVideo = this.getDeleteVideo.bind(this);

    this.onChannelGroupChange = this.onChannelGroupChange.bind(this);

    this.onNoteChange = this.onNoteChange.bind(this);
    this.onNoteSubmit = this.onNoteSubmit.bind(this);

    this.onClose = this.onClose.bind(this);

    this.modal = createRef();
  }

  componentDidMount() {
    this.getCrtOptions();
  }

  componentDidUpdate() {
    if (this.props.open) {
      this.modal.current.showModal()
    }
  }

  getCrtOptions() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_CRTAPPROVALS_OPTIONS, { body: fd }, (data) => {
      const crtData = data.map((item, index) => ({
        value: index,
        label: item,
      }));

      this.setState({
        crt: {
          ...this.state?.crt,
          options: crtData,
        },
      }, this.getRatings);
    });
  }

  getRatings() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_RATINGS_ALL, { body: fd }, (data) => {
      const ratingsData = data.map((item, index) => ({
        value: index,
        label: item.label,
      }));
      this.setState((state) => ({
        ratings: {
          ...state.ratings,
          options: ratingsData,
        },
      }), this.getGroupOptions);
    });
  }

  getGroupOptions() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUPS_ALLGROUPS, { body: fd }, (data) => {
      this.setState({ chanGroups: { options: data, selected: null } }, this.getMeowOptions);
    });
  }

  getMeowOptions() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    apirequest(API_MEOW_OPTIONS, { body: fd }, (data) => {
      this.setState({ meow: { options: data, selected: null } });
    });
  }

  addTagsToVideo(domEvent) {
    domEvent.preventDefault();

    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEO_UPDATETAGS, { body: fd }, (data) => {
      let result;
      if (this.props.onTagsSave) {
        this.props.onTagsSave(data);
      }
    });
  }

  deleteVideo(domEvent) {
    domEvent.preventDefault();

    const blockid = domEvent.target.video_delete_value.value;
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    /**
     * Until we can standardize the API end points to spit out the same property names.
     */
    let vid;
    if (Object.hasOwn(this.props.videoData, 'video_yt_id')) {
      vid = this.props.videoData.video_yt_id;
    }

    if (Object.hasOwn(this.props.videoData, 'video_youtubeID')) {
      vid = this.props.videoData.video_youtubeID;
    }

    if (blockid === 'all') {
      const yes = window.confirm('Are you sure you want to delete this video from AVA?');

      if (yes) {
        fd.set('videoid', vid);
        apirequest(API_VIDEO_DELETE, { body: fd }, (response) => {
          if (this.props.onDeleteVideo) {
            this.props.onDeleteVideo(response, this.modal);
          }
        });
      } else {
        alert('Video not deleted.');
      }
    } else {
      const yes = window.confirm('Are you sure you want to remove this video from that playlist?');
      fd.set('video', vid);
      fd.set('playlist', blockid);

      apirequest(API_VIDEO_REMOVEFROMSET, { body: fd }, (response) => {
        if (this.props.hasOwnProperty('onDeleteFromBlock')) {
          this.props.onDeleteFromBlock(response);
        }
      });
    }
  }

  addVideoToVideoBlock(data) {
    this.setState({ addVideoButtonDisabled: false }, this.props.onVideoBlockSelect(data));
  }

  addChannelToGroup(domEvent) {
    domEvent.preventDefault();

    const channel = this.props.videoData.video_channel_yt_id;

    const fd = new FormData();
    fd.set('group', this.state.chanGroups.selected);
    fd.set('channels', channel);
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUPS_ADD_CHANNEL_TO_GROUP, { body: fd }, this.addedToGroup)
  }

  addedToGroup(data) {
    const sel = this.state.chanGroups.selected;
    const grp = this.state.chanGroups.options.find((g) => +g.group_id === +sel);

    if (data.success) {
      alert(`Added "${this.props.videoData.video_channel_title}" to "${grp.group_name}."`);
    } else {
      alert(`"${this.props.videoData.video_channel_title}" is already in "${grp.group_name}."`);
    }
  }

  showChannelPage() {
    window.open(`https://www.youtube.com/channel/${this.props.videoData.video_channel_yt_id}/videos`, '_blank');
  }

  getAddChannel() {
    if (!this.state.chanGroups.options) return;

    return (
      <form className="video__info__field video__info_changroup" onSubmit={() => {}} onChange={this.onChannelGroupChange}>
        <label htmlFor="modal_group_select">Add Channel to Group:</label>
        <PleaseAddToAva hidden={this.props.videoData.video_alreadyprocessed} />
        <span className="form__input_button_group">
          <select name="modal_group_select" id="modal_group_select" disabled={!this.props.videoData.video_alreadyprocessed}>
            <option value="">Select Group</option>
            {this.state.chanGroups.options.map((st, k) => <option key={st.group_id} value={st.group_id}>{st.group_name}</option>)}
          </select>
          <button type="submit" className="btn btn--action" onClick={this.addChannelToGroup}>Add Channel</button>
        </span>
      </form>
    )
  }

  addVideo(domEvent) {
    domEvent.preventDefault();
    this.props.onAddVideoSubmit(this.props.videoData.video_yt_id);
  }

  videoAdded() {
    const videoData = { ...this.state.videoData };
    videoData.video_alreadyprocessed = 'y';
    this.setState({ videoAdded: true, videoData });
  }

  getAddBtn() {
    let action;

    if (this.props.videoData.video_alreadyprocessed === 'y' || this.props.videoData.video_alreadyprocessed === true || this.state.videoAdded) {
      action = <b>Video Added to System</b>;
    } else {
      action = <button type="button" className="video__info__in btn btn--action" onClick={this.addVideo}>Add to System</button>
    }

    return (
      <div className="video__info__in_sys">
        {action}
      </div>
    )
  }

  onCRTChange(domEvent) {
    if (domEvent.target) {
      const chosen = +domEvent.target.value;
      this.setState({
        crt: {
          ...this.state.crt,
          selected: chosen,
        },
      });
    }
  }

  onCRTSubmit(domEvent) {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('ytid', this.props.videoData?.video_yt_id)
    fd.set('status', this.state.crt.selected);
    fd.set('user_id', getAuthData('uuid'));
    fd.set('token', getAuthData('token'));

    apirequest(API_CRTAPPROVALS_SETSTATUS, { body: fd }, (data) => {
      alert(data.message);
      this.props.onCRTSave(data);
    });
  }

  onMeowChange(domEvent) {
    let value;

    if (domEvent.target) {
      value = domEvent.target.value;
      this.setState((state) => ({
        meow: {
          ...state.meow,
          selected: value,
        },
      }));
    }
  }

  onMeowSubmit(domEvent) {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('ytid', this.props.videoData.video_yt_id)
    fd.set('status', this.state.meow.selected);
    fd.set('user_id', getAuthData('uuid'));
    fd.set('token', getAuthData('token'));

    apirequest(API_MEOW_SETSTATUS, { body: fd }, (data) => {
      window.alert(data.message);
      if (this.props.onMeowSave) {
        this.props.onMeowSave(data);
      }
    });
  }

  onNoteChange(domEvent) {
    this.setState({
      note: {
        text: domEvent.target.value,
      },
    });
  }

  onNoteSubmit(domEvent) {
    domEvent.preventDefault();
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('ytid', this.props.videoData.video_yt_id);
    fd.set('note', this.state.note.text);

    apirequest(API_VIDEO_ADDNOTE, { body: fd }, (data) => {
      if (data.error === false) {
        alert(MESSAGE_VIDEO_NOTE_SAVED);
      }
    });
  }

  onRatingsChange(domEvent) {
    const rating = this.state.ratings.options.find((rating) => domEvent.target.value == rating.value);
    this.setState((state) => ({
      ratings: {
        ...state.ratings,
        selected: rating,
      },
    }));
  }

  onRatingsSubmit(domEvent) {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('userid', getAuthData('uuid'));
    fd.set('videoid', this.props.videoData.video_yt_id);
    fd.set('rating', this.state.ratings.selected.value);
    fd.set('token', getAuthData('token'));

    apirequest(API_RATINGS_VIDEOUPDATE, { body: fd }, (data) => {
      window.alert(data.message);

      if (this.props.onRatingSave) {
        this.props.onRatingSave(data);
      }
    });
  }

  onClose() {
    const callback = (cb) => {
      if (this.props.onCloseHandler) {
        this.props.onCloseHandler(this.modal);
      }
    };

    this.setState((state) => ({
      crt: {
        ...state.crt,
        selected: 0,
      },
      ratings: {
        ...state.ratings,
        selected: null,
      },
      meow: {
        ...state.meow,
        selected: null,
      },
    }), callback);
  }

  getMeowLabel() {
    let label = null;
    if (this.props.videoData.hasOwnProperty('meow')) {
      label = this.props.videoData.meow ? this.props.videoData.meow.label : '';
    } else {
      label = MESSAGE_NOT_SUBMITTED;
    }
    return label;
  }

  onChannelGroupChange(domEvent) {
    const cg = { ...this.state.chanGroups };
    this.setState({
      chanGroups: Object.assign(cg, { selected: domEvent.target.value }),
    });
  }

  getDeleteVideo() {
    if (!this.props.isAdmin) return;

    const setblocklist = () => {
      let response = [];

      if (
        Object.hasOwn(this.props.videoData, 'dest_set_block_assignments')
        && this.props.videoData?.dest_set_block_assignments.length
      ) {
        response = this.props.videoData.dest_set_block_assignments
          .filter((vset) => vset !== null)
          .map((st, index) => {
            const rundate = st.episode_run_date ? format(new Date(st.episode_run_date), 'yyyy-MM-dd') : NULL_DATE_TEXT;

            const isStation = st.isStation ? '(station)' : '';

            return (
              <option key={index.toString()} value={st.block_id}>
                {st.destination_name}
                {' '}
                { st.vs_name }
                {' '}
                {rundate}
                {' '}
                { isStation }
              </option>
            )
          });
      }
      return response;
    };

    return (
      <form className="video__info_field video__info__field" onSubmit={this.deleteVideo}>
        <label htmlFor="video_delete_value">Delete Video from Block or System:</label>
        <span className="form__input_button_group">
          <select name="video_delete_value" id="video_delete_value">
            <option value="all">Delete Video From System</option>
            {setblocklist()}
          </select>
          <button className="btn btn--action">Delete Video</button>
        </span>
      </form>
    )
  }

  getSysContent() {
    const vid = this.props.videoData;
    const tags = vid.hasOwnProperty('video_customtags') ? vid.video_customtags.replace(/,/, '') : '';
    const vid_id = vid.video_yt_id || '';

    let pubDate;
    // Comes in as a PHP timestamp.
    if (this.props.videoData.hasOwnProperty('video_publishdate')) {
      pubDate = isValid(this.props.videoData.video_publishdate) ? format(new Date(this.props.videoData.video_publishdate * 1000), 'PP') : null;
    }

    return (
      <div>
        <DestinationsSelect
          videoBlockSelected={this.videoBlockSelected}
          onSubmit={this.props.onAddVideoToDestinationSubmit}
          onReset={() => {}}
          onVideoBlockSelect={this.addVideoToVideoBlock}
          mode="video_info"
          disabled={!vid.video_alreadyprocessed}
          showEdit={false}
        />

        { this.getRatingsMenu() }
        { this.getMeowMenu() }
        { this.getCrtMenu() }

        <form className="video__info__custom_tags video__info__field" onSubmit={this.addTagsToVideo}>
          <input type="hidden" name="videoid" value={vid_id} onChange={() => {}} />
          <label htmlFor="tags">Add/Edit Custom Video Tags:</label>
          <PleaseAddToAva hidden={this.props.videoData.video_alreadyprocessed} />
          <span className="form__input_button_group">
            <input
              type="text"
              id="tags"
              name="tags"
              defaultValue={tags}
              placeholder="Add Comma Separated Tags"
              disabled={!this.props.videoData.video_alreadyprocessed}
            />
            <button className="btn btn--action" type="submit">Update Video Tags</button>
          </span>
        </form>

        {this.getDeleteVideo()}

        <section className="video__info__field">
          <h5>Channel Info:</h5>
          <ul className="video__info__channel">
            <li>
              <b className="listText">Name:</b>
              <a rel="noopener noreferrer" target="_blank" href={`https://www.youtube.com/channel/${this.props.videoData.video_channel_yt_id}/videos`}>
                <span className="itemsBadge textLink divTransition">{ this.props.videoData.video_channel_title }</span>
              </a>
            </li>
            <li>
              <b className="listText">Date:</b>

              <span className="itemsBadge">{ pubDate }</span>
            </li>
          </ul>
          {this.getAddChannel()}

          <VideoNote onChange={this.onNoteChange} onSubmit={this.onNoteSubmit} value={this.props.videoData.video_note} disabled={!this.props.videoData.video_alreadyprocessed} />

        </section>
      </div>
    );
  }

  getRatingsMenu() {
    let val = '';

    if (this.state.ratings.selected) {
      val = this.state.ratings.selected.value;
    } else if (this.props.videoData.rating) {
      val = this.state.ratings.options.findIndex((opt) => this.props.videoData.rating.value === opt.value);
    }

    return (
      <RatingsMenu
        actionURL="api/ratings"
        options={this.state.ratings.options}
        onChangeHandler={this.onRatingsChange}
        onSubmitHandler={this.onRatingsSubmit}
        value={val}
        disabled={!this.props.videoData.video_alreadyprocessed}
      />
    );
  }

  getCrtMenu() {
    let val = '';

    if (this.props.videoData.hasOwnProperty('crt') && !this.state.crt.selected) {
      val = this.props.videoData.crt.id;
    } else if (this.state.crt.selected) {
      val = this.state.crt.selected;
    }

    return (
      <CRTApprovalsMenu
        onSubmit={this.onCRTSubmit}
        onChange={this.onCRTChange}
        options={this.state.crt.options}
        value={val}
        disabled={!this.props.videoData.video_alreadyprocessed}
      />
    );
  }

  getMeowMenu() {
    let val = '';

    if (this.props.videoData.hasOwnProperty('meow') && this.props.videoData.meow && !this.state.meow.selected) {
      val = this.props.videoData.meow.id;
    } else if (this.state.meow.selected) {
      val = this.state.meow.selected;
    }

    return (
      <MeowMenu
        onSubmit={this.onMeowSubmit}
        onChange={this.onMeowChange}
        options={this.state.meow.options}
        value={val}
        disabled={!this.props.videoData.video_alreadyprocessed}
      />
    );
  }

  makeVideoSets() {
    let video_sets = null;

    if (this.props.videoData.hasOwnProperty('dest_set_block_assignments')) {
      video_sets = this.props.videoData.dest_set_block_assignments
        .filter((vset) => vset !== null)
        .map((vset, index) => {
          if (!vset) return;
          const rundate = vset.episode_run_date ? format(new Date(vset.episode_run_date), 'yyyy-MM-dd') : NULL_DATE_TEXT;
          const eptitle = vset.episode_title ? `: ${vset.episode_title}` : '';
          return (
            <li key={index.toString()} data-videosetblock={vset.video_set_block_id} className="video__info__setname">
              <a className="video__info__vs_badge" href={`/destinations/playlist/${vset.block_id}`} target="_blank" rel="nofollow noreferrer">
                <b>
                  {vset.destination_name}
                  :
                  {' '}
                  {vset.vs_name}
                  {eptitle}
                  {' '}
                  (
                  {rundate}
                  )
                </b>
              </a>
            </li>
          );
        });
    }
    return (
      <li className="video__stats__list_vs">
        <b>Video Sets:</b>
        <div className="resizable--vert">
          <ul className="video__info__eplist">{video_sets}</ul>
        </div>
      </li>
    );
  }

  getContent() {
    if (!this.props.videoData) return null;
    let video_rating = MESSAGE_VIDEO_NOTRATED;

    if (this.props.videoData.rating && this.props.videoData.rating.hasOwnProperty('label')) {
      video_rating = this.props.videoData.rating.label;
    }

    let customtags = [];

    if (this.props.videoData.hasOwnProperty('video_customtags')) {
      customtags = this.props.videoData.video_customtags.split(',').map((tag) => tag.trim());
    }

    const videoTags = customtags
      .concat(this.props.videoData.video_tags)
      .join(', ').replace(/^, /, '');

    let pubdate = new Date();
    if (this.props.videoData.hasOwnProperty('video_publishdate')) {
      pubdate = parseFloat(this.props.videoData.video_publishdate, 10) * 1000;
    }

    let avadate = new Date();
    if (this.props.videoData.hasOwnProperty('video_addeddate')) {
      avadate = parseFloat(this.props.videoData.video_addeddate, 10) * 1000;
    }

    return (
      <dialog ref={this.modal} className="VideoModal" id={this.props.id}>
        <CloseButton onClick={this.onClose} longForm className="video__modal__close" title="Close" />
        <div className="video__info__container">
          <YouTube
            id={this.props.videoData.video_yt_id}
            videoId={this.props.videoData.video_yt_id}
            opts={VIDEO_OPTIONS}
            className="video__info__vid"
          />

          { this.getAddBtn() }

          <section className="video__stats video__info__field">
            <h5>Video Stats:</h5>
            <ul className="list__meta__data video__stats__list">
              <li>
                <b>Channel Name:</b>
                <span>{ this.props.videoData.video_channel_title }</span>
              </li>
              <li>
                <b>Publish Date:</b>
                <span>{ format(pubdate, 'PP') }</span>
              </li>
              <li>
                <b>Added to AVA:</b>
                <span>{ format(avadate, 'PP') }</span>
              </li>
              <li>
                <b>Duration:</b>
                <span>{ formatTime(this.props.videoData.video_duration)}</span>
              </li>
              <li>
                <b>Views:</b>
                <span>{numberFormat(this.props.videoData.video_viewcount)}</span>
              </li>
              <li>
                <b>Likes:</b>
                <span>{numberFormat(this.props.videoData.video_likecount)}</span>
              </li>
              <li>
                <b>Dislikes:</b>
                <span>{numberFormat(this.props.videoData.video_dislikecount)}</span>
              </li>
              <li>
                <b>Favorites:</b>
                <span>{numberFormat(this.props.videoData.video_favoriteCount)}</span>
              </li>
              <li className="video__stats__list_vt">
                <b>Video Tags:</b>
                <div className="resizable--vert">
                  <div>{ videoTags }</div>
                </div>
              </li>

              { this.makeVideoSets() }

              <li>
                <b>Video Rating:</b>
                <span>{video_rating}</span>
              </li>
              <li>
                <abbr>MEOW:</abbr>
                <span>
                  <BadgeMEOWCRT mode="meow" {...this.props.videoData.meow} fullSize />
                </span>
              </li>

              <li>
                <abbr title="Content Review Team">CRT:</abbr>
                <span>
                  <BadgeMEOWCRT mode="crt" {...this.props.videoData.crt} fullSize />
                </span>
              </li>
            </ul>
          </section>

          <section className="video__info__description video__info__field">
            <h5>Video Description:</h5>
            <div className="description" contentEditable="true" dangerouslySetInnerHTML={{ __html: this.props.videoData.video_description }} />
          </section>

          { this.getSysContent() }
        </div>
        <CloseButton onClick={this.onClose} longForm className="video__modal__close" title="Close" />
      </dialog>
    )
  }

  render() {
    return this.getContent()
  }
}

VideoModal.defaultProps = {
  onVideoBlockSelect: () => {},
  onCRTSave: () => {},
  mode: null,
};

VideoModal.propTypes = {
  onVideoBlockSelect: PropTypes.func,
  onCRTSave: PropTypes.func,
  mode: PropTypes.oneOf(['videos_in_system', 'stations', null]),
  onDeleteVideo: PropTypes.func,
};
