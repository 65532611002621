/**
 * EpisodesList.js
 * Displays modal list of episodes within a video set.
 */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { CloseButton } from '../subcomponents/CloseButton';
import {
  format,
  isValid
} from 'date-fns';
import { Link } from 'react-router-dom';
import LoaderSimple from '../subcomponents/LoaderSimple';
import SeasonsEpsInput from './SeasonsEpsInput';
import EpisodeListCount from './EpisodeListCount';

import {
  API_VIDEOSET_ASSIGNMENTS,
  NULL_SET_TITLE,
  NULL_EPISODE_TITLE,
  NULL_DATE_TEXT_STATIONS
} from '../../js/Configuration';

import {
  apirequest,
  formatTime,
  getAuthData
} from '../../js/Utilities';

import './css/EpisodesList.css';

export default class EpisodesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      loading: true
    };

    this.getEpisodes = this.getEpisodes.bind(this);
    this.makeEpisodesTable = this.makeEpisodesTable.bind(this);
    this.makeBatchView = this.makeBatchView.bind(this);

    this.makeBody = this.makeBody.bind(this);

    this.makeControls = this.makeControls.bind(this);

    this.onBatchEdit = this.onBatchEdit.bind(this);
    this.onBatchSave = this.onBatchSave.bind(this);
    this.onSeasonEpChange = this.onSeasonEpChange.bind(this);

    this.countSeasons = this.countSeasons.bind(this);
    
    this.epListModal = createRef();
    this.closeModal = this.closeModal.bind(this);
  }

  // Get series for this station
  componentDidMount() {
    this.getEpisodes();
  }
  
  componentDidUpdate() {
    if ( this.props.mode === 'view') {
      if( this.props.open ) {
        this.epListModal.current.showModal()
      } else {
        this.epListModal.current.close()
      }
    }
  }
  
  closeModal() {
    if(this.props.onClose) {
      this.props.onClose()
    }
    this.epListModal.current.close()
  }

  getEpisodes() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', this.props.meta.id);
    apirequest(API_VIDEOSET_ASSIGNMENTS, {body: fd}, (data) => {
      // Add place holder properties for season and episode
      const episodes = data.map((ep) => {
        let s_e = {
          season: +ep.season || 0,
          episode: +ep.episode || 0
        };
        return Object.assign(ep, s_e);
      });
      this.setState({
        list: episodes,
        loading: false
      });
    });
  }

  onBatchEdit() {
    this.props.onBatchEdit();
  }

  onBatchSave(domEvent) {
    domEvent.preventDefault();
    this.props.onBatchSave(this.state.list);
  }

  onSeasonEpChange(domEvent) {
    const episodes = [...this.state.list];
    /*
    Seasons and episodes follow the format y:nnnn where y is either
    's' for 'season' or 'e' for 'episode.'
    
    This line tells us whether we need to set the season or the
    episode property, and gives us the episode ID.
    */
    const sEandID = domEvent.target.name.split(':');

    let which = episodes.findIndex(item => +sEandID[1] === +item.vsb_id);

    if(sEandID[0] === 's') {
      episodes[which].season = +domEvent.target.value;
    }

    if(sEandID[0] === 'e') {
      episodes[which].episode = +domEvent.target.value;
    }

    this.setState({list: episodes});
  }

  makeEpisodesView() {
    return (
      <div>
        <p className="episodes__display__batch">
          <button type="button" className="btn btn--action" onClick={this.onBatchEdit} hidden={this.props.mode === 'page'}>Batch Edit</button>
        </p>
        {this.makeEpisodesTable()}
      </div>
    );
  }

  makeBatchView() {
    return (
      <div>
        <form id="EpisodesViewBatchSave" method="post" onSubmit={this.onBatchSave}></form>
        <p className="episodes__display__batch">
          <button type="submit" form="EpisodesViewBatchSave" className="btn btn--action">Save Changes</button>
        </p>
        {this.makeEpisodesTable()}
      </div>
    );
  }

  makeBody() {
    let body;

    if(this.state.loading) {
      body = <LoaderSimple open={this.state.loading} />
    } else {
      body = this.props.mode === 'edit' ? this.makeBatchView() :  this.makeEpisodesView();
    }
    return body;
  }

  makeEpisodesTable() {
    let table;
    if(!this.state.list.length) {
      table = <h2>No episodes in this set</h2>;
    } else {

      const season_count = this.countSeasons();

      const rows = this.state.list.map((item, idx) => {
        const runtime = item.duration ? (item.duration) : 0;
        const rundate = isValid( item.vsb_datetimetorun ) ? format( new Date(item.vsb_datetimetorun), 'D MMM yyyy' ) : NULL_DATE_TEXT_STATIONS;
        
        let season, episode;

        if(this.props.mode === 'edit') {
          season  = <SeasonsEpsInput value={item.season || 0} id={`s:${item.vsb_id}`} onChange={this.onSeasonEpChange} form="EpisodesViewBatchSave" label="Season Number" />;
          episode = <SeasonsEpsInput value={item.episode || 0} id={`e:${item.vsb_id}`} onChange={this.onSeasonEpChange} form="EpisodesViewBatchSave" label="Episode Number" />;
        } else {
          season  = item.season || '―';
          episode = item.episode || '―';
        }

        const fruntime = (runtime === 0) ? '00:00:00' : formatTime(runtime);
  
        return (
          <tr key={item.vsb_id}>
            <td>{item.title || NULL_EPISODE_TITLE}</td>
            <td>{rundate}</td>
            <td>{item.video_count}</td>
            <td>{fruntime}</td>
            <td>{this.makeControls(item.vsb_id)}</td>
            <td>{season}</td>
            <td>{episode}</td>
          </tr>
        );
      });

      table = (
        <div>
          <EpisodeListCount
            episodes={this.state.list.length}
            seasons={season_count.seasons}
            unassigned={season_count.unassigned} />   
          <table className="episodes__display__table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Run date</th>
                <th>Video count</th>
                <th>Run time</th>
                <th>Actions</th>
                <th>Season</th>
                <th>Episode</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      );
    }
    return table;
  }

  makeControls(episodeid) {
    return (
      <div>
        <Link to={{
          pathname: `/stations/episode/${episodeid}`,
          state: {
            set: this.props.meta,
            list: this.state.list,
            breadcrumbs: [...this.props.breadcrumbs]
          }
        }}>View / Edit</Link>

        <form className="episodes__list__dup" method="post" onSubmit={this.props.onDuplicateEpisode}>
          <input type="hidden" name="id" value={episodeid} />
          <button type="submit" className="btn__link">Duplicate</button>
        </form>
      </div>
    );
  }

  countSeasons() {
    const eps = this.state.list;
    if(!eps.length) return;

    // Extract the season number from every episode
    const season_numbers = eps.map((item) => { return item.season; });
   
    // Find unique season values that aren't NULL
    const unique = season_numbers.filter((item, index, arr) => {
      return item && arr.indexOf(item) === index;
    });

    // Find unique season values that are falsy (0 or NULL)
    const unassigned = season_numbers.filter(item => item == false);

    // Return the number of values
    return {seasons: unique.length, unassigned: unassigned.length};
  }

  render() {
    let title;
    let mode;

    if(this.props.meta.hasOwnProperty('title')) {
      title = this.props.meta.title ? this.props.meta.title.trim() : NULL_SET_TITLE;
    }

    if(this.props.mode === 'page') {
      mode = this.makeBody();
    } else {
      mode = (
        <dialog setid={this.props.meta.id} id="EpisodesView" ref={this.epListModal}>
          <CloseButton onClick={this.props.onClose} longForm={true} title="Close" />
          <h2>Episodes for {`\u201c${title}\u201d`}</h2>
          {this.makeBody()}
        </dialog>
      );
    }
    return mode;
  }
}

EpisodesList.defaultProps = {
  open: false,
  onClose: ()=>{},
  onDuplicateEpisode: ()=>{}
}

EpisodesList.propTypes = {
  onDuplicateEpisode: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  meta: PropTypes.object, // Should probably be a shape, but fine for now.
}
