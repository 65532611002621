/**
 * MeowMenu.js
 * Used on the VideosPage modal to set whether a video's MEOW status
 * See PropTypes definitions at the end of this file for parameters
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PleaseAddToAva } from '../../Videos/PleaseAddToAva';

export default class MeowMenu extends React.Component {
  constructor( _props ) {
    super( _props );
    this.makeMenuItems = this.makeMenuItems.bind(this);
  }

  makeMenuItems(arrayOfItems=[]) {
    const defaultSelect = [<option key='selectrating_rating-1' value="">Select a MEOW Status</option>];
    let menu = null;

    if(arrayOfItems.length) {
      menu = arrayOfItems.map((item, index) => {
        return <option key={`selectrating_${index.toString()}`} value={index}>{item}</option>
      });
    }
    menu = defaultSelect.concat(menu);

    return menu;
  }

  render() {
    const cssClasses = classNames(
      this.props.cssClass,
      {
        'video__info__meow__select': true,
        'video__info__field': true
      }
    );

    return (
      <form action={this.props.actionURL} method={this.props.method} className={cssClasses} onChange={this.props.onChange} onSubmit={this.props.onSubmit}>
        <label htmlFor="video_rating">MEOW Status:</label>
        <PleaseAddToAva hidden={ !this.props.disabled } />
        <span className="form__input_button_group">
          <select name="meow_status" id="meow_status" onChange={()=>{}} value={this.props.value} disabled={ this.props.disabled }>
            {this.makeMenuItems(this.props.options)}
          </select>
          <button type="submit" disabled={this.props.disabled} className="btn btn--action">Save</button>
        </span>
      </form>
    );
  }
};

MeowMenu.defaultProps = {
  actionURL: 'api/ratings',
  cssClass: null,
  method: 'POST'
};

MeowMenu.propTypes = {
  actionURL: PropTypes.string,
  method: PropTypes.string,
  cssClass: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string
};
