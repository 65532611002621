import React from 'react';
import PropTypes from 'prop-types';

import {
  csvrequest,
  getAuthData
} from '../../js/Utilities';

import { DownloadCSV } from '../subcomponents/DownloadCSV';

class ReportsResults extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isDownloading: false
    }

    this.makeColumnHeadings = this.makeColumnHeadings.bind(this);
    this.makeRows = this.makeRows.bind(this);
    this.makeCells = this.makeCells.bind(this);
    this.makeTable = this.makeTable.bind(this);
  }

  makeColumnHeadings(headingsArray) {
    const ths = headingsArray.map((h, index) => {
      return <th key={index.toString()}>{h}</th>;
    });

    return (
      <thead>
        <tr>
          {ths}
        </tr>
      </thead>
    );
  }

  makeCells(rowObject) {
    const fields = Object.keys(rowObject).filter((key) => {
      return key !== 'url';
    });

    const tds = fields.map((f, index) => {
      let cell;

      if(f === 'title'){ 
        if(rowObject.hasOwnProperty('url')) {
          cell = <a href={rowObject.url} target="_blank" rel="noopener noreferrer">{rowObject.title}</a>;
        } else {
          cell = <b>{rowObject.title}</b>;
        }
      } else {
        cell = rowObject[f];
      }

      return <td key={`${f}_${index.toString()}`}>{cell}</td>;

    });

    return tds;
  }

  makeRows(rowsArray) {
    return rowsArray.map((item, index) => {
      const cells = this.makeCells(item);

      return (
        <tr key={index.toString()}>
          {cells}
        </tr>
      );
    });
  }

  makeTable(headerArray, rowsArray) {
    const rows = this.makeRows(rowsArray);
    const header = this.makeColumnHeadings(headerArray);
    const css = `reports__table__results reports__results__viddest ${this.props.cssClass}`;

    return (
      <table className={css}>
        {header}
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  }
  
  render() {
    // Will stop executing if we don't have a results count.
    if(this.props.count === null) return null;
    return (
      <div>
        <DownloadCSV {...this.props} onSubmit={this.props.onDownload} working={this.state.isDownloading} />
        <div className="reports__table__overflow">
          {this.makeTable(this.props.columnHeadings, this.props.results)}
        </div>
      </div>
    );
  }
}

ReportsResults.defaultProps = {
  results: [],
  columnHeadings: [],
  limit: '500'
};

ReportsResults.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object),
  columnHeadings: PropTypes.array.isRequired,
  start: PropTypes.string,
  destination: PropTypes.string,
  end: PropTypes.string,
  limit: PropTypes.string,
  cssClass: PropTypes.string
};

export default ReportsResults;
