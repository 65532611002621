/**
 * Videos.js
 * Top-level component that displays the
 */

import React from 'react';
import VideoModal from './subcomponents/Videos/VideoModal';

import FilterComponent from './Videos/FilterComponent.js';
import NextPrevious from './Search/NextPrevious';
import LoaderSimple from './subcomponents/LoaderSimple.js';

import AddVideosField from './Videos/AddVideosField';
import VideoItem from './subcomponents/VideoItem';

import {
  apirequest,
  getAuthData,
  getSortDirection,
  getVideoData,
  isAdmin
} from '../js/Utilities';

import {
  setStateCloseVideo
} from '../actions/DestinationsPlaylistUtils';

import * as VU from '../actions/VideosUtils';

import {
  setStateOnVideoBlockSelect
} from '../actions/VideosUtils';

import {
  API_CATEGORIES,
  API_VIDEO_ADDTOSET,
  API_RATINGS_VIDEOUPDATE,
  API_RATINGS_ALL,
  API_VIDEO_GETBATCH,
  API_VIDEO_ADDNEW,
  VIDEOS_PER_PAGE,
} from '../js/Configuration';

import '../css/videosPage.css';
import './Destinations/css/VideoDisplay.css';

export default class Videos extends React.Component {
  constructor( _props ) {
    super( _props );

    this.state = {
      videoList: [],
      isLoading: true,
      videoinfo: {
        open: false,
        videoData: {},
        rating: '',
        selectedSet: {
          videosetid: '-1',
          videoblockid: '-1'
        },
        groups: []
      },
      page: 1,
      perPage: VIDEOS_PER_PAGE,
      pageCount: 1,
      numResults: 0,
      ratings: [],
      selectedRating: '',
      filter: {
        categories: [],
        category: null,
        filterOn: 'video_title',
        hideCategories: true,
        itemsName: 'Videos',
        searchValue: '',
        showHint: false
      },
      sortBy: 'video_setscount',
      ascending: true,
    };

    // Prevents API requests from clogging up and blocking the UI.
    this.fetchcancelqueue = new Map();

    this.onFilterChangeHandler = this.onFilterChangeHandler.bind(this);
    this.onSortChangeHandler = this.onSortChangeHandler.bind(this);
    this.onFilterClearHandler = this.onFilterClearHandler.bind(this);
    this.onDirectionChangeHandler = this.onDirectionChangeHandler.bind(this);

    this.addVideoToSet = this.addVideoToSet.bind(this);

    this.getPage = this.getPage.bind(this);
    this.getRecent = this.getRecent.bind(this);
    this.getNextPage = this.getNextPage.bind(this);
    this.getPrevPage = this.getPrevPage.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.getRatings = this.getRatings.bind(this);

    this.receivedVideoData = this.receivedVideoData.bind(this);

    this.playVideo = this.playVideo.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addVideo = this.addVideo.bind(this);
    this.videoSetSelected = this.videoSetSelected.bind(this);

    this.importGoogleSheets = this.importGoogleSheets.bind(this);

    // After the CRT setting changes in VideoInfo, we need to update the current
    // video batch display
    this.postCRTHandler = this.postCRTHandler.bind(this);

    this.postMeowHandler   = this.postMeowHandler.bind(this);
    this.postRatingHandler = this.postRatingHandler.bind(this);
    this.postTagsHandler   = this.postTagsHandler.bind(this);

    this.onDeleteVideo = this.onDeleteVideo.bind(this);
    this.onDeleteFromBlock = this.onDeleteFromBlock.bind( this );
    this.isAdmin = isAdmin(this.props.project);
  }

  componentDidMount() {
    this.getRecent();
  }

  componentWillUnmount() {
    const signals = this.fetchcancelqueue;
    signals.forEach((value, key) => {
      value.abort();
    });
  }

  addVideoToSet(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();

    const fd = new FormData();
    fd.set('videoid', this.state.videoinfo.videoData.video_ava_id);
    fd.set('ytid', this.state.videoinfo.videoData.video_yt_id);
    fd.set('setid', this.state.videoinfo.selectedSet.videosetid);
    fd.set('blockid', this.state.videoinfo.selectedSet.videoblockid);
    fd.set('token', getAuthData('token'));


    const ac = new AbortController();
    const signal = ac.signal;
    this.fetchcancelqueue['addVideoToSet'] = ac;

    apirequest(API_VIDEO_ADDTOSET, {body: fd, signal }, (data) => {
      // TODO: Should probably handle errors too.
      window.alert('Video added to set.');
    });
  }

  getPage() {
    console.log('getPage')
    const cb = (data) => {
      this.setState((state) => VU.setStateAfterGetPage(state, data));
    }

    const ac = new AbortController();
    const signal = ac.signal;
    this.fetchcancelqueue.set('getPage', ac );
    this.setState(VU.setStateLoading, () => VU.requestPage( this.state, cb, signal ));
  }

  getRecent() {
    this.getPage(1, VIDEOS_PER_PAGE);
  }

  getNextPage() {
    this.setState(VU.setStatePreviousPage, this.getPage);
  }

  getPrevPage() {
    this.setState(VU.setStateNextPage, this.getPage);
  }

  getCategories() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    const ac = new AbortController();
    const signal = ac.signal;
    this.fetchcancelqueue['getCategories'] = ac;

    apirequest(API_CATEGORIES, { body: fd, signal }, (data) => {
      this.setState((state) => VU.setStateAfterGetCategories( state, data ), this.getPage );
    });
  }

  getRatings() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));

    const ac = new AbortController();
    const signal = ac.signal;
    this.fetchcancelqueue['getRatings'] = ac;

    apirequest(API_RATINGS_ALL, { body: fd, signal }, (data) => {
      this.setState((state) => VU.setStateOnGetRatings(state, data));
    });
  }

  receivedVideoData(data) {
    this.setState((state) => { return VU.setStateReceivedVideoData(state, data) });
  }

  onFilterChangeHandler(domEvent) {
    const target = domEvent.target;
    this.setState( (state) => VU.setStateOnFilterChange( state, target ), () => {
      if( this.state.filter.filterOn === 'video_category') {
        this.getCategories()
      } else {
        this.getPage()
      }
    });
  }

  onSortChangeHandler(domEvent) {
    const sort_criteria = domEvent.target.value;
    this.setState((state) => VU.setStateOnSortChange(state, sort_criteria), this.getPage);
  }

  onDirectionChangeHandler(domEvent) {
    this.setState(VU.setStateOnDirectionChange, this.getPage);
  }

  onFilterClearHandler(domEvent) {
    domEvent.preventDefault()
    this.setState(VU.setStateOnFilterClear, this.getPage);
  }

  getContent() {
    let videos;
    let pagingComponent;
    let list;

    if( this.state.isLoading ) {
      videos = <div className="search__results__results"><LoaderSimple open={true} /></div>;
      pagingComponent = null;

    } else {

      if(this.state.videoList.length) {
        const lst = this.state.videoList.map( ( _video, index ) => {
          return <VideoItem key={ index.toString() } videoData={_video} id={ _video.video_youtubeID } playVideo={this.playVideo} />;
        });
        list = <div className="List">{lst}</div>
      } else {
        list = <h3>No videos match your criteria</h3>
      }
      videos = <div className="search__results__results video__display video__display--grid">{list}</div>;

      /*
        NextPrevious activates the previous button based on the PRESENCE of a
        prev_page prop, NOT its value. So we're going to conditionally add
        that prop based on the value of this.state.page.
      */
      let pgeprops = {
        next: this.getNextPage,
        prev: this.getPrevPage,
        result_count: this.state.videoList.length,
        per_page: this.state.perPage,
      };
      if(this.state.page > 1) { pgeprops.prev_page = true; }

      pagingComponent = <NextPrevious {...pgeprops} />;
    }

    let pgCount;
    if(this.state.videoList.length) {
      pgCount = `Page ${this.state.page} of ${this.state.pageCount} (${this.state.numResults} results)`;
    } else {
      pgCount = `Page – of – (0 results)`;
    }

    return (
      <div className="VideoListGroup search__results__panel--open">
        <div className="videos__pagination">
          <span className="videos__pgcount">{pgCount}</span>
          {pagingComponent}
        </div>

        {videos}

        <div className="videos__pagination">
          <span className="videos__pgcount">{pgCount}</span>
          {pagingComponent}
        </div>
      </div>
    );
  }

  playVideo(id) {
    getVideoData(id, this.receivedVideoData);
  }

  closeModal(modalRef) {
    this.setState(VU.setStateOnCloseModal, () => {
      if(modalRef && Object.hasOwn(modalRef, 'current')) {
        modalRef.current.close();
      }
    });
  }

  addVideo(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();

    const fd = new FormData();
    fd.set('videoids', domEvent.target.elements.video_ids.value);
    fd.set('setID', '-1');
    fd.set('blockID', '-1');
    fd.set('uuid', getAuthData('uuid'));
    fd.set('userName', getAuthData('user'));
    fd.set('token', getAuthData('token'));

    const ac = new AbortController();
    const signal = ac.signal;
    this.fetchcancelqueue['addVideo'] = ac;

    apirequest(API_VIDEO_ADDNEW, {body: fd, signal}, (data) => {
      if( data.success ) {
        this.setState( VU.setStateOnAddNew, this.getRecent );
      } else {
        alert( 'Something went wrong while adding the video. Please tell an administrator.' );
      }
      domEvent.target.reset();
    });
  }

  videoSetSelected( _data ) {
    let value = _data ? _data.value : null;
    this.setState((state) => {
      return {
        videoinfo: {
          ...state.videoinfo,
          selectedSet: value
        }
      }
    });
  }

  importGoogleSheets() {
    this.importModal.open(0);
  }

  // After the user changes the CRT value, we should update it in state with
  // this method.
  postCRTHandler(data) {
    if(data.status !== 'success') return;

    const vl = [...this.state.videoList];
    const whichVideo = vl.findIndex((item) => {
      return item.video_youtubeID === data.video_ytid;
    });

    vl[whichVideo].video_crt = data.video_crt;

    this.setState({videoList: vl});
  }

  postMeowHandler(data) {
    if(data.status !== 'success') return;

    const vl = [...this.state.videoList];
    const whichVideo = vl.findIndex((item) => {
      return item.video_youtubeID === data.video_ytid;
    });

    vl[whichVideo].meow = data.meow;

    this.setState({videoList: vl});
  }

  postRatingHandler(data) {
    if( data.error === true ) return;

    const vl = [...this.state.videoList];
    const whichVideo = vl.findIndex((item) => {
      return item.video_youtubeID === data.youtubeid;
    });

    vl[whichVideo].rating = data.rating;

    this.setState({videoList: vl});
  }

  postTagsHandler(data) {
    let result;

    if( data.error ) {

      result = data.message;

    } else {

      this.setState((state) => {
        return {
          videoinfo: {
            ...state.videoinfo,
            videoData: {
              ...state.videoinfo.videoData,
              video_customtags: data.customTags
            }
          }
        }
      }, ( data ) => {
        result = `Updated tags for "${this.state.videoinfo.videoData.video_title}" (YouTube ID: ${ this.state.videoinfo.videoData.video_yt_id }).`;
      });
    }
    window.alert( result );
  }

  onDeleteVideo(data, modalRef=null) {
    if(!data.videosDeleted.length) return;
    const list = [...this.state.videoList];

    // Removes deleted videos from the displayed list.
    const removevid = (id) => {
      const remove = list.findIndex((item) => { return id == item.video_youtubeID; });
      list.splice(remove, 1);
    }
    
    data.videosDeleted.forEach(removevid);

    this.setState((state) => {
      return {
        videoList: list,
        videoinfo: {
          ...state.videoinfo,
          open: false
        }
      }
    }, () => {
      if (modalRef && Object.hasOwn(modalRef, 'current') ) {
        modalRef.current.close();
      }
    });
  }

  onDeleteFromBlock( data ) {
    if(!data.videosDeleted.length) {
      window.alert('I could not remove the video from the set. This may be a bug. Please tell an administrator.');
      return;
    }

    let remove;
    if( this.state.videoinfo.videoData.hasOwnProperty('dest_set_block_assignments') ) {
      const blocks = [...this.state.videoinfo.videoData.dest_set_block_assignments];
      remove = blocks.findIndex( (block) => block.block_id === data.deletedFrom );

      const block_title = blocks[remove].vs_name;

      blocks.slice(remove, 1); // Remove that block from the list.

      this.setState({
        videoinfo: {
          ...this.state.videoinfo,
          videoData: {
            ...this.state.videoinfo.videoData,
            dest_set_block_assignments: blocks
          }
        }
      }, () => window.alert(`I removed "${ this.state.videoinfo.videoData.video_title }" from "${ block_title }"`))
    }
  }

  render() {
    return (
      <div className="videoListHolder">
        <div id="videoHeaderParent">
            <div id="videoHeader">
              <AddVideosField action={API_VIDEO_ADDNEW} onSubmit={this.addVideo} />
              <FilterComponent
                { ...this.state.filter }
                filterData={[
                    { value:'video_title', name:'Video Title'},
                    { value:'video_title_tags', name:'Video Tags & Title'},
                    { value:'video_description', name:'Video Description'},
                    { value:'video_channel_title', name:'Channel Title'},
                   { value:'video_addedbyname', name:'User Name'},
                    { value:'video_category', name:'Category'},
                ]}
                sortDefault={ this.state.sortBy }
                sortData={[
                    { value:'video_title', name:'Video Title'},
                    { value:'video_updateddate', name:'Video Last Updated'},
                    { value:'video_addeddate', name:'Date Video Added to System'},
                    { value:'video_channel_title', name:'Channel Title'},
                    { value:'video_category', name:'Category'},
                    { value:'video_duration_seconds', name:'Video Duration'},
                    { value:'video_viewcount', name:'Video View Count',},
                    { value:'video_likecount', name:'Video Likes Count',},
                    { value:'video_setscount', name:'Number of Video Sets',},
                    { value:'video_publishdate', name:'Publish Date',},
                    { value:'video_channel_publishdate', name:'Channel Date'},
                    { value:'video_rating', name:'Rating'},
                    { value:'meow', name: 'MEOW Status' }
                ]}
                ascending={this.state.ascending}
                onSortChange={this.onSortChangeHandler}
                onFilterChange={this.onFilterChangeHandler}
                onFilterClear={this.onFilterClearHandler}
                onDirectionChange={this.onDirectionChangeHandler}
              />
            </div>
          </div>

        { this.getContent() }
        <VideoModal
          id="VideoInfo"
          {...this.state.videoinfo}
          isAdmin={this.isAdmin}
          onCloseHandler={this.closeModal}
          mode='videos_in_system'
          onAddVideoToDestinationSubmit={this.addVideoToSet}
          onDeleteVideo={this.onDeleteVideo}
          onDeleteFromBlock={this.onDeleteFromBlock}
          onVideoBlockSelect={(data) => {
            this.setState((state) => VU.setStateOnVideoBlockSelect(state, data));
          }}
          onRatingSave={this.postRatingHandler}
          onTagsSave={this.postTagsHandler}
          onCRTSave={this.postCRTHandler}
          onMeowSave={this.postMeowHandler} />
      </div>
    );
  }
}
