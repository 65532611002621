/**
 * CheckForDuplicatesModal.js
 * Displays a list of available videos to add. 
 * Used in DestinationsPlaylist.js
 */

import React, { createRef } from 'react';

import LoaderSimple from '../subcomponents/LoaderSimple';
import uniqueId from 'lodash.uniqueid';
import { CheckForDuplicatesRemove } from './CheckForDuplicatesRemove';
import { CheckForDuplicatesExclude } from './CheckForDuplicatesExclude';
import { CloseButton } from '../subcomponents/CloseButton';
import { ActionMessageDisplay } from '../subcomponents/ActionMessageDisplay';

import { indicesOfDupes } from '../../actions/DestinationsPlaylistUtils';

import './css/CheckForDuplicatesModal.css';

export default class CheckForDuplicatesModal extends React.Component {
  constructor(props) {
    super(props);
    this.makeBody = this.makeBody.bind(this);
    this.checkingModeDisplay = this.checkingModeDisplay.bind(this);
    this.findDupesIndices = this.findDupesIndices.bind(this);
    this.addingModeDisplay = this.addingModeDisplay.bind(this);
    this.sortByVideoName = this.sortByVideoName.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.checkForDuplicatesModal = createRef();
  }
  
  componentDidUpdate() {
    if( this.props.open ) {
      this.checkForDuplicatesModal.current.showModal()
    }
  }

  closeModal() {
    if(this.props.onClose) {
      this.props.onClose(this.checkForDuplicatesModal)
    }
  }

  /* 
    Finds the indices of the original and the duplicate.
    returns an object in the form {youtubeid: [array,of,indices]}
  */
  findDupesIndices() {
    if(!this.props.dupes.length) return;
    const callback = (item) => {
      return indicesOfDupes(this.props.inSet, item)
    };
    return this.props.dupes.map(callback);
  }

  makeBody() {
    let body = null;
    switch(this.props.mode){
      case 'checking':
      case 'checked':
        body = this.checkingModeDisplay();
        break;

      default:
        body = this.addingModeDisplay();
    }
    return body;
  };

  sortByVideoName(a, b) {
    const c = a.name.toLowerCase();
    const d = b.name.toLowerCase();
    if (c < d) { return -1; }
    if (c > d) { return 1; }

    // names must be equal
    return 0;
  }

  onSubmit(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();
    
    if(this.props.onSubmit) {
      this.props.onSbumit(domEvent, this.checkForDuplicatesModal)
    }
  }

  checkingModeDisplay() {
    const dupes = this.findDupesIndices() || [];
    let table = null;

    if(!dupes.length) {
      table = <div><h2>Check for Duplicates</h2><p>No duplicates found.</p></div>;
    } else {
      /* For each array of indices in each row return a row...*/
      const rows = dupes
        .map((r) => r.indexes.map((index) => this.props.inSet[index]))
        .reduce((accumulator, value) => accumulator.concat(value), []) // flattens the array
        .sort(this.sortByVideoName) // alphabetize
        .map((instance, index) => {
          const ky = uniqueId(`${instance.ytID}__`);
          return (
            <tr key={ky}>
              <td>{instance.name}</td>
              <td>{+instance.ordinal + 1}</td>
              <td>
                <CheckForDuplicatesRemove id={instance.ytID} playlist={this.props.playlist} order={instance.position} onSubmit={this.props.onRemove} />
              </td>
            </tr>
          );
        });

       table = (
        <div>
          <h2>Found duplicates</h2>
          <ActionMessageDisplay
            mode={this.props.response.itemsDeleted ? 'success' : 'failed'}
            message={this.props.response.itemsDeleted ? this.props.message : 'Error: Something went wrong. Please tell an administrator.'}
            hidden={this.props.mode !== 'checked'}
            onClose={this.props.onErrorClose} />
          <LoaderSimple open={this.props.working} />
          <p>The following videos appear more than once in this play list. Please select which instance(s) you'd like to remove.</p>
          <table className="CheckForDuplicatesModal__table__checking">
            <thead>
              <tr>
                <th>Video Name</th>
                <th>Order</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </table>
         </div>
      );
    }
    return table;
  }

  addingModeDisplay() {
    const dupes = this.props.dupes;
    let table;

    const rows = dupes
    .map((du) => {
      const instance = this.props.inSet.find(is => is.ytID === du.id);
      const ky = uniqueId(`${du.id}__`);
      const formId = uniqueId(`${du.id}--`);

      return (
        <tr key={ky}>
          <td><label htmlFor={formId}>{instance.name}</label></td>
          <td>
            <CheckForDuplicatesExclude onChange={this.props.onChange} youtubeid={du.id} id={formId} name="exclude" checked={du.checked}/>
          </td>
        </tr>
      );
    }); 

    return (
      <form onSubmit={this.props.onSubmit}>
        <h2>Found duplicates</h2>
        <LoaderSimple open={this.props.working} />
        <p>The following videos are already part of this list. Do you still want to add them?</p>
        <input id="PostDupeCheck" name="PostDupeCheck" value="1" type="hidden" />
        <table className="CheckForDuplicatesModal__table__adding">
          <thead>
            <tr>
              <th>Video Name</th>
              <th>
                Yes
                <span className="note">(uncheck to exclude)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
        <div className="button__group">
          <button type="submit" className="btn btn--action">Save</button>
          <button type="button" className="btn" onClick={this.props.onCancel}>Cancel</button>
        </div>
      </form>
    );
  }

  render() {
    let body;
    if(this.props.loading) {
      body = (
        <div>
          <h2>Check for Duplicates</h2>
          <LoaderSimple open={true} />
        </div>
      )
    } else {
      body = this.makeBody();
    }

    return (
      <dialog onClose={this.props.onClose} ref={this.checkForDuplicatesModal} id="CheckForDuplicatesModal" className="check_dupes__modal">
        <CloseButton title="Close" longForm={true} onClick={this.closeModal} />
        {body}
      </dialog>
    )
  }
}
