/**
 * Created by Webinista, Inc 3/23/18.
 */

import React from 'react';
import ListDisplay from './Reports/ListDisplay';
import LoaderSimple from './subcomponents/LoaderSimple';
import DashboardCounts from './Reports/DashboardCounts';
import { useLocation } from 'react-router-dom';

import {
  apirequest,
  getAuthData
} from '../js/Utilities';

import {
  API_REPORTS
} from '../js/Configuration';


import '../css/reportsPage.css';

class ReportsDashboardPage extends React.Component
{
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      isLoading: true,
      videos: 0,
      destinations: 0,
      channels: 0
    };
    
    this.fetchCancel = new AbortController();
  }
  
  componentDidMount() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    
    const signal =  this.fetchCancel.signal;
    apirequest(API_REPORTS, {body: fd, signal}, (data) => this.setState({
      reports: data.reports,
      videos: +data.count_videos,
      channels: +data.count_channels,
      destinations: +data.count_destinations,
      stations: +data.count_stations,
      isLoading: false
    }));
  }

  componentWillUnmount() {
    this.fetchCancel.abort();
  }

  render() {
    let display;

    if(this.state.isLoading) {
      display = <LoaderSimple open={true} />
    } else {
       display = (
        <ListDisplay items={this.state.reports} />
      );
    }

    return (
      <div className="reports__page">
        <h1>Reports</h1>
        <DashboardCounts
          videos={this.state.videos}
          destinations={this.state.destinations}
          channels={this.state.channels}
          stations={this.state.stations} />
        {display}
      </div>
    )
  }
}

export default (props) => (
  <ReportsDashboardPage
      {...props}
      params={useLocation()}
  />
);