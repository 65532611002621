/**
 * AmzReportsDetails.js
 */
import React from 'react';
import { Link,  Route } from 'react-router-dom';
import uniqueId from 'lodash.uniqueid';

import LoaderSimple from '../subcomponents/LoaderSimple';
import { AmzSongSearch } from './AmzSongSearch';
import { AmzSongAddSongs } from './AmzSongAddSongs';
import { NoSuchReport } from './NoSuchReport';

import {
  API_GENRES_REPORTS,
  API_CLUSTERS_SINGLEGENRE,
  API_GENRES_SONGDUPES,
  API_GENRES_ARTISTDUPES
} from '../../js/Configuration';

import {
  apirequest,
  capitalize,
  getAuthData
} from '../../js/Utilities';

import {
  download
} from '../../actions/ReportsUtils';

import {
  amzUrl,
  setStateOnClusterTitle,
  setStateOnReportResponse,
  setStateOnSongTitleDupes,
  setStateOnArtistNameDupes
} from '../../actions/AmzUtils';

import './css/AmzReports.css';

export default class AmzReportsDetails extends React.Component {
  constructor( _props ) {
    super( _props );

    this.state = {
      loading: true,
      results: [],
      meta: {}
    }

    this.getSongTitleDupes = this.getSongTitleDupes.bind(this);
    this.getArtistNameDupes = this.getArtistNameDupes.bind(this);
    this.makeCSV = this.makeCSV.bind(this);
    this.makeDisplay = this.makeDisplay.bind(this);
    this.makeTable = this.makeTable.bind(this);
    this.makeTableHeader = this.makeTableHeader.bind(this);
    this.makeTableRows = this.makeTableRows.bind(this);
    this.makeBackLink = this.makeBackLink.bind(this);
  }

  componentDidMount() {
    this.resetStateOnSongTitleDupes();
  }

  resetStateOnSongTitleDupes() {
    const report = this.props.match.params.report;

    let callback = (report === 'title') ? this.getSongTitleDupes : this.getArtistNameDupes
    this.setState({ loading: true, meta: {}}, callback);
  }

  getSongTitleDupes() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', this.props.match.params.id);
    if(this.props.match.params.hasOwnProperty('genre')) {
      fd.set('genre', this.props.match.params.genre);
    }

    apirequest(API_GENRES_SONGDUPES, {body: fd}, (response) => {
      this.setState((state) => setStateOnSongTitleDupes(state, response));
    });
  }

  getArtistNameDupes() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', this.props.match.params.id);

    apirequest(API_GENRES_ARTISTDUPES, {body: fd}, (response) => {
      this.setState((state) => setStateOnArtistNameDupes(state, response));
    });
  }

  makeBackLink() {
    let link = null;
    if(this.props.location.hasOwnProperty('state') && this.props.location.state.hasOwnProperty('previous')) {
      link = <p><Link to={{pathname: this.props.location.state.previous}} className="amz__reports_backlink">Return to report</Link></p>
    }
    return link;
  }

  makeTable() { 
    let body = <LoaderSimple open={true} />;
    if(this.state.results.length) {
      body = (
        <table className="reports__table__results">
          {this.makeTableHeader()}
          {this.makeTableRows()}
        </table>
      )
    }
    return body;
  }

  makeTableHeader() {
    if(!this.state.results.length) return null;
    const fields = Object.keys(this.state.results[0]);
    const th = fields
              .filter((f) => ['sASIN','aASIN','gid'].indexOf(f) < 0)
              .map((f) => <th key={uniqueId()}>{capitalize(f)}</th>);

    return (
      <thead>
        <tr>
          {th}
        </tr>
      </thead>
    );
  }

  makeTableRows() {
    if(!this.state.results.length) return null;
    const results = this.state.results;

    const rows = results.map((f, index, results) => {
      const cols = Object.keys(f).filter((f) => ['gid'].indexOf(f) < 0);

      const td = cols
                 .map((c) => {
                    let cell = f[c];

                    if(c === 'Sub-genre' && f.hasOwnProperty('gid')) {
                      cell = <Link to={{pathname: `/music/songs/${f.gid}`}}>{f['Sub-genre']}</Link>
                    }

                    return <td key={uniqueId()}>{cell}</td>
                  });

      return (
        <tr key={uniqueId()}>
          {td}
        </tr>
      );
    });

    return (
      <tbody>
        {rows}
      </tbody>
    );
  }

  makeDisplay(){
    return (
      <div>
        <header>
          <h1>Details for <em>{this.state.meta.name}</em></h1>
          {this.makeBackLink()}
          <p className="button__group" style={{'textAlign': 'right'}}>
            <button type="button" className="btn btn--action" onClick={this.makeCSV}>Export CSV</button>
          </p>
        </header>
        {this.makeTable()}
      </div>
    )
  }

  makeCSV() {
    if(!this.state.results.length) return;
    const rows = this.state.results;
    const exclude = ['gid','sASIN','aASIN']
    let csv = [];

    const headers = Object.keys(rows[0]);
    csv[0] = headers
            .filter((h) => exclude.indexOf(h) < 0)
            .map((h) => `"${capitalize(h)}"`);

    const rws = rows.map((r) => {
      const fields = Object.keys(r).filter((r) => exclude.indexOf(r) < 0);
      return fields.map((f) => `"${r[f]}"`);
    });

    const filename = `${capitalize(this.props.match.params.report)}_Report_for_${this.state.meta.name}`;
    download(csv.concat(rws), filename, 'Duplicate', '');
  }

  render() {
    return this.makeDisplay();
  }
}
