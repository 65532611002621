/**
 * Tools.js
 * Tools for calculating statistics about lists of video IDs
 */

import React from 'react';
import ImportSpreadsheet from './Import/ImportSpreadsheet';
import { getSheetId } from '../js/Utilities';

import '../css/Tools.css';
import './subcomponents/css/fancy-radiobuttons.css';

export default class Tools extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calcs: {
        total: 0,
        median: 0,
        mean: 0,
        mode: [],
        min: 0,
        max: 0
      },
      sheet: {
        url: '',
        sheet: 'Sheet1',
        operation: '' // can be an empty string, 'averages', or 'durations'
      }
    }

    this.updateState = this.updateState.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  updateState(domEvent) {
    const spread = {
      ...this.state.sheet,
      [domEvent.target.name]: domEvent.target.value
    };
    this.setState({sheet: spread});
  }

  onSubmit(domEvent) {
    domEvent.preventDefault();
    const view = this.state.sheet.operation === 'durations' ? 'durationmode' : 'averages';
    window.location = `/tools/${view}/${getSheetId(this.state.sheet.url)}/${this.state.sheet.sheet}`;
  }

  render() {
    return (
      <div className="tools__start">
        <h1>Video List Calculator</h1>
        <ImportSpreadsheet
          loading={this.state.loading}
          onSubmit={this.onSubmit}
          onChange={this.updateState}
          {...this.state.sheet} />
      </div>
    );
  }
}
