/*
LoginUtils.js
*/

import {
  apirequest,
  setAuthData
} from '../js/Utilities';

import {
  API_USER_LOGIN,
  API_USER_PW_RESET,
  ERROR_MISSING_USERNAME,
  ERROR_LOGIN_FAILED,
  API_USER_SUGGEST_PASSWORD
} from '../js/Configuration';

export const login = (formdata, callback) => {
  const options = {
    method: 'POST',
    body: formdata
  };
  apirequest(API_USER_LOGIN, options, callback);
}

export const setStatePostLogin = (state, data) => {
  let response;

  if(data.result === 'success') {
    setAuthData(data);
    response = {user: data};
  } else {
    response = {error: ERROR_LOGIN_FAILED};
  }

  return response;
}

export const requestPassword = (domEvent) => {
  if(!domEvent.target.elements.uname.value) {
    alert(ERROR_MISSING_USERNAME);
    return;
  }

  domEvent.preventDefault();
  domEvent.persist();

  const fd = new FormData();
  fd.append('id', domEvent.target.elements.uname.value);
  fd.append('tok', domEvent.target.elements.tok.value);

  const options = {
    method: 'POST',
    body: fd
  };

  apirequest(API_USER_PW_RESET, options, (response) => {
    if(response.result === 'success') {
      window.location = '/emailsent';
    }
  });
}

export const isCapsLocked = (keyboardEvent, state) => {
  if (keyboardEvent.target.value && keyboardEvent.getModifierState && keyboardEvent.getModifierState('CapsLock')) {
    state.setState({capslock: true});
  } else {
    state.setState({capslock: false});
  }
}

export const clearIsCapsLocked = (keyboardEvent, state) => {
  if(keyboardEvent.target.value.length === 0) {
    state.setState({capslock: false});
  }
}

export const requestPasswordSuggestion = (reqtok, callback) => {
  const fd = new FormData();
  fd.set('tok', reqtok);

  const options = {
    method: 'POST',
    body: fd
  };

  apirequest(API_USER_SUGGEST_PASSWORD, options, callback);
}

export const setStatePasswordSuggestion = (state, suggestionObj) => {
  return {
    password1: {
      value: suggestionObj.suggestion,
      type: 'text'
    }
  };
}

export const setStatePasswordResetShowHide = (state, fieldId) => {
  const opts = ['password', 'text']; // Options we can use.
  const index = +(state[fieldId].type === 'password'); // + converts this boolean to an integer
  return {
    [fieldId]: {
      ...state[fieldId],
      type: opts[index]
    }
  }
}
