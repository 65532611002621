import React from 'react';
import { Project } from './Login/Project';
import { ProjectIcons } from '../js/ProjectIcons';
import uniqueId from 'lodash.uniqueid';

import {
  getAuthData,
  apirequest
} from '../js/Utilities';

import {
  setStateOnProjects
} from '../actions/UserUtils';

import '../css/UserProjects.css';

export default class UserProjects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: [],
      icons: {
        
      }    
    }

    this.getProjects = this.getProjects.bind(this);
    this.makeLinks = this.makeLinks.bind(this);
  }

  componentDidMount() {
    this.getProjects();
  }
  
  getProjects() {
    // Confirm that the user has access to this page.
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('uuid', getAuthData('uuid'));

    apirequest('api/projectsforuser', {body: fd}, (data) => {
      this.setState((state) => setStateOnProjects(state, data), this.makeLinks);
    });
  }

  makeLinks() {
    let projects = null;
    
    if(this.state.projects && this.state.projects.length) {
      projects = this.state.projects
      .filter((pj) => pj.name !== 'User Admin') // Removes user admin from the list.
      .map((pj) => {
        const ico = ProjectIcons[pj.icon];
        return <Project key={uniqueId()} path={`/${pj.path}`} name={pj.name} icon={ico} />
      });
    }
    return projects;
  }
  
  render() {
  
    return (
      <div className="UserProjects">
        {this.makeLinks()}
      </div>
    );
  }
}

