/**
 * Genres.js
 */
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import uniqueId from 'lodash.uniqueid';
import LoaderSimple from './subcomponents/LoaderSimple';
import { AmzGenreAddNew } from './Music/AmzGenreAddNew';
import AmzGenreRemove from './Music/AmzGenreRemove';

import {
  API_CLUSTERS_GENRES,
  API_CLUSTERS_GENREADD,
  MESSAGE_ERROR_GENERAL,
} from '../js/Configuration';

import {
  apirequest,
  getAuthData,
} from '../js/Utilities';

import * as Amz from '../actions/AmzUtils';

import './Music/css/AmzGenres.css';

const Genres = () => {
  const initState = {
    loading: true,
    genres: [],
    perPage: 1000,
    page: 1,
    ascending: 1,
    addNew: {
      open: false,
      name: '',
      notes: '',
    },
  };

  const [state, setState] = useState(initState);

  function getGenres() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('perPage', state.perPage);
    fd.set('page', state.page);
    fd.set('ascending', state.ascending);

    apirequest(API_CLUSTERS_GENRES, { body: fd }, (response) => {
      setState({ ...state, genres: response.results, loading: false });
    });
  }

  useEffect(() => {
    if (!state.genres.length) {
      getGenres();
    }
  });

  const refAddNewGenre = useRef(null);

  const onAddNewClose = (ref = null) => {
    if (ref && Object.hasOwn(ref, 'current')) {
      ref.current.close()
    }
  }

  function onAddNewOpen(ref = null) {
    if (ref && Object.hasOwn(ref, 'current')) {
      ref.current.showModal()
    }
  }

  function onAddNewChange(domEvent) {
    const field = domEvent.target.name;
    const { value } = domEvent.target;
    setState(() => Amz.setStateAddGenreChange(state, field, value));
  }

  function onAddNewSubmit(domEvent) {
    domEvent.preventDefault()
    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));

    apirequest(API_CLUSTERS_GENREADD, { body: fd }, (response) => {
      const cb = () => Amz.setStateAddGenrePostSubmit(state, response);
      setState(cb);

      if (!Object.hasOwn(response, 'error')) {
        onAddNewClose(refAddNewGenre);
      }
    });
  }

  function afterGenreRemove(response) {
    if (!response.success) {
      // eslint-disable-next-line no-alert
      setState({ loading: false }, () => { alert(MESSAGE_ERROR_GENERAL); });
    } else {
      setState(() => Amz.setStateAfterGenreRemoval(state, response))
    }
  }

  function onGenreRemove(domEvent) {
    domEvent.preventDefault();

    const form = domEvent.target;
    setState({ ...state, loading: true });

    Amz.genreRemove(form, afterGenreRemove);
  }

  function makeLinks() {
    if (Object.hasOwn(state, 'genres') && !state.genres.length) return null;
    let body = <LoaderSimple open />

    if (state.loading === false) {
      const links = state.genres.map((l) => (
        <li key={`genre_${uniqueId()}`}>
          <Link to={{ pathname: `/music/clusters/${l.id}` }}>{l.name}</Link>
          <AmzGenreRemove id={l.id} onSubmit={onGenreRemove} />
        </li>
      ));
      body = (
        <ul className="genres__list">
          {links}
        </ul>
      );
    }
    return body;
  }

  return (
    <article id="AmzGenres">
      <header>
        <h1>Genres</h1>
        <p>Click a genre name to view clusters.</p>
      </header>

      <AmzGenreAddNew
        ref={refAddNewGenre}
        id="AmzGenreAddNew"
        {...state.addNew}
        onClose={onAddNewClose}
        onChange={onAddNewChange}
        onOpen={onAddNewOpen}
        onSubmit={onAddNewSubmit}
      />

      {makeLinks()}

    </article>
  )
}

export default Genres;
