/**
 * AmzSongSearch.js
 */
import React from 'react';


import { getAuthData } from '../../js/Utilities';

export const AmzSongSearch = (props) => {
  let autocomp = 'off';
  switch(props.autocomplete) {
    case true:
      autocomp = 'on';
      break;
    case (props.autocomplete !== true):
      autocomp = props.autocomplete;
      break;
    default:
      autocomp = 'off';
  }
  
  return (
    <form id="AmzSongSearch" onSubmit={props.onSubmit}>
      <div>
        <label htmlFor="q">Search Songs by Title or ASIN</label>
      </div>
      <div>
        <input type="hidden" value={getAuthData('token')} name="token" id="token" onChange={()=>{}}/>
        <input type="text" value={props.value} name="q" id="q" onChange={props.onChange} autoComplete={autocomp} />
        <button type="submit" className="btn btn--action" disabled={!props.value}>Search</button>
      </div>
    </form>
  );
}
