/**
 * Stations.js
 * /stations page-level component
 */
import React from 'react';
import { Link } from 'react-router-dom';
import VideoSetEdit from './Destinations/VideoSetEdit';
import LoaderSimple from './subcomponents/LoaderSimple';


import {
  API_STATIONS,
  API_DESTINATIONS_ADDNEW
} from '../js/Configuration';

import {
  apirequest,
  getAuthData,
  isAdmin
} from '../js/Utilities';

import '../css/Stations.css';

export default class Stations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stations: [],
      addnew: {
        open: false,
        title: ''
      },
      loading: true
    };

    this.buildLinks = this.buildLinks.bind(this);
    this.onAddNewStation = this.onAddNewStation.bind(this);
    this.onAddNewStationInput = this.onAddNewStationInput.bind(this);
    this.onAddNewStationSave = this.onAddNewStationSave.bind(this);
    this.onAddNewStationClose = this.onAddNewStationClose.bind(this);
    this.makeAddNewStationModal = this.makeAddNewStationModal.bind(this);
    this.makeNewStationButton = this.makeNewStationButton.bind(this);
    
    this.isAdmin = isAdmin(this.props.project);
    this.closeVseModal = this.closeVseModal.bind(this);
  }

  componentDidMount() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_STATIONS, {body: fd}, (data) => {
      this.setState({stations: data, loading: false}, this.buildLinks)
    });
  }

  closeVseModal(dialogRef=null) {
    if(dialogRef && Object.hasOwn(dialogRef, 'current')) {
      dialogRef.current.close();
    }
  }

  onAddNewStation() {
    this.setState((state)=> {
      return {
        ...state,
        addnew: {
          ...state.addnew,
          open: true
        }
      }
    });
  }

  onAddNewStationInput(domEvent) {
    this.setState((state)=> {
      return {
        ...state,
        addnew: {
          ...state.addnew,
          title: domEvent.target.value
        }
      }
    });
  }

  onAddNewStationSave(dialogRef=null) {
    const fd = new FormData();
    fd.set('name', this.state.addnew.title.trim());
    fd.set('isstation', 1);
    fd.set('token', getAuthData('token'));

    apirequest(API_DESTINATIONS_ADDNEW, {body: fd}, (data) => {
      if(data.result === 'success') {
        this.setState((state) => {
          const newStation = [{
            id: data.d_id,
            station: data.d_name
          }];
        
          return {
            ...state,
            stations: newStation.concat([...this.state.stations]),
            addnew: {
              ...state.addnew,
              open: false,
              title: ''
            }
          }
        }, () => this.closeVseModal(dialogRef));

      } else {
        alert(data.message);
      }
    });
  }

  onAddNewStationClose(dialogRef) {
    this.setState((state) => {
      return {
        ...state,
        addnew: {
          ...state.addnew,
          setId: null,
          blockId: null,
          title: '',
          open: false 
        }
      }
    }, () => { this.closeVseModal(dialogRef); } );
  }


  makeAddNewStationModal() {
    return (
      <VideoSetEdit
         title="Add a new station"
         labelText="Enter a name for this station"
         open={this.state.addnew.open}
         value={this.state.addnew.title}
         onInput={this.onAddNewStationInput}
         onSubmit={this.onAddNewStationSave}
         onClose={this.onAddNewStationClose}
         error={this.state.addnew.error} />
    );
  }

  buildLinks(resp) {
    if(this.state.loading) {
      return <LoaderSimple open={this.state.loading} />;
    }
    
    return this.state.stations.map((item) => {
      return (
        <li key={`station_${item.id}`}>
          <Link to={item.id} state={{station: item.station }}>{item.station}</Link>
        </li>
      )
    });
  }

  makeNewStationButton() {
    let btn;
    if(this.isAdmin){ 
      btn = (
        <div>
          <button type="button" className="btn btn--action" onClick={this.onAddNewStation}>Add New Station</button>
        </div>
      );
    } else {
      btn = null;
    }
    return btn;
  }

  render() {
    return (
      <article className="stations__view">
        <header>
          <div>
            <h1>Stations</h1>
            <p>Click a station name to view series</p>
          </div>
          {this.makeNewStationButton()}
        </header>

        <ul className="stations__list">
          {this.buildLinks()}
        </ul>

        {this.makeAddNewStationModal()}
      </article>
    );

  }
}
