/**
 * ReportsChannelsUse
 * - Retrieves and displays destination information for a particular channel.
 */

import React from 'react';
import {
  useEffect,
  useState
} from 'react';

import SearchChannelUsage from './SearchChannelUsage';
import ReportsChannelUsageResults from './ReportsChannelUsageResults';
import LoaderSimple from '../subcomponents/LoaderSimple';

import {
  apirequest,
  getAuthData,
  debounce,
  setPageNumber
} from '../../js/Utilities';

import {
  download,
  prepareDestinationsMenu,

  setChannelUsePanelPage,
  reportsChannelUseDetailDefaultState,

} from '../../actions/ReportsUtils';

import {
  getDestinations,
  setLoading
} from '../../actions/DestinationsUtils';

import {
  API_CHANNEL_USAGE,
  API_CHANNELS_TYPEAHEAD,
  API_DESTINATIONS_LIST
} from '../../js/Configuration';

import '../../css/ReportsChannelsUse.css';

const ReportsChannelsUse = () => {
  let display;
  const limit = 50;

  const [ ascending, setAscending  ] = useState(0);
  const [ channel, setChannel ] = useState();
  const [ channels, setChannels ] = useState([]);
  const [ destinations, setDestinations ] = useState([]);
  const [ forDestination, setForDestination ] = useState(undefined);
  const [ loading, setLoading  ] = useState(false);
  const [ results, setResults ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ csv, setCSV ] = useState({});

  const abortcontroller = new AbortController();

  useEffect(() => {
    // Make sure we're at the top of the page
    window.scrollTo(0,0);

    // If no destinations are loaded, fetch them.
    if(!destinations.length) {
      getDestinations((response) => {
        const destMenu = prepareDestinationsMenu(response);
        setDestinations(destMenu);
      });
    }

    if( results.length ) {
      setLoading(false);
      setCount(results.length);
    }

  }, [results] );

  const onTextInput = (domEvent) => {
    setChannel(domEvent.target.value);

    const signal = abortcontroller.signal;
    const fd = new FormData();
    fd.set('search', domEvent.target.value);
    fd.set('token', getAuthData('token'));

    /* Populates SearchChannelUsage's <datalist> HTML element with matches */
    apirequest(API_CHANNELS_TYPEAHEAD, {body: fd, signal}, (data) => {
      setChannels(data.data);
    });
  }

  const onSelectChange = (value) => {
    setForDestination(value)
  }

  const makeFormData = () => {
    const fd =  new FormData();
    fd.set('channel', channel);
    fd.set('token', getAuthData('token'));
    return fd;
  }

  const searchDo = (domEvent) => {
    setLoading(true);
    const ch = channels.find((c) => domEvent.target.channel.value == c.channel_title);

    setChannel(ch.channel_youtubechannelid);

    const signal = abortcontroller.signal;
    const fd =  makeFormData();
    fd.set('group_by', 'destination');
    fd.set('destination', +domEvent.target.destination.value);
    fd.set('ascending', +ascending);

    apirequest(API_CHANNEL_USAGE, {body: fd, signal}, (response) => {
      setResults(reportsChannelUseDetailDefaultState(response.data.results));
      setCount(response.data.results.length);
      setCSV(response.data.csv)
    });

  }

  const onSearchSubmit = (domEvent) => {
    domEvent.preventDefault();
    searchDo(domEvent);
  }

  const onSearchReset = (domEvent) => {
    setAscending(0);
    setChannel('');
    setDestinations([]);
    setForDestination(0);
    setLoading(false);
    setResults([]);
    setCount(null);
  }

  const massageDataListResults = (results) => {
    if(!results) return;
    return res = results.map((result) => {
      return {
        title: result.channel_title,
        id: result.channel_youtubechannelid,
      }
    });
  } 

  const onDownload = (domEvent) => {
    domEvent.preventDefault();
    if(!csv.length) return;
    download( csv, csv[0] );
  }

  const getPage = (domEvent=null) => {
    const page = domEvent ? domEvent.target.value : null;
    const destId = JSON.parse(domEvent.target.dataset.data)?.destination;

    const current = results.find((r) => +r.destination === +destId);

    const dest = {
      ...current,
      detail: {
        ...current.detail,
        loading: !current.detail.loading,
        'page': setPageNumber(page, current.detail)
      }
    }

    fetchDetail(dest);
  }

  const onPanelToggle = (domEvent=null) => {
    let destination = 0;
    if(Object.hasOwn(domEvent.target.dataset, 'destinationId') ) {
      destination = +domEvent.target.dataset.destinationId;
    }

    /* Find the destination we want to fetch data for. */
    const dest = results.find((r) => destination === +r.destination);

    /*
      This is a detail/summary view. domEvent.target is a detail element with an
      open attribute. If it's open, and we don't already have results, fetch them.
      Send the destination as an object
    */
    if( domEvent.target.open && !dest.detail.results.length) {
      fetchDetail(dest);
    }
  }

  const onViewToggle = (domEvent=null) => {
    const destId = JSON.parse(domEvent.currentTarget.dataset.data)?.destination;
    const current = results.find((r) => +r.destination === +destId);

    const dest = {
      ...current,
      detail: {
        ...current.detail,
        loading: true,
          toggle: {
            ...current.detail.toggle,
            selected: +(!current.detail.toggle.selected),
            ascending: 0,
          }
      }
    }
    fetchDetail(dest);
  }

  const fetchDetail = (destination={}, changeView = false) => {
    const groupByOpts = ['none','video']

    /* Find the right destination object */
    const dest = {...destination};

    const signal = abortcontroller.signal;
    const fd =  makeFormData();
    fd.set('channel', channel); // Use channel from state
    fd.set('group_by', groupByOpts[+dest.detail.toggle.selected]);
    fd.set('destination', +dest.destination);
    fd.set('ascending', +ascending);
    fd.set('page', +dest.detail.page);
    fd.set('perPage', +dest.detail.perPage)

    apirequest(API_CHANNEL_USAGE, {body: fd, signal}, (response) => {
      setResults((previous) => {

        // Find which destination object to update from current state
        const next = [...previous];
        const index = next.findIndex((r) => +r.destination === +response.data.destination)

        const update = {
          ...dest,
          detail: {
            ...dest.detail,
            ...response.data,
            loading: false,
          },
        }
        next[index] = update;
        return next;
      });
    });
  }

  if(loading) {
    display = <LoaderSimple open={true} />
  } else {
     display = (
      <ReportsChannelUsageResults
        ascending={ascending}
        channel={channel}
        destinations={destinations}
        results={...results}
        count={count}
        csv={csv}
        onDownload={onDownload}
        onPanelToggle={onPanelToggle}
        onViewToggle={onViewToggle}
        onPageClick={getPage} />
    );
  }

  return (
    <div className="reports__page">
      <h1>Channel Usage</h1>
      <SearchChannelUsage
        apiURL={API_CHANNEL_USAGE}
        datalist={massageDataListResults(channels)}
        id="channel"
        selectId="destination"
        labelText="Enter a channel title, URL or YouTube ID:"
        name="channel"
        onSubmit={onSearchSubmit}
        onReset={onSearchReset}
        onInput={onTextInput}
        onSelectChange={onSelectChange}
        value={channel}
        limit={limit}
        options={destinations} />
      {display}
    </div>
  );
}

export default ReportsChannelsUse;