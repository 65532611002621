/**
 * DestinationsSearchResults.js
 * Display table data for the stations series.
 */
import React from 'react';
import { Link } from 'react-router-dom'
import { DestinationsRestoreSet } from './DestinationsRestoreSet';

import './css/DestinationsSearchResults.css';

export default class DestinationsSearchResults extends React.Component {
  constructor(props) {
    super(props);

    this.makeLink = this.makeLink.bind(this);
    this.makeTitle = this.makeTitle.bind(this);
    this.makeResultGroup = this.makeResultGroup.bind(this);
    this.makeControls = this.makeControls.bind(this);
    this.onDuplicate = this.onDuplicate.bind(this);
  }
  
  makeTitle() {
    let tle = null;
    if(this.props.keyword) {
      tle = <h3>Video sets matching <q>{this.props.keyword}</q></h3>
    }
    return tle;
  }

  makeLink(item) {
    const stateobj = {title: item.video_set_name};
    return <Link to={{ pathname: `/destinations/rundates/${item.id}`, state: stateobj }}>{item.video_set_name}</Link>
  }

  makeControls(item) {
    const stateobj = {title: item.video_set_name};
    let link= <Link to={{ pathname: `/destinations/rundates/${item.id}`, state: stateobj}} className="btn btn-sm btn--action">View / Edit</Link>;
    
    if(item.isActive == false && this.props.isAdmin) {
      link = <DestinationsRestoreSet id={item.id} onSubmit={this.props.onRestore} />;
    }
    return link;
  }

  makeResultGroup() {
    const groups = this.props.results.map((item, index) => {
      // Make rows of results
      const rows = item.sets.map((trd, idx) => {
        return (
          <tr key={`set_${trd.id}`}>
            <td>{this.makeLink(trd)}</td>
            <td>{this.makeControls(trd)}</td>
          </tr>
        );
      });
      // Return the group.
      return (
        <section className="destination__search_videosets" key={index}>
          <h4>{item.title}</h4>
          <table>
            <tbody>
              {rows}
            </tbody>
          </table>
        </section>
      )
    });

    return groups;
  }
 
  onDuplicate(domEvent) {
    domEvent.preventDefault();
    if(this.props.onDuplicate) {
      this.props.onDuplicate(domEvent.target.block.value);
    }
  }

  render() {
    return (
      <div id="DestinationSearchResults">
        {this.makeTitle()}
        {this.makeResultGroup()}
      </div>
    );
  }
}
