/**
 * ChannelListSearch
 * Search form for ChannelsList component.
 * Author: Webinista, Inc 6/11/18.
 */

import React from 'react';
import TextInput from './TextInput';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ChannelListSearch extends React.Component
{
  constructor(props)
  {
    super(props);
    this.submitHandler = this.submitHandler.bind(this);
  }

  submitHandler(domEvent) {
    domEvent.preventDefault();
    this.props.onSubmitHandler(domEvent);
  }

  render() {
    const cssClass = classNames(
      this.props.cssClass,
      {'channel__list__search': true}
    );

    return (
      <div>
        <form id="channel_list_search" action={this.props.apiURL} method="POST" className={cssClass} onSubmit={this.submitHandler}>
          <input type="hidden" id="limit" name="limit" value={this.props.limit} />
          <TextInput
            labelText={this.props.labelText}
            name="channel_filter"
            onInputHandler={this.props.onInputHandler}
            value={this.props.value}
            datalist={this.props.datalist}
            isRequired={true} />
        </form>
      </div>
    )
  }
}

ChannelListSearch.defaultProps = {
  apiURL: null,
  id: null,
  onChangeHandler: null,
  onInputHandler: null,
  onModalClickHandler: null,
  datalist: [],
  value: ''
};

ChannelListSearch.propTypes = {
  apiURL: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  onChangeHandler: PropTypes.func,
  onInputHandler: PropTypes.func,
  onSubmitHandler: PropTypes.func,
  value: PropTypes.string.isRequired,
  datalist: PropTypes.array
};

export default ChannelListSearch;
