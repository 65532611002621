import React from 'react';
import PropTypes from 'prop-types';

import DateInput from '../subcomponents/DateInput';
import './css/SearchDateRange.css';

export default class SearchDateInput extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(domEvent) {
    this.props.onChange(domEvent);
  }

  render() {

    return (
      <fieldset className="search__form__daterange">
        <div>
          <legend>Published Between:</legend>
          <div>
            <DateInput
              cssClass="search__form__date__label"
              name="publishedAfter"
              isRequired={false}
              value={this.props.afterDate}
              onChangeHandler={this.onChange} />
              <span>and</span>
            <DateInput
              cssClass="search__form__date__label"
              name="publishedBefore"
              isRequired={false}
              labelText="End of range"
              value={this.props.beforeDate}
              onChangeHandler={this.onChange} />
          </div>
        </div>
      </fieldset>
    )
  }
}

SearchDateInput.propTypes = {
  date: PropTypes.string
};

