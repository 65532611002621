import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import YTPlayIcon from './YTPlayIcon';

import '../../css/VideoItem.css';

import {
  formatTime,
  isNumeric
} from '../../js/Utilities';

import {
  MESSAGE_NOT_SUBMITTED
} from '../../js/Configuration';

export default class VideoDragItem extends React.Component {
  constructor(props)
  {
    super(props);
    this.playHandler = this.playHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);

    /*
      These three functions almost, but don't quite duplicate the same ones in
      VideoItem. We should fix that at some point.
    */
    this.meowOK = this.meowOK.bind(this);
    this.crtOK = this.crtOK.bind(this);
    this.makeBadge = this.makeBadge.bind(this);
  }

  playHandler(data) {
    if(this.props.playVideo){
      this.props.playVideo(data)
    };
  }

  deleteHandler(data) {
    if(this.props.deleteVideo){ 
      this.props.deleteVideo(data);
    }
  }

  makeBadge(obj = {}, isMeow = false) {
    const label = obj.label || MESSAGE_NOT_SUBMITTED;
    let css;

    if(isMeow) {
      css = classNames({
        'video__meow--okay': this.meowOK() === true,
        'video__meow--notok': this.meowOK() === false,
        'video__meow--escalate': this.meowOK() === 'escalate'
      });
    } else {
      css = classNames({
        'video__crt--okay': this.crtOK(),
        'video__crt--notok': this.crtOK() === false,
        'video__crt--escalate': this.crtOK() === 'escalate'
      });
    }

    return (
      <b className={ `video__badge itemsBadge ${css}`} >{label}</b>
    )
  }

  meowOK() {
    // Return this if it's not submitted.
    let okay;
    if(isNumeric(this.props.data.meow.id)) {
      switch(+this.props.data.meow.id) {
        case 0:
          okay = true;
          break;
        case 16:
          okay = 'escalate';
          break;
        default:
          okay = false;
      }
    } else {
      okay = null;
    }
    return okay;
  }

  crtOK() {
    let approved = false;
    const crtId =  +this.props.data.crt.id;

    if(crtId === 0) {
      approved = null;
    }

    if(crtId === 1) {
      approved = true;
    }

    if(crtId >= 10) {
      approved = 'escalate';
    }
    return approved;
  }

  render() {
    let cssClasses = classNames({
      'video__draggable' : true,
      'results__list__item--interstitial': this.props.data.is_interstitial,
      'video__inactive' : this.props.data.active === 'n'
    });

    return (
      <div className={cssClasses}>
        <div className="video__draggable__ordinal">{this.props.ordinal + 1}</div>
        <h5 data-video={this.props.data.ytID} onClick={this.props.playVideo.bind(null, this.props.data.ytID)} className="resultItemLink videoResultItemHeader results__list__item__title divTransition">{ this.props.data.name }</h5>
        <button data-video={this.props.data.ytID} onClick={this.props.playVideo.bind(null, this.props.data.ytID)} className="video__item__thumb videoThumb">
          <img src={this.props.data.thumb} alt={`Watch ${this.props.data.name}`} />
          <YTPlayIcon />
        </button>

        <p className="video__drag__item__duration">Duration: {formatTime(this.props.data.duration)}</p>
        <ul className="video__drag__item__data">
          <li><span><abbr>MEOW</abbr>:</span> <span>{this.makeBadge(this.props.data.meow, true)}</span></li>
          <li><span><abbr title="Content Review Team">CRT</abbr>:</span><span>{this.makeBadge(this.props.data.crt)}</span></li>
        </ul>
        <span className="video__item__delete">
          <button data-video={this.props.data.ytID} type='button' disabled={this.props.disabled} className='btn btn-sm btn--action' onClick={this.props.deleteVideo.bind(null, {id: this.props.data.ytID, position: this.props.data.position})}>{this.props.deleteText}</button>
        </span>
      </div>
    )
  }
}

VideoDragItem.defaultProps = {
  deleteText: 'Delete Video From Set'
}

VideoDragItem.propTypes = {
  deleteVideo: PropTypes.func,
  playVideo: PropTypes.func,
  data: PropTypes.any
};
