/**
 * DestinationsVideoSetsToggleSort.js
 * Toggles between list and grid views on Destinations, Stations
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import ToggleSwitch from '../subcomponents/ToggleSwitch';

export const DestinationsVideoSetsToggleSort = (props) => {
  return <ToggleSwitch label="Sort video sets: " selectedIndex={props.selectedIndex} options={['A - Z', 'Z - A', 'Newest first']} disabled={props.disabled} onChange={props.onChange} name="DestinationsVideoSetsToggleSort"/>
}
