/**
 * DestinationsSearch.js
 * Displays playlist URL and comments form fields.
 */
import React from 'react';
import PropTypes from 'prop-types';

import './css/DestinationsSearch.css';
import { DestinationsIncludeInactive } from './DestinationsIncludeInactive';

export default class DestinationsSearch extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  onSubmit(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();
    if(this.props.onSubmit) {
      this.props.onSubmit(domEvent);
    }
  }

  onReset(domEvent) {
    if(this.props.onReset) {
      this.props.onReset(domEvent);
    }
  }

  render() {
    let showIncludeInactive = null;
    if(this.props.isAdmin) {
      showIncludeInactive = <DestinationsIncludeInactive onChange={this.props.onInactiveChange} />;
    }
    return(
      <form method="dialog" id="DestinationsSearch" onSubmit={this.onSubmit} onReset={this.onReset}>
        <label htmlFor="q">Search for a Video Set</label>
        <div className="form__input_button_group">
          <span>
            <input type="search" id="q" name="q" value={this.props.value} onChange={this.props.onChange} aria-describedby="DestinationsSearch__hint" autoComplete="off" onFocus={this.props.onFocus} />
            <button type="submit" className="btn btn--action">Search</button>
          </span>
        </div>
        <p>
          <span id="DestinationsSearch__hint" className="aria__description">Add quotes around phrases for better results, (e.g. <code>"world cup"</code> instead of <code>world cup</code>.)</span>
          {showIncludeInactive}
        </p>
        <button type="reset" className="trigger__fauxlink" disabled={!this.props.value.length}>Return to Destinations list</button>
      </form>
    )
  }
}

DestinationsSearch.defaultProps = {
  value: '',
}

DestinationsSearch.propTypes = {
  value: PropTypes.string
};
