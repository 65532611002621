/**
 * AddVideosByURL.js
 * Displays playlist URL and comments form fields.
 */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CloseButton } from '../subcomponents/CloseButton';

import DialogModal from '../subcomponents/DialogModal';
import LoaderSimple from '../subcomponents/LoaderSimple';
import ToggleSwitch from '../subcomponents/ToggleSwitch';

import {
  getAuthData
} from '../../js/Utilities';

import {
  MESSAGE_ERROR_TOO_MANY_URLS
} from '../../js/Configuration';

import './css/AddVideosByURL.css';

export default class AddVideosByURL extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.makeDialogBody = this.makeDialogBody.bind(this);
    this.setFocus = this.setFocus.bind(this);
    this.makeToggle = this.makeToggle.bind(this);

    this.urlField = createRef();
    this.addVideoModal = createRef();
    this.closeModal = this.closeModal.bind(this);
  }
  
  componentDidUpdate() {
    if( this.props.open ) {
      this.addVideoModal.current.showModal()
    } 
  }

  closeModal() {
    if(this.props.onClose) {
      this.props.onClose(this.addVideoModal);
      return;
    } else {
      this.addVideoModal.current.close();
    }
  }

  onChange(domEvent) {
    if(!this.props.onChange) return;
    this.props.onChange(domEvent);
  }
  
  onSubmit(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();

    if(this.props.onSubmit) {
      this.props.onSubmit(domEvent, this.addVideoModal);
    }
  }

  setFocus() {
    this.refs.urlField.current.focus();
  }

  makeToggle() {
    let toggle = null;
    if(this.props.toggle) {
      toggle = <ToggleSwitch onChange={this.props.onPrependChange} label="Add to beginning?" className="addvideos__toggle" options={this.props.toggle.options} name="prepend" selectedIndex={+this.props.toggle.selected} />;
    }
    return toggle;
  }

  makeDialogBody() {
    const blockid = (this.props.block && this.props.block.hasOwnProperty('vsb_id')) ? this.props.block.vsb_id : '';
    const setid = (this.props.block && this.props.block.hasOwnProperty('vsb_setID')) ? this.props.block.vsb_setID : '';
    
    return (
      <div id="add_videos_by_url">
        <form method="dialog" id="add_videos_by_url_form" onSubmit={this.onSubmit}>
          <LoaderSimple cssClass="add__by__url__loading" open={this.props.working} />
          <input type="hidden" name="setid" id="add_videos_by_url_videoset" value={setid} onChange={()=>{}} />
          <input type="hidden" name="blockid" id="add_videos_by_url_blockid" value={blockid} onChange={()=>{}} />
          <input type="hidden" name="userID" id="add_videos_by_url_userid" value={getAuthData('uid')} onChange={()=>{}} />
          <input type="hidden" name="userUID" id="add_videos_by_url_uid" value={getAuthData('uuid')} onChange={()=>{}} />
          <input type="hidden" name="userName" id="add_videos_by_url_username" value={getAuthData('user')} onChange={()=>{}} />
                
          <p>
            <label htmlFor="add_videos_by_url_urls">Enter URLs</label> <span className="form__instructions" id="add_videos_by_url_note">(Maximum 50 URLs or YouTube identifiers.)</span><br />
            <textarea className="form-control" id="add_videos_by_url_urls" aria-describedby="add_videos_by_url_note" name="videoids" value={this.props.value} onChange={this.onChange} rows="20" ref="urlField" required disabled={this.props.working}></textarea>
            <span className="form__instructions aria__description">Use valid YouTube URLs or video IDs. URLs or IDs should be separated either by a comma (,) or new line. If using URLs, include the protocol for each, e.g.: <code>http://</code> or <code>https://</code>. Limit 50.</span>
          </p>     
        </form>
        
        <div>
          <i><span hidden={!this.props.maxIds} className="form__instructions form__error"><b>{MESSAGE_ERROR_TOO_MANY_URLS[0]}</b> {MESSAGE_ERROR_TOO_MANY_URLS[1]}</span></i>
          {this.makeToggle()}
          <button type="submit" form="add_videos_by_url_form" disabled={this.props.maxIds} className="btn btn--action">Add Videos</button>
        </div> 
      </div>
    )
  }

  render() {
    const cssClass = classNames({
      'add__videos__by__url': true,
    }, this.props.cssClass);

    return (
      <dialog ref={this.addVideoModal} id="AddVideoByURL" onClose={this.props.onClose} className={cssClass}>
        <CloseButton longForm={true} onClick={this.closeModal} />
        <h2>Add Videos By URL</h2>
        {this.makeDialogBody()}
      </dialog>
    );
  }
}

 
AddVideosByURL.defaultProps = {
  open: false,
  block: null
}

AddVideosByURL.propTypes = {
  open: PropTypes.bool.isRequired,
  block: PropTypes.any, // For now, this can be an object that contains the block ID/vsb_id.
  cssClass: PropTypes.string
};
