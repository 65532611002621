import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SelectMenuControlled from '../subcomponents/SelectMenuControlled';

import './css/FilterComponent.css';

export default class FilterComponent extends React.Component {
  constructor( _props ) {
    super( _props );
    this.makeMenuItems = this.makeMenuItems.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.makeSortIndicator = this.makeSortIndicator.bind(this);
    
    const sortData = [{ value:'none', name:'Select property to sort by', type:'none' }];
    const filterData = [{ isDisabled: false, value:'none', name:'Select property to filter by', type:'none' }];
    
    this.state = {
      sortData: sortData.concat(this.props.sortData),
      filterData: filterData.concat(this.props.filterData)
    }
  }

  onFilterChange(domEvent) {
    domEvent.persist();
    domEvent.preventDefault();
    this.props.onFilterChange(domEvent);
  }

  onSortChange(domEvent) {
    domEvent.persist();
    domEvent.preventDefault();   
    this.props.onSortChange(domEvent);
  }
  
  makeMenuItems(arrayOfItems=[]) {
    let menu = null;
    
    if(arrayOfItems.length) {
      menu = arrayOfItems.map((item, index) => {
        let disabledOpt;
        
        disabledOpt = item.hasOwnProperty('isDisabled') ? item.isDisabled : false;
        return <option key={index.toString()} value={`${item.value}`} disabled={disabledOpt}>{item.name}</option>
      });
    }
    return menu;
  }
  
  makeSortIndicator() {
    if(!this.props.hasOwnProperty('ascending')) return null;

    const arrow = this.props.ascending ? '↑' : '↓';
    const label = this.props.ascending ? 'Ascending' : 'Descending';
    
    return (
      <b className="sort__order__icon" aria-label={label}>{arrow}</b>
    );
  }

  render() {

    const cssClass = classNames(
      this.props.cssClass,
      {'filter__component': true}
    );

    return (
      <div className={cssClass}>
        <form id="filter_videos" method="dialog" onChange={ this.onFilterChange } onReset={ this.props.onFilterClear }>          
          <div className="filter__vid">
            <label htmlFor="searchValue">{`Filter ${this.props.itemsName}:`}</label>
            <input className="form-control" type='text' name="searchValue"  onChange={()=>{}} id="searchValue" value={ this.props.searchValue } disabled={!this.props.hideCategories} />
          </div>

          <p hidden={!this.props.showHint} id="filter__component__hint">
            For best results, use quotes for filters containing multiple words. <abbr title="example">ex:</abbr> <code><q>room makeover</q></code> instead of <code>room makeover</code>.
          </p>
          <p className="ava-input-select">
            <select name="filterOn" id="filterOn" value={ this.props.filterOn } onChange={()=>{}}>
              { this.makeMenuItems( this.state.filterData )}
            </select>
          </p>
 
          <div className="filter__categories" hidden={this.props.hideCategories}>
            <SelectMenuControlled
              options={this.props.categories}
              labelText="Category"
              firstEmpty={false}
              name="category"
              value={this.props.category}
              disabled={ !this.props.categories.length } />
          </div>

          <p><button type="reset" className="btn btn-sm btn--action filterBtn">Clear Filter</button></p>
        </form>

        <form id="sort_videos">
          <div className="filter__sort">
            <label htmlFor="sortBy">{`Sort ${this.props.itemsName} by Property:`}</label>
            <div className="ava-input-select">
              <select name='sortBy' id='sortBy' value={this.props.sortDefault} onChange={this.onSortChange}>
                {this.makeMenuItems(this.state.sortData)}
              </select>
            </div>
          </div>
          <div style={{'display': 'flex', 'alignItems': 'center'}}>
            <button type='button' className='btn btn-sm btn--action filterBtn' onClick={this.props.onDirectionChange}>Change Direction</button>
            <span className="sort__order"><span className="form__access__label">Sort Direction is now:</span>  {this.makeSortIndicator()}</span>
          </div>
        </form>
      </div>
    );
  }
}

FilterComponent.defaultProps = {
  categories: []
};

