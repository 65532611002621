import React from 'react';
import PropTypes from 'prop-types';

import './css/SearchInChannels.css';

export default class SearchInChannels extends React.Component {
  constructor(props) {
    super(props);
    this.onShowChannels = this.onShowChannels.bind(this);
  }

  onShowChannels(domEvent) {
    if(this.props.onShowChannels) {
      this.props.onShowChannels();
    }
  }

  render() {
    return (
      <div className="search__form__item form-group search__inchannels">
        <b>Search in Channels:</b>
        <button type="button" className="btn btn-sm btn--action" onClick={this.onShowChannels}>Show Channel List</button>
      </div>
    )
  }
}

SearchInChannels.defaultProps = {
  count: 9000
};

SearchInChannels.propTypes = {
  count: PropTypes.number,
  onShowChannels: PropTypes.func.isRequired
};
