/**
 * ResetPasswordEmailSent.js
 * Displayed when there is no password token in the URL.
 */
import React from 'react';
import { PASSWORD_REQUEST_SENT } from '../js/Configuration';

export const ResetPasswordEmailSent = () => {
  return (
    <div className="page__set__password">
      <h1>Thanks!</h1>
      <p>{PASSWORD_REQUEST_SENT}</p>
      <p><a href="/">Log in</a></p>
    </div>
  );
}
