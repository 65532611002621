/** *
 * Group.js
 * Page component for single group page
 */

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import GroupsChannelInfo from './subcomponents/GroupsChannelInfo';
import ChannelsList from './ChannelsList';

import {
  API_CHANNEL_BATCH,
  API_CHANNEL_INFO,
  API_CHANNEL_REMOVE,
  API_CHANNEL_SETTAGS,
  API_CHANNEL_DELETE,
  API_GROUPS_ALLGROUPS,
  API_GROUPS_ADD_CHANNEL_TO_GROUP,
  API_GROUP_ADD_NEW,
  API_GROUPS_DELETE,
  API_GROUPS_REMOVE_CHANNEL,
  API_GROUPS_CHANNELS_IN_GROUP
} from '../js/Configuration';


import {
  apirequest,
  getAuthData,
  getSortDirection,
  isAdmin
} from '../js/Utilities';

import * as G from '../actions/GroupsUtils';

import '../css/Group.css';
import '../css/GroupsPageTable.css';


class Group extends React.Component {
  constructor( _props ) {
    super( _props );

    this.state = {
      channelList: [],
      /* ChannelInfo modal */
      channelInfo: {
        open: false,
      },
      /* ChannelList modal */
      allChannels: {
        open: false,
        loading: true,
        page: 1,
        perPage: 10,
        pageCount: 1,
        list: [],
        sortBy: 'video_addeddate',
        ascending: true,
        keyword: '',
        filterOn: '',
        toAdd: [],
      }      
    };

    this.closeModal = this.closeModal.bind(this);
    this.getChannelInfo = this.getChannelInfo.bind(this);
    this.getChannelsInGroup = this.getChannelsInGroup.bind(this);
    this.makeTable = this.makeTable.bind(this);
    this.makeNoChannels = this.makeNoChannels.bind(this);
    this.onClose = this.onClose.bind(this);

    this.onShowChannels = this.onShowChannels.bind(this);

    this.onChannelFromRemoveGroup = this.onChannelFromRemoveGroup.bind(this);
    this.onUpdateTags = this.onUpdateTags.bind(this);
    
    this.onChannelListSearchChange = this.onChannelListSearchChange.bind(this);
    this.onChannelListClose = this.onChannelListClose.bind(this);
    this.onChannelSelectChange = this.onChannelSelectChange.bind(this);
    this.onPaginationClick = this.onPaginationClick.bind(this);
    this.onChannelAddToGroup = this.onChannelAddToGroup.bind(this);
    this.onChangePerPage = this.onChangePerPage.bind(this);
    this.onDeleteGroup = this.onDeleteGroup.bind(this);
    this.onChannelRemoveSystem = this.onChannelRemoveSystem.bind(this);
  }

  closeModal(dialogRef) {
    if(dialogRef && Object.hasOwn(dialogRef, 'current')) {
      dialogRef.current.close()
    }
  }
  
  onChannelListSearchChange(domEvent) {
    const value = domEvent.target.value;
    this.setState((state) => G.setStateOnChannelFilter(state, value), this.getChannelBatch);
  }

  onChannelListClose(dialogRef) {
    this.setState((G.setStateOnCloseChannelList), () => {
      this.closeModal(dialogRef)
    });
  }

  onUpdateTags(domEvent) {
    domEvent.preventDefault();
    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));
    fd.set('id', +this.state.channelInfo.channel_id);
    apirequest(API_CHANNEL_SETTAGS, {body: fd}, (data) => {
      this.setState({showChannelInfo: false})
    });
  }
  
  getChannelsInGroup() {
    if( !Object.hasOwn(this.props.params, 'groupId') ) {
      alert('No group ID passed');
      return;
    }
    
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', +this.props.params.groupId)
    apirequest(API_GROUPS_CHANNELS_IN_GROUP, {body: fd}, (data) => {
      this.setState((state) => G.setStateOnGetGroupChannels(state, data))
    });
  }

  componentDidMount() {
    this.getChannelsInGroup();
  }
  
  getChannelInfo(domEvent=null) {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', domEvent.target.dataset.channelid)
    apirequest(API_CHANNEL_INFO, {body: fd}, (data) => {
      this.setState((state) => G.setStateOnShowChannelInfoResponse(state, data));
    });
  }
  
  getChannelBatch() {
    const fd = new FormData();
    fd.set('perpage', this.state.allChannels.perPage);
    fd.set('page', this.state.allChannels.page);
    fd.set('sort_by', this.state.allChannels.sortBy);
    fd.set('filter_by', this.state.allChannels.keyword);
    fd.set('filter_on', this.state.allChannels.filterOn);
    fd.set('order', getSortDirection(this.state.allChannels.ascending));
    fd.set('token', getAuthData('token'));

    apirequest(API_CHANNEL_BATCH, {body: fd}, (data) => {
      this.setState((state) => {
        return {
          allChannels: {
            ...state.allChannels,
            list: data.channels,
            page: +data.current_page,
            pages: data.pages,
            loading: false,
            open: true
          }
        }
      });
    });
  }
  
  /*
   Using the same function to close both modals. They should never be open at the same
   time, and updating state with every invocation doesn't harm performance.
  */
  onClose(dialogRef=null) {
    this.setState((state) => {
      return {
        channelInfo: {
          ...state.channelInfo,
          open: false
        },
        allChannels: {
          ...state.allChannels,
          open: false
        }
      }
    }, () => this.closeModal(dialogRef));
  }
    
  onChannelFromRemoveGroup(groupId, channelId, dialogRef=null) {
    const confirmed = window.confirm('Are you sure that you want to remove this channel from the group?');
    if(!confirmed) return;

    const fd = new FormData();
    fd.set('group', groupId);
    fd.set('channel', channelId);
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUPS_REMOVE_CHANNEL, {body: fd}, (data) => {
      this.setState(
        (state) => G.setStateAfterChannelDelete(state, data),
        () => {
          if(dialogRef && Object.hasOwn(dialogRef, 'current')) {
            dialogRef.current.close();
          }
        }
      );
    });
  }
  
  onShowChannels() {
    this.setState((state) => {
      return {
        allChannels: {
          ...state.allChannels,
          loading: true,
          open: false,
        }
      }
    }, this.getChannelBatch);
  }

  onChangePerPage(domEvent) {
    domEvent.persist();

    this.setState((state) => {
      return {
        allChannels: {
          ...state.allChannels,
          loading: true,
          page: 1,
          perPage: +domEvent.target.value
        }
      }
    }, this.getChannelBatch);
  }

  onPaginationClick(domEvent) {
    domEvent.persist();
   
    this.setState((state) => {
      return {
        allChannels: {
          ...state.allChannels,
          loading: false,
          page: state.allChannels.page + (+domEvent.target.value)
        }
      }
    }, this.getChannelBatch);    
  }

  onChannelSelectChange(domEvent) {
    const selectedItem = domEvent.target;
    
    this.setState((state) => {
      let add;

      const prev = state.allChannels.toAdd;
      
      if( selectedItem.checked ) {
        add = [...prev, selectedItem.value]
      } else {
        add = [...prev].filter((ch) => ch !== selectedItem.value);
      }
      
      return {
        allChannels: {
          ...state.allChannels,
          toAdd: add,
        }
      };
    });

  }
 
  onChannelAddToGroup(dialogRef) {
    const fd = new FormData();
    fd.set('group', this.state.group_id);
    fd.set('channels', this.state.allChannels.toAdd.join(','));
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUPS_ADD_CHANNEL_TO_GROUP, {body: fd}, (data) => {     
      this.setState( (state) => {
        return {
          channelList: data.list,
          allChannels: {
            ...state.allChannels,
            open: false,
            loading: true,
          }
        }
      }, () => {
          this.closeModal(dialogRef);
          this.getChannelsInGroup();
        });
    });
  }

  onDeleteGroup() {
    const confirm = window.confirm(`Confirm that you want to delete "${this.state.group_name}" by clicking OK.`);

    if(!confirm) return;

    const fd = new FormData();
    fd.set('id', +this.state.group_id);
    fd.set('token', getAuthData('token'));
    apirequest(API_GROUPS_DELETE, {body: fd}, (response) => {
      if(response.success) {
        window.alert(`Group "${this.state.group_name}" was deleted.`);
        window.location = `/groups`;
      } else {
        window.alert(`Could not delete ${this.state.group_name}.`);
      }
    });
  }

  onChannelRemoveSystem(domEvent) {
    domEvent.preventDefault();

    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));

    apirequest(API_CHANNEL_REMOVE, {body: fd}, (data) => {
      const deleted_channel = domEvent.target.delete_channel.value;       
  
      const deleted_object = [...this.state.channelList].filter((ch) => ch.youtubeid === deleted_channel);

      if( data.status === 'success' ) {
        this.setState((state) => {
          return {
            channelList: [...state.channelList].filter((ch) => ch.youtubeid !== deleted_channel),
            channelInfo: {
              ...state.channelInfo,
              open: false,
            }
          }
        }, () => {
          alert(`${deleted_object[0].channel_title} was deleted from AVA.`);
        });
      }
    });
  }

  makeNoChannels() {
    return (
      <>
        <h2>No channels in this group.</h2>
        <p><Link to="/groups">Return to Groups page</Link></p>
      </>
    )
  }

  makeTable() {
    const rows = this.state.channelList.map((ch, idx) => {
      const onRemove = (domEvent) => {
        const g = this.props.params.groupId;
        const c = domEvent.target.dataset.channelid
        this.onChannelFromRemoveGroup(g, c);
      }
 
      return (
        <tr key={idx}>
          <td>{ch.channel_title}</td>
          <td>
            <div className="button__group">
              <button type="button" className="btn btn-sm btn--action" onClick={this.getChannelInfo} data-channelid={ch.youtubeid}>Show Channel Info</button>
              <button type="button" className="btn btn-sm btn--action" onClick={onRemove} data-channelid={ch.youtubeid}>Remove</button>
            </div>
          </td>
        </tr>
      )
    });
    
    return (
      <table className="groups__table" style={{marginTop: '4rem'}}>
        <thead>
          <tr>
            <th>Channel Name</th>
            <th style={{width: '15%'}}>Edit Group</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }

  render() {

    return (
      <>
        <article>
          <h2>Channels for Group <q>{this.state.group_name}</q></h2>
          
          <p className="button__group">
            <button onClick={this.onShowChannels} type="button" className="btn btn-sm btn--action">Add Channel To Group</button>
            <button onClick={this.onDeleteGroup} type="button" className="btn btn-sm btn--action">Delete Group</button>
          </p>

          { !!this.state.channelList.length ? this.makeTable() : this.makeNoChannels() }
        </article>
      
        <GroupsChannelInfo
          {...this.state.channelInfo}
          selectedGroup={this.props.params.groupId}
          onAddHandler={this.props.onAddChannelToGroup}
          onClose={this.onClose}
          onRemoveFromSystem={this.onChannelRemoveSystem}
          onUpdateTags={this.onUpdateTags}
          onRemoveFromGroup={this.onChannelFromRemoveGroup}
          onGroupChangeHandler={this.props.onGroupChangeHandler} />
        
        <ChannelsList
          filterKeyword={this.state.allChannels.keyword}
          onChannelFilterChange={this.onChannelListSearchChange}
          open={this.state.allChannels.open}
          currentSet={this.state.channelList}
          onSelectChange={this.onChannelSelectChange}
          closeHandler={this.onChannelListClose}
          currentPage={+this.state.allChannels.page}
          totalPages={this.state.allChannels.pageCount}
          perPage={this.state.allChannels.perPage}
          onPerPageChange={this.onChangePerPage}
          channels={this.state.allChannels.list}
          onSubmit={this.onChannelAddToGroup}
          onPaginationClick={this.onPaginationClick}
          videoSearchSubmitHandler={this.videoSearchSubmitHandler}
          videoSearchInputHandler={this.videoSearchInputHandler}
          showPrevious={this.state.page > 1}
          showNext={this.state.allChannels.page < this.state.allChannels.pages}
         />
      </>
    )
  }
}

export default (props) => (
  <Group
      {...props}
      params={useParams()}
  />
);

