/**
 * ChannelEditTags.js
 */
import React from 'react';
import './css/ChannelEditTags.css';
import { ActionMessageDisplay } from '../subcomponents/ActionMessageDisplay';


export const ChannelEditTags = (props) => {
  return (
    <details id="ChannelsEditTags" className="channel__singlepg__panel" open>
      <summary>
        <h2>Edit Tags</h2>
      </summary>
      <form className="form__channel__edit__tags" onSubmit={props.onSubmit}>
        <ActionMessageDisplay mode='success' hidden={!props.success} message="Tags updated." onClose={props.onMessageClose} />
        <p>
          <input type="hidden" name="channelID" value={props.channel} />
          <label htmlFor="channel_tags">Add or remove tags from the list</label>
          <textarea id="channel_tags" name="channel_tags" value={props.tags} onChange={props.onChange}></textarea>
        </p>
        <button type="submit" className="btn btn--action">Save Tags</button>
      </form>
    </details>  
  );
}

