/**
 * UserAdminHeader.js
 * The navigation display for the UserAdmin page(s).
 */

import React from 'react';
import {
  NavLink
} from 'react-router-dom';

import { SwitchProjects } from './SwitchProjects';
import { SwitchProjectsMenu } from './SwitchProjectsMenu';

import { checkAccess } from '../../js/Utilities';

export default class YouTubeHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let switcher = this.props.canswitch ? <SwitchProjects onClick={this.props.onSwitch} /> : null;

    const nav = (
      <div className="nav__project__wrap">
        <nav id="UserAdminHeader" className="nav__project" aria-label="User Admin Menu">
          <b className="nav__project_label">User Administration</b>
        </nav>
        { switcher }
        <SwitchProjectsMenu open={this.props.menuopen} onClose={this.props.onSwitch} />
      </div>
    );
    return nav;
  }
}
