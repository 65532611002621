/**
 * AmzSongsRemove.js
 * A form for removing songs from a cluster.
 */
import React from 'react';
import uniqueId from 'lodash.uniqueid';

export const AmzSongsRemove = (props) => {  
  const emptyOnChange = ()=>{};
  
  return (
    <form className="AmzSongsRemove" id={uniqueId('AmzSongsRemove_')} onSubmit={props.onSubmit}>
      <input type="hidden" name="asin" value={props.ASIN} onChange={emptyOnChange} />
      <input type="hidden" name="cluster" value={props.cluster} onChange={emptyOnChange} />
      <button type="submit" className="btn btn--action">Remove from Cluster</button>
    </form>
  );
}
