/**
 * ToggleSwitch.js
 * Toggles between two options
 * See PropTypes definitions at the end of this file for parameters
 */

import React, { useId } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';

import './css/ToggleSwitch.css';

const ToggleSwitch = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const name = props.name || uniqueId('ToggleSwitchInput__');

  /* eslint-disable */
  const cssClass = classNames({
    'toggle__switch': true,
  }, props.className);
  /* eslint-enable */

  const options = props.options.map((opt, index) => {
    const fieldId = useId();

    return (
      <div key={uniqueId()}>
        <input
          type="radio"
          disabled={props.disabled}
          value={(!index)}
          name={name}
          onChange={() => {}}
          checked={+index == +props.selectedIndex}
          id={fieldId}
        />
        <label htmlFor={fieldId}>{opt}</label>
      </div>
    );
  });

  return (
    <div id={`${props.name}Control`} className={cssClass}>
      <form
        id={`${props.name}Form`}
        className="toggle__switch__form"
        data-data={props.data}
        onChange={props.onChange}
      >
        <fieldset>
          <div className="toggle__switch__group">
            <legend>{props.label}</legend>
            {options}
          </div>
        </fieldset>
      </form>
    </div>
  );
}

ToggleSwitch.defaultProps = {
  selectedIndex: 0,
  disabled: false,
};

ToggleSwitch.propTypes = {
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  selectedIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

export default ToggleSwitch;
