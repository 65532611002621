/**
 * DateInput.js
 * A no-framework date input 
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DateInput = ({...props}) => {

  const showOptionalMessage = () => {
    let msg = '';
    msg = props.isRequired ? '(Required)' : '(Optional)';
    return msg;
  }

  const cssClasses = classNames('input-date destination-date', props.cssClass);
  const id = props.id || props.name;

  return (
    <div className={cssClasses}>
      <label htmlFor={id}>{props.labelText}</label>
      <input type="date"
        defaultValue={props.value || ''}
        name={props.name}
        id={props.id}
        required={props.isRequired}
        onChange={props.onChangeHandler} /><br />
        <span>{showOptionalMessage()}</span>
    </div>
  );
}


DateInput.defaultProps = {
  cssClass: null,
  id: null,
  value: '',
  labelText: "Add label text",
  isRequired: true
};

DateInput.propTypes = {
  id: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  cssClass: PropTypes.string,
  value: PropTypes.string,
  isRequired: PropTypes.bool
};

export default DateInput;
