/**
 * DestinationsPlaylistTable.js
 * Display table data for the stations series.
 */
import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom'


import {
  NULL_DATE_TEXT_STATIONS,
  INTERSTITIAL_INDICATOR,
  INTERSTITIAL_NOTE
} from '../../js/Configuration';

import {
  formatTime
} from '../../js/Utilities';


import '../Stations/css/StationsSeriesTable.css';
import './css/DestinationsPlaylistTable.css';

export default class DestinationsPlaylistTable extends React.Component {
  constructor(props) {
    super(props);

    this.makeHeader = this.makeHeader.bind(this);
    this.makeRows = this.makeRows.bind(this);
    this.makeLink = this.makeLink.bind(this);
    this.makeControls = this.makeControls.bind(this);

    this.onDuplicate = this.onDuplicate.bind(this);
  }

  makeHeader() {
    const ths = this.props.header.map((thd, idx) => {
      return <th key={idx}>{thd}</th>;
    });
    return <thead><tr>{ths}</tr></thead>;
  }
  
  makeLink(item) {
    const datelabel = item.vsb_datetimetorun && item.vsb_datetimetorun !== '0000-00-00' ? format(new Date( item.vsb_datetimetorun ), 'PP' ) : NULL_DATE_TEXT_STATIONS;
    let interstit = item.contains_interstitial ? <b className="icon"><span aria-hidden="true">{INTERSTITIAL_INDICATOR}</span> <span className="form__access__label">Contains Interstitial</span></b> : null;
    return (
      <span>
        {interstit}
        <Link to={`/destinations/playlist/${item.vsb_id}`}>{datelabel}</Link>
      </span>
    );
  }

  makeControls(item) {
    return (
      <div>
        <Link to={`/destinations/playlist/${item.vsb_id}`} className="btn btn-sm btn--action">View / Edit</Link>
        <form action="/" onSubmit={this.onDuplicate} className="playlist__edit">
          <input type="hidden" name="block" id="block_id" value={item.vsb_id} />
          <button type="submit" className="btn btn-sm btn--action">Duplicate</button>
        </form>
      </div>
    );
  }

  makeRows() {
    const trs = this.props.rows.map((trd, idx) => {
      return (
        <tr key={`set_${trd.vsb_id}`}>
          <td>{this.makeLink(trd)}</td>
          <td className="duration">{formatTime(trd.duration)}</td>
          <td>
            {this.makeControls(trd)}
          </td>
        </tr>
      );
    });
    
    return <tbody>{trs}</tbody>;
  }
 
  onDuplicate(domEvent) {
    domEvent.preventDefault();
    if(this.props.onDuplicate) {
      this.props.onDuplicate(domEvent.target.block.value);
    }
  }
  render() {
    return (
      <div className="destinations__playlist">
        <p className="destinations__component__note">{INTERSTITIAL_NOTE}</p>

        <table className="stations__series__table destinations__playlist__table">
          {this.makeHeader()}
          {this.makeRows()}
        </table>

      </div>
    );
  }
}
