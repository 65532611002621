/**
 * PaginationBar.js
 * A no-framework pagination component
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { arraychunk, range } from '../../js/Utilities';

import '../../css/pagination.css';

class PaginationBar extends React.Component {
  constructor(props) {
    super(props);
    this.batches = this.batches.bind(this);
    this.makeNav = this.makeNav.bind(this);
    this.makeButton = this.makeButton.bind(this);
    this.makeFirstLast = this.makeFirstLast.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onClick(domEvent = null) {
    domEvent.persist();
    if(this.props.onClickHandler) {
      this.props.onClickHandler(domEvent);
    }
  }
  
  batches() {
    let max = 1;

    if(this.props.pages) {
      max = this.props.pages;
    }
    const navSize = this.props.navSize || 10;
    
    const pgs = range(1, max);
    const batches = arraychunk(pgs, navSize);
    return batches;
  }

  makeButton(pg) {
    const cssClass = classNames({
      'pagination__pg': true,
      'pagination__pg__current': pg === this.props.page
    });
    return <button key={`pagination__pg--${pg}`} className={cssClass} type="button" value={pg} onClick={this.onClick} data-data={this.props.data}>{pg}</button>
  }

  makeFirstLast(last = false, disabled = false) {
    if(this.props.pages <= this.navSize) { return null; }
 
    let text = 'first';
    if(last) {
      text = 'last';
    }
    return (
      <button
        className={`pagination__${text} pagination__pg`}
        type="button"
        onClick={this.onClick}
        data-data={this.props.data}
        value={text}
        disabled={disabled}><span className="form__access__label">{text.charAt(0).toUpperCase()} Page</span></button>
    );
  }

  makeNav() {
    // Break the number of pages into chunks of 10.
    const batches = this.batches();
    
    // Finds the index of the batch that contains the currently-requested page
    const batch = batches.findIndex((b) => b.indexOf(this.props.page) > -1);
    
    /*
     If we change the number of pages available and our new requested page is no
     longer in range, reset the page batch to 0
    */
    const index = (batch > -1) ? batch : 0;
    
    // Return a set of buttons for the current chunk
    return batches[index].map(this.makeButton);
  }
    
  render() {    
    const isFirstPage = (this.props.pages === 1) || (this.props.page === 1);

    let isLastPage = false;
    if(this.props.page >= this.props.pages) {
      isLastPage = true;
    }

    return (
      <div className="pagination__component--bar">
        {this.makeFirstLast(false, isFirstPage)}
        <button
          data-data={this.props.data}
          className="pagination__previous pagination__pg"
          type="button"
          onClick={this.onClick}
          value="previous"
          disabled={isFirstPage}><span className="form__access__label">Previous</span></button>
        {this.makeNav()}
        <button
          data-data={this.props.data}
          className="pagination__next pagination__pg"
          type="button"
          onClick={this.onClick}
          value="next"
          disabled={isLastPage}><span className="form__access__label">Next</span></button>
        {this.makeFirstLast(true, isLastPage)}
      </div>
    )
  }
}

PaginationBar.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  onClickHandler: PropTypes.func.isRequired
};

export default PaginationBar;
