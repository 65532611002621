/*
  eslint react/destructuring-assignment: 0

*/

/**
 * AmzSongs.js
 */
import React from 'react';
import { useParams } from 'react-router-dom';
import uniqueId from 'lodash.uniqueid';

import LoaderSimple from '../subcomponents/LoaderSimple';
import { AmzSongSearch } from './AmzSongSearch';
import AmzSongAddSongs from './AmzSongAddSongs';
import { AmzSongsReorder } from './AmzSongsReorder';
import { AmzSongsRemove } from './AmzSongsRemove';
import { AmzSongsReorderTrigger } from './AmzSongsReorderTrigger';

import {
  API_CLUSTERS_SONGS,
  API_CLUSTERS_SINGLEGENRE,
  API_CLUSTERS_SONG_GET_ASINDATA,
  API_CLUSTERS_SONGSREORDER,
  API_CLUSTERS_SONG_REMOVE,
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData,
} from '../../js/Utilities';

import {
  download,
} from '../../actions/ReportsUtils';

import * as Amz from '../../actions/AmzUtils';

import './css/AmzSongs.css';

const withParams = (Component) => ((props) => <Component {...props} params={useParams()} />);

class AmzSongs extends React.Component {
  constructor(_props) {
    super(_props);

    this.state = {
      loading: true,
      songs: [],
      meta: {},
      ascending: 1,
      sortfield: 'artist',
      search: {
        value: '',
        loading: false,
      },
      addSongs: {
        open: false,
        value: '',
        maxIds: false,
        working: false,
        data: [],
        mode: 'add', // Should be one of ['add','save']
      },
      mode: 'display', // One of ['reorder', 'display']
    }

    this.getClusterTitle = this.getClusterTitle.bind(this);
    this.getSongs = this.getSongs.bind(this);

    this.goBack = this.goBack.bind(this);

    this.makeDisplay = this.makeDisplay.bind(this);
    this.makeTable = this.makeTable.bind(this);
    this.makeTableHeader = this.makeTableHeader.bind(this);
    this.makeTableRows = this.makeTableRows.bind(this);
    this.makeNoResults = this.makeNoResults.bind(this);
    this.makeTheResults = this.makeTheResults.bind(this);

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);

    this.onAddSongsRequest = this.onAddSongsRequest.bind(this);
    this.onAddSongsClose = this.onAddSongsClose.bind(this);
    this.onAddSongsChange = this.onAddSongsChange.bind(this);
    this.onAddSongsSubmit = this.onAddSongsSubmit.bind(this);
    this.onAddSongsSubmitResponse = this.onAddSongsSubmitResponse.bind(this);

    this.onAddSongsSave = this.onAddSongsSave.bind(this);
    this.onChangeOrder = this.onChangeOrder.bind(this);

    this.onAsinCheck = this.onAsinCheck.bind(this);
    this.onAsinOrderChange = this.onAsinOrderChange.bind(this);

    this.onSortFieldChange = this.onSortFieldChange.bind(this);
    this.makeCSV = this.makeCSV.bind(this);
    this.changeMode = this.changeMode.bind(this);
    this.onRemoveSubmit = this.onRemoveSubmit.bind(this);
    this.asinRemoveSubmit = this.asinRemoveSubmit.bind(this);
  }

  componentDidMount() {
    this.getClusterTitle();
  }

  onSearchChange(domEvent = {}) {
    domEvent.persist();
    let value = '';
    if (Object.hasOwn(domEvent, 'target')) {
      value = domEvent.target.value;
    }
    this.setState((state) => Amz.setStateOnSearchChange(state, value), this.getSongs);
  }

  onSearchSubmit(domEvent = null) {
    if (domEvent) {
      domEvent.preventDefault();
    }
    this.getSongs();
  }

  onAddSongsRequest() {
    this.setState(Amz.setStateOnAddSongsRequest);
  }

  onAddSongsClose() {
    this.setState(Amz.setStateOnAddSongsClose);
  }

  onAddSongsChange(domEvent) {
    const { value } = domEvent.target;
    this.setState((state) => Amz.setStateOnAddSongsChange(state, value));
  }

  onAddSongsSubmit(domEvent = null) {
    if (domEvent) {
      domEvent.preventDefault();
    }
    this.setState(Amz.setStateOnAddSongsSubmit, this.getScrapedAsin);
  }

  onAddSongsSave(domEvent = null) {
    if (domEvent) {
      domEvent.preventDefault();
    }

    const cb = () => this.setState(Amz.setStateAfterSave, this.getSongs);

    this.setState(Amz.setStateOnAddSongsSave, () => {
      Amz.makeSaveRequest(this.props.params.id, this.state.addSongs.data, cb);
    });
  }

  onAsinCheck(domEvent = null) {
    let include = false;
    let asin;

    if (domEvent) {
      include = domEvent.target.checked;
      asin = domEvent.target.id;
    }
    this.setState((state) => Amz.setStateOnExclude(state, asin, include));
  }

  /* Change when adding a song */
  onAsinOrderChange(domEvent = null) {
    let value = -1;
    let asin;

    if (domEvent) {
      // eslint-disable-next-line prefer-destructuring
      asin = domEvent.target.id.match(/\[([A-Z0-9]{1,})\]/)[1]; // Looks for [ASINID]
      value = domEvent.target.value;
    }

    this.setState((state) => Amz.setStateOnOrderChangeAdding(state, asin, +value));
  }

  onRemoveSubmit(domEvent = null) {
    if (!domEvent) return;
    domEvent.preventDefault();

    const todelete = this.state.songs.find((s) => domEvent.target.asin.value === s.ASIN);

    // eslint-disable-next-line no-alert
    const confirm = window.confirm(`Are you sure that you want to remove “${todelete['Song Title']}” from this cluster?`);
    if (confirm) {
      const form = domEvent.target;
      this.setState({ loading: true }, () => this.asinRemoveSubmit(form));
    }
  }

  /* Change in reordering mode */
  onChangeOrder(domEvent) {
    let value = -1;
    let asin;

    if (domEvent) {
      asin = domEvent.target.id;
      value = domEvent.target.value;
    }
    this.setState((state) => Amz.setStateOnReorder(state, asin, +value));
  }

  onAddSongsSubmitResponse(response) {
    const songs = response.map((r) => {
      const x = { ...r };
      x.include = true;
      x.ordinal = 0;
      return x;
    });

    this.setState((state) => Amz.setStateOnAddSongsPostSubmit(state, songs));
  }

  onSortFieldChange(domEvent) {
    const value = domEvent.target.dataset.sortfield;
    this.setState((state) => Amz.setStateOnFieldChange(state, value), this.getSongs);
  }

  getClusterTitle() {
    if (Object.hasOwn(this.state.meta, 'name')) return;
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', this.props.params.id);

    apirequest(API_CLUSTERS_SINGLEGENRE, { body: fd }, (response) => {
      this.setState((state) => Amz.setStateOnClusterTitle(state, response), this.getSongs);
    });
  }

  getSongs() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', this.props.params.id);
    fd.set('sortfield', this.state.sortfield);
    fd.set('ascending', this.state.ascending);

    if (this.state.search.value && this.state.search.value.length > 2) {
      fd.set('keyword', this.state.search.value);
    }

    apirequest(API_CLUSTERS_SONGS, { body: fd }, (response) => {
      this.setState((state) => Amz.setStateAfterSongs(state, response));
    });
  }

  getScrapedAsin() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('asin', this.state.addSongs.value);
    apirequest(API_CLUSTERS_SONG_GET_ASINDATA, { body: fd }, this.onAddSongsSubmitResponse)
  }

  changeMode() {
    if (this.state.mode === 'display') {
      this.setState(Amz.setStateOnModeChange);
    } else {
      const updated = this.state.songs.map((song) => ({
        asin: song.ASIN,
        ordinal: song.ordinal,
      }));

      const fd = new FormData();
      fd.set('genre', this.props.params.id);
      fd.set('order', JSON.stringify(updated));
      fd.set('token', getAuthData('token'));

      apirequest(API_CLUSTERS_SONGSREORDER, { body: fd }, (response) => {
        this.setState((state) => Amz.setStateAfterSongs(state, response));
      })
    }
  }

  asinRemoveSubmit(form) {
    const fd = new FormData(form);
    fd.set('token', getAuthData('token'));

    apirequest(API_CLUSTERS_SONG_REMOVE, { body: fd }, (response) => {
      this.setState((state) => Amz.setStateAfterSongRemoval(state, response));
    });
  }

  goBack(domEvent) {
    domEvent.preventDefault();
    window.history.go(-1);
  }

  makeDisplay() {
    let body = <LoaderSimple open />;
    if (Object.hasOwn(this.state.meta, 'error')) {
      body = (
        <div>
          <h2>No such cluster</h2>
          <p><a href="/music/clusters/" onClick={this.goBack}>Back to Clusters</a></p>
        </div>
      )
    } else if (this.state.loading === false) {
      if (this.state.songs.length || this.state.search.value) {
        body = this.makeTheResults()
      } else {
        body = this.makeNoResults();
      }
    }
    return body;
  }

  makeTable() {
    return (
      <table className="amz__songs__tbl">
        {this.makeTableHeader()}
        {this.makeTableRows()}
      </table>
    );
  }

  makeTableRows() {
    const rows = this.state.songs
      .map((song) => {
        const tds = Object.values(song).map((f, idx) => {
          /* eslint-disable no-param-reassign */
          if (idx === 0 && this.state.mode === 'reorder') {
            f = <AmzSongsReorder {...song} onChange={this.onChangeOrder} />
          }

          // Add exception for ASINs
          if (idx === 1) {
            f = <a href={Amz.amzUrl(f)} target="_blank" rel="noopener noreferrer">{f}</a>;
          }
          return <td key={uniqueId()}>{f}</td>;
          /* eslint-enable */
        });

        return (
          <tr key={uniqueId()}>
            {tds}
            <td>
              <AmzSongsRemove
                {...song}
                cluster={this.props.params.id}
                onSubmit={this.onRemoveSubmit}
              />
            </td>
          </tr>
        );
      });
    return (
      <tbody>
        {rows}
      </tbody>
    );
  }

  makeTableHeader() {
    if (!this.state.songs.length) return null;
    const ths = Object.keys(this.state.songs[0])
      .map((h) => {
        const txt = (h === 'ordinal') ? 'Order' : h;
        const th = (
          <th key={uniqueId()} scope="col">
            <button
              type="button"
              data-sortfield={txt.toLowerCase().replace(/\s/g, '')}
              className="tbl__sort_trigger"
              onClick={this.onSortFieldChange}
            >
              {txt}
            </button>
          </th>
        );
        return th;
      });
    return (
      <thead>
        <tr>
          {ths}
          <th>Remove</th>
        </tr>
      </thead>
    )
  }

  makeNoResults() {
    return (
      <div>
        <h2>There are no songs in this cluster.</h2>
        <p><a href="/music/clusters/" onClick={this.goBack}>Back to Clusters</a></p>

        <p>
          <button type="button" className="btn btn--action" onClick={this.onAddSongsRequest}>Add songs</button>
        </p>

        <AmzSongAddSongs
          {...this.state.addSongs}
          cluster={this.props.params.id}
          onClose={this.onAddSongsClose}
          onChange={this.onAddSongsChange}
          onSubmit={this.onAddSongsSubmit}
          onSave={this.onAddSongsSave}
          onExcludeToggle={this.onAsinCheck}
          onOrderChange={this.onAsinOrderChange}
        />
      </div>
    );
  }

  makeTheResults() {
    const { loading } = this.state.search;
    const body = loading ? <LoaderSimple open /> : this.makeTable();

    /* eslint-disable react/jsx-one-expression-per-line */
    return (
      <div>
        <header className="amz__songs__head">
          <h1>
            Songs in <q>{this.state.meta.name}</q>
          </h1>
          <h2>{this.state.meta.meta.trim()}</h2>
          <p>{this.state.meta.notes.trim()}</p>
          <p style={{ textAlign: 'right' }}>
            <button type="button" className="btn btn--action" onClick={this.onAddSongsRequest}>Add songs</button>
          </p>
          <p className="l__flex__sb">
            <span style={{ fontSize: '2rem' }}>
              {this.state.songs.length || 0} songs
            </span>
            <span>
              <b>Curated by: </b>
              {this.state.meta.curator}
            </span>
          </p>
        </header>

        <div>
          <AmzSongSearch
            onChange={this.onSearchChange}
            onSubmit={this.onSearchSubmit}
            {...this.state.search}
          />
        </div>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            <AmzSongsReorderTrigger mode={this.state.mode} onClick={this.changeMode} />
          </span>
          <button type="button" onClick={this.makeCSV} className="btn btn--action">Export as CSV</button>
        </p>
        {body}
        <AmzSongAddSongs
          {...this.state.addSongs}
          cluster={this.props.params.id}
          onClose={this.onAddSongsClose}
          onChange={this.onAddSongsChange}
          onSubmit={this.onAddSongsSubmit}
          onSave={this.onAddSongsSave}
          onExcludeToggle={this.onAsinCheck}
          onOrderChange={this.onAsinOrderChange}
        />
      </div>
    );
    /* eslint-enable */
  }

  makeCSV() {
    if (!this.state.songs.length) return;

    const headers = [
      [
        'ASIN',
        'Artist',
        'Song Name',
        'Album Name',
        'Genre',
        'Sub-genre',
        'Curator',
        'Entry #',
      ],
    ];

    const rows = this.state.songs.map((song) => ([
      `"${song.ASIN}"`,
      `"${song.Artist}"`,
      `"${song['Song Title']}"`,
      `"${song.Album}"`,
      `"${this.state.meta.parent.name}"`,
      `"${this.state.meta.name}"`,
      '',
      song.ordinal,
    ]));

    const csv = headers.concat(rows);
    download(csv, this.state.meta.name.replace(/\s/g, '_'), 'Music', '');
  }

  render() {
    let body = <LoaderSimple open />;
    const { loading } = this.state;

    if (loading === false) {
      body = (
        <article id="AmzGenres">
          {this.makeDisplay()}
        </article>
      );
    }
    return body;
  }
}

export default withParams(AmzSongs);
