import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';

const makeFieldId = (projectId) => {
  return `${projectId}_${uniqueId()}`;
}

export const AddUserFormProjects = (props) => {
  const makeProjects = (projObj) => {

    const fieldId = makeFieldId(projObj.pid);

    // If the map contains an entry for this project ID, use that. Otherwise set these defaults.
    let boxState = props.userprojects.has(projObj.pid) ? props.userprojects.get(projObj.pid) : {checked: false, role: 0};

    return (
      <fieldset key={uniqueId()} className="user__admin__project">
         <p className="fancy__chex">
            <input type="checkbox" id={fieldId} name={`project_access[${projObj.pid}]`} onChange={props.onChange} checked={boxState.checked} />
            <label htmlFor={fieldId} style={{'justifyContent': 'unset'}}>{projObj.name}</label>
          </p>
          <div hidden={!boxState.checked} className='user__admin__p_role'>
            {makeRoles(projObj, props.userprojects)}
          </div>
      </fieldset>
    )
  }

  const makeRoles = (projObj, userProjObj) => {
    return projObj.roles.map((role) => {
      const fieldId = makeFieldId(projObj.pid);

      let checked = userProjObj.has(projObj.pid) ? (userProjObj.get(projObj.pid).role == role.level) : false;

      return (
        <p className="fancy__radio" key={uniqueId()}>
          <input type="radio" id={fieldId} value={role.level} name={`project_role[${projObj.pid}]`} onChange={props.onChange} checked={checked} />
          <label htmlFor={fieldId} style={{'justifyContent': 'unset'}}>{role.name}</label>
        </p>
      );
    });
  }

  const projects = (
    <div>{ props.projects.map(makeProjects) }</div>
  )

  return projects;
}
