export const setStateOnGetGroups = (state, data) => {
  return {groupList: data};
}

export const setStateOnChannelFilter = (state, value) => {
  return {
    allChannels: {
      ...state.allChannels,
      filterOn: 'channel_title',
      keyword: value
    }
  }
}

export const setStateOnCloseChannelInfo = (state) => {
  return {showChannelInfo: false}
}

export const setStateOnCloseChannelList = (state) => {
  return {
    allChannels: {
      ...state.allChannels,
      open: false,
    }
  }
}

export const setStateOnShowChannels = (state) => {
  return {
    allChannels: {
      ...state.allChannels,
      open: true,
      loading: false
    }
  }
}

export const setStateOnShowChannelInfoResponse = (state, data) => {
  return {
    channelInfo: { 
      ...state.channelInfo,
      open: true,
      ...data
    },
  }   
}

export const setStateAfterGroupDelete = (state, data) => {
  const groups = [...state.groupList];
  
  return {
    groupList: groups.filter((g) => +g.group_id !== +data.deleted)
  }
}

export const deleteGroupConfirmation = (group_name) => {
  window.alert(`Group "${group_name}" was removed.`)
}

export const setStateAfterChannelDelete = (state, data) => {
  const removed = [...state.channelList].filter((c) => c.youtubeid === data.channel);
  window.alert(`"${removed[0].channel_title}" was removed from group.`);

  return {
    ...state,
    channelList: [...state.channelList].filter((c) => c.youtubeid != data.channel),
    channelInfo: {
      ...state.channelInfo,
      open: false,
    },
  } 
}

export const setStateOnGetGroupChannels = (state, data) => {
  return {
    ...state,
    ...data
  }
}

export const deleteChannelConfirmation = (channel_name) => {
  
}
