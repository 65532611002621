/**
 * UpdateAccountPage.js
 * Enables users to update their own account page.
 */

import React from 'react';
import ActionMessageDisplay from './subcomponents/ActionMessageDisplay';
import UpdateAccountHeader from './subcomponents/UpdateAccountHeader';
import PasswordResetShowHide from './UpdateAccount/PasswordResetShowHide';
import { IconApprovedRejected } from './subcomponents/IconApprovedRejected';
import { Meter } from './subcomponents/Meter';

import {
  API_USERS_GETUSERINFO,
  API_USERS_UPDATEUSER,
  API_USER_TEST_PASS,
  SET_PASS_RULES,
  SET_PASS_RULES2,
} from '../js/Configuration';

import {
  getAuthData,
  apirequest
}  from '../js/Utilities';

import {
  requestPasswordSuggestion,
  setStatePasswordResetShowHide
} from '../actions/LoginUtils';

import * as UserUtils from '../actions/UserUtils';

import './subcomponents/css/fancy-checkboxes.css';
import './UpdateAccount/css/UpdateAccountPage.css';

import {
  setStateProjectMenuToggle
} from '../actions/ProjectUtils';


export default class UpdateAccountPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      user: getAuthData('user'),
      first_name: getAuthData('displayname_first'),
      second_name: getAuthData('displayname_second'),
      email: getAuthData('email'),
      uuid: getAuthData('uuid'),
      language: getAuthData('lang'),
      level: getAuthData('level'),
      uid: getAuthData('uid'),
      update_status: null,
      action_mode: null,
      show_pass: false,
      password1: {
        value: '',
        type: 'password'
      },
      password2: {
        value: '',
        type: 'password'
      },
      strength: 0,
      length: 0,
      token: '',
      reqtok: '',
      expired: false,
      message: null,
      mode: null,
      canSave: true,
      menu: {
        open: false
      }
    }

    this.updateAccount = this.updateAccount.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangePass = this.onChangePass.bind(this);
    this.showAction = this.showAction.bind(this);

    this.onSuggestionRequest = this.onSuggestionRequest.bind(this);
    this.onShowPassword = this.onShowPassword.bind(this);
    this.checkPass = this.checkPass.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    const fd = new FormData();
    fd.set('id', getAuthData('uid'));
    fd.set('uid', getAuthData('uuid'));
    fd.set('token', getAuthData('token'));

    apirequest(API_USERS_GETUSERINFO, { body: fd }, (data) => {
      this.setState((state) => UserUtils.setStateOnLoadUser(state, data));
    });
  }

  onChange(domEvent) {
    const field = domEvent.target.name;
    const value = domEvent.target.value;

    this.setState((state) => UserUtils.setStateUpdateField(state, field, value));
  }

  onChangePass(domEvent) {
    const field = domEvent.target.name;
    const value = domEvent.target.value;
    this.setState((state) => UserUtils.setStateUpdatePass(state, field, value), this.checkPass);
  }

  updateAccount(domEvent) {
    domEvent.preventDefault();
    const fd = new FormData();
    fd.set('username', this.state.user);
    fd.set('displayname', `${this.state.first_name}|${this.state.second_name}`);
    fd.set('name1', this.state.first_name);
    fd.set('name2', this.state.second_name);
    fd.set('email', this.state.email);
    fd.set('id', this.state.uid);
    fd.set('uuid', this.state.uuid);
    fd.set('token', getAuthData('token'));

    if(this.state.password1.value) {
      fd.set('password', this.state.password1.value);
    }
  
    apirequest(API_USERS_UPDATEUSER, { body: fd }, (response) => {
      this.setState((state) => UserUtils.setStateAfterUpdate(state, response));
    });
  }

  onSuggestionRequest() {
    requestPasswordSuggestion(getAuthData('token'), (response) => {
      this.setState((state) => UserUtils.setStatePasswordSuggestion(state, response), this.checkPass);
    });
  }

  onShowPassword(domEvent) {
    if(domEvent.target.nodeName !== 'BUTTON') return;
    const fieldId = domEvent.target.dataset.controls;
    this.setState((state) => setStatePasswordResetShowHide(state, fieldId));
  }

  checkPass() {
    const fd = new FormData();
    fd.set('tok', getAuthData('token'));
    fd.set('password1', this.state.password1.value);

    apirequest(API_USER_TEST_PASS, {body: fd}, (response) => {
      this.setState((state) => UserUtils.setStateOnCheckPass(state, response));
    });
  }

  showAction() {
    return  <ActionMessageDisplay mode={this.state.action_mode} hidden={this.state.action_mode === null} message={this.state.update_status} />;
  }

  toggleMenu(domEvent) {
    this.setState(setStateProjectMenuToggle);
  }
  
  render() {
    const isStrong = this.state.strength < 3;
    const isApproved = isStrong ? false : true;
    const isApproved2 = isStrong || (this.state.password1.value !== this.state.password2.value)  ? false : true;

    return (
      <div className="update__account">
        <UpdateAccountHeader onSwitch={this.toggleMenu} canswitch={getAuthData('permissions').length} menuopen={this.state.menu.open} />

        {this.showAction()}
        <form action={API_USERS_UPDATEUSER} method="POST" onSubmit={this.updateAccount} onFocus={() => this.setState(UserUtils.setStateOnFormFocus)}>
          <p>
            <input type="hidden" name="uuid" id="uuid" value={this.state.uuid || ''} />
            <label htmlFor="name">User Name:</label>
            <input className="form-control" type="text" name="name" id="name" value={this.state.user || ''} disabled />
          </p>

          <p>
            <label htmlFor="email">User Email:</label>
            <input type="text" className="form-control" name="email" id="email" value={this.state.email || ''} onChange={this.onChange} />
          </p>

          <p>
            <label htmlFor="password1">Enter new password</label>
            <button type="button" className="btn--request" onClick={this.onSuggestionRequest}>Get a password suggestion</button>
            <span className="set__pass__approvable">
              <PasswordResetShowHide type={this.state.password1.type || ''} name="password1" id="password1" minLength="12" value={this.state.password1.value} onChange={this.onChangePass} onClick={this.onShowPassword} aria-describedby="password1-note" autoComplete="new-password" />
              <b className="set__pass__approved" role="img" aria-label={isStrong ? 'Unacceptable' : 'Approved'} hidden={!this.state.password1.value.length > 0}><IconApprovedRejected approved={ isApproved } /></b>
            </span>
            <span id="password1-note" className="set__pass__note" dangerouslySetInnerHTML={SET_PASS_RULES} />
          </p>

          <p className="set__pass__passwordindic">
            Strength:
            <Meter className="password__strength" max="4" low="3" optimum="3.999" value={this.state.strength} />
          </p>

          <p>
            <label htmlFor="password2">Confirm new password</label>
            <span className="set__pass__approvable">
              <PasswordResetShowHide type={this.state.password2.type} name="password2" id="password2" minLength="12" value={this.state.password2.value} onChange={this.onChangePass} onClick={this.onShowPassword} aria-describedby="password2-note" autoComplete="new-password" disabled={isStrong} />
              <b className="set__pass__approved" role="img" aria-label={isStrong ? 'Unacceptable' : 'Approved'} hidden={!this.state.password2.value.length > 0}><IconApprovedRejected approved={ isApproved2 } /></b>
            </span>
            <span id="password2-note" className="set__pass__note">{SET_PASS_RULES2}</span>
          </p>

          <fieldset>
            <legend>Display Name</legend>
            <p>
              <label htmlFor="first_name">First Name:</label>
              <input type="text" className="form-control" name="first_name" id="first_name" defaultValue={this.state.first_name} onInput={this.onChange} />
            </p>
            <p>
              <label htmlFor="second_name">Second Name:</label>
              <input type="text" className="form-control" name="second_name" id="second_name" defaultValue={this.state.second_name} onInput={this.onChange} />
            </p>
          </fieldset>

          <p>
            <button type="submit" className="btn btn--action" disabled={!this.state.canSave}>Update Account Info</button>
          </p>
        </form>
      </div>
    );
  }
}
