/** *
 * GroupsPage.js
 * Page component for Groups tab.
 */

import React from 'react';

import GroupsPageTable from './subcomponents/GroupsPageTable';

import {
  API_CHANNEL_INFO,
  API_CHANNEL_SETTAGS,
  API_CHANNEL_DELETE,
  API_GROUPS_ALLGROUPS,
  API_GROUPS_ADD_CHANNEL_TO_GROUP,
  API_GROUP_ADD_NEW,
  API_GROUPS_DELETE,
  API_GROUPS_REMOVE_CHANNEL
} from '../js/Configuration';


import {
  apirequest,
  getAuthData,
  getSortDirection,
  isAdmin
} from '../js/Utilities';

import * as G from '../actions/GroupsUtils';

import '../css/Groups.css';

export default class GroupsPage extends React.Component {
  constructor( _props ) {
    super( _props );

    this.state = {
      groupList: [],
      isLoadingGroups: true,
      showEdit: false,
      groupItem: 0,
      addToGroupId: 0,
      currentlyViewedChannel: {}, // Holds data for channel info modal.
      showChannelInfo: false,
      showChannels: false,

      groupName: '',
      groupNameError: null,

    };

    this.createGroup = this.createGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);

    this.onInputHandler = this.onInputHandler.bind(this);
    this.getAllGroups = this.getAllGroups.bind(this);
  }

  getAllGroups() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_GROUPS_ALLGROUPS, {body: fd}, (data) => {
      this.setState((state) => G.setStateOnGetGroups(state, data));
    });
  }

  componentDidMount() {
    this.getAllGroups();
  }

  onInputHandler(domEvent) {
    this.setState((state) => {
      return {
        groupName: domEvent.target.value,
      }
    });
  }

  makeGroupEdit() {
    if(this.state.showEdit) {
      return (
        <GroupsPageEditGroups
          showChannelInfo={this.state.showChannelInfo}
          showChannels={this.state.showChannels}
          currentChannel={this.state.currentlyViewedChannel}
          groups={this.state.groupList}

          channels={this.state.channelList}
          currentChannelPage={+this.state.channelListCurrentPage}
          channelListPerPage={+this.state.channelListPerPage}
          channelListAscending={this.state.channelListAscending}
          channelListFilterKeyword={this.state.channelListFilterKeyword}
          channelListFilterOn={this.state.channelListFilterOn}
          channelListSortBy={this.state.channelListSortBy}
          onPerPageChange={this.changePerPageHandler}
          onChannelSelectChange={this.channelSelectChangeHandler}
          totalChannelPages={this.state.channelListPageCount}
          groupItem={this.state.groupItem}
         />
      );
    } else {
        return null;
    }
  }

  createGroup(domEvent) {
    domEvent.preventDefault();

    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUP_ADD_NEW,{body: fd}, (data) => {
      if(data.result === 'success') {
        this.setState({groupName: ''}, this.getAllGroups);
      } else {
        this.setState({groupNameError: data.message});
      }
    })
   }

  deleteGroup(domEvent) {
    const group = this.state.groupList.filter((g) => {
      return g.group_id === domEvent.target.dataset.groupid;
    });
    
    const confirm = window.confirm(`Confirm that you want to delete "${group[0].group_name}" by clicking OK.`);

    if(!confirm) return;
    
    const fd = new FormData();
    fd.set('id', group[0].group_id);
    fd.set('token', getAuthData('token'));
    apirequest(API_GROUPS_DELETE, {body: fd}, (response) => {
      if(response.success) {
        this.setState((state) => G.setStateAfterGroupDelete(state, response), () => G.deleteGroupConfirmation(group[0].group_name));
      } else {
        window.alert(`Could not delete ${group[0].group_name}.`);
      }
    });
  }

  render() {
    return (
      <div id="curationContent">
        <div id="curationMenu">
          <div className="groupHeaderParent">
              <form action={API_GROUP_ADD_NEW} method="post" onSubmit={this.createGroup} className="groupHeader form__single__input">
                <div className="form__input_button_group">
                  <label htmlFor="group_name">Create Group:</label>
                  <span>
                    <input name="groupname" id="group_name" value={this.state.groupName} onInput={this.onInputHandler} required={true} type="text" />
                    <button type="submit" className="btn btn-sm btn--action">Submit</button>
                  </span>
                </div>
                <p className="msg__error" hidden={!this.state.groupNameError}>{this.state.groupNameError}</p>
              </form>
          </div>
          <GroupsPageTable groupList={this.state.groupList} onDelete={this.deleteGroup} onRowSelect={this.showGroupEdit} isAdmin={isAdmin(this.props.project)} />
          </div>
          { this.makeGroupEdit() }
      </div>
    )
  }
}
