/**
 * AmzReports.js
 */
import React from 'react';
import { Link,  Route } from 'react-router-dom';
import uniqueId from 'lodash.uniqueid';

import LoaderSimple from '../subcomponents/LoaderSimple';
import { AmzSongSearch } from './AmzSongSearch';
import { AmzSongAddSongs } from './AmzSongAddSongs';
import { NoSuchReport } from './NoSuchReport';

import {
  API_GENRES_REPORTS,
  API_CLUSTERS_SINGLEGENRE
} from '../../js/Configuration';

import {
  apirequest,
  capitalize,
  getAuthData
} from '../../js/Utilities';

import {
  download
} from '../../actions/ReportsUtils';

import {
  amzUrl,
  setStateOnClusterTitle,
  setStateOnReportResponse
} from '../../actions/AmzUtils';

import './css/AmzReports.css';

export default class AmzReports extends React.Component {
  constructor( _props ) {
    super( _props );

    this.state = {
      loading: true,
      results: [],
      sortby: 'frequency',
      ascending: 0,
      meta: {}
    }

    this.makeCSV = this.makeCSV.bind(this);
    this.getDuplicates = this.getDuplicates.bind(this);
    this.getGenreTitle = this.getGenreTitle.bind(this);
    this.makeDisplay = this.makeDisplay.bind(this);
    this.makeTitle = this.makeTitle.bind(this);
    this.makeTable = this.makeTable.bind(this);
    this.makeTableHeader = this.makeTableHeader.bind(this);
    this.makeTableRows = this.makeTableRows.bind(this);
  }

  componentDidMount() {
    this.getGenreTitle();
  }

  getGenreTitle() {
    if(this.state.meta.hasOwnProperty('name')) return;
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', this.props.match.params.id);

    apirequest(API_CLUSTERS_SINGLEGENRE, {body: fd}, (response) => {
      this.setState((state) => setStateOnClusterTitle(state, response), this.getDuplicates);
    });
  }

  getDuplicates() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('report', this.props.match.params.report);
    fd.set('genre',this.props.match.params.id);
    fd.set('ascending',this.state.ascending);
    fd.set('sort',this.state.sortby);

    apirequest(API_GENRES_REPORTS, {body: fd}, (response) => {
      this.setState((state) => setStateOnReportResponse(state, response));
    });
  }

  makeTable() { 
    let body = <LoaderSimple open={true} />;
    if(this.state.results.length) {
      body = (
        <table className="reports__table__results">
          {this.makeTableHeader()}
          {this.makeTableRows()}
        </table>
      )
    }
    return body;
  }

  makeTableHeader() {
    if(!this.state.results.length) return null;
    const fields = Object.keys(this.state.results[0]);
    const th = fields
              .filter((f) => ['sASIN','aASIN'].indexOf(f) < 0)
              .map((f) => <th key={uniqueId()}>{capitalize(f)}</th>);

    return (
      <thead>
        <tr>
          {th}
        </tr>
      </thead>
    );
  }

  makeTableRows() {
    if(!this.state.results.length) return null;
    const results = this.state.results;

    const rows = results.map((f, index, results) => {
      const cols = Object.keys(f).filter((f) => ['aASIN', 'sASIN'].indexOf(f) < 0);

      const td = cols
                 .map((c) => {
                    let cell = f[c];

                    if(c === 'ASIN' || c === 'Song' && f.hasOwnProperty('sASIN')) {
                      cell = <Link to={{pathname: `../details/title/${window.encodeURIComponent(f.sASIN)}/${window.encodeURIComponent(this.props.match.params.id)}/`, state: { previous: this.props.location.pathname }}}>{f[c]}</Link>
                    }

                    if(c === 'Artist') {
                      cell = <Link to={{pathname: `../details/artist/${window.encodeURIComponent(f.Artist.toLowerCase())}`, state: { previous: this.props.location.pathname }}}>{f[c]}</Link>
                    }

                    return <td key={uniqueId()}>{cell}</td>
                  });

      return (
        <tr key={uniqueId()}>
          {td}
        </tr>
      );
    });

    return (
      <tbody>
        {rows}
      </tbody>
    );
  }

  makeTitle(){
    return (
      <header>
        <h1 style={{'textTransform': 'capitalize'}}>
          Duplicate {this.props.match.params.report} Report for <q>{this.state.meta.name}</q>
        </h1>

        <p><a className="amz__reports_backlink" href={`/music/clusters/${this.props.match.params.id}/`}>Return to {this.state.meta.name}</a></p>

        <p className="button__group" style={{'textAlign': 'right'}}>
          <button type="button" className="btn btn--action" onClick={this.makeCSV}>Export CSV</button>
        </p>
      </header>
    );
  }

  makeDisplay(){
    let body = <NoSuchReport />;
    if(['artists','songs'].indexOf(this.props.match.params.report) > -1) {
      body = (
        <div>
          {this.makeTitle()}
          {this.makeTable()}
        </div>
      );
    }
    return body;
  }

  makeCSV() {
    if(!this.state.results.length) return;
    const rows = this.state.results;
    const exclude = ['ASIN','sASIN','aASIN','sgid','gid'];
    let csv = [];

    const headers = Object.keys(rows[0]);
    csv[0] = headers
            .filter((h) => exclude.indexOf(h) < 0)
            .map((h) => `"${capitalize(h)}"`);

    const rws = rows.map((r) => {
      const fields = Object.keys(r).filter((r) => exclude.indexOf(r) < 0);
      return fields.map((f) => `"${r[f]}"`);
    });

    const filename = `${capitalize(this.props.match.params.report)}_Report_for_${this.state.meta.name}`;
    download(csv.concat(rws), filename, 'Duplicate', '');
  }

  render() {
    return this.makeDisplay();
  }
}
