/*
DialogModal. Depends on the presence of dialog-polyfill.js
and dialog-polyfill.css with the page in non-Chrome browsers.
https://github.com/GoogleChrome/dialog-polyfill
*/

import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { CloseButton } from './CloseButton';

import './css/DialogModal.css';

export default class DialogModal extends React.Component {
  constructor( _props ) {
    super( _props );
    this.dialog = createRef();
    this.closeModal = this.closeModal.bind(this);
    this.closeButton = this.closeButton.bind(this);
    this.makeTitle = this.makeTitle.bind(this);
  }

  componentDidUpdate() {
    if( this.dialog.current.open ) {
      this.closeModal()
    } else {
      this.dialog.current.showModal()
    }
  }

  closeModal() {
    if(this.props.onClose) {
      this.props.onClose();
    }
    this.dialog.current.close();
  }

  makeTitle() {
    if(this.props.title) {
      return <h2 id={`${this.props.id}_title`}>{this.props.title}</h2>;
    } else {
      return null;
    }
  }

  closeButton() {
    return (
      <CloseButton color="#CBCBCB" className="dialog__body__close" onClick={this.closeModal}/>
    );
  }


  render() {
    return  (
      <dialog ref={this.dialog} id={this.props.id} aria-labelledby={`${this.props.id}_title`}>
        <div className="dialog__body" role="document">
          {this.makeTitle()}
          {this.closeButton()}
          {this.props.body || this.props.children}
        </div>
      </dialog>
    )
  }
}

DialogModal.defaultProps = {
  open: false,
  title: null,
  body: null
};

DialogModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.any,
  body: PropTypes.any,
  onClose: PropTypes.func.isRequired
};
