/**
 * PlaylistMeta.js
 * Displays playlist URL and comments form fields.
 */
import React from 'react';
import PropTypes from 'prop-types';

export default class PlaylistMeta extends React.Component {
  constructor( _props ) {
    super( _props );
    this.onChange = this.onChange.bind(this);
  }

  onChange(domEvent) {
    this.props.onChange(domEvent);
  }

  render() {    
    return (
      <div className="destinations__playlist_copy">
        <label htmlFor="playlist_url" className="form__access__label">Enter Playlist URL:</label>
        <input form={this.props.form} onChange={this.onChange} className="blockInfoTxt form-control" type="text" id="playlist_url" name="vsb_playlistURL" value={this.props.url} placeholder="Enter Playlist URL" disabled={this.props.disabled} />
        <label htmlFor="playlist_comments" className="form__access__label">Enter comments for this play list:</label>
        <textarea form={this.props.form} className='blockInfoTxt copy-txt form-control' name='vsb_proofCopy' id='playlist_comments' value={this.props.copy} onChange={this.onChange} placeholder="Playlist Copy" disabled={this.props.disabled}></textarea>
      </div>
    );
  }
}

PlaylistMeta.defaultProps = {
  url: null,
  copy: null,
  disabled: false
}

PlaylistMeta.propTypes = {
  url: PropTypes.string,
  copy: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  form: PropTypes.string
};
