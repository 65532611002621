/**
 * AmzHeader.js
 * Manages the navigation display for the Amazon Music project
 */

import React from 'react';
import {
  NavLink,
  useLocation,
} from 'react-router-dom';
import { SwitchProjects } from './SwitchProjects';
import { SwitchProjectsMenu } from './SwitchProjectsMenu';

const AmzHeader = (props) => {
  const {
    canswitch,
    onSwitch,
    menuopen,
  } = props;

  const cluster = useLocation();
  let clust = null;

  if (Object.hasOwn(cluster, 'state')) {
    clust = { ...cluster.state };
  }

  const switcher = canswitch ? <SwitchProjects onClick={onSwitch} /> : null;

  const activeClassName = ({ isActive }) => (isActive ? 'header__selected' : '');

  const nav = (
    <div className="nav__project__wrap">
      <nav id="AmzHeader" className="nav__project" aria-label="Amazon Project Menu">
        <b className="nav__project_label">Music</b>
        <NavLink className={activeClassName} to="/music" key="genres">Genres</NavLink>
        {
         // eslint-disable-next-line operator-linebreak
         clust &&
          (
            <NavLink
              className={activeClassName}
              to={`/music/clusters/${clust.clusterId}`}
              key="clusters"
            >
              {clust.clusterName}
            </NavLink>
          )
        }
      </nav>
      { switcher }
      <SwitchProjectsMenu open={menuopen} onClose={onSwitch} />
    </div>
  );
  return nav;
}

export default AmzHeader;
