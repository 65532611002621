import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Meter } from './subcomponents/Meter';
import { ActionMessageDisplay } from './subcomponents/ActionMessageDisplay';
import { IconApprovedRejected } from './subcomponents/IconApprovedRejected';

import PasswordResetShowHide from './UpdateAccount/PasswordResetShowHide';

import {
  apirequest,
  debounce,
  meetsMinLength,
  isSameString
} from '../js/Utilities';

import {
  requestPasswordSuggestion,
  setStatePasswordSuggestion,
  setStatePasswordResetShowHide
} from '../actions/LoginUtils';


import {
  API_USER_CHECK_SET_PASS_ID,
  API_USER_SET_PASS,
  API_USER_TEST_PASS,
  SET_PASS_RULES,
  SET_PASS_RULES2,
  PASSWORDS_MISMATCH,
  PASSWORD_REQUEST_EXPIRED
} from '../js/Configuration';

import '../css/ResetPassword.css';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password1: {
        value: '',
        type: 'password'
      },
      password2: {
        value: '',
        type: 'password'
      },
      strength: 0,
      length: 0,
      token: '',
      reqtok: '',
      expired: false,
      message: null,
      mode: null
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);

    this.savePass = this.savePass.bind(this);
    this.checkPass = debounce(200, this.checkPass.bind(this));
    this.disableSave = this.disableSave.bind(this);

    this.onSuggestionRequest = this.onSuggestionRequest.bind(this);
    this.onShowPassword = this.onShowPassword.bind(this);
    this.postSaveMessage = this.postSaveMessage.bind(this);
  }

  componentDidMount() {
    const fd = new FormData();
    fd.set('guid', this.props.params.pwordReqID);

    apirequest(API_USER_CHECK_SET_PASS_ID, {body: fd}, (response) => {
      this.setState({expired: response.expired, reqtok: response.request_token});
    });

    const p1 = document.querySelector('#password1');
    if(p1) {
      p1.focus();
    }
  }

  checkPass() {
    const fd = new FormData();
    fd.set('tok', this.state.reqtok);
    fd.set('password_token', this.props.params.pwordReqID);
    fd.set('password1', this.state.password1.value);

    apirequest(API_USER_TEST_PASS, {body: fd}, (response) => {
      // Returns an object {length: xxx, strength: xxx}
      this.setState(response);
    });
  }

  disableSave() {
    return (this.state.password1.value === '') || (this.state.password2.value === '') || (this.state.password1.value !== this.state.password2.value) || this.state.strength < 3;
  }

  postSaveMessage(message, mode) {
    let msg = <ActionMessageDisplay hidden={!message} message={message} mode={mode} />
    return msg;
  }

  savePass() {
    const fd = new FormData();
    fd.set('tok', this.state.reqtok);
    fd.set('password_token', this.props.params.pwordReqID);
    fd.set('password1', this.state.password1.value);
    fd.set('password2', this.state.password2.value);

    apirequest(API_USER_SET_PASS, {body: fd}, (response) => {
      let newstate = {
        message: 'Could not save your password. Tell an administrator.',
        mode: 'failed'
      };

      if(response.hasOwnProperty('status') && response.status === 'success') {
        newstate.message = 'Your new password has been saved. <a href="/">Log in now</a>';
        newstate.mode = response.status;
      }

      this.setState(newstate);
    });
  }

  onChange(domEvent) {
    let newstate = {
      [domEvent.target.name]: {
        ...this.state[domEvent.target.name],
        value: domEvent.target.value
      }
    };

    if(domEvent.target.name === 'password1' && domEvent.target.value === '') {
      newstate.password2 = {
        ...this.state.password2,
        value: ''
      };
    }
    this.setState(newstate, this.checkPass);
  }

  onSubmit(domEvent) {
    domEvent.preventDefault();
    // Send a request to the server containing the change request GUID and the password,
    // but only if the passwords match

    if(!isSameString(this.state.password1.value, this.state.password2.value)) {
      alert(PASSWORDS_MISMATCH);
    }

    else if(!meetsMinLength(this.state.password2.value)) {
      alert(`${SET_PASS_RULES} Please try again.`);
    }

    else {
      // send the request to the server.
      this.savePass();
    }
  }

  onSuggestionRequest() {
    requestPasswordSuggestion(this.state.reqtok, (response) => {
      this.setState((state) => setStatePasswordSuggestion(state, response), this.checkPass);
    });
  }

  onShowPassword(domEvent) {
    if(domEvent.target.nodeName !== 'BUTTON') return;
    const fieldId = domEvent.target.dataset.controls;
    this.setState((state) => setStatePasswordResetShowHide(state, fieldId));
  }

  render() {
    if(this.state.expired) {
      return <h1 className="page__set__password">{PASSWORD_REQUEST_EXPIRED}</h1>;
    }

    const isStrong = this.state.strength < 3;
    const isApproved = isStrong ? false : true;

    return (
      <section className="page__set__password" >
        <header>
          <h1>Set a new password</h1>
          {this.postSaveMessage(this.state.message, this.state.mode)}
        </header>

        <form method="post" onSubmit={this.onSubmit}>
          <p>
            <input type="hidden" name="password_token" value={this.props.params.pwordReqID} />
            <label htmlFor="password1">Enter new password</label>
            <button type="button" className="btn--request" onClick={this.onSuggestionRequest}>Get a password suggestion</button>
            <span className="set__pass__approvable">
              <PasswordResetShowHide type={this.state.password1.type} name="password1" id="password1" minLength="12" value={this.state.password1.value} onChange={this.onChange} onClick={this.onShowPassword} aria-describedby="password1-note" autoComplete="new-password" required />
              <b className="set__pass__approved" role="img" aria-label={isStrong ? 'Unacceptable' : 'Approved'} hidden={!this.state.password1.value.length > 0}><IconApprovedRejected approved={ isApproved } /></b>
            </span>
            <span id="password1-note" className="set__pass__note" dangerouslySetInnerHTML={SET_PASS_RULES} />
          </p>

          <p className="set__pass__passwordindic">
            Strength:
            <Meter className="password__strength" max="4" low="3" optimum="3.999" value={this.state.strength} />
          </p>

          <p>
            <label htmlFor="password2">Confirm new password</label>
            <span className="set__pass__approvable">
              <PasswordResetShowHide type={this.state.password2.type} name="password2" id="password2" minLength="12" value={this.state.password2.value} onChange={this.onChange} onClick={this.onShowPassword} aria-describedby="password2-note" autoComplete="new-password" required disabled={ isStrong } />
              <b className="set__pass__approved" role="img" aria-label={isStrong ? 'Unacceptable' : 'Approved'} hidden={this.disableSave()}><IconApprovedRejected approved={ isApproved } /></b>
            </span>
            <span id="password2-note" className="set__pass__note">{SET_PASS_RULES2}</span>
          </p>

          <p>
            <button type="submit" className="btn btn--action" disabled={this.disableSave()}>Submit</button>
          </p>
        </form>
      </section>
    )
  }
}

export default (props) => (
    <ResetPassword
        {...props}
        params={useParams()}
    />
);