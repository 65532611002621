/**
 * ImportSpreadsheetFields.js
 * Enables users to import data into AVA from GoogleSheet.
 */

import React from 'react';

export const ImportSpreadsheetFields = (props) => {
  return (
    <fieldset>
      <p className="alert alert-warning"><b>Important!</b> Make sure that the page you're importing contains <b>5000</b> or fewer YouTube <abbr title="identifiers">IDs</abbr>. </p>
      <div>
        <legend className="form__access__label">Google Sheet Information</legend>
        
        <p>
          <label htmlFor="url">Google Sheet URL</label>
          <input disabled={!props.email} type="url" id="url" name="url" value={props.url} aria-describedby="url_note" onChange={props.onChange} required />
          <span id="url_note">Make sure that you've shared this spreadsheet with the email address <b>{props.email}</b>.</span>
        </p>
        
        <p>
          <label htmlFor="sheet">Sheet name</label>
          <input disabled={!props.email} type="sheet" id="sheet" name="sheet" value={props.sheet} aria-describedby="sheet_hint" onChange={props.onChange} required />
          <span id="sheet_hint">You'll find this at the bottom of the Google Sheets screen. It's the text that indicates which tab or page of the spreadsheet you're currently on.</span>
        </p>
      </div>
    </fieldset>
  )
}

