import {
  getAuthData,
  setAuthData
} from '../js/Utilities';

export const setStateOnLoadUser = (state, data) => {
  return {
    user: data.username,
    first_name: data.displayname_first || '',
    second_name: data.displayname_second || '',
    email: data.email,
    uuid: data.uuid,
    uid: data.id
  }
}

export const setStateOnPasswordChange = (state, field, input) => {
  let updates = {
    [field]: {
      ...state[field],
      value: input
    }
  }

  if(field === 'password1' && input === '') {
    updates.password2 = {
      ...state.password2,
      value: ''
    };
  }
  return updates;
}

export const setStateUpdateField = (state, field, input) => {
  return {
    [field]: input
  }
}

export const setStateUpdatePass = (state, field, input) => {
  let update = {
    [field]: {
      ...state[field],
      value: input
    }
  };

  if(field === 'password1' && input === '') {
    update.password2 = {...state.password2, value: ''};
    update.canSave = true;
  }

  if(field === 'password2' && state.password1.value === input) {
    update.canSave = true;
  }

  if(field === 'password2' && input === '') {
    update.canSave = false;
  }

  return update;
}

export const setStateOnCheckPass = (state, grade) => {
  let updates;
  if(grade.length > 0 && grade.strength < 3) {
    updates = {
      canSave: false
    }
  }
  return Object.assign(grade, updates);
}

export const setStatePasswordSuggestion = (state, suggestionObj) => {
  return {
    password1: {
      value: suggestionObj.suggestion,
      type: 'text'
    },
    canSave: false
  };
}

export const setStateAfterUpdate = (state, response) => {
  let updates = {
    action_mode: response.status,
    update_status: response.message
  };

  let userUpdates = {}
  if(response.hasOwnProperty('data')) {
    userUpdates = {
      ...getAuthData(),
      display_name: response.data.displayname,
      email: response.data.email,
      user: response.data.username
    }
    setAuthData(userUpdates);
  }
  return Object.assign(updates, userUpdates);
}

export const setStateOnFormFocus = (state) => {
  return {
    update_status: null,
    action_mode: null
  }
}

export const setStateOnProjects = (state, response) => {
  return {
    projects: response
  }
}
