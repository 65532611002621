/**
 * DestinationsSelect.js
 * Used on VideoInfo only to select a destination
 */

import React from 'react';
import Select from 'react-select';
import {
  format,
  isValid
} from 'date-fns';
import DatePicker from 'react-datepicker';

import {
  apirequest,
  getAuthData
} from '../../js/Utilities';

import {
  setStateOnVideoSetSelect,
  setStateOnDestinationSelect
} from '../../actions/DestinationsUtils';


import {
  API_DESTINATIONS_ADDNEW,
  API_DESTINATIONS_ASSIGNMENTS,
  API_DESTINATIONS_LIST,
  API_VIDEOSET_ASSIGNMENTS,
  API_VIDEOSET_ADDNEW,
  API_VIDEOSETBLOCK_ADDNEW_DEPRECATED,
  API_VIDEOSETBLOCK_INFO,
  NULL_DATE_TEXT,
  INTERSTITIAL_INDICATOR,
  MESSAGE_ADD_TO_AVA
} from '../../js/Configuration';

import '../Destinations/css/DestinationsSelect.css';
import 'react-datepicker/dist/react-datepicker.css';


export default class DestinationsSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      destinations: {
        disabled: true,
        options: [],
        mode: 'select',
        value: null,
        name: null
      },
      videosets: {
        options: [],
        mode: 'select',
        value: null,
        deleteDisabled: true,
        editDisabled: true,
        name: null
      },
      videosetblocks: {
        options: [],
        mode: 'select',
        value: null,
        newDate: null,
        datePickerDisabled: false
      }
    }

    this.blockNoDateSelected = this.blockNoDateSelected.bind(this);

    this.getDestinations = this.getDestinations.bind(this);
    this.getVideoSets = this.getVideoSets.bind(this);
    this.getBlockList = this.getBlockList.bind(this);
    this.getVideosForBlock = this.getVideosForBlock.bind(this);

    this.onDestinationsLoaded = this.onDestinationsLoaded.bind(this);
    this.onDestinationSelect = this.onDestinationSelect.bind(this);
    this.onCreateDestinationCancel = this.onCreateDestinationCancel.bind(this);
    this.onCreateDestinationChange = this.onCreateDestinationChange.bind(this);
    this.onCreateDestinationSubmit = this.onCreateDestinationSubmit.bind(this);

    this.onVideoSetsLoaded = this.onVideoSetsLoaded.bind(this);
    this.onVideoSetSelect = this.onVideoSetSelect.bind(this);
    this.onCreateVideoSetCancel = this.onCreateVideoSetCancel.bind(this);
    this.onCreateVideoSetSubmit = this.onCreateVideoSetSubmit.bind(this);
    this.onCreateVideoSetChange = this.onCreateVideoSetChange.bind(this);

    this.onVideoBlockSelect = this.onVideoBlockSelect.bind(this);
    this.onBlockListLoaded = this.onBlockListLoaded.bind(this);
    this.onBlockVideosLoaded = this.onBlockVideosLoaded.bind(this);
    this.onCreateBlockSubmit = this.onCreateBlockSubmit.bind(this);
    this.onCreateBlockReset = this.onCreateBlockReset.bind(this);
    this.onCreateBlockDateSelect = this.onCreateBlockDateSelect.bind(this);
    this.onCreateBlockDateReset = this.onCreateBlockDateReset.bind(this)

    this.makeDestinations = this.makeDestinations.bind(this);
    this.makeVideoBlocks = this.makeVideoBlocks.bind(this);
  }

  componentDidMount() {
    this.getDestinations();
  }

  getDestinations() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_DESTINATIONS_LIST, {body: fd}, this.onDestinationsLoaded);
  }

  getVideoSets() {
    /*
      Convert this.state.destinations.value to an integer so that
     'create' OR an empty value will return false.
    */

    if(
      !(+this.state.destinations.value) ||
      this.state.destinations.mode === 'create'
    ) return;

    const fd = new FormData();

    fd.set('id', this.state.destinations.value);
    fd.set('token', getAuthData('token'));

    apirequest(API_DESTINATIONS_ASSIGNMENTS, {body: fd}, (data) => {
      this.onVideoSetsLoaded(data);
    });
  }

  getBlockList(value) {
    if ( !value ) return;
    if( this.state.videosets.mode === 'create' ) return;

    /* Only make this request if the mode is 'select' */

    const fd= new FormData();
    fd.set('id', value);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSET_ASSIGNMENTS, { body: fd }, ( response ) => {
      this.onBlockListLoaded( response );
    });
  }

  getVideosForBlock() {
    const fd = new FormData();
    fd.set('id', this.state.videosetblocks.value);
    fd.set('token', getAuthData('token'));
    apirequest(API_VIDEOSETBLOCK_INFO, {body: fd}, this.onBlockVideosLoaded);
  }

  onDestinationsLoaded(data = []) {
    const dl = [
      { value: 'create', label: "Create Destination" }
    ];

    const list = data.map((item) => {
      return { value: item.dID, label: item.dName };
    });

    const dests = Object.assign(
      {...this.state.destinations},
      {
        options: dl.concat(list),
        disabled: false
      }
    );
    this.setState({destinations: dests});
  }

  onVideoSetsLoaded(data = []) {
    const vsl = [
      { value: 'create', label: "Create Video Set" }
    ];
    const videoSetList = data.map((item) => {
      return { value: item.setID, label: item.setName };
    });

    const newVideoSets = Object.assign(
      {...this.state.videosets},
      {options:  vsl.concat(videoSetList)}
    );
    this.setState({videosets: newVideoSets});
  }

  onBlockVideosLoaded(data) {
    if( !this.props.onVideosLoad ) return;
    this.props.onVideosLoad(data);
  }

  onCreateDestinationCancel(event) {
    this.setState({destinations: {...this.state.destinations, mode: 'select', value: null, name: null}});
  }

  onDestinationSelect(value) {
    if( !value ) return;
    this.setState((state) => setStateOnDestinationSelect(state, value), this.getVideoSets);
  }

  onCreateDestinationChange(domEvent) {
    let updatedState = {...this.state.destinations};
    updatedState.name = domEvent.target.value;
    this.setState({destinations: updatedState});
  }

  onCreateDestinationSubmit(domEvent) {
    domEvent.preventDefault();
    const fd = new FormData();

    fd.set('name', this.state.destinations.name);
    fd.set('token', getAuthData('token'));

    apirequest(API_DESTINATIONS_ADDNEW, {body: fd}, (response) => {
      if(response.result === 'success') {

        const newDestination = {
          value: `${response.d_id}`,
          label: response.d_name
        };

        this.setState({
          destinations: {
            ...this.state.destinations,
            value: `${response.d_id}`,
            mode: 'select',
            options: [
              ...this.state.destinations.options,
              newDestination,
            ]
          }
        },() => {
          this.onDestinationSelect(newDestination);
        });
      } else {
        alert(response.message);
      }
    });
  }

  onCreateVideoSetCancel(event) {

    const newState = {
      videosets: {
        ...this.state.videosets,
        mode: 'select',
        value: null,
        name: null
      }
    };

    this.setState(newState);
  }

  onVideoSetSelect(value) {

    let currentValue = value || {value: '', label: ''};

    let updateState = {
      value: currentValue.value,
      deleteDisabled: (currentValue.value) ? false : true,
      mode: (currentValue.value === 'create') ? 'create' : 'select'
    };

    const newState = Object.assign({...this.state.videosets}, updateState);

    this.setState({videosets: newState}, () => {
      this.getBlockList(currentValue.value);
    });
  }

  onCreateVideoSetChange(domEvent) {
    let updatedState = {...this.state.videosets};
    updatedState.name = domEvent.target.value;
    this.setState({videosets: updatedState});
  }

  onCreateVideoSetSubmit(domEvent) {
    domEvent.preventDefault();
    const fd = new FormData();
    fd.set('name', this.state.videosets.name);
    fd.set('destination', this.state.destinations.value);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSET_ADDNEW, {body: fd}, (response) => {
      this.setState((state) => setStateOnVideoSetSelect(state, response), () => { this.getBlockList(this.state.videosets.value); });
    });
  }

  onVideoBlockSelect(block) {
    let currentValue = block || {value: '', label: ''};

    const newState = Object.assign(
      {...this.state.videosetblocks},
      {
        value: currentValue.value,
        mode: (currentValue.value === 'create') ? 'create' : 'select'
      }
    );

    if(this.props.mode === 'video_info') {
      this.setState({videosetblocks: newState}, () => {
        this.props.onVideoBlockSelect(this.state);
      });
    } else {
       this.setState({videosetblocks: newState}, this.getVideosForBlock);
    }
  }

  onBlockListLoaded(data = []) {
    const bl = [
      { value: 'create', label: "Create Video Run Date" },
    ];

    const blocks = data.map((item) => {
    const rundate = (item.vsb_datetimetorun === null || item.vsb_datetimetorun === '0000-00-00') ? NULL_DATE_TEXT : format( new Date( item.vsb_datetimetorun ), 'PP', { timeZone: 'Europe/London' } );
    const interstitial = item.contains_interstitial ? ` ${INTERSTITIAL_INDICATOR} ` :  '';

      return {
        value: item.vsb_id,
        label: `${rundate} ${interstitial}`
      };
    });

    const newState = Object.assign(
      {...this.state.videosetblocks},
      {
        options: bl.concat(blocks),
        deleteDisabled: false
      }
    );
    this.setState({videosetblocks: newState});
  }

  onCreateBlockSubmit(domEvent) {
    domEvent.preventDefault();
    const fd = new FormData();

    const date = this.state.videosetblocks.newDate;
    const yyyymmdd =  date ? format(date, 'yyyy-MM-dd') : '0000-00-00';
    fd.set('date', yyyymmdd);
    fd.set('setID', this.state.videosets.value);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSETBLOCK_ADDNEW_DEPRECATED, {body: fd}, (response) => {
      if(response.result === 'success') {
        const dte = new Date( this.state.videosetblocks.newDate );

        const dateTxt = dte ? format(dte, 'PP' ) : NULL_DATE_TEXT;
        const newblock = [{
          value: response.blockID,
          label: dateTxt
        }];

        const newoptions = [...this.state.videosetblocks.options].concat(newblock);

        const newVsbState = Object.assign(
          {...this.state.videosetblocks},
          {
            options: newoptions,
            mode: 'select',
            value: response.blockID,
            datePickerDisabled: false
          }
        );

        this.setState({videosetblocks: newVsbState}, () => {
          this.props.onVideoBlockSelect(this.state);
        });

      } else {
        alert(response.message);
      }
    });
  }

  onCreateBlockReset(domEvent) {
    const obj = {
      ...this.state.videosetblocks,
      mode: 'select',
      newDate: null,
      value: null,
      datePickerDisabled: false
    };
    this.setState({videosetblocks: obj});
  }

  onCreateBlockDateSelect(value) {
    this.setState({videosetblocks: {...this.state.videosetblocks, newDate: value}});
  }

  blockNoDateSelected(domEvent) {
    let value = {
      ...this.state.videosetblocks,
      datePickerDisabled: domEvent.target.checked,
      newDate: ''
    };
    this.setState({ videosetblocks: value });
  }

  onCreateBlockDateReset() {
    this.setState({videosetblocks: {...this.state.videosetblocks, mode: 'select'}})
  }

  makeDestinations() {
    if(this.state.destinations.mode === 'create') {
      return (
        <form id="destinations__new_dest" className="destinations__create__new" onReset={this.onCreateDestinationCancel} onSubmit={this.onCreateDestinationSubmit}>
          <div className="form__label_btn_group">
            <label htmlFor="destination_name form__access__label">Enter a name for this destination</label>
            <input type="text" className="form-control" onChange={this.onCreateDestinationChange} id="destination_name" name="destination_name" maxLength="50" />
            <button type="reset" className="btn btn-default">Cancel</button>
            <button type="submit" disabled={!this.state.destinations.name} className="btn btn-default">Create</button>
          </div>
        </form>
      );
    }

    return (
      <div>
        <label htmlFor="DestinationsSelect__destination" className="form__access__label">Select a destination</label>
        <Select
          name="DestinationsSelect__destination"
          id="DestinationsSelect__destination"
          value={this.state.destinations.value}
          options={this.state.destinations.options}
          disabled={this.state.destinations.disabled || this.props.disabled }
          onChange={this.onDestinationSelect}
          maxLength="200"
          clearable={true}
          placeholder="Select Destination" />
      </div>
    );
  }

  makeVideoSets() {
    if(this.state.videosets.mode === 'create') {
      return (
        <form id="destinations__new_videoset" className="destinations__create__new" onReset={this.onCreateVideoSetCancel} onSubmit={this.onCreateVideoSetSubmit}>
          <div className="form__label_btn_group">
            <label htmlFor="list_name">Enter a name for this video set</label>
            <input type="text" className="form-control" onChange={this.onCreateVideoSetChange} id="list_name" name="list_name" maxLength="100" />
            <button type="reset" className="btn btn-default">Cancel</button>
            <button type="submit" disabled={!this.state.videosets.name} className="btn btn-default">Create</button>
          </div>
        </form>
      );
    }

    return (
      <div>
        <label htmlFor="DestinationsSelect__videoset" className="form__access__label">Select a Video Set</label>
        <Select
          name="DestinationsSelect__videoset"
          id="DestinationsSelect__videoset"
          value={this.state.videosets.value}
          options={this.state.videosets.options}
          disabled={!this.state.destinations.options.length || !this.state.videosets.options.length}
          onChange={this.onVideoSetSelect}
          maxLength="200"
          placeholder="Select Video Set" />
      </div>
    );
  }

  makeVideoBlocks() {
    if( this.state.videosetblocks.mode === 'create' ) {
      return (
        <form method="dialog" className="dateCreateContainer destinations__date_select" onSubmit={this.onCreateBlockSubmit} onReset={this.onCreateBlockDateReset}>
          <DatePicker disabled={this.state.videosetblocks.datePickerDisabled} id="create_date" selected={ this.state.videosetblocks.newDate } onChange={this.onCreateBlockDateSelect} dateFormat="ll" />
          <span className="fancy__chex">
            <input type="checkbox" id="no_date" name="create_date_none" onChange={this.blockNoDateSelected} />
            <label htmlFor="no_date">Unscheduled (No date)</label>
          </span>
          <span className="button__group">
            <button type="submit" className="btn btn-sm">Create</button>
            <button type="reset" className="btn btn-sm">Cancel</button>
          </span>
        </form>
      );
    }

    return (
      <div>
        <label htmlFor="DestinationsSelect__rundate" className="form__access__label">Select a Video Set</label>
        <Select
          name="DestinationsSelect__rundate"
          id="DestinationsSelect__rundate"
          value={this.state.videosetblocks.value}
          options={this.state.videosetblocks.options}
          disabled={!this.state.videosets.value || !this.state.destinations.value }
          onChange={this.onVideoBlockSelect}
          maxLength="200"
          placeholder="Select Video Run Date" />
      </div>
    );
  }

  render() {
    return (
      <section className="destinations__component destinations__component--videos">
        <h5>Add Video to Destination:</h5>
        <p hidden={ !this.props.disabled } className="form__instructions">{ MESSAGE_ADD_TO_AVA }</p>
        {this.makeDestinations()}
        {this.makeVideoSets()}
        {this.makeVideoBlocks()}
        <form id="DestinationsSelect" action="/" method="post" onSubmit={this.props.onSubmit}>
          <button id="DestinationsSelect" type="submit" className="btn btn--action" disabled={this.state.addVideoButtonDisabled || this.props.disabled }>Add Video To Destination</button>
        </form>
      </section>
    );
  }
}
