/**
 * VideoSetEdit.js
 * Allows for renaming of video sets.
 */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { CloseButton } from '../subcomponents/CloseButton';

import '../../css/VideoSetEdit.css';

import { debounce } from '../../js/Utilities';

export default class VideoSetEdit extends React.Component {
  constructor( _props ) {
    super( _props );

    this.onInput = this.onInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onClose = this.onClose.bind(this);
    
    this.modal = createRef();
  }
  
  componentDidUpdate() {
    if( this.props.open ) {
      this.modal.current.showModal()
    }
  }

  onInput(domEvent) {
    debounce(200, this.props.onInput(domEvent));
  }

  onSubmit(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();
    if(this.props.onSubmit) {
      this.props.onSubmit(this.modal);
    }
  }
  
  onClose() {
    if(this.props?.onClose) {
      this.props.onClose(this.modal);
    } 
  }

  onReset(domEvent) {
    this.onClose(this.modal);
    if(this.props.onReset) {
      this.props.onReset(domEvent);
    }
  }

  render() { 

    return (
      <dialog ref={this.modal} id="VideoSetEdit">
        <CloseButton longForm={true} onClick={this.onClose} title="Close" />
        <h1>{this.props.title}</h1>
        <form action={this.props.apiURL} method="dialog" onSubmit={this.onSubmit} onReset={this.onReset} className="video__set__edit__form">
          <div>
            <label htmlFor="destinations_new_set_name">{this.props.labelText}</label>
            <div>
              <input type="text" onChange={this.onInput} name={this.props.id} id={this.props.id} value={this.props.value || ''} required aria-describedby="video_set_edit_error" />
              <button type="submit" className="btn btn--action">Save</button>
              <button type="reset" className='btn'>Cancel</button>
            </div>
            <p id="video_set_edit_error" className="video__set__edit__error" hidden={!this.props.error}>{this.props.error}</p>
          </div>
        </form>
      </dialog>
    )
  }
}

VideoSetEdit.defaultProps = {
  onInput:  ()=>{},
  onSubmit: ()=>{},
  onReset:  ()=>{},
  onClose:  ()=>{},
  apiURL:   'api/videosetrename',
  labelText: 'Enter a name for this video set',
  id: 'destinations_new_set_name'
};

VideoSetEdit.propTypes = {
  onInput: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  apiURL: PropTypes.string.isRequired,
  value: PropTypes.string,
  id: PropTypes.string.isRequired
};
