/**
 * Destinations.js
 */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  API_DESTINATIONS_ADDNEW,
  API_DESTINATIONS_RENAME,
  API_VIDEOSET_ADDNEW,
  API_DESTINATIONS_SEARCH,
  GENERIC_NO_RESULTS,
  MESSAGE_ERROR_GENERAL,
  MESSAGE_DESTINATION_DELETE_CONFIRM,
  MESSAGE_DESTINATION_DELETED,
  MESSAGE_DESTINATION_DELETE_UNNAMED
} from '../js/Configuration';

import { ActionMessageDisplay } from './subcomponents/ActionMessageDisplay';
import DestinationsSearchResults from './Destinations/DestinationsSearchResults';
import DestinationsSearch from './Destinations/DestinationsSearch';
import LoaderSimple from './subcomponents/LoaderSimple';
import { CreateDestination } from './Destinations/CreateDestination';
import { CreateVideoSet } from './Destinations/CreateVideoSet';
import { DestinationsAddNew } from './Destinations/DestinationsAddNew';
import { DestinationsRenameTrigger } from './Destinations/DestinationsRenameTrigger';
import { DestinationsRename } from './Destinations/DestinationsRename';


import {
  apirequest,
  formatString,
  getAuthData,
  isAdmin,
} from '../js/Utilities';

import {
  duplicatePlaylist,
  getDestinations,
  deleteDestination,
  findDestinationById,
  restoreVideoSet,
  setDestinationsLoadedState,
  setSearchResetState,
  setSearchResponseState,
  setSearchLoadingState,
  setSearchState,
  setResetEpisodeState,
  setStatePostCreateDestination,
  setStateCloseCreateDestModal,
  setStateCreateVidsetClose,
  setStateOnCloseMessage,
  setStateOnCreateChange,
  setStateOnCreateOpen,
  setStateGetInteractives,
  setStateOnRenameOpen,
  setStateOnRenameChange,
  setStateOnRenameClose,
  setStateOnRenameSave,
  setStateAfterRenameChange
} from '../actions/DestinationsUtils';

import './Destinations/css/Destinations.css';

export default class Destinations extends React.Component {
  constructor( _props ) {
    super( _props );

    this.state = {
      loading: true,
      search: {
        loading: false,
        active: false,
        results: [],
        keyword: ''
      },
      destinations: {
        list: [],
        withinactives: false,
        renaming: {
          open: false,
          destination: '',
          value: ''
        }
      },
      create: {
        open: false,
        value: ''
      },
      videoset: {
        open: false,
        value: '',
        destination: {
          dID: '',
          dName: ''
        },
      },
      remove: {
        mode: 'success',
        message: '',
        hidden: true
      }
    };

    this.buildLinks = this.buildLinks.bind(this);
    this.makeSearchResults = this.makeSearchResults.bind(this);
    this.handleSearchResponse = this.handleSearchResponse.bind(this);
    this.showCreate = this.showCreate.bind(this);
    this.showDelete = this.showDelete.bind(this);
    this.showRename = this.showRename.bind(this);

    this.duplicate = this.duplicate.bind(this);
    this.onDestinationsLoaded = this.onDestinationsLoaded.bind(this)

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchDestinations = this.onSearchDestinations.bind(this);
    this.onResetSearch = this.onResetSearch.bind(this);
    this.onSearchFocus = this.onSearchFocus.bind(this);

    this.onResetEpisodes = this.onResetEpisodes.bind(this);
    this.onCreateChange = this.onCreateChange.bind(this);
    this.onCreateOpen = this.onCreateOpen.bind(this);
    this.onCreateSave = this.onCreateSave.bind(this);
    this.onCreateVidsetClose = this.onCreateVidsetClose.bind(this);
    this.onCreateDestinationClose = this.onCreateDestinationClose.bind(this);
    this.onCreateDestinationResponse = this.onCreateDestinationResponse.bind(this);
    this.onCreateVidsetResponse = this.onCreateVidsetResponse.bind(this);
    this.onInactivesChange = this.onInactivesChange.bind(this);

    this.onRenameOpen = this.onRenameOpen.bind(this);
    this.onRenameClose = this.onRenameClose.bind(this);
    this.onRenameChange = this.onRenameChange.bind(this);
    this.onRenameSave = this.onRenameSave.bind(this);

    this.onCloseMessage = this.onCloseMessage.bind(this);

    this.onDelete = this.onDelete.bind(this);
    this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
    this.onDeleteComplete = this.onDeleteComplete.bind(this);

    this.onPopState = this.onPopState.bind(this);
    this.onRestore = this.onRestore.bind(this);
    this.onRestoreAfter = this.onRestoreAfter.bind(this);

    this.isAdmin = isAdmin(this.props.project);
  }

  onPopState(domEvent) {
    domEvent.preventDefault();

    var pars = new URLSearchParams(window.location.search);
    if(!pars.get('q')) return;

    /*
     * If the length of the q parameter is less than or equal to the length of
     * the current keyword being searched, assume this is a back button operation
     * and replace the last item in the browser history.
     */
    if( pars.get('q').length <= this.state.search.keyword.length ) {
      window.history.replaceState({}, 'Destinations', '/destinations');
      // Reset search object.
      this.onResetSearch();
    }
  }

  componentWillMount() {
    window.scrollTo(0,0);
    window.addEventListener('popstate', this.onPopState);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onPopState);
  }

  componentDidMount() {
    getDestinations(this.onDestinationsLoaded);
  }

  /*
  Method will disappear in a future version of React if we ever get around to
  upgrading. For now, it's fine.
  */
  componentWillUpdate(nextProps, nextState) {
    if(!window.location.search && nextState.search.keyword) {
      this.onResetSearch();
    }
  }

  onDestinationsLoaded(response) {
    this.setState((state) => setDestinationsLoadedState(state, response));
  }

  onInactivesChange(domEvent) {
    const value = domEvent.target.checked;
    this.setState((state) => setStateGetInteractives(state, value), () => this.onRestoreAfter(value));
  }

  onRestore(domEvent) {
    domEvent.preventDefault();
    const setId = domEvent.target.elements.id.value;
    restoreVideoSet(setId, this.onRestoreAfter);
  }

  onRestoreAfter(value) {
    if(this.state.search.keyword) {
      this.onSearchDestinations();
    } else {
      getDestinations(this.onDestinationsLoaded, false, value);
    }
  }

  showCreate() {
    let button = null;
    if(this.isAdmin) {
      button = <button type="button" className="btn btn--action" onClick={this.onCreateOpen} data-action="create">Create New Destination</button>
    }
    return button;
  }

  showDelete(item) {
    let button = null;
    if(this.isAdmin) {
      button = <button type="button" className="btn btn--destructive" data-destinationid={item.dID} onClick={this.onDelete}>Delete <span className="form__access__label">to <q>{item.dName}</q></span></button>
    }
    return button;
  }

  showRename(item) {
    let button = null;
    if(this.isAdmin) {
      button = <DestinationsRenameTrigger onClick={this.onRenameOpen} {...item} />
    }
    return button;
  }

  buildLinks(resp) {
    // If there's something in the search field, assume this is a search and clear
    // links.
    if(this.state.search.keyword) { return; }
    if(this.state.loading) {
      return <LoaderSimple open={this.state.loading} />;
    }

    const dests = this.state.destinations.list.map((item) => {
      const css = item.hasOwnProperty('isNew') ? 'destinations__list__new' : '';

      return (
        <tr key={`destination_${item.dID}`}  className={css}>
          <td>
            <Link to={{ pathname: `/destinations/videosets/${item.dID}`, state: { destination: item.dName} }}>{item.dName}</Link>
          </td>
          <td className="button__group">
            <DestinationsAddNew {...item} onClick={this.onCreateOpen} />
            {this.showRename(item)}
            {this.showDelete(item)}
          </td>
        </tr>
      )
    });

    return (
      <table className="destinations__list">
        <thead>
          <tr>
            <td>Name</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>{dests}</tbody>
      </table>
    );
  }

  makeSearchResults() {
    let results;
    if(!this.state.search.keyword) return;

    if(this.state.search.loading) {
      return <LoaderSimple open={this.state.search.loading} />;
    }

    if(!!this.state.search.results.length) {
      results = (
        <DestinationsSearchResults
          keyword={this.state.search.keyword}
          results={this.state.search.results}
          onRestore={this.onRestore} isAdmin={this.isAdmin} />
      );
    } else {
      results = <h3>{GENERIC_NO_RESULTS}</h3>;
    }
    return results;
  }

  handleSearchResponse(response) {
    this.setState((state) => setSearchResponseState(state, response));
  }

  onResetSearch() {
    this.setState(setSearchResetState);
  }

  duplicate(id) {
    duplicatePlaylist(id, (data) => {
      this.props.history.push(`/destinations/playlist/${data}`, {is_dupe: true});
    });
  }

  onSearchChange(domEvent) {
    const value = domEvent.target.value;
    this.setState((state) => setSearchState(state, value), this.onSearchDestinations);
  }

  onSearchDestinations(domEvent = null) {
    if(domEvent) {
      domEvent.preventDefault();
    }

    window.history.pushState({'activeKeyword': this.state.search.keyword}, 'search', `?q=${this.state.search.keyword}`);
    this.setState(setSearchLoadingState, () => {
      if(!this.state.search.keyword) return;

      const fd = new FormData();
      fd.set('q', this.state.search.keyword);
      fd.set('token', getAuthData('token'));

      if(this.state.destinations.withinactives) {
        fd.set('inactives', 1);
      }

      apirequest(API_DESTINATIONS_SEARCH, {body: fd}, this.handleSearchResponse);
    });
  }

  onSearchFocus() {
    window.history.pushState({}, 'search', '?q=');
  }

  onResetEpisodes(domEvent) {
    this.setState(setResetEpisodeState);
  }

  onCreateChange(domEvent) {
    const input = domEvent.target.value;
    const form = domEvent.target.form.id
    this.setState((state) => setStateOnCreateChange(state, form, input));
  }

  onCreateSave( domEvent, dialogRef = null) {
    let callback;
    let endpoint;

    switch(domEvent.target.id) {
      case 'destination_create_vidset':
        callback = (response) => { this.onCreateVidsetResponse(response, dialogRef) };
        endpoint = API_VIDEOSET_ADDNEW;
        break;
      default:
        callback = (response) => { this.onCreateDestinationResponse(response, dialogRef )};
        endpoint = API_DESTINATIONS_ADDNEW;
    }
    const fd = new FormData(domEvent.target);
    apirequest(endpoint, {body: fd}, callback);
  }

  onCreateDestinationSave(domEvent) {
    domEvent.preventDefault();
    const fd = new FormData(domEvent.target);
    apirequest(API_DESTINATIONS_ADDNEW, {body: fd}, this.onCreateDestinationResponse);
  }

  onCreateDestinationClose(dialogRef=null) {
    this.setState((state, prevState) => {
      return {
        create: {
          ...state.create,
          open: false,
        }
      }
    }, () => { if( dialogRef ) dialogRef.current.close(); })
  }

  onCreateDestinationResponse(response, dialogRef=null) {
    this.setState(
      (state) => setStatePostCreateDestination(state, response),
      () => { if(dialogRef) dialogRef.current.close(); }
    );
  }

  onCreateVidsetResponse(response, dialogRef = null) {
    if(response.result !== 'success') {
      this.setState({remove: {mode: 'failed', message: MESSAGE_ERROR_GENERAL, hidden: false}});
      return;
    }
    this.setState({
      videoset: {
        ...this.state.videoset,
        open: false
      },
      remove: {
        mode: 'success',
        message: <span><q>{response.setName}</q> was added to <q>{response.destination}</q> <Link to={{ pathname: `/destinations/rundates/${response.setID}`, state: { destination: response.setName} }}>View</Link></span>,
        hidden: false
      }
    }, () => { dialogRef.current.close() });
  }

  onCreateVidsetClose(dialogRef) {
    this.setState(setStateCreateVidsetClose, () =>  dialogRef.current.close());
  }

  onCreateOpen(domEvent = null) {
    if(!domEvent) return;
    const action = domEvent.target.dataset.action;
    let destination = null;

    if(domEvent.target.dataset.destinationid) {
      destination = findDestinationById(this.state.destinations.list, domEvent.target.dataset.destinationid);
    }
     
    this.setState((state) => setStateOnCreateOpen(state, action, destination));
  }

  onDelete(domEvent) {
    const id = domEvent.target.dataset.destinationid;
    const toDelete = findDestinationById(this.state.destinations.list, id);

    let destName = MESSAGE_DESTINATION_DELETE_UNNAMED;
    if( toDelete.hasOwnProperty('dName') && toDelete?.dName ) {
      destName = toDelete.dName.toUpperCase();
    }

    const confirm = window.confirm(formatString(MESSAGE_DESTINATION_DELETE_CONFIRM, [destName]));
    if(!confirm) return;
    this.setState((state) => { return { loading: true} }, () => { this.onDeleteSubmit(toDelete) });
  }

  onDeleteSubmit(destObj) {
    deleteDestination(destObj.dID, (response) => {
      const ok = response.result === 'success';
      const removeState = {
        mode: (ok ? 'success' : 'failed'),
        message: ok ? <span><q>{destObj.dName}</q> {MESSAGE_DESTINATION_DELETED.replace(/%s\s/,' ')}</span> : MESSAGE_ERROR_GENERAL,
        hidden: false
      };

      this.setState({ loading: false, remove: removeState}, this.onDeleteComplete);
    });
  }

  onDeleteComplete() {
    getDestinations(this.onDestinationsLoaded);
  }

  onCloseMessage() {
    this.setState(setStateOnCloseMessage)
  }

  onRenameOpen(domEvent = null) {
    if(!domEvent) return;
    const destination = findDestinationById(this.state.destinations.list, domEvent.target.dataset.destinationid);
    this.setState((state) => setStateOnRenameOpen(state, destination));
  }

  onRenameClose(dialogRef) {
    this.setState(setStateOnRenameClose, () => dialogRef.current.close() )
  }

  onRenameChange(domEvent = null) {
    if(!domEvent) return;
    const value = domEvent.target.value;
    this.setState((state) => setStateOnRenameChange(state, value))
  }

  onRenameSave(domEvent, dialogRef=null) {
    domEvent.preventDefault();
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', this.state.destinations.renaming.destination.dID);
    fd.set('name', this.state.destinations.renaming.value);
    apirequest(API_DESTINATIONS_RENAME, {body: fd}, (response) => {
      this.setState(
        (state) => setStateAfterRenameChange(state, response),
        () => { if(dialogRef) dialogRef.current.close(); }
      )
    })
  }

  render() {
    return (
      <article className="stations__view">
        <header id="destination__header">
          <h1>Destinations</h1>
          {this.showCreate()}
        </header>

        <DestinationsSearch
          isAdmin={this.isAdmin}
          value={this.state.search.keyword}
          onSubmit={this.onSearchDestinations}
          onChange={this.onSearchChange}
          onFocus={this.onSearchFocus}
          onReset={this.onResetSearch}
          onInactiveChange={this.onInactivesChange} />

        <ActionMessageDisplay hidden={this.state.remove.hidden} message={this.state.remove.message} mode={this.state.remove.mode} onClose={this.onCloseMessage} />

        {this.buildLinks()}
        {this.makeSearchResults()}

        <CreateDestination {...this.state.create} onSubmit={this.onCreateSave} onChange={this.onCreateChange} onClose={this.onCreateDestinationClose} />
        <CreateVideoSet {...this.state.videoset} onClose={this.onCreateVidsetClose} onSubmit={this.onCreateSave} onChange={this.onCreateChange} />
        <DestinationsRename {...this.state.destinations.renaming} onClose={this.onRenameClose} onSubmit={this.onRenameSave} onChange={this.onRenameChange} />
      </article>
    );
  }
}
