import React from 'react';
import './css/RegionLangDisplay.css';

/*
Spaces before the </b> are invisible, non-breaking.
Delete once Chrome 84 is released.
*/

export const RegionLangDisplay = (props) => { 
  return (
      <div id="RegionLangDisplay">
        <b>Region: </b>
        <b>{props.region.name}</b>
        <i>{props.region.flag.flag}</i>
        <b>Language:  </b>
        <b>{props.language.name}</b>
        <button className="btn__link" onClick={props.onClick}>Change</button>
      </div>
    );
}

