/**
 * ChannelGroupDelete.js
 */
import React from 'react';
import './css/ChannelGroupDelete.css';

export const ChannelGroupDelete = (props) => {
  return (
    <form className="badge__delete" onSubmit={props.onSubmit} key={props.group}>
      <input type="hidden" name="groupID" value={props.group} />
      <input type="hidden" name="channelID" value={props.channel} />
      <span>
        {props.name}
        <button type="submit" className="badge__delete__btn">
          <b className="form__access__label">Remove channel from {props.name}</b>
          ✕
        </button>
      </span>
    </form>
  );
}

