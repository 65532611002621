/**
 * EditUserModal.js
 * Form for adding a new user
 * See PropTypes definitions at the end of this file for parameters
 */

import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../subcomponents/Modal';
import AddUserForm from './AddUserForm';

const EditUserModal = (props) => (
  <Modal id="UserAdminEditUser" onClose={props.onClose} open={props.open}>
    <AddUserForm
      edit={true}
      cssClass="user__admin__edit__user"
      title={`Editing user: ${props.form.user_name || props.form.username}`}
      onToggle={null}
      {...props} />
  </Modal>
);

EditUserModal.defaultProps = {
  form: {},
  projects: [],
  mode: {},
  onChange: () => {}
}

EditUserModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onChange: PropTypes.func
};

export default EditUserModal;
