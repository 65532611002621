/**
 * EpisodeViewTitle.js
 * Title and change title component
 */
import React from 'react';
import { NULL_EPISODE_TITLE } from '../../js/Configuration';

import './css/EpisodeViewTitle.css';

export default class EpisodeViewTitle extends React.Component {
  render() {
    let del;
    
    if(this.props.isAdmin) {
      del = <button type="button" onClick={this.props.onEpisodeDelete} className="btn btn-sm btn-light">Delete Episode</button>;
    } else {
      del = null;
    }

    return (
      <header className="episode__view__title">
        <h1>{this.props.title || NULL_EPISODE_TITLE}</h1>
        <button type="button" onClick={this.props.onEpisodeEdit} className="btn btn-sm btn--action">Edit Episode Name</button>
        <button type="button" onClick={this.props.onEpisodeMove} className="btn btn-sm btn--action">Move Episode</button>
        {del}
      </header>
    ); 
  }
}
