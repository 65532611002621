import {
  apirequest,
  getSortDirection,
  getAuthData
} from '../js/Utilities';

import {
  API_VIDEO_GETBATCH
} from '../js/Configuration';


export const setStateOnDirectionChange = (state) => {
  return {
    isLoading: true,
    ascending: !state.ascending
  }
};

export const setStateOnSortChange = (state, value) => {
  return {
    sortBy: value
  }
};

export const setStateOnFilterClear = (state, value) => {
  return {
    filter: {
      ...state.filter,
      searchValue: '',
      filterOn: 'video_title',
      categories: [],
      hideCategories: true,
      category: '',
      showHint: false
    }
  }
}

export const setStateOnVideoBlockSelect = (state, response) => {
  return {
    videoinfo: {
      ...state.videoinfo,
      selectedSet: {
        videosetid: response.videosets.value,
        videoblockid: response.videosetblocks.value
      }
    }
  };
}

export const setStateOnCloseModal = (state) => {
  return {
    videoinfo: {
      ...state.videoinfo,
      open: false,
      videoData: {},
      selectedSet: {
        videosetid: '-1',
        videoblockid: '-1'
      }
    }
  }
}

export const setStateLoading = (state) => {
  return { isLoading: true }
}

export const setStateAfterGetPage = (state, response) => {
  return {
    ...state,
    filter: {
      ...state.filter,
      hideCategories: state.filter.filterOn !== 'video_category'
    },
    videoList: response.videos,
    isLoading: !state.isLoading,
    page: response.current_page,
    pageCount: response.pages,
    numResults: response.num_results
  };
}


export const requestPage = (state, callback, signal) => {
  let sig;

  sig = signal ? signal : new AbortController();

  const fd = new FormData();
  fd.set('perpage', state.perPage);
  fd.set('page', state.page);
  fd.set('sort_by', state.sortBy);
  fd.set('filter_by', state.filter.searchValue);
  fd.set('filter_on', state.filter.filterOn);
  fd.set('order', getSortDirection(state.ascending));
  fd.set('token', getAuthData('token'));
  fd.set('category', state.filter.category);
  apirequest(API_VIDEO_GETBATCH, { body: fd, sig }, callback);
}

export const setStatePreviousPage = (state) => {
  const nextPage = (state.page > state.pageCount) ? state.pageCount : state.page + 1;
  return {page: nextPage, isLoading: true};
}

export const setStateNextPage = (state) => {
  const prevPage = (state.page <= 0) ? 1 : state.page - 1;
  return {page: prevPage, isLoading: true};
}

export const setStateReceivedVideoData = (state, response) => {
  return {
    videoinfo: {
      ...state.videoinfo,
      open: true,
      videoData: response
    }
  }
}

export const setStateOnGetRatings = (state, response) => {
  return {
    ratings: response
  }
}

export const setStateOnRatingsChange = (state, value) => {
  return {
    videoinfo: {
      ...state.videoinfo,
      rating: value
    }
  }
}

export const setStateAfterRatingsUpdate = (state, value) => {
  return {
    videoinfo: {
      ...state.videoinfo,
      open: false,
      rating: '',
      videoData: {},
    },
    videoList: value
  }
}

export const setStateAfterGetCategories = (state, response) => {
  const r = [...response];
  r.unshift({label: 'Select a category', id: ''});

  return {
    filter: {
      ...state.filter,
      categories: r
    }
  };
}

export const setStateOnFilterChange = (state, target) => {
  let response = {
    isLoading: true,
    filter: {
      ...state.filter,
      [target.name]: target.value,
      hideCategories: target.value !== 'video_category',
      showHint: target.name == 'video_title_tags' || target.name == 'video_description'
    }
  };

  if( target.value === 'video_category' ) {
    response.filter.searchValue = '';
  }
  return response;
}

export const setStateOnAddNew = (state) => {
  return {
    sortBy: 'video_addeddate',
    isLoading: true,
    ascending: false,
  }
}
