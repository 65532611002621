import React, { useRef } from 'react';
import { CloseButton } from '../subcomponents/CloseButton';
import PropTypes from 'prop-types';
import TextInput from '../subcomponents/TextInput';

import { API_DESTINATIONS_ADDNEW } from '../../js/Configuration';
import { getAuthData } from '../../js/Utilities';

export const CreateDestination = (props) => {
  const cdref = useRef();
  if(props.open) {
    cdref.current.showModal();
  }

  const onClose = () => { 
    if(props.onClose) props.onClose(cdref);
  }
  
  const onSubmit = (domEvent) => {
    domEvent.preventDefault();
    domEvent.persist();

    if( props.onSubmit ) {
      props.onSubmit(domEvent, cdref);
    }
  }
  
  return (
    <dialog ref={cdref} id="DestinationsCreateNew">
      <CloseButton onClick={onClose} longForm={true} />
      <h2>Create a New Destination</h2>
      <form onSubmit={onSubmit} id="destination_create_new" action={API_DESTINATIONS_ADDNEW} method="POST">
        <input type="hidden" name="token" value={getAuthData('token')} onChange={()=>{}}/>
        <TextInput
          cssClass="destinations__create-new"
          labelText="Enter the name for this destination"
          name="name"
          maxLength="50"
          isRequired={true}
          onInputHandler={props.onChange}
          value={props.value} />
        <button type="submit" className="btn btn--action">Save</button>
      </form>
    </dialog>
  );
}

CreateDestination.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};
