/**
 * CloseButton.js
 * A no-framework meter input
 */
import React from 'react';
import classNames from 'classnames';

import './css/CloseButton.css';

export const CloseButton = (props) => {
  const cssClasses = classNames(
    props.className,
    {
      'close__button': true,
      'close__button--longform': props.longForm
    }
  );
  
  let title, longform = '';
  
  title = props.title ? <title>{title}</title> : 'Close this modal';

  if(props.longForm) {
    longform = <span>{props.title}</span>;
    title = '';
  }

  return (
    <button type="button" className={cssClasses} onClick={props.onClick}>
      <svg viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg">
        {title}
        <polygon fill={props.color} points="1.61065574 242.514344 93.8196721 150.305328 1 57.7909836 57.7909836 1 150.610656 93.5143443 242.819672 1.30532787 298.084016 56.875 205.875 149.084016 299 242.209016 242.209016 299 149.084016 205.875 56.875 298.084016"></polygon>
      </svg>
      {longform}
    </button>
  );
}



