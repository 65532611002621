import React from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';

import YTPlayIcon from './YTPlayIcon';
import { BadgeMEOWCRT } from '../Videos/BadgeMEOWCRT';

import {
  truncateString,
  numberFormat,
  formatTime,
  isNumeric
} from '../../js/Utilities';

import { MESSAGE_NOT_SUBMITTED } from '../../js/Configuration';

import '../../css/VideoItem.css';

export default class VideoItem extends React.Component {
  constructor( _props ) {
    super( _props );
    this.onPlayVideo = this.onPlayVideo.bind(this);
    this.makeBadge = this.makeBadge.bind(this);
  }

  onPlayVideo() {
    this.props.playVideo(this.props.videoData.video_youtubeID);
  }

  makeBadge(obj = {}, isMeow = false) {
    return (
      <BadgeMEOWCRT {...obj} mode={ isMeow ? 'meow' : 'crt' } />
    )
  }

  render() {
    if( !this.props.videoData ) return null;

    const cssClasses = classNames({
      'videoListItem': true,
      'results__list__item': true,
      'results__list__item--interstitial': this.props.videoData.is_interstitial
    }, this.props.cssClass);

    let channelTitle = this.props.videoData.channel_title ? truncateString(this.props.videoData.channel_title, 20) : "No Channel Info";

    let video_rating = this.props.videoData.rating ? this.props.videoData.rating.label : "—";

    const ratingCSS = this.props.videoData.rating ? '' : '__unrated';

    return (
      <div className={cssClasses}>
          <h5 onClick={this.onPlayVideo} className="results__list__item__title videoResultItemHeader divTransition">{this.props.videoData.video_title}</h5>

          <button onClick={this.onPlayVideo}  className="videoThumb video__item__thumb"><img lazy="true" width="480" height="360" src={this.props.videoData.videoext_thumbnail} alt={`Watch ${this.props.videoData.video_title}`} /><YTPlayIcon /></button>

          <ul className="video__item__metadata">
            <li><b className="listText">Channel Title:</b> <span className="itemsBadge">{channelTitle}</span></li>
            <li><b className="listText">Video Publish Date:</b><span className="itemsBadge">{ format( this.props.videoData.videoext_publishdatetime * 1000, 'PP' )  }</span></li>
            <li><b className="listText">Added to AVA:</b><span className="itemsBadge">{ format( this.props.videoData.video_dateadded * 1000, 'PP' )  }</span></li>
            <li><b className="listText">Duration:</b><span className="itemsBadge">{ formatTime(this.props.videoData.videoext_duration) }</span></li>
            <li><b className="listText">Views:</b><span className="itemsBadge">{ numberFormat( this.props.videoData.videoext_viewcount )}</span></li>
            <li><b className="listText"><abbr title="number">No.</abbr> of Video Sets:</b><span className="itemsBadge">{ this.props.videoData.video_setcount }</span></li>
            <li><b className="listText">Video Rating:</b><span className={`itemsBadge video__item__rating${ratingCSS}`}>{ video_rating }</span></li>
            <li><abbr className="listText">MEOW:</abbr> <span>{this.makeBadge(this.props.videoData.meow, true)}</span></li>
            <li><abbr title="Content Review Team" className="listText">CRT:</abbr> <span>{this.makeBadge(this.props.videoData.video_crt)}</span></li>
            <li><b className="listText">Category:</b> <span>{this.props.videoData.category}</span></li>
          </ul>
      </div>
    );
  }
};
