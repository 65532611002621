import React from 'react';
import { SearchForm } from './Search/SearchForm';
import SearchResultsPanel from './Search/SearchResultsPanel';
import ChannelsList from './ChannelsList';
import VideoModal from './subcomponents/Videos/VideoModal';
import LoaderSimple from './subcomponents/LoaderSimple';
import { RegionLangDisplay } from './subcomponents/RegionLangDisplay';
import uniqueId from 'lodash.uniqueid';

import {
  API_SEARCH,
  API_VIDEO_ADDTOSET,
  VIDEOS_PER_PAGE
} from '../js/Configuration';

import {
  apirequest,
  getAuthData,
  getSortDirection,
  showLoader,
  yyyymmdd
} from '../js/Utilities';

import {
  getChannels,
  getGroups,
  getRegions,
  getLanguages,
  search,
  addVideo,
  setStateAfterGetRegions,
  setStateAfterGetLangs,
  setStateAfterGetGroups,
  setStateBeforeGetChannels,
  setStateAfterGetChannels,
  setStateAfterResults,
  setStateChannelChoose,
  toggleAdvancedOptions,
  showAdvancedOptions,
  setStateOnSetRegion,
  setStateOnSetLanguage,
  setStateOnVideoBlockSelect,
  setStateAfterSubmittingVideoBlock,
  saveSearchCriteria,
  setStateIfSearchCriteria,
  setStateOnSetUserLang,
  setStateOnSearchReset,
  setStateOnModalClose,
  setStateAfterRatingsChange,
  setStateAfterMEOWChange,
  setStateAfterCRTChange,
  setStateOnPlayVideo
} from '../actions/SearchUtils';

import '../css/SearchMultiChannel.css';

export default class SearchMultiChannel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: [{label: 'Select Group', value: '0'}],
      advancedOpen: false,
      criteria: {
        order: 'viewCount',
        channelsearch: [], // Channels in which to search
        channelSearchGroup: 0
      },
      languages: [],
      regions: [],
      results: {},
      loading: false,
      channelslist: { // For the modal
        channels: [],
        currentPage: 1,
        perPage: 10,
        totalPages: 0,
        sortBy: 'channel_title',
        ascending: true,
        filterKeyword: '',
        filterOn: '',
        open: false,
        loading: false
      },
      videoinfo: {
        open: false,
        videoData: {},
        rating: '',
        selectedSet: {
          videosetid: '',
          videoblockid: ''
        },
        groups: [{label: 'Select Group', value: '0'}],
        tags: ''
      },
      international: {
        region: {
          code: 'YTE',
          name: 'All Regions',
          flag: {
            flag: '🌐',
            region: ''
          }
        },
        language: {
          code: 'YTE',
          name: 'All Languages'
        }
      }
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);

    this.onPanelToggle = this.onPanelToggle.bind(this);

    this.onShowChannels = this.onShowChannels.bind(this);
    this.onHideChannels = this.onHideChannels.bind(this);

    this.setCriteria = this.setCriteria.bind(this);
    this.setChannelGroup = this.setChannelGroup.bind(this);
    this.setRegion = this.setRegion.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.setNewRegion = this.setNewRegion.bind(this);
    this.setNewLanguage = this.setNewLanguage.bind(this);

    this.setResults = this.setResults.bind(this);

    this.setUserLang = this.setUserLang.bind(this);
    this.makeResults = this.makeResults.bind(this);
    this.makeResultPanel = this.makeResultPanel.bind(this);
    this.makeChannelResultPanel = this.makeChannelResultPanel.bind(this);

    this.makeFormData = this.makeFormData.bind(this);

    this.onNext = this.onNext.bind(this);
    this.onPrevious = this.onPrevious.bind(this);
    this.onChannelListNav = this.onChannelListNav.bind(this);
    this.onChannelSelect = this.onChannelSelect.bind(this);
    this.onChannelSearchSubmit = this.onChannelSearchSubmit.bind(this);
    this.onChannelsPerPageChange = this.onChannelsPerPageChange.bind(this);
    this.onChannelFilterChange = this.onChannelFilterChange.bind(this);
    this.onShowOptions = this.onShowOptions.bind(this);
    this.onToggleOptions = this.onToggleOptions.bind(this);

    this.getChannelBatch = this.getChannelBatch.bind(this);
    this.doGetChannelBatch = this.doGetChannelBatch.bind(this);

    this.makeChannelList = this.makeChannelList.bind(this);

    this.preSearch = this.preSearch.bind(this);
    this.doSearch = this.doSearch.bind(this);
    this.preSavedSearch = this.preSavedSearch.bind(this);
    this.updateAfterAdding = this.updateAfterAdding.bind(this);

    this.playVideo = this.playVideo.bind(this);
    this.closeVideo = this.closeVideo.bind(this);

    this.addToSystem = this.addToSystem.bind(this);
    this.addVideoToSet = this.addVideoToSet.bind(this);
    this.postCRTHandler = this.postCRTHandler.bind(this);
    this.postMeowHandler = this.postMeowHandler.bind(this);
    this.postRatingHandler = this.postRatingHandler.bind(this);
    this.postTagsHandler = this.postTagsHandler.bind(this);

    this.isSelectedChannel = this.isSelectedChannel.bind(this);
    this.getLangs = this.getLangs.bind(this);
    this.getGrps = this.getGrps.bind(this);

    this.afterGetRegions = this.afterGetRegions.bind(this);
    this.afterGetLangs = this.afterGetLangs.bind(this);
    this.afterGetGroups = this.afterGetGroups.bind(this);

    this.updateVideo = this.updateVideo.bind(this);

    // Prevents API requests from clogging up and blocking the UI.
    this.fetchcancelqueue = new Map();
  }

  componentDidMount() {
    const userSearch = getAuthData('userSearch');

    if( userSearch ) {
      this.setState(showLoader, this.preSavedSearch);
    } else {
      getRegions(this.afterGetRegions);
    }
  }

  componentWillUnmount() {
    saveSearchCriteria( this.state.criteria );
    this.fetchcancelqueue.forEach((value, key) => {
      value.abort();
    });
  }

  afterGetRegions(response) {
    this.setState((state) => setStateAfterGetRegions(state, response), this.getLangs);
  }

  afterGetLangs(response) {
    this.setState((state) => setStateAfterGetLangs(state, response), this.getGrps);
  }

  afterGetGroups(response) {
    this.setState((state)=> setStateAfterGetGroups(state, response), this.setUserLang);
  }

  getLangs() {
    getLanguages(this.afterGetLangs);
  }

  getGrps() {
    getGroups(this.afterGetGroups);
  }

  preSearch(dialogRef = null) {
    if( dialogRef && Object.hasOwn(dialogRef, 'current')) {
      dialogRef.current.close();
    }
    this.setState(showLoader, this.doSearch);
  }

  preSavedSearch() {
    this.setState(setStateIfSearchCriteria, () => {
      getRegions(this.afterGetRegions);
      this.doSearch();
    });
  }

  doSearch() {
    const fd = this.makeFormData();
    
    // If we have an array of channels, make a separate request for each.
    if(this.state.criteria.channelsearch.length) {
      this.state.criteria.channelsearch.forEach((channel) => {

        fd.set('channelId', channel.youtubeid || channel.channel_youtubechannelid);
        fd.set('maxResults', VIDEOS_PER_PAGE);
        search({body: fd}, this.setResults);

      });

    } else {
      fd.set('maxResults', VIDEOS_PER_PAGE * 2);
      search({body: fd}, this.setResults);
    }
  }

  makeFormData() {
    const fd = new FormData();
    const fields = Object.keys(this.state.criteria);

    fields
    .filter((item) => ['channelsearch', 'channelSearchGroup'].indexOf(item) == -1)
    .forEach((item) => {
      let criterion;

      if( (item === 'regionCode' || item === 'relevanceLanguage') ) {
        switch(this.state.criteria[item]) {
          case 'all':
          case 'YTE':
            criterion = '';
            break;
          default:
            criterion = this.state.criteria[item];
        }

      } else {
        criterion = this.state.criteria[item];
      }

      fd.set(item, criterion);
    });
    fd.set('token', getAuthData('token'));
    return fd;
  }

  onShowOptions() {
     this.setState(showAdvancedOptions);
  }

  onToggleOptions() {
     this.setState(toggleAdvancedOptions);
  }

  doGetChannelBatch() {
    const fd = new FormData();
    fd.set('perpage', this.state.channelslist.perPage);
    fd.set('page', this.state.channelslist.currentPage);
    fd.set('sort_by', this.state.channelslist.sortBy);
    fd.set('filter_by', this.state.channelslist.filterKeyword);
    fd.set('filter_on', this.state.channelslist.filterOn);
    fd.set('order', getSortDirection(this.state.channelslist.ascending));
    fd.set('token', getAuthData('token'));

    const cb = (data) => {
      this.setState((state) => setStateAfterGetChannels(state, data));
    };
    getChannels({ body: fd }, cb);
  }

  getChannelBatch(callback=null) {
    this.setState(setStateBeforeGetChannels, this.doGetChannelBatch);
  }

  onChange(domEvent) {
    domEvent.persist();

    switch(domEvent.target.name) {
      case 'search_group':
        // Clear out channels array and search results display
        this.setState({results: {}});
        this.setChannelGroup(domEvent.target.value);
        break;

      case 'order':
        this.setCriteria(domEvent.target, this.onSubmit);
        break;

      case 'regionCode':
        this.setRegion(domEvent.target);
        break;

      case 'relevanceLanguage':
        this.setLanguage(domEvent.target);
        break;

      default:
        this.setCriteria(domEvent.target);
    }

    if(domEvent.target.name === 'search_group' && domEvent.target.value === +0) {
      this.setState( { criteria: {...this.state.criteria, channelsearch: []}} );
    }
  }

  onNext(resultPanelData) {
    // Copy the current result set to a new variable.
    const res = {...this.state.results};

    const fd = this.makeFormData();
    fd.set('pageToken', resultPanelData.next_page);

    if(resultPanelData.channel_ytid) {
      // Clear out the current results so that we can trigger the loader.
      res[resultPanelData.channel_ytid].results = null;

      fd.set('channelId', resultPanelData.channel_ytid);
      fd.set('maxResults', VIDEOS_PER_PAGE);

    } else {
      if(Object.hasOwn(res, 'All Channels') ) {
        res['All Channels'].results = null;
      }
      fd.set('maxResults', VIDEOS_PER_PAGE * 2);
    }

    this.setState((state) =>{ return { results: res } }, () => search({body: fd}, this.setResults));
  }

  onPrevious(resultPanelData) {
    const res = {...this.state.results};

    const fd = this.makeFormData();
    fd.set('pageToken', resultPanelData.prev_page);

    if(resultPanelData.channel_ytid) {
      res[resultPanelData.channel_ytid].results = null;

      fd.set('channelId', resultPanelData.channel_ytid);
      fd.set('maxResults', VIDEOS_PER_PAGE);
    } else {
      res['All Channels'].results = null;
      fd.set('maxResults', VIDEOS_PER_PAGE * 2);
    }

    this.setState({results: res});
    search({body: fd}, this.setResults);
  }

  onChannelListNav(domEvent) {
    domEvent.persist();

    this.setState((state) => {
      let curPage = state.channelslist.currentPage + (+domEvent.target.value);

      return {
        channelslist: Object.assign({}, this.state.channelslist, {currentPage: curPage})
      };
    }, this.getChannelBatch);
  }

  isSelectedChannel(channelId) {
    const callback = (channel) => {
      return channelId === channel.channel_youtubechannelid;
    };
    return this.state.criteria.channelsearch.findIndex(callback) > -1;
  }

  onChannelSelect(domEvent) {
    let searchwithin = [];
    let newresults = {...this.state.results};

    // Delete 'All Channels' object if it's in the list.
    delete newresults['All Channels'];

    if(domEvent.target.checked) {
      // If it isn't already in the list to search within, add it
      if(!this.isSelectedChannel(domEvent.target.value)) {
        const addThis = this.state.channelslist.channels.find((item) => item.channel_youtubechannelid === domEvent.target.value);
        searchwithin = [addThis, ...this.state.criteria.channelsearch];
      } else {
        searchwithin = [...this.state.criteria.channelsearch];
      }
    } else {
      // Filter this.state.searchInChannels to exclude the item.
      searchwithin = this.state.criteria.channelsearch.filter((item) => item.channel_youtubechannelid !== domEvent.target.value);
      delete newresults[domEvent.target.value];
    }
    this.setState({criteria: {...this.state.criteria, channelsearch: searchwithin}, results: newresults}, this.makeResults);
  }

  onChannelSearchSubmit(domEvent) {
    this.setState({
      channelslist: {...this.state.channelslist, open: false, filterKeyword: ''}
    }, this.preSearch);
  }

  onChannelsPerPageChange(domEvent) {
    this.setState({
      channelslist: Object.assign({}, this.state.channelslist, {perPage: +domEvent.target.value})
    }, this.getChannelBatch);
  }

  onChannelFilterChange(domEvent) {
    const newcl = {
      filterKeyword: domEvent.target.value.toString(),
      filterOn: 'channel_title'
    };

    this.setState({
      channelslist: Object.assign({}, this.state.channelslist, newcl)
    }, this.getChannelBatch);
  }

  onPanelToggle(channel = '') {
    const chn = {...this.state.results};
    chn[channel].open = !this.state.results[channel].open;
    this.setState({results: chn})
  }

  onShowChannels() {
    this.getChannelBatch();
  }

  onHideChannels(dialogRef) {
    const resetCl = {
      channels: [],
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      sortBy: 'channel_title',
      ascending: true,
      filterKeyword: '',
      filterOn: '',
      open: false,
      loading: false
    }

    this.setState({
      loading: false,
      channelslist: Object.assign({...this.state.channelslist}, resetCl)
    }, () => this.preSearch(dialogRef));
  }

  onSubmit(domEvent = null) {
    if(domEvent) {
      domEvent.preventDefault();
    }

    let newresults = {};
    this.setState({results: newresults}, this.preSearch);
  }

  onReset() {
    this.setState(setStateOnSearchReset);
  }

  setCriteria(domEventTarget, callback = null) {
    let fieldValue;
    let newValue = {};

    // If publication date range changed, and there's a value, set those fields,
    // and set the order field to date.
    switch(domEventTarget.name) {
      case 'publishedAfter':
        fieldValue = domEventTarget.value ? `${yyyymmdd(domEventTarget.value)}T00:00:00Z` : '';
        newValue['order'] = domEventTarget.value ? 'date' : 'viewCount';
        break;
      case 'publishedBefore':
        fieldValue = domEventTarget.value ? `${yyyymmdd(domEventTarget.value)}T23:59:59Z` : '';
        newValue['order'] = domEventTarget.value ? 'date' : 'viewCount';
        break;
      default:
        fieldValue = domEventTarget.value;
        newValue['order'] = 'viewCount';
    }

    newValue[domEventTarget.name] = fieldValue;

    this.setState({
      criteria: Object.assign({...this.state.criteria}, newValue)
    }, callback);
  }

  setNewRegion(regionCode) {
    if(!regionCode) return;
    this.setState((state) => setStateOnSetRegion(state, regionCode));
  }

  setNewLanguage(languageCode) {
    if(!languageCode) return;
    this.setState((state) => setStateOnSetLanguage(state, languageCode));
  }

  setRegion(domEventTarget = null) {
    if(!domEventTarget) return;
    this.setCriteria(domEventTarget, () => { this.setNewRegion(domEventTarget.value); })
  }

  setLanguage(domEventTarget = null) {
    if(!domEventTarget) return;
    this.setCriteria(domEventTarget, () => { this.setNewLanguage(domEventTarget.value); })
  }

  setResults(data) {
    this.setState((state) => setStateAfterResults(state, data));
  }

  setChannelGroup(groupid) {
    this.setState((state) => setStateChannelChoose(state, groupid));
  }

  setUserLang() {
    this.setState(setStateOnSetUserLang);
  }

  makeErrorPanel() {
    const youtubeApiMessage = { __html: this.state.results.error.message };

    return (
      <section>
        <header>
          <div className="search__results__title">
            <h2>YouTube API Error</h2>
          </div>
        </header>
        <p>{this.state.results.error.message}</p>
      </section>
    );
  }

  makeResultPanel(results = []) {
    return (
      <SearchResultsPanel
        singleChannel={true}
        onAddVideo={this.addToSystem}
        onPlayVideo={this.playVideo}
        title='All Channels'
        data={results}
        id='all_channels_results'
        next={this.onNext}
        open={results.open}
        previous={this.onPrevious}
        onToggle={this.onPanelToggle} />
    );
  }

  makeChannelResultPanel(channel, index) {
    const chytid = channel.channel_youtubechannelid || channel.youtubeid;

    const results = {...this.state.results};

    const information = Object.hasOwn(results, chytid) ? results[chytid] : [];
    information.loading = true;

    return (
      <SearchResultsPanel
        singleChannel={this.state.criteria.channelsearch.length <= 1}
        key={chytid}
        onAddVideo={this.addToSystem}
        onPlayVideo={this.playVideo}
        title={channel.channel_title}
        data={information}
        id={chytid}
        next={this.onNext}
        open={information.open}
        previous={this.onPrevious}
        onToggle={this.onPanelToggle} />
    );
  }

  makeResults() {
    let panels = null;

    const panelCount = Object.keys(this.state.results);

    if(panelCount.length > 1) {
      panels = this.state.criteria.channelsearch.map(this.makeChannelResultPanel);
    }

    if(panelCount.length == 1) {
      if(Object.hasOwn(this.state.results, 'error')) {
        panels = this.makeErrorPanel();
      }

      if(Object.hasOwn(this.state.results, 'All Channels')) {
        panels = this.makeResultPanel(this.state.results['All Channels']);
      }
    }

    return panels;
  }

  makeChannelList() {
    return (
      <ChannelsList
          {...this.state.channelslist}
          currentSet={this.state.criteria.channelsearch}
          closeHandler={this.onHideChannels}
          onClose={this.onHideChannels}
          onPaginationClick={this.onChannelListNav}
          onSelectChange={this.onChannelSelect}
          onSubmit={this.onChannelSearchSubmit}
          onPerPageChange={this.onChannelsPerPageChange}
          onChannelFilterChange={this.onChannelFilterChange}
          showPrevious={this.state.channelslist.currentPage > 1}
          showNext={this.state.channelslist.currentPage < this.state.channelslist.totalPages} />
    );
  }

  addToSystem(youtubeID) {
    addVideo(youtubeID, this.updateAfterAdding);
  }

  addVideoToSet(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();

    const fd = new FormData();
    fd.set('videoid', this.state.videoinfo.videoData.video_ava_id);
    fd.set('ytid', this.state.videoinfo.videoData.video_yt_id);
    fd.set('setid', this.state.videoinfo.selectedSet.videosetid);
    fd.set('blockid', this.state.videoinfo.selectedSet.videoblockid);
    fd.set('token', getAuthData('token'));

    const ac = new AbortController();
    const signal = ac.signal;
    this.fetchcancelqueue.set('addVideoToSet', ac);

    apirequest(API_VIDEO_ADDTOSET, { body: fd, signal }, (data) => {
      // TODO: Should probably handle errors too.
      window.alert('Video added to set.');
    });
  }

  updateVideo( results, key, obj ) {
    const idx = results.findIndex( (r) => r.video_yt_id === obj.youtubeid );

    if( idx > -1) {
      results[ idx ][ key ] = obj[ key ];
    }

    return results;
  }

  postCRTHandler(data) {
    if( data.error === true ) return;

    const results = { ...this.state.results };
    const result_groups = Object.entries( results );

    for( var r in results) {
      if( Object.hasOwn(results, r ) ) {
        results[ r ].results = this.updateVideo( results[ r ].results, 'crt', data );
      }
    }

    this.setState((state) => setStateAfterCRTChange(state, results, data));
  }

  postMeowHandler(data) {
    if( data.error === true ) return;

    const results = { ...this.state.results };
    const result_groups = Object.entries( results );

    for( var r in results) {
      if( Object.hasOwn(results, r ) ) {
        results[ r ].results = this.updateVideo( results[ r ].results, 'meow', data );
      }
    }

    this.setState((state) => setStateAfterMEOWChange(state, results, data));
  }

  postRatingHandler(data) {
    if( data.error === true ) return;

    const results = { ...this.state.results };
    const result_groups = Object.entries( results );

    for( var r in results) {
      if( results.hasOwnProperty( r ) ) {
        results[ r ].results = this.updateVideo( results[ r ].results, 'results', data );
      }
    }

    this.setState((state) => setStateAfterRatingsChange(state, results, data));
  }

  postTagsHandler(data) {
    let result;

    if( data.error ) {

      result = data.message;

    } else {

      this.setState((state) => {
        return {
          videoinfo: {
            ...state.videoinfo,
            videoData: {
              ...state.videoinfo.videoData,
              video_customtags: data.customTags
            }
          }
        }
      }, ( data ) => {
        result = `Updated tags for "${this.state.videoinfo.videoData.video_title}" (YouTube ID: ${ this.state.videoinfo.videoData.video_yt_id }).`;
      });
    }
    window.alert( result );
  }

  updateAfterAdding(data) {
    const allres = {...this.state.results};
    const channels = Object.keys(allres);

    if(data.result === 'success' && channels.length) {

      channels.forEach((chan) => {
        // Find the index of the video that needs to be updated.
        const idx = allres[chan].results.findIndex( item => item.video_yt_id === data.addedVideos[0]);
        /*
         If this item isn't in this channel, idx will be -1
         No need to do any more after that point. Just return.
        */
        if(idx < 0) return;

        // Otherwise, update the alreadyprocessed property of the video object.
        const update = [...allres[chan].results];
        update[idx].video_alreadyprocessed = true;

        // Then update state.
        allres[chan].results = update;

        this.setState({
          results: allres,
          videoinfo: {
            ...this.state.videoinfo,
            videoData: {
              ...this.state.videoinfo.videoData,
              video_alreadyprocessed: true,
            }
          }
        });
      });
    }
  }

  playVideo(videoObject) {
    this.setState((state) => setStateOnPlayVideo(state, videoObject));
  }

  closeVideo() {
    this.setState(setStateOnModalClose);
  }

  onAddVideoToSetSubmit(domEvent) {
    domEvent.preventDefault();
    const fd = new FormData();

    fd.set('ytid', this.state.videoinfo.videoData.id);
    fd.set('blockid', this.state.videoinfo.selectedSet.videoblockid);
    fd.set('token', getAuthData('token'));

    const ac = new AbortController();
    const signal = ac.signal;
    this.fetchcancelqueue.set('onAddSubmit', ac);

    apirequest(API_VIDEO_ADDTOSET, {body: fd, signal}, (data) => {
      // TODO: Should probably handle errors too.
      this.setState(( state ) => setStateAfterSubmittingVideoBlock( state, data ));
    });

  }

  render() {
    let body = <LoaderSimple open={true} />

    if( this.state.loading == false) {
      body = this.makeResults();
    }

    return (
      <div>
        <RegionLangDisplay {...this.state.international} onClick={this.onShowOptions} />
        <SearchForm
          action={API_SEARCH}
          onChange={this.onChange}
          onShowChannels={this.onShowChannels}
          onSubmit={this.onSubmit}
          onReset={this.onReset}
          onShowOptions={this.onToggleOptions}
          groups={this.state.groups}
          regions={this.state.regions}
          languages={this.state.languages}
          advancedOpen={this.state.advancedOpen}
          {...this.state.criteria} />

        { body }
        {this.makeChannelList()}

        <VideoModal
          { ...this.state.videoinfo }
          isAdmin={ this.isAdmin }
          onCloseHandler={ this.closeVideo }
          onAddVideoToDestinationSubmit={this.addVideoToSet}
          onAddVideoSubmit={ this.addToSystem }
          onVideoBlockSelect={(data) => {
            this.setState((state) => setStateOnVideoBlockSelect(state, data));
          }}
          onRatingSave={this.postRatingHandler}
          onCRTSave={this.postCRTHandler}
          onMeowSave={this.postMeowHandler}
          onTagsSave={this.postTagsHandler} />
      </div>
    );
  }
}
