/**
 * SelectMenuControlled.js
 * A no-framework dropdown menu. This sends the entire DOMEvent to the onChange
 * instead of just the value. It's also a CONTROLLED rather than an UNCONTROLLED
 * component.
 *
 * Second version is due to some legacy code. Easier to add this component than
 * to rewrite SelectMenu and everywhere it's used to make it controlled.
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';
import SelectOption from './SelectOption';
import classNames from 'classnames';

import '../../css/SelectMenu.css';

class SelectMenuControlled extends React.Component {
  constructor(props) {
    super(props);
    this.makeOptions = this.makeOptions.bind(this);
  }

  makeOptions(arrayOfObjects) {
    return arrayOfObjects.map((o, x) => {
      return (
        <SelectOption
          value={o.value}
          key={x.toString()}
          label={o.label} />
      );
    });
  }

  render() {
    const cssClasses = classNames('ava-input-select', this.props.cssClass);
    const id = this.props.id || this.props.name;

    const options = this.makeOptions(this.props.options);
    if(this.props.firstEmpty) {
      options.unshift(<option key="selectmenufirstempty"></option>);
    }

    return (
      <div className={cssClasses}>
        <label htmlFor={id}>{this.props.labelText}</label>
        <select
          disabled={this.props.disabled}
          hidden={this.props.hidden}
          required={this.props.isRequired}
          value={this.props.value}
          id={id}
          name={this.props.name}
          onChange={this.props.onChange}
          size={this.props.size}
          multiple={this.props.multiple}
          aria-labelledby={this.props.labelledby}
          aria-describedby={this.props.describedBy}>
          {options}
        </select>
      </div>
    );
  }
}

SelectMenuControlled.defaultProps = {
  cssClass: null,
  id: null,
  onChange: ()=>{},
  options: [{ label: 'Empty Menu', value: '' }],
  value: '',
  isRequired: false,
  firstEmpty: true,
  multiple: false
};

SelectMenuControlled.propTypes = {
  id: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })).isRequired,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  firstEmpty: PropTypes.bool.isRequired,
  size: PropTypes.string,
  multiple: PropTypes.bool
};

export default SelectMenuControlled;
