/**
 * TextInput.js
 * A no-framework text input 
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class TextInput extends React.Component {
  constructor( props ) {
    super( props );
    this.makeDataList = this.makeDataList.bind(this);
  }

  makeDataList(arrayOfOptions) {
    return arrayOfOptions.map( (item, index) => {
      return <option value={item.title} key={index.toString()} />
    });
  }
  
  render() {
    const cssClasses = classNames('input-text', this.props.cssClass);
    const id = this.props.id || this.props.name;

    let dataList, dataListAttr;

    if(this.props.datalist) {
      dataListAttr = `${id}_dl`;
      dataList = <datalist id={dataListAttr}>{this.makeDataList(this.props.datalist)}</datalist>;
    } else {
      dataListAttr = null;
      dataList = null;
    }

    return (
      <div className={cssClasses}>
        <label htmlFor={id}>{this.props.labelText}</label>
        <input type="text"
          name={this.props.name}
          id={id}
          list={dataListAttr}
          onChange={this.props.onInputHandler}
          value={this.props.value}
          required={this.props.isRequired}
          aria-describedby={this.props.ariaDescribedBy} />
        {dataList}
      </div>
    );
  }
}

TextInput.defaultProps = {
  cssClass: null,
  id: null,
  value: '',
  labelText: "Add label text",
  datalist: null,
  isRequired: false
};

TextInput.propTypes = {
  cssClass: PropTypes.string,
  datalist: PropTypes.array,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  onInputHandler: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default TextInput;
