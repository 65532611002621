/**
 * YTPlayIcon.js
 * YouTube's Play Icon in SVG for crispness.
 **/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class YTPlayIcon extends React.Component {
  render(){
    const iso = {
      isolation: 'isolate'
    };
    
    const cssClasses = classNames('youtube__play__icon', this.props.cssClass);

    const title = this.props.title ? <title>{this.props.title}</title> : null;
    return (
      <svg focusable="false" width={this.props.width} height={this.props.height} style={iso} viewBox="0 0 172.592 121.599" className={cssClasses}>
          {title}
          <defs>
              <clipPath id="_clipPath_rlc6PkcFvDQwJZ96IQjYNr05YeqrKMg5">
                  <rect width="172.592" height="121.599" />
              </clipPath>
          </defs>
          <g clipPath="url(#_clipPath_rlc6PkcFvDQwJZ96IQjYNr05YeqrKMg5)">
              <clipPath id="_clipPath_eEVTedFdv6HDi8Yy2TvjhUY1hq7FWVgj">
                  <path d=" M 0 0 L 0 121.599 L 172.592 121.599 L 172.592 0 L 0 0 Z " fill="rgb(255,255,255)" />
              </clipPath>
              <g clipPath="url(#_clipPath_eEVTedFdv6HDi8Yy2TvjhUY1hq7FWVgj)">
                  <path d=" M 168.985 18.988 C 167.001 11.514 161.153 5.628 153.727 3.63 C 140.267 0 86.296 0 86.296 0 C 86.296 0 32.325 0 18.865 3.63 C 11.44 5.628 5.591 11.514 3.607 18.988 C 0 32.535 0 60.8 0 60.8 C 0 60.8 0 89.064 3.607 102.612 C 5.591 110.085 11.44 115.971 18.865 117.97 C 32.325 121.599 86.296 121.599 86.296 121.599 C 86.296 121.599 140.267 121.599 153.727 117.97 C 161.153 115.971 167.001 110.085 168.985 102.612 C 172.592 89.064 172.592 60.8 172.592 60.8 C 172.592 60.8 172.592 32.535 168.985 18.988 Z " fill="rgb(236,54,56)" />
              </g>
              <clipPath id="_clipPath_FGVafdfWIM4x72ePH055Y1i4054mmNEi">
                  <path d=" M 0 0 L 0 121.599 L 172.592 121.599 L 172.592 0 L 0 0 Z " fill="rgb(255,255,255)" />
              </clipPath>
              <g clipPath="url(#_clipPath_FGVafdfWIM4x72ePH055Y1i4054mmNEi)">
                  <path d=" M 68.645 86.462 L 113.754 60.8 L 68.645 35.137 L 68.645 86.462 Z " fill="rgb(254,254,254)" />
              </g>
          </g>
      </svg>
    );
  }
}

YTPlayIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string
};


YTPlayIcon.defaultProps = {
  width: 172.592,
  height: 121.599
};
