/**
 * DownloadCSV.js
 */
import React from 'react';
import LoaderSimple from './LoaderSimple';

import './css/DownloadCSV.css';

export const DownloadCSV = (props) => {
  const countString = `${props.count} results`;  
  return (
    <form className="reports__result_count" onSubmit={props.onSubmit}>
      <span>{countString}</span>
      <button type="submit" className="download" disabled={props.disabled || props.working}>
        <b>Download CSV</b>
        <LoaderSimple open={props.working} cssClass="downloading__indicator" title="Downloading data" />
      </button>
    </form>
  )
}
