/**
 * SeriesView.js
 * /stations page-level component
 */
import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../subcomponents/Breadcrumb';
import EpisodesList from './EpisodesList';
import LoaderSimple from '../subcomponents/LoaderSimple';

import {
  API_SERIES,
  API_VIDEOSET_ASSIGNMENTS,
  API_VIDEOSET_DUPLICATE,
  NULL_SET_TITLE
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData
} from '../../js/Utilities';

class SeriesView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      breadcrumbs: [{
        path: '/stations',
        label: 'Stations'
      }],
      seriesmeta: {
        title: null,
        id: null
      },
      list: [],
      loading: true
    };

    this.onEpisodesClose = this.onEpisodesClose.bind(this);
    this.onBatchEdit = this.onBatchEdit.bind(this);
    this.onBatchSave = this.onBatchSave.bind(this);
    this.onEpisodeDuplicate = this.onEpisodeDuplicate.bind(this);

    this.getSeries = this.getSeries.bind(this);
    this.getSeriesMeta = this.getSeriesMeta.bind(this);
  }

  componentDidMount() {
    this.getSeriesMeta();
  }

  getSeries() {
    const fd = new FormData();

    fd.set('id', this.props.params.seriesID);
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSET_ASSIGNMENTS, {body: fd}, (data) => {
      this.setState({ list: data, loading: false });
    }, this.getSeriesMeta);
  }

  getSeriesMeta() {
    const fd = new FormData();
    fd.set('id', this.props.params.seriesID);
    fd.set('token', getAuthData('token'));

    apirequest(API_SERIES, {body: fd}, (data) => {
      const slice = [{
        path: `/stations/${data.station_id}`,
        label: data.Station
      },
      {
        path: `/stations/series/${this.props.params.seriesID}`,
        label: data.Video_Set || NULL_SET_TITLE
      }];

      this.setState({
        breadcrumbs: [...this.state.breadcrumbs].concat(slice),
        seriesmeta: {
          title: data.Video_Set || NULL_SET_TITLE,
          id: data.vs_id
        },
        loading: false
      })
    });
  }

  onEpisodesClose(domEvent) {
    const epState = {
      open: false,
      list: [],
      meta: {
        id: 0,
        title: null
      },
      mode: 'view'
    }
    this.setState({episodes: epState});
  }

  onBatchSave(){}
  onBatchEdit(){}

  onEpisodeDuplicate(domEvent) {
    domEvent.preventDefault();

    const fd = new FormData();
    fd.set('id', domEvent.target.elements.id.value)
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSET_DUPLICATE, {body: fd}, (data) => {
      if( Number.isNaN(+data) ) {
        throw new TypeError('Invalid response. Please notify an administrator.');
      } else {
         window.location = `/stations/episode/${data}`;
      }
    });
  }

  makeEpisodesList() {
    let body;

    if(this.state.loading) {
      body = <LoaderSimple open={true} />;
    } else {
      body = (
        <EpisodesList
          meta={{id: this.props.params.seriesID}}
          mode="page"
          {...this.state.list}
          onClose={this.onEpisodesClose}
          breadcrumbs={this.state.breadcrumbs.slice(0, 2)}
          onDuplicateEpisode={this.onEpisodeDuplicate}
          onBatchEdit={this.onBatchEdit}
          onBatchSave={this.onBatchSave} />
      );
    }
    return body;
  }

  render() {
    return (
      <div className="stations__view">
        <Breadcrumb items={this.state.breadcrumbs} />
        <h1>Episodes for <q>{this.state.seriesmeta.title}</q></h1>
        {this.makeEpisodesList()}
      </div>
    ); 
  }
}

export default (props) => (
    <SeriesView
        {...props}
        params={useParams()}
    />
);


