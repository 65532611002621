/*
StationsUtils.js

Functions that are shared between multiple views in stations
*/
import uniqueId from 'lodash.uniqueid';

import {
  apirequest, 
  getAuthData,
  totalRunTime
} from '../js/Utilities';

import {
  API_SAVE_SEASONEPS,
  API_VIDEO_ADDBATCH,
  NULL_SET_TITLE,
  NULL_EPISODE_TITLE
} from '../js/Configuration';

export const saveSeasonEp = (blockid, season, episode, callback = null) => {
  if(!season && !episode) return;

  const fd = new FormData();
  fd.set('blockid', blockid);
  fd.set('season', season);
  fd.set('episode', episode);
  fd.set('userid', getAuthData('uid'));
  fd.set('uuid', getAuthData('uuid'));
  fd.set('token', getAuthData('token'));

  apirequest(API_SAVE_SEASONEPS, {body: fd}, callback);
}

/*
Yeah, the parameters are weird and sorta inconsistent. Just roll with it.
Added prepend later, which is why it comes after callback
*/
export const saveURLsToBlock = (formObject, urls, callback, prepend = 0) => {
  let fd;

  if( Array.prototype.toString.call(formObject) === '[object HTMLFormElement]') {
    fd = new FormData(formObject);
  }
  
  if(Array.prototype.toString.call(formObject) === '[object FormData]') {
    fd = formObject;
  }
 
  fd.set('videoids', urls);
  fd.set('token', getAuthData('token'));
  fd.set('prepend', prepend);
  apirequest(API_VIDEO_ADDBATCH, {body: fd}, callback);
}

export const setStateGetVideosForBlock = (state, response) => {
  const crumb = [{
    path: `/stations/${response.station_id}`,
    label: response.Station
  },
  {
    path: `/stations/series/${response.vsb_setID}`,
    label: response.Video_Set || NULL_SET_TITLE
  },
  {
    path: '',
    label: response.title || NULL_EPISODE_TITLE
  }];

  return {
    series: response.vsb_setID,
    list: formatVideoList(response.videoList.returnData),
    meta: response,
    title: response.title,
    videostoadd: {...state.videostoadd, selected: response.videoList.returnData},
    runtime: totalRunTime(response.videoList.returnData),
    edittitle: {...state.edittitle, value: response.title},
    breadcrumbs: crumb
  }
}

export const formatVideoList = (videos) => {
  let list = videos.length ? videos : [];

  const items = list.map(( item, index ) => {
    return {
      name: item.Video_Name,
      ordinal: index,
      id: item.video_id,
      ytID: item.Video_ID,
      thumb: item.videoext_thumbnail,
      duration: item.Duration,
      is_interstitial: item.is_interstitial,
      crt: item.crt,
      meow: item.meow,
      position: +item.order
    };
  });
  return items;
}

export const setStateOnPerPage = (state, value) => {
  return {
    videostoadd: {
      ...state.videostoadd,
      perPage: +value
    }
  }
}

export const setStateOnCrtChange = (state, data) => {
  return {
    crtstatus: {
      ...state.crt,
      approved: data.approved,
      status: data.status,
      loading: false
    }
  }
}

export const setStateRating = (state, data) => {
  return {rating: data.rating}
}

export const setStateOnVideoSelected = (state, selected, add) => {

  return {
    videostoadd: {
      ...state.videostoadd,
      selected: selected
    },
    addByURL: {
      ...state.addByURL,
      value: add.join(',')
    }
  }
}

export const setStateOnPaginationClick = (prevState, value) => {
  let curPage = prevState.videostoadd.page + (+value);
  
  return {
    videostoadd: {
      ...prevState.videostoadd,
      page: curPage
    }
  };

}

export const setStateGetVidBatch = (state) => {
  return {
    videostoadd: {
      ...state.videostoadd,
      isloading: true
    }
  }
}

export const setStateAfterVidBatch = (state, response) => {
  return {
    videostoadd: {
      ...state.videostoadd,
      available: response.videos,
      page: response.current_page,
      pageCount: response.pages,
      results: response.num_results,
      isloading: false,
      open: true     
    }
  }
}

export const setStateOnFilterChange = (state, keyword, criterion) => {
  return {
    videostoadd: {
      ...state.videostoadd,
      filterKeyword: keyword,
      filterOn: criterion,
      page: 1,
      pageCount: 1
    }
  }
}

export const setStateOnSortChange = (state, criterion) => {
  return {
    videostoadd: {
      ...state.videostoadd,
      sortBy: criterion
    }
  }
}

export const setStateOnDirectionChange = (state) => {
  return {
    videostoadd: {
      ...state.videostoadd,
      ascending: !state.videostoadd.ascending,
    }
  }
}

export const setStateOnFilterClear = (state) => {
  return {
    videostoadd: {
      ...state.videostoadd,
      filterOn: '',
      filterKeyword: '',
      ascending: false
    }
  }
}

export const setStateOnInfoClose = (state) => {
  return {
    videopreview: {
      ...state.videopreview,
      open: false
    }
  }
}

export const setStateOnDeleteFromSystem = (state, response) => {
  const vlist = [...state.list];

  // Removes deleted videos from the list.
  const removevid = (id) => {
    const remove = vlist.findIndex((item) => { return +id === +item.id; });
    vlist.splice(remove, 1);
  }
  response.videosDeleted.forEach(removevid);

  return {
    list: vlist,
    videopreview: {
      ...state.videopreview,
      open:false
    }
  };
}

export const setStateOnSeasonEpChange = (state, property, value) => {
  return {
    meta: {
      ...state.meta,
      se: {
        ...state.meta.se,
        [property]: value
      }
    }
  }
}

export const setStateAddByURLWorking = (state) => {
  return {
    addByURL: {
      ...state.addByURL,
      working: true
    }
  };
}

export const setStateOnRatingChange = (state, value) => {
  return {
    videopreview: {
      ...state.videopreview,
      currentrating: value
    }
  }
}

export const setStateAfterRating = (state, value) => {
  return {
    videopreview: {
      ...state.videopreview,
      open: false,
      currentrating: null,
      current: {} 
    }
  }
}

export const setStateHideSaved = (state) => {
  return {
    videostoadd: {
      ...state.videostoadd,
      savestatus: {
        hidden: true
      }
    }
  };
}

export const setStateGridToggle = (state, value) => {
  return {useGrid: value}
}

export const setStateOnVideoBlockSelect = (state, data) => {
  return {
    videopreview: {
      ...state.videopreview,
      selectedSet: {
        videosetid: data.videosets.value,
        videoblockid: data.videosetblocks.value
      }
    }
  };
}

export const setStateCloseVideo = (state) => {
  return {
    videopreview: {
      ...state.videopreview,
      open: false,
      currentrating: null,
      selectedSet: {}
    }
  }
}

export const setStateAddByURL = (state, value) => {
  return {
    addByURL: {
      ...state.addByURL,
      open: true
    },
    videostoadd: {
      ...state.videostoadd,
      open: false
    }
  }
}

export const setStateOnCloseCheckForDupes = (state) => {
  return {
    checkForDupes: {
      ...state.checkForDupes,
      open: false,
      loading: true,
      dupes: [],
      mode: 'adding', // Can be 'adding' or 'checking'
      working: false,
      message: ''
    },
    videostoadd: {
      ...state.videostoadd,
      open: false,
      selected: []
    },
    addByURL: {
      ...state.addByURL,
      open: false,
      value: '',
      maxIds: false,
      working: false
    }
  };
}

export const setStateAfterRemoveInstance = (state, data) => {
  return {
    checkForDupes: {
      ...state.checkForDupes,
      working: false,
      loading: false,
      mode: 'checked',
      response: data
    }
  }
}

export const setStateSavePostDupeCheck = (state) => {
  return {
    checkForDupes: {
      ...state.checkForDupes,
      working: true
    }
  }
}

export const setStateOnReOrder = (state, data) => {
  if(!data.returnData.length) return state;

  return {
    list: formatVideoList(data.returnData),
    runtime: totalRunTime(data.returnData),
  }
}

export const setStateOnSESave = (state, data) => {
  const changes = {
    meta: {
      ...state.meta,
      se: data
    },
  };

  return Object.assign(changes, setStateIndicateSaved(state));
}

export const setStateOnRunDateChange = (state, newdate) => {
  return {
    meta: {
      ...state.meta,
      vsb_datetimetorun: newdate
    }
  }
}

export const setStateIndicateSaved = (state) => {
  return {
    videostoadd: {
      ...state.videostoadd,
      savestatus: {
        hidden: false
      }
    }
  }
}
