/**
 * ReportsChannels
 * Component display for reports/display/channels
 * Last modified: 18 Oct 2023
 */

import React from 'react';
import {
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import Results from './ReportsResults';
import LoaderSimple from '../subcomponents/LoaderSimple';

import {
  apirequest,
  getAuthData
} from '../../js/Utilities';

import {
  API_REPORTS_CHANNELS
} from '../../js/Configuration';


const ReportsChannels = ({params = null}) => {
  const [ channels, setChannels ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ results, setResults ] = useState(null);

  const massageResults = (results) => {
    return results.map( (result) => {
      return {
        title: result['channel_title'],
        url: result['youtubeID'],
        id: result['video_channelID'],
        frequency: result['frequency']
      }
    });
  }

  useEffect(() => {
    if( channels.length ) return;
    
    const fetchCancel = new AbortController();
    const fd = new FormData();
    fd.set('limit', 100);
    fd.set('token', getAuthData('token'));
    const signal = fetchCancel.signal;

    fd.set('token', getAuthData('token'));
    apirequest(API_REPORTS_CHANNELS, {body: fd, signal}, (data) => {
      setChannels( data.data );
      setResults(data.data.length),
      setLoading(false)
    });

    return () => {
      if( channels.length ) {
        fetchCancel.abort();
      }
    }
  });

  if(loading) {
    display = <LoaderSimple open={loading} />;
  } else {
   display = (
     <Results
        columnHeadings={['Channel Title', 'Frequency']}
        results={massageResults(channels)}
        count={results}
        downloadURL={`${API_REPORTS_CHANNELS}/csv`} />
    );
  }

  return (
    <div className="reports__page">
      <h1>{params.state.title}</h1>
      {display}
    </div>
  );
}

export default () => <ReportsChannels params={useLocation()} />
