/**
 * Channel.js
 * /channel/:channelID single channel, page-level component
 */
import React from 'react';
import { format } from 'date-fns';

import { useParams } from 'react-router-dom';

import Breadcrumb from '../subcomponents/Breadcrumb';
import LoaderSimple from '../subcomponents/LoaderSimple';
import ChannelsManageGroups from './ChannelsManageGroups';
import { ChannelVideos } from './ChannelVideos';
import { ChannelEditTags } from './ChannelEditTags';

import {
  API_CHANNEL_INFO,
  API_CHANNEL_REMOVE,
  API_GROUPS_ALLGROUPS,
  API_GROUPS_ASSIGN_CHANNEL_TO_GROUP,
  API_CHANNEL_GET_GROUPS,
  API_CHANNEL_GET_VIDEOS,
  API_CHANNEL_SETTAGS,
  API_REMOVE_CHANNEL_FROM_GROUP,
  MESSAGE_NO_TAGS
} from '../../js/Configuration';

import './css/Channel.css';

import {
  apirequest,
  debounce,
  getAuthData,
  numberFormat
} from '../../js/Utilities';

import {
  stateOnPerPageSelect,
  stateSetVideos,
  stateSetVideosPage,
  setStateOnGroupListUpdate
} from '../../actions/ChannelsUtils';

class Channel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      videos: {
        list: [],
        page: 1,
        perPage: 25,
        total: 1,
        sort: 0,
        ascending: 1,
        loading: true,
        rangelimit: 10, // End of the pagination display.
        start: 1        // Start of the pagination display.
      },
      groups: {
        options: [],
        selected: ''
      },
      loading: true,
      crumbs: [{
        path: `/channels/`,
        label: 'Channels',
        state: {}
      }],
      _delete: {
        success: false
      },
      edit_tags: {
        tags: '',
        success: false
      }
    };

    this.DATE_FORMAT_TEXT = 'PP';
    this.makeBreadcrumbs = this.makeBreadcrumbs.bind(this);
    this.makeCategories =  this.makeCategories.bind(this);
    this.makeGroupTags =  this.makeGroupTags.bind(this);

    this.getChannel = this.getChannel.bind(this);
    this.getGroups = this.getGroups.bind(this);
    this.getGroupsForChannel = this.getGroupsForChannel.bind(this);
    this.getVideos = this.getVideos.bind(this);

    this.onGroupSelect = this.onGroupSelect.bind(this);
    this.onGroupSubmit = this.onGroupSubmit.bind(this);

    this.onGroupDelete = this.onGroupDelete.bind(this);
    this.onChannelDelete = this.onChannelDelete.bind(this);
    this.onChannelDeleteRequest = this.onChannelDeleteRequest.bind(this);
    this.onCustomTagsSubmit = this.onCustomTagsSubmit.bind(this);
    this.onCustomTagsChange = this.onCustomTagsChange.bind(this);
    this.onPerPageSelect = this.onPerPageSelect.bind(this);
    this.onPaginationClick = this.onPaginationClick.bind(this);
  }

  componentDidMount() {
    // get the channel's data
    this.getChannel(this.props.params.channelID);
  }

  getChannel(id = null) {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', id);

    apirequest(API_CHANNEL_INFO, {body: fd}, (data) => {
      this.setState({data: data, edit_tags: {...this.state.edit_tags, tags: data.channel_customtags}}, this.getGroupsForChannel);
    });
  }

  getGroups() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_GROUPS_ALLGROUPS, {body: fd}, (data) => {
      this.setState({groups: {...this.state.groups, options: data}, loading: false});
    });
  }

  getGroupsForChannel() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', this.state.data.channel_youtubechannelid);

    apirequest(API_CHANNEL_GET_GROUPS, {body: fd}, (response = {} ) => {
      // response comes back with the channel ID as a key.
      const channelID = Object.keys(response)[0];
      this.setState((state) => {
        return { data: {...state.data, groupList: response[channelID]}}
      }, this.getVideos);
    });
  }

  getVideos() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', this.state.data.channel_youtubechannelid);
    fd.set('page', this.state.videos.page);
    fd.set('perPage', this.state.videos.perPage);

    apirequest(API_CHANNEL_GET_VIDEOS, {body: fd}, (response) => {
      this.setState((state) => { return stateSetVideos(state, response); }, this.getGroups);
    });
  }

  makeBreadcrumbs() {
    let slice = [...this.state.crumbs];
    let lastCrumb = [];

    if(this.state.data.hasOwnProperty('channel_title')) {
      lastCrumb = [{
        label: this.state.data.channel_title,
        state: {}
      }];
    };

    return <Breadcrumb items={slice.concat(lastCrumb)} />;
  }

  makeCategories() {
    if(!this.state.data.channel_category_names) return;
    return this.state.data.channel_category_names.join(', ');
  }

  makeGroupTags(){
    console.log(this.state.data.groupList);
  }

  onGroupSelect(value) {
    this.setState((state) => {
      return state.groups.selected = value;
    })
  }

  onGroupSubmit(domEvent) {
    domEvent.preventDefault();
    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));

    apirequest(API_GROUPS_ASSIGN_CHANNEL_TO_GROUP, {body: fd}, (response) => {
      this.setState((state) => setStateOnGroupListUpdate(state, response));
    });
  }

  onGroupDelete(domEvent) {
    domEvent.preventDefault();

    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));
    apirequest(API_REMOVE_CHANNEL_FROM_GROUP, {body: fd}, (response) => {
      const channelID = Object.keys(response)[0];
      this.setState((state) => {
        return { data: {...state.data, groupList: response[channelID]}}
      });

    });
  }

  onChannelDeleteRequest(id = null) {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', id);

    apirequest(API_CHANNEL_REMOVE, {body: fd}, (data) => {
      if(data.status === 'success') {
        window.location.href = '/channels';
      }
    });
  }

  onChannelDelete(domEvent) {
    const confirmed = window.confirm(`Are you sure that you want to delete ${this.state.data.channel_title}`);
    if(confirmed) {
      this.onChannelDeleteRequest(this.state.data.channel_youtubechannelid);
    }
  }

  onCustomTagsChange(domEvent){
    const value = domEvent.target.value;
    this.setState((state) => {
      return { edit_tags: {...state.edit_tags, tags: value}}
    });
  }

  onCustomTagsSubmit(domEvent) {
    domEvent.preventDefault();
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('id', this.state.data.channel_id); // using a numeric ID for now.
    fd.set('tags', this.state.edit_tags.tags);

    apirequest(API_CHANNEL_SETTAGS, {body: fd}, (response) => {

      this.setState((state) => {
        return {
          data: {
            ...state.data,
            channel_customtags: response.channelTags
          },
          edit_tags: {
            ...state.edit_tags,
            success: true
          }
        };
      });
    });
  }

  onPerPageSelect(domEvent) {
    const value = domEvent.target.value;
    this.setState((state) => stateOnPerPageSelect(state, value), this.getVideos);
  }

  onPaginationClick(domEvent){
    const value = domEvent.target.value;
    this.setState((state) => stateSetVideosPage(state, value), this.getVideos);
  }

  render() {
    if(this.state.loading) {
      return <LoaderSimple open={this.state.loading} />
    }
    return (
      <div className="ava__view__wrap">
        {this.makeBreadcrumbs()}
        <div id="channel_single_page_header">
          <h1>{this.state.data.channel_title} <a className="channel__external" href={`https://youtube.com/channel/${this.state.data.channel_youtubechannelid}`} target="_blank">View channel on YouTube</a></h1>
          <img className="channel__single__thumb" src={this.state.data.channel_thumbnail} />
          <div className="channel__single__description">
            {this.state.data.channel_description}
          </div>

          <h2>Channel Stats:</h2>
          <ul className="channel__single__meta">
            <li><span className="listText">Added to System:</span><span className="itemsBadge">{ format(new Date(this.state.data.channel_dateaddedtosystem), this.DATE_FORMAT_TEXT ) }</span></li>
            <li><span className="listText">Date Updated in System:</span><span className="itemsBadge">{ format(new Date(this.state.data.channel_datelastupdated),  this.DATE_FORMAT_TEXT ) }</span></li>
            <li><span className="listText">Publication Date:</span><span className="itemsBadge">{ format(new Date(this.state.data.channel_dateadded), this.DATE_FORMAT_TEXT ) }</span></li>
            <li><span className="listText">Videos In AVA:</span><span className="itemsBadge">{numberFormat( this.state.videos.total )}</span></li>
            <li><span className="listText">Subscriber Count:</span><span className="itemsBadge">{numberFormat( this.state.data.channel_subscribercount )}</span></li>
            <li><span className="listText">Videos in Channel:</span><span className="itemsBadge">{numberFormat( this.state.data.channel_videocount )}</span></li>
            <li><span className="listText">Categories / Topics:</span><output className="itemsBadge textNormal listItemScrollingTextBox resizable--vert">{this.makeCategories()}</output></li>
            <li><span className="listText">Keywords:</span><span className="itemsBadge textNormal listItemScrollingTextBox resizable--vert">{ this.state.data.channel_keywords }</span></li>
            <li><span className="listText">Tags:</span><span className="itemsBadge textNormal listItemScrollingTextBox resizable--vert">{ this.state.data.channel_customtags || MESSAGE_NO_TAGS}</span></li>
          </ul>
        </div>

        <ChannelsManageGroups
          channel={this.state.data.channel_youtubechannelid}
          groups={this.state.groups.options}
          inGroups={this.state.data.groupList}
          onChange={this.onGroupSelect}
          onSubmit={this.onGroupSubmit}
          onChannelDelete={this.onChannelDelete}
          onGroupDelete={this.onGroupDelete} />

        <ChannelEditTags
          channel={this.state.data.channel_youtubechannelid}
          {...this.state.edit_tags}
          onSubmit={this.onCustomTagsSubmit}
          onChange={this.onCustomTagsChange}
          onMessageClose={() =>  this.setState((state) => {return {edit_tags: {...state.edit_tags, success: false}}}) }/>

        <ChannelVideos {...this.state.videos} onPerPageSelect={this.onPerPageSelect} onPaginationClick={this.onPaginationClick} />

      </div>
    )
  }
}

export default (props) => (
    <Channel
        {...props}
        params={useParams()}
    />
);