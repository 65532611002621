/**
 * Pagination.js
 * A no-framework pagination component
 * See PropTypes definitions at the end of this file for parameters
 */
import React from 'react';
import PropTypes from 'prop-types';

import '../../css/pagination.css';

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.getPreviousPage = this.getPreviousPage.bind(this);
    this.getNextPage = this.getNextPage.bind(this);
  }

  getPreviousPage(domEvent){
    this.props.onClickHandler(domEvent);
  }

  getNextPage(domEvent){
    this.props.onClickHandler(domEvent);
  }

  render() {
    return (
      <div className="pagination__component">
        <button
          className="btn btn-light pagination__previous"
          type="button"
          onClick={this.getPreviousPage}
          value="-1"
          disabled={this.props.disablePrevious}>Previous</button>
      
        <button
          className="btn btn-light pagination__next"
          type="button"
          onClick={this.getNextPage}
          value="+1"
          disabled={this.props.disableNext}>Next</button>
      </div>
    )
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onClickHandler: PropTypes.func.isRequired
};

export default Pagination;
