import React from 'react'

export const NoSuchReport = () => {
  return (
    <div id="NoSuchReport">
      <h1>That report does not exist.</h1>
      <p>Please double-check the URL.</p>
    </div>
  )
}
