/**
 * DestinationVideoSets.js
 * View of videosets for an individual destination
 */
import React from 'react';
import { format } from 'date-fns';
import { Link, useParams } from 'react-router-dom';

import { ActionMessageDisplay } from '../subcomponents/ActionMessageDisplay';
import Breadcrumb from '../subcomponents/Breadcrumb';
import LoaderSimple from '../subcomponents/LoaderSimple';
import { CreateVideoSet } from './CreateVideoSet';
import { EditVideoSet } from './EditVideoSet';
import PaginationBar from '../subcomponents/PaginationBar';
import SelectPerPage from '../subcomponents/SelectPerPage';
import { DestinationsVideoSetsToggleSort } from './DestinationsVideoSetsToggleSort';
import { DestinationsIncludeInactive } from './DestinationsIncludeInactive';
import { DestinationsRestoreSet } from './DestinationsRestoreSet';

import {
  INTERSTITIAL_NOTE,
  API_VIDEOSET_ADDNEW,
  API_VIDEOSET_ASSIGNMENTS,
  API_VIDEOSET_RENAME,
  API_DESTINATIONS_INFO,
  MESSAGE_DESTINATION_NOVIDEOSETS,
  MESSAGE_DESTINATIONS_ADDVIDEOSET,
  NULL_DATE_TEXT_STATIONS
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData,
  formatString,
  getDuration
} from '../../js/Utilities';

import {
  duplicatePlaylist,
  setStateOnTogglePanel,
  setStatePostTogglePanel,
  setStateGetDestInfo,
  setStateOnPerPage,
  setLoading,
  setStateOnCreateVidsetClose,
  setStateOnCreateVidsetFailure,
  setStateOnCreateVidsetOpen,
  setStateDVSOnCreateChange,
  setStateOnEditVidsetOpen,
  setStateOnEditVidsetClose,
  setStateOnSortChange,
  mergeExistingSetList,
  setStateGetInteractives,
  restoreVideoSet,
  deleteVideoSet
} from '../../actions/DestinationsUtils';

import {
  setStateOnCreateVidsetResponse,
  setStateOnEditVidsetResponse
} from '../../actions/DestinationVideoSetsUtils';

import {
  setPageState
} from '../../actions/PaginationBarUtils';

import './css/DestinationVideoSets.css';

class DestinationVideoSets extends React.Component {
  constructor( _props ) {
    super( _props );

    this.state = {
      pagination: {
        perPage: 15,
        page: 1,
        total: 1,
        sort: 0,
        ascending: 1
      },
      breadcrumbs: [],
      loading: true,
      create: {
        open: false,
        value: '',
        destination: {
          dName: '',
          dID: ''
        }
      },
      postCreate: {
        hidden: true,
        message: '',
        mode: 'failed'
      },
      destinations: {
        setList: [],
        withinactives: false
      },
      edit: {
        open: false,
        item: '',
        value: ''
      }
    };

    /*
    Allows us to cancel outstanding fetch requests 
    See the getRunDateForSet method.
    */
    this.fetchcancelqueue = [];

    this.getDestinationInfo = this.getDestinationInfo.bind(this);
    this.duplicate = this.duplicate.bind(this);

    this.makeSets = this.makeSets.bind(this);
    this.makeASet = this.makeASet.bind(this);
    this.makeASetDates = this.makeASetDates.bind(this);
    this.makeBreadcrumbs = this.makeBreadcrumbs.bind(this);
    this.makePagination = this.makePagination.bind(this);

    this.getPage = this.getPage.bind(this);
    this.getPageCallback = this.getPageCallback.bind(this);

    this.getRunDateForSet = this.getRunDateForSet.bind(this);
    this._findCurrent = this._findCurrent.bind(this);

    this.onCreateVidsetClose = this.onCreateVidsetClose.bind(this);
    this.onCreateVidsetOpen = this.onCreateVidsetOpen.bind(this);
    this.onCreateChange = this.onCreateChange.bind(this);
    this.onCreateSave = this.onCreateSave.bind(this);
    this.onCreateVidsetResponse = this.onCreateVidsetResponse.bind(this);

    this.onEditVidsetOpen = this.onEditVidsetOpen.bind(this);
    this.onEditVidsetClose = this.onEditVidsetClose.bind(this);
    this.onEditVidsetSave = this.onEditVidsetSave.bind(this);
    this.onEditVidsetResponse = this.onEditVidsetResponse.bind(this);

    this._updateTitle = this._updateTitle.bind(this);

    this.onTogglePanel = this.onTogglePanel.bind(this);
    this.onTogglePanelCallback = this.onTogglePanelCallback.bind(this);
    this.onPerPageSelect = this.onPerPageSelect.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onInactivesChange = this.onInactivesChange.bind(this);
    this.onRestore = this.onRestore.bind(this);
    this.onDeleteSet = this.onDeleteSet.bind(this);

    this._getSortValue = this._getSortValue.bind(this);
  }

  componentWillMount() {
    window.scrollTo(0,0);
  }

  componentDidMount() {
    this.getDestinationInfo();
  }

  componentWillUnmount() {
    // Cancels all outstanding network requests. See getRunDateForSet
    this.fetchcancelqueue.forEach((ac) => {
      ac.abort();
    })
  }

  _findCurrent(id) {
    return this.state.destinations.setList.findIndex((item) => item.setID == id);
  }

  getDestinationInfo() {
    const fd = new FormData();
    fd.set('id', this.props.params.id);
    fd.set('token', getAuthData('token'));

    if(this.state.pagination.perPage) {
      fd.set('perPage', this.state.pagination.perPage);
    }

    if(this.state.pagination.page) {
      fd.set('page', this.state.pagination.page);
    }

    fd.set('inactives', +this.state.destinations.withinactives);
    fd.set('sort', this._getSortValue(+this.state.pagination.sort));
    fd.set('ascending', this.state.pagination.ascending);

    apirequest(API_DESTINATIONS_INFO, {body: fd}, (response) => {
      this.setState((state) => setStateGetDestInfo(state, response), this.makeSets);
    });
  }

  getRunDateForSet(videosetID, callback = null) {
    if(!videosetID) {
      throw new Error('No video set ID was passed.');
    }

    // Check if we've already fetched a list of blocks. If so, just show what we have.
    const videoSet = {...this.state.destinations}.setList.find((vs) => vs.setID == videosetID);
    if(videoSet.hasOwnProperty('blockList')) {
      callback([...videoSet.blockList]);
      return;
    }

    /*
     Setting state in this method can cause a no-op error if the user clicks
     away from the page while the network calls are resolving. To prevent this
     no-op and related memory links, we're using AbortController to create a
     cancelable promise instead of using isMounted() to check whether the
     component is still mounted before setting state.

     https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
     https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
    */
    this.fetchcancelqueue[videosetID] = new AbortController();
    const signal = this.fetchcancelqueue[videosetID].signal;

    const fd = new FormData();
    fd.set('id', videosetID);
    fd.set('token', getAuthData('token'));
    apirequest(API_VIDEOSET_ASSIGNMENTS, {body: fd, signal}, callback);
  }

  onTogglePanel(domEvent) {
    const id = domEvent.currentTarget.dataset.id;

    if(!domEvent.currentTarget.open) {
      const which = this._findCurrent(id);
      this.setState((state) => setStateOnTogglePanel(state, which), () => {
        const cb = (response) => this.onTogglePanelCallback(response, id);
        this.getRunDateForSet(id, cb);
      });
    }
  }

  onTogglePanelCallback(response, setID) {
    const which = this._findCurrent(setID);
    this.setState((state) => setStatePostTogglePanel(state, which, response));
  }

  onInactivesChange(domEvent) {
    const value = domEvent.target.checked;
    this.setState((state) => setStateGetInteractives(state, value), this.getDestinationInfo);
  }

  makeASet(videoset) {
    let plural = videoset.count > 1 ? 's' : '';
    let body;
    let action;

    if(videoset.hasOwnProperty('blockList') && !videoset.loading) {
      const dates = videoset.blockList.map(this.makeASetDates);
      body = <ul className="videosets__list">{dates}</ul>
    } else {
      body = <LoaderSimple open={true} className="videoset__rundates__loader" />
    }

    let vs;
    // videoset.activecomes in as a string. Use + to convert it to an integer.
    if(videoset.hasOwnProperty('active') && +videoset.active) {
      vs = (
        <details key={`vs_${videoset.setID}`} className="videoset__rundates" onClick={this.onTogglePanel} data-id={videoset.setID}>
          <summary>
            <h4 className="videoset__rundates__title">
              <b>{videoset.setName} <span className="videoset__rundates__count">({videoset.count} playlist{plural})</span></b>
              <button type="button" className="videoset__edittitle trigger__fauxlink" data-videoset={videoset.setID} onClick={this.onEditVidsetOpen}>Edit title</button>
              <button type="button" className="btn btn-sm btn--destructive" data-videoset={videoset.setID} onClick={this.onDeleteSet}>Delete video set</button>
            </h4>
          </summary>
          {body}
        </details>
      );
    } else {
      vs = (
        <div key={`vs_${videoset.setID}`} className="videoset__rundates">
          <div>
            <b className="videoset__rundates__title">{videoset.setName}</b>
            <DestinationsRestoreSet id={videoset.setID} onSubmit={this.onRestore} />
          </div>
        </div>
      );
    }

    return vs;
  }

  makeASetDates(item) {
    let datetxt = (!item.vsb_datetimetorun || item.vsb_datetimetorun === '0000-00-00') ? NULL_DATE_TEXT_STATIONS : format(new Date(`${item.vsb_datetimetorun}T00:00:00`), 'PP' );
    return <li key={item.vsb_id}><Link to={`/destinations/playlist/${item.vsb_id}`}>{datetxt}</Link> {getDuration(item.duration)}</li>;
  }

  makeSets() {
    const vsets = Object.values(this.state.destinations.setList);
    let rundates;

    if(this.state.loading === false && !vsets.length) {
      rundates = <h3>{MESSAGE_DESTINATION_NOVIDEOSETS}</h3>;
    } else {
      rundates = vsets.map(this.makeASet)
    }
    return rundates;
  }

  makeBreadcrumbs() {
    let crumbs = [{
      path: '/destinations',
      label: 'Destinations',
      state: {}
    }];

    let lastCrumb =  [{label: ''}];

    if(this.state.destinations.hasOwnProperty('dName')) {
      lastCrumb = [{
        label: this.state.destinations.dName,
        state: {}
      }];
    };

    return <Breadcrumb items={crumbs.concat(lastCrumb)} />;
  }

  makePagination() {
    return (
      <div className="videosets__pagination">
        <SelectPerPage id="VideoSetsPerPage" value={this.state.pagination.perPage} onChangeHandler={this.onPerPageSelect} options={['View All|-1', 15, 30, 50, 75, 100]} replace={true} cssClass="VideoSetsPerPage__select" />
        <PaginationBar {...this.state.pagination} onClickHandler={this.getPage} />
      </div>
    );
  }

  onPerPageSelect(domEvent) {
    const value = +domEvent.target.value;
    this.setState((state) => setStateOnPerPage(state, value), this.getDestinationInfo);
  }

  getPage(domEvent=null) {
    const value = domEvent ? domEvent.target.value : null;
    this.setState(setLoading, () => this.getPageCallback(value));
  }

  getPageCallback(value) {
    this.setState((state) => setPageState(state, value), this.getDestinationInfo);
  }

  onCreateVidsetClose(dialogRef) {
    this.setState(setStateOnCreateVidsetClose, () =>  dialogRef.current.close());
  }

  onCreateVidsetResponse(response, dialogRef=null) {

    if(response.result === 'fail') {
      this.setState((state) => setStateOnCreateVidsetFailure(state, response));
      return;
    }

    const makeMsg = (resp) => {
      const msg = formatString(MESSAGE_DESTINATIONS_ADDVIDEOSET, [response.setName, response.destination]);
      return (
        <span>
          <b>{msg}</b>
          <Link to={`/destinations/rundates/${response.setID}`} state={{destination: response.setName}}>View it now</Link>
        </span>
      );
    }
    const message = makeMsg();
    this.setState(
      (state) => setStateOnCreateVidsetResponse(state, response, message),
      () => {
        if(dialogRef) {
          dialogRef.current.close();
        }
        this.getDestinationInfo();
      }
    );
  }

  onCreateVidsetOpen() {
    this.setState((state) => setStateOnCreateVidsetOpen(state, this.props.params.id));
  }

  onCreateChange(domEvent) {
    const targetID = domEvent.target.id
    const val = domEvent.target.value;
    this.setState((state) => setStateDVSOnCreateChange(state, targetID, val));
  }

  onCreateSave(domEvent, dialogRef=null) {
    domEvent.preventDefault();
    const fd = new FormData(domEvent.target);
    apirequest(
      API_VIDEOSET_ADDNEW,
      {body: fd},
      (response) => { this.onCreateVidsetResponse(response, dialogRef); }
    );
  }

  onEditVidsetOpen(domEvent) {
    const which = this.state.destinations.setList.find((vs) => domEvent.target.dataset.videoset == vs.setID);
    const vsId = domEvent.target.dataset.videoset;
    this.setState((state) => setStateOnEditVidsetOpen(state, vsId,  which.setName));
  }

  onEditVidsetClose(dialogRef=null) {
    this.setState(
      setStateOnEditVidsetClose, 
      () => { if(dialogRef) dialogRef.current.close();
    });
  }

  onEditVidsetSave(domEvent, dialogRef=null) {
    const fd = new FormData(domEvent.target);
    fd.set('title', this.state.edit.value);
    apirequest(API_VIDEOSET_RENAME, {body: fd}, (response) => this.onEditVidsetResponse(response, dialogRef));
  }

  _updateTitle(id) {
    const changed = this._findCurrent(id);
    const setlist = [...this.state.destinations.setList];
    setlist[changed].setName = this.state.edit.value;
    return setlist;
  }

  onEditVidsetResponse(response, dialogRef) {
    this.setState(
      (state) => setStateOnEditVidsetResponse(state, response),
      () => {
        if(dialogRef) dialogRef.current.close();
        this.getDestinationInfo();        
      }
    );
  }

  duplicate(id) {
    duplicatePlaylist(id, (data) => {
      this.props.history.push(`/destinations/playlist/${data}`, {is_dupe: true});
    });
  }

  onSortChange(domEvent) {
    const value = +domEvent.target.value
    this.setState((state) => setStateOnSortChange(state, value), this.getDestinationInfo);
  }

  _getSortValue(index) {
    const options = ['alpha', 'alpha', 'newest'];
    return options[index];
  }

  onDeleteSet(domEvent) {
    domEvent.preventDefault();
    const setId = domEvent.target.dataset.videoset;
    const title = this.state.destinations.setList[this._findCurrent(setId)].setName;
    const confirm = window.confirm(`Are you sure that you want to delete “${title}”?`);

    if(confirm) {
      deleteVideoSet(setId, this.getDestinationInfo);
    }
  }

  onRestore(domEvent) {
    domEvent.preventDefault();
    const setId = domEvent.target.elements.id.value;
    restoreVideoSet(setId, this.getDestinationInfo);
  }

  render() {
    let body;

    if(this.state.loading) {
      body = <LoaderSimple open={this.state.loading} />
    }  else {
      body = (
        <article className="stations__view">
          {this.makeBreadcrumbs()}
          <header id="destination__header">
            <h1>Video Sets for <q>{this.state.destinations.dName}</q></h1>
            <button type="button" className="btn btn--action" onClick={this.onCreateVidsetOpen}>Create New Video Set</button>
          </header>
          <p className="destinations__component__note">{INTERSTITIAL_NOTE}</p>
          <ActionMessageDisplay {...this.state.postCreate} onClose={this.onCloseMessage} className="destinations__videosets__msg" />
          <div className='l-shelf-flex50'>
            <DestinationsVideoSetsToggleSort onChange={this.onSortChange} selectedIndex={this.state.pagination.sort} disabled={!this.state.destinations.setList.length} />
            <DestinationsIncludeInactive onChange={this.onInactivesChange} />
          </div>
          {this.makePagination()}
          {this.makeSets()}
          <CreateVideoSet {...this.state.create} onClose={this.onCreateVidsetClose} onSubmit={this.onCreateSave} onChange={this.onCreateChange} />
          <EditVideoSet {...this.state.edit} onClose={this.onEditVidsetClose} onSubmit={this.onEditVidsetSave} onChange={this.onCreateChange} />
          {this.makePagination()}
        </article>
      )
    }
    return body;
  }
}

export default (props) => (
    <DestinationVideoSets
        {...props}
        params={useParams()}
    />
);