export const VIDEOS_PER_PAGE = 24;
export const CHANNELS_PER_PAGE = 24;
export const DESTINATIONS_VIDEOS_PER_PAGE = 10;
export const REPORTS_CHANNEL_USAGE_PER_PAGE = 10;
export const LISTS_PER_PAGE = 25;

// https://developers.google.com/youtube/player_parameters
export const VIDEO_OPTIONS = {
  height: '390',
  width: '640',
  playerVars: {
    autoplay: 0
  }
};

export const USER_PERMISSIONS_LEVELS = [
  {label: 'User', value: 1},
  {label: 'Admin', value: 100},
  {label: 'Global Admin', value: 255}
];

/*========================================================*/
/* API RELATED CONSTANTS                                  */
/*========================================================*/

export const NULL_DATE_TEXT = 'No date set';
export const NULL_DATE_TEXT_STATIONS = '[Unscheduled]';
export const NULL_CHANNEL_TEXT = 'No channel info';
export const NULL_VIDEOSET_TITLE_TEXT = 'No title was set';
export const NULL_EPISODE_TITLE = '[Untitled Episode]';
export const NULL_SET_TITLE = '[Untitled Set]';

export const SEARCH_NO_RESULTS = 'No videos found. Try changing your filters.';
export const GENERIC_NO_RESULTS = 'No results found.';
export const MESSAGE_GENERIC_ERROR = "Whoops! Something went wrong with the API. This is a bug. Please tell an administrator.";

export const SET_PASS_RULES = {__html: 'Your password must be at least <b>12 characters long</b> and should be hard to guess. Try using a few unrelated words. You may add numbers or punctuation, or mix upper and lower case letters. Spaces are also allowed. <b>Avoid passwords</b> such as <code>p@$$w0rd123!</code> or <code>h@ppy!b1rthd@y</code>. They are not strong enough.'};
export const SET_PASS_RULES2 = 'Please re-type the password you entered above.';
export const PASSWORDS_MISMATCH = 'Your confirmation does not match your original password.';
export const PASSWORD_REQUEST_EXPIRED = 'Your password request has expired. Please tell an AVA administrator.';
export const PASSWORD_REQUEST_SENT = "If you're still an active user, we'll send a password reset link to the email address for your AVA account.";
export const PASSWORD_REQUEST_NO_TOKEN = "You didn't give me a request identifier.";
export const ERROR_MISSING_USERNAME = 'Please enter your user name in the correct field.';

export const MESSAGE_ADMIN_PASSWORD_RESET = "I sent a password reset email to";
export const MESSAGE_ERROR_GENERAL = "Something went wrong. Please tell an administrator.";
export const ERROR_LOGIN_FAILED = "That user name and password did not work. Try again.";
export const ERROR_LOGIN_CAPSLOCK = "Your Caps Lock key is on.";

export const MESSAGE_USER_ACTIVATED = 'This user is now active.';
export const MESSAGE_USER_DEACTIVATED = 'This user is now inactive.';
export const MESSAGE_USER_DELETE_CONFIRM = "Are you sure you want to delete this user from the system?";
export const MESSAGE_USER_DELETED = 'User was deleted';
export const MESSAGE_USER_NOPASS = 'This user has not set a password.';

export const MESSAGE_CLIPBOARD_OK = 'The IDs have been copied to your clipboard.';
export const MESSAGE_CLIPBOARD_FAILED = 'Uh-oh! I couldn\'t copy those IDs to your clipboard.';

export const MESSAGE_ERROR_TOO_MANY_URLS = ['Too many URLs or IDs. ', 'Please remove a few. You should also check for and remove extra line breaks, spaces, or commas at the end of URLs/IDs.'];
export const MESSAGE_CHANNEL_ADDED_GROUP = 'Channel added to group.';
export const MESSAGE_ERROR_TOO_MANY_SELECTED = ['Too many videos selected', 'Please deselect a few.'];

export const MESSAGE_NOT_SUBMITTED = 'Not Submitted';
export const MESSAGE_VIDEO_NOTE_SAVED = "Your note was saved.";

export const MESSAGE_DESTINATION_DELETE_CONFIRM = 'Are you sure that you want to delete %s?';
export const MESSAGE_DESTINATION_DELETED = '%s was deleted.';
export const MESSAGE_DESTINATION_NOVIDEOSETS = "This destination doesn't have any video sets.";
export const MESSAGE_DESTINATIONS_ADDVIDEOSET = 'Success! "%s" was added to "%s"';
export const MESSAGE_DESTINATION_DELETE_UNNAMED = '[UNNAMED DESTINATION]';

export const MESSAGE_ADD_TO_AVA = 'Please add this video to AVA first.';

export const MESSAGE_VIDEO_NOTRATED = 'Not Yet Rated';
export const MESSAGE_ERROR_NOTACHANNEL = "Whoops! Something you submitted was not a valid channel URL or ID.\nPlease check what you've entered and try again.";
export const MESSAGE_ERROR_ADDCHANNELTOSYSTEM = 'I think something went wrong with the API. Please tell an administrator.';

export const MESSAGE_NO_TAGS = "[NO TAGS]";
export const MESSAGE_VIDEO_ADDED_TO_SET = "Video added to set.";

export const API_SEARCH = 'api/search';

export const API_CHANNEL_BATCH = 'api/getchannelbatch';
export const API_CHANNEL_INFO = 'api/channelinfo';
export const API_CHANNEL_DELETE = 'api/channeldelete'; // Deprecated.
export const API_CHANNEL_REMOVE = 'api/channelremove'; // Use this one instead.

export const API_CHANNEL_SETTAGS = 'api/channelsettags';
export const API_CHANNEL_USAGE = 'reports/channelusage';
export const API_CHANNELS_TYPEAHEAD = 'reports/channelstypeahead/';
export const API_CHANNEL_ADD_TO_SYSTEM = 'api/channeladd';
export const API_CHANNEL_GET_GROUPS = 'api/channelgetgroups';
export const API_CHANNEL_GET_VIDEOS = 'api/channelgetvideos';

export const API_GROUPS_ALLGROUPS = 'api/allgroups';
export const API_GROUPS_ASSIGNMENTS = 'api/groupassignments';
export const API_GROUPS_SET_TAGS = 'api/channelsettags';
export const API_GROUPS_ADD_CHANNEL_TO_GROUP = 'api/channeladdtogroup';
export const API_GROUPS_ASSIGN_CHANNEL_TO_GROUP = 'api/channelsassigngroup';
export const API_GROUPS_CHANNELS_IN_GROUP = 'api/groupinfo';

export const API_GROUP_ADD_NEW = 'api/groupadd';
export const API_GROUPS_DELETE = 'api/deletegroup';
export const API_GROUPS_REMOVE_CHANNEL = 'api/channelremovefromgroup'; // *DOES NOT* return an updated list of groups.
export const API_REMOVE_CHANNEL_FROM_GROUP = 'api/channelremoveassignment'; // Returns an updated list of groups.

export const API_RATINGS_ALL = 'api/ratings';
export const API_RATINGS_VIDEOUPDATE = 'api/updatevideorating';

export const API_CRTAPPROVALS_OPTIONS = 'api/approvalstatuses';
export const API_CRTAPPROVALS_SETSTATUS = 'api/approvalsetstatus';
export const API_CRTAPPROVALS_GETSTATUS = 'api/approvalgetstatus';

// Gets approval status for entire set.
export const API_CRTAPPROVALS_GETVIDSETSTATUS = 'api/approvalvideosetstatus';

export const API_VIDEOSETBLOCK_ADDNEW_DEPRECATED = 'api/videosetblockadd';
export const API_VIDEOSETBLOCK_ADDNEW = 'api/videosetaddblock';
export const API_VIDEOSETBLOCK_DELETE = 'api/videosetblockdelete';
export const API_VIDEOSETBLOCK_RATING = 'api/getvideosetblockrating';
export const API_VIDEOSETBLOCK_INFO     = 'api/videosetblockinfo';
export const API_VIDEOSETBLOCK_UPDATE = 'api/videosetblockupdate';
export const API_VIDEOSETBLOCK_INFO_CSV = 'api/videosetblockinfo/csv';

export const API_VIDEOSETBLOCK_REINIT = 'api/videosetblockreinitialize';
export const API_VIDEOSETBLOCK_REVIEWED = 'api/videosetblockreviewed';
export const API_VIDEOSETBLOCK_REORDERED = 'api/videosetblockupdateorder';

export const API_VIDEO_ADDTOSET = 'api/videoaddtoset';
//export const API_VIDEO_ADDTOEP = 'api/videoaddtoepisode';
export const API_VIDEO_ADDNEW = 'api/videoaddnew';
export const API_VIDEO_DELETE = 'api/videodelete';
export const API_VIDEO_GETBATCH = 'api/getvideobatch';
export const API_VIDEO_INFO = 'api/videoinfo';
export const API_VIDEO_REMOVEFROMSET = 'api/videoblockremovevideo';
export const API_VIDEO_SEARCH = 'api/videosearch';
export const API_VIDEO_UPDATETAGS =  'api/videoupdatetags';
export const API_VIDEO_ADDBATCH = 'api/videoaddbatchbyurl';
export const API_VIDEO_ADDNOTE = 'api/videoaddnote';

export const API_VIDEOSET_ADDNEW = 'api/videosetadd';
export const API_VIDEOSET_DELETE = 'api/videosetdelete';
export const API_VIDEOSET_RESTORE = 'api/videosetrestore';
export const API_VIDEOSET_ASSIGNMENTS = 'api/videosetassignments';
export const API_VIDEOSET_REINIT = 'api/videosetreinitialize';
export const API_VIDEOSET_RENAME = 'api/videosetrename';
export const API_VIDEOSET_DUPLICATE = 'api/duplicateepisode';
export const API_VIDEOSET_ISDUPLICATE = 'api/isduplicateepisode';

export const API_DESTINATIONS_CHECKFORUPDATES = 'api/videosetcheckforupdates';
export const API_DESTINATIONS_ADDNEW = 'api/destinationadd';
export const API_DESTINATIONS_CHECKFORUPDATES_CSV = 'api/getvideohistoryascsv';
export const API_DESTINATION_DELETE = 'api/destinationdelete';
export const API_DESTINATIONS_LIST = 'api/alldestinationslist'; // Lists all destinations
export const API_DESTINATIONS_ASSIGNMENTS = 'api/destinationassignmentsbasic'; // Get name, set list for a destination
export const API_DESTINATIONS_INFO = 'api/destinationinfo'; // Get name, set list for a destination, INCLUDING how many playlists there are per run date.
export const API_DESTINATIONS_SEARCH = 'api/findvideosets';
export const API_DESTINATIONS_RENAME = 'api/destinationrename';

export const API_USER_CHECK_SET_PASS_ID = 'api/checksetpassid';
export const API_USER_LOGIN = 'api/login';
export const API_USER_PW_RESET = 'api/requestpass';
export const API_USER_SET_PASS = 'api/usersetpass';
export const API_USER_UNAUTHED_REQUEST_TOKEN = 'api/requesttoken';
export const API_USER_TEST_PASS = 'api/checkpassstrength';
export const API_USER_SUGGEST_PASSWORD = 'api/suggestpass';

export const API_USERS_GETALL = 'api/allusers';
export const API_USERS_ADD = 'api/useradd';
export const API_USERS_UPDATEUSER = 'api/useredit';
export const API_USERS_DELETEUSER = 'api/userdelete';
export const API_USERS_GETUSERINFO = 'api/userinfo';
export const API_USER_UPDATES_SELF = 'users/updateAccountInfo';
export const API_USERS_DEACTIVATE = 'api/userdeactivate';
export const API_USERS_REACTIVATE = 'api/userreactivate';
export const API_USERS_RESETPASSWORD = 'api/resetpassword';

export const API_REPORTS = 'api/reports'
export const API_REPORTS_DROPOFFS = 'reports/videodropoffs';
export const API_REPORTS_DROPOFFS_CSV = 'reports/videodropoffs/csv';
export const API_REPORTS_VIDSBYDEST = 'reports/videosbydestination';
export const API_REPORTS_CHANNELSBYDEST = 'reports/channelsbydestination';
export const API_REPORTS_DESTINATIONSBYVIDSET = 'reports/destinationsbyvideoset';
export const API_REPORTS_CHANNELS = 'reports/channels';
export const API_DOWNLOAD_PREFIX = '/ava/api/api/';

export const API_REPORTS_VIDSET_TYPEAHEAD = 'reports/videosettypeahead';
export const API_REPORTS_VIDEO_USAGE = 'reports/videousage'
export const API_REPORTS_VIDEOS_TYPEAHEAD = 'reports/videostypeahead';

export const API_STATIONS = 'api/stations';
export const API_SAVE_SEASONEPS = 'api/saveseasoneps';
export const API_SERIES = 'api/series';

export const API_STATIONS_EPISODE_EDIT = 'api/episodeedit';
export const API_STATIONS_SERIES_EDIT = 'api/seriesedit';

export const API_STATIONS_FIND_SERIES = 'api/findepisodes';

export const API_MEOW_OPTIONS = 'api/meowstatuses';
export const API_MEOW_SETSTATUS = 'api/meowsetstatus'

export const API_IMPORT_EMAIL = 'api/spreadsheetemail';
export const API_IMPORT_SPREADSHEET = 'api/spreadsheetdurations';
export const API_EXPORT_DURATIONS_TO_GOOG = 'api/spreadsheetupdate';
export const API_IMPORT_SPREADSHEET_FINDRANGES = 'api/spreadsheetdurationranges';
export const API_IMPORT_GSHEET_TO_PLAYLIST = 'api/importplaylistfromgsheet';

export const API_CATEGORIES = 'api/categories';

export const API_CLUSTERS_GENRES = 'api/genres';
export const API_CLUSTERS_SINGLEGENRE = 'api/genre';
export const API_CLUSTERS_GENREADD = 'api/genreadd';
export const API_CLUSTERS_GENREREMOVE = 'api/genreremove';
export const API_CLUSTERS_SONGS = 'api/genresongs';
export const API_CLUSTERS_GENRESIZES = 'api/genresizes';
export const API_CLUSTERS_SONG_GET_ASINDATA = 'api/scrapeasin';
export const API_CLUSTERS_SONG_IMPORT = 'api/importasin';
export const API_CLUSTERS_SONG_REMOVE = 'api/genreremoveasin';
export const API_GENRES_REPORTS = 'api/genrereports';
export const API_GENRES_SONGDUPES = 'api/genresongdupes';
export const API_GENRES_ARTISTDUPES = 'api/genreartistdupes';
export const API_CLUSTERS_SONGSREORDER = 'api/genresongsreorder';
export const API_CLUSTERS_GENREIMPORT = 'api/genreimport';

export const API_GENRES_EXPORTCSV = 'api/genreexportcsv';

export const API_PROJECTS = 'api/projects';
export const API_PROJECTS_FORUSER = 'api/projectsforuser';

export const API_LANGUAGES = 'api/languages';
export const API_REGIONS = 'api/regions';

export const API_AAPL_IMPORT = 'api/aaplimport';
export const API_AAPL_LIST = 'api/aapllist';
export const API_AAPL_EDIT = 'api/aapledit';
export const API_AAPL_GETREVISION = 'api/aaplrevision';
export const API_AAPL_SAVE = 'api/aaplsave';
export const API_AAPL_DELETE = 'api/aapldelete';

export const INTERSTITIAL_INDICATOR = '✪';
export const INTERSTITIAL_NOTE = `(${INTERSTITIAL_INDICATOR} = contains an interstitial video)`;

export const ERROR_UNAUTHORIZED = {error: 1, message: 'Please log in.'};

export const STRIP_TAGS_REGEX = new RegExp(/<[^>]+>/g);

export const AAPL_ERROR_DELETE = 'Error: Something went wrong. Please tell an administrator.';
