/*
ModalDialog
Use this instead of DialogModal. Uses the HTML dialog element.
*/

import React, { useEffect, useRef } from 'react';
import { CloseButton } from './CloseButton';

import './css/ModalDialog.css';

const ModalDialog = ({
  open,
  children,
  id,
  onClose,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (!open) {
      return;
    }
    const dialog = ref.current;

    dialog.showModal();

    // eslint-disable-next-line consistent-return
    return () => (dialog.close());
  }, [open]);

  return (
    <dialog ref={ref} id={id} aria-labelledby={`${id}_title`}>
      <CloseButton
        color="#CBCBCB"
        className="dialog__body__close"
        onClick={onClose}
      />

      <div className="dialog__body" role="document">
        {children}
      </div>
    </dialog>
  )
}

export default ModalDialog;
