/**
 * VideoSetRundates.js
 * View run dates or playlists for a particular video set
 */
import React from 'react';
import { useParams, redirect } from 'react-router-dom';

import Breadcrumb from '../subcomponents/Breadcrumb';
import LoaderSimple from '../subcomponents/LoaderSimple';
import DestinationsPlaylistTable from './DestinationsPlaylistTable';
import { CreateRunDate } from './CreateRunDate';

import {
  API_VIDEOSETBLOCK_ADDNEW
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData,
} from '../../js/Utilities';

import {
  duplicatePlaylist,
  getRunDates,
  getVideoSetBreadcrumbs
} from '../../actions/DestinationsUtils';

import './css/DestinationVideoSets.css';

class VideoSetRundates extends React.Component {
  constructor( _props ) {
    super( _props );

    this.state = {
      episodes: [],
      loading: true,
      create: {
        open: false,
        date: '',
        mode: 'add',
        playlistID: null
      },
      crumbTrail: []
    };

    this.duplicate = this.duplicate.bind(this);
    this.onCreateRundateClose = this.onCreateRundateClose.bind(this);
    this.onCreateOpen = this.onCreateOpen.bind(this);
    this.onCreateSave = this.onCreateSave.bind(this);
    this.onCreateChange = this.onCreateChange.bind(this);
    this.onCreateRundateResponse = this.onCreateRundateResponse.bind(this);
    this.setBreadcrumbs = this.setBreadcrumbs.bind(this);
    this.makeBreadcrumbs = this.makeBreadcrumbs.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
    getRunDates(this.props.params.id, (response) => {
      let createState = {};
      createState = {create: {...this.state.create, title: this.state.create.title }};
      this.setState(Object.assign(createState, {episodes: response, loading: false}));
    });
    getVideoSetBreadcrumbs(this.props.params.id, this.setBreadcrumbs);
  }

  duplicate(id) {
    duplicatePlaylist(id, (data) => {
      window.location = `/destinations/playlist/${data}`;
    });
  } 

  onCreateSave(domEvent = null) {
    if(domEvent) {
      domEvent.preventDefault();
    }
    const fd = new FormData();
    fd.set('setID', this.props.params.id);
    fd.set('date', this.state.create.date);
    fd.set('title', '');
    fd.set('token', getAuthData('token'));

    apirequest(API_VIDEOSETBLOCK_ADDNEW, {body: fd}, this.onCreateRundateResponse);
  }

  onCreateRundateResponse(response) {
    this.setState({
      create: Object.assign({...this.state.create}, {mode: response.result, playlistID: response.blockID, title: response.set_title})
    });
  }

  onCreateChange(domEvent) {
    this.setState({
      create: Object.assign({...this.state.create}, {date: domEvent.target.value})
    }, () => {
      if(this.state.create.date == '') {
        this.onCreateSave();
      }
    });
  }

  onCreateOpen() {
    this.setState({create: Object.assign({...this.state.create}, {open: true}) });
  }

  onCreateRundateClose() {
    const updates = {
      open: false,
      mode: 'add',
      playlistID: null,
      date: ''
    };
    this.setState({create: Object.assign({...this.state.create}, updates) });
  }

  setBreadcrumbs(response) {
    const items = [
      {
        path: '/destinations',
        label: 'Destinations'
      },
      {
        path: `/destinations/videosets/${response.station_id}`,
        label: response.Station,
        state: {
          destination: response.Station
        }
      },
      {
        label: response.Video_Set
      }
    ];
    this.setState({crumbTrail: items, create: {...this.state.create, title: response.Video_Set }});
  }

  makeBreadcrumbs() {
    return <Breadcrumb items={this.state.crumbTrail} />;
  }

  render() {
    let body;
    if(this.state.loading) {
      body = <LoaderSimple open={true} />
    } else {
      body = (
        <DestinationsPlaylistTable
          header={['Run Dates', 'Duration', 'Actions']}
          rows={this.state.episodes}
          onDuplicate={this.duplicate} /> 
      )
    }

    return (
      <div>
        {this.makeBreadcrumbs()}
        <header id="destination__header">
          <h1>Run dates for <q>{this.state.create.title}</q></h1>
          <button type="button" className="btn btn--action" onClick={this.onCreateOpen}>Create New Playlist</button>
        </header>
        {body}
        <CreateRunDate
          {...this.state.create}
          videoset={this.props.params.id}
          onClose={this.onCreateRundateClose}
          onSubmit={this.onCreateSave}
          onChange={this.onCreateChange}
          />
      </div>
    )
  }
}

export default (props) => (
    <VideoSetRundates
        {...props}
        params={useParams()}
    />
);

