import {
  apirequest,
  getAuthData,
  hideLoader,
  setAuthData,
  showLoader,
  removeAuthData,
} from '../js/Utilities';

import {
  API_CHANNEL_BATCH,
  API_GROUPS_ALLGROUPS,
  API_SEARCH,
  API_VIDEO_ADDNEW,
  API_LANGUAGES,
  API_REGIONS,
  VIDEOS_PER_PAGE,
  MESSAGE_GENERIC_ERROR,
  MESSAGE_VIDEO_ADDED_TO_SET
} from '../js/Configuration';

import { REGION_FLAGS } from '../js/RegionFlagsConfiguration';

export const getRegions = ( callback ) => {
  const fd = new FormData();
  fd.set('token', getAuthData('token'));
  return apirequest(API_REGIONS, {body: fd}, callback);
}

export const getLanguages = (callback) => {
  const fd = new FormData();
  fd.set('token', getAuthData('token'));
  return apirequest(API_LANGUAGES, {body: fd}, callback);
}

export const getGroups = (callback) => {
  const fd = new FormData();
  fd.set('token', getAuthData('token'));
  return apirequest(API_GROUPS_ALLGROUPS, {body: fd}, callback);
}

export const setStateAfterGetRegions = (state, response) => {
  const regionShapes = response.map((region) => {
    return {
      label: region.name,
      value: region.code
    };
  });

  return {
    regions: regionShapes
  }
}

export const setStateAfterGetLangs = (state, response) => {
  const langShapes = response.map((language) => {
    return {
      label: language.name,
      value: language.code
    };
  });

  return {
    languages: langShapes
  }
}

export const setStateOnSetUserLang = ( state ) => {
  const userIntl = getAuthData('international');
  let response;

  if(userIntl) {
    response = {
      criteria: {
        ...state.criteria,
        regionCode: userIntl.region.code,
        relevanceLanguage: userIntl.language.code
      },
      international: userIntl
    }
  } else {
    response = {...state}
  }

  return response;
}
export const setStateAfterGetGroups = (state, response) => {
  const groups = response.map((item) => {
    return {
      label: item.group_name,
      value: item.group_id,
      channelList: item.channelList
    };
  });

  return {
    groups: [...state.groups].concat(groups)
  };
}

export const setStateBeforeGetChannels = (state) => {
  return {
    channelslist: {
      ...state.channelslist,
      open: true,
      loading: true
    }
  };
}

export const setStateAfterGetChannels = (state, data) => {
  return {
    channelslist: {
      ...state.channelslist,
      channels: data.channels,
      currentPage: data.current_page,
      totalPages: data.pages,
      loading: false
    }
  };
}

export const setStateAfterResults = (state, data) => {
  let key = 'All Channels';

  // If we have an error message
  if(data.error) {
    key = 'error';
  }

  // If we have a channel identifier
  if(data.channel_ytid) {
    key = data.channel_ytid;
  }

  return {
    results: {
      ...state.results,
      [key]: {
        ...data,
        open: !!data.result_count
      }
    },
    loading: false
  };
}

export const setStateChannelChoose = (state, groupid) => {
  let list = [];
  const selected = state.groups.find((item) => item.value == groupid);

  if(Object.hasOwn(selected, 'channelList')) {
    list = selected.channelList;
  };

  return {
    criteria: {
      ...state.criteria,
      channelsearch: list,
      channelSearchGroup: +groupid
    },
    loading: false
  };
}

export const search = (options, callback) => {
  return apirequest(API_SEARCH, options, callback);
}

export const getChannels = (options, callback) => {
  return apirequest(API_CHANNEL_BATCH, options, callback);
}

export const addVideo = (youtubeID, callback) => {
  const fd = new FormData();
  fd.set('videoids', youtubeID);
  fd.set('setID', '-1');
  fd.set('blockID', '-1');
  fd.set('uuid', getAuthData('uuid'));
  fd.set('userName', getAuthData('user'));
  fd.set('token', getAuthData('token'));

  const options = {
    method: 'POST',
    body: fd
  };

  apirequest(API_VIDEO_ADDNEW, options, callback);
}

export const setStateOnPlayVideo = (state, videoObj) => {
  return {
    videoinfo: {
      open: true,
      videoData: videoObj
    }
  };
}

export const setStateOnGetGroups = (state, groups) => {
  return {
    groups: {
      ...state.groups,
      data: groups
    }
  }
}

export const setStateGetChannelBatch = (state) => {
  const loading = showLoader();

  return {
    channelslist: {
      ...state.channelslist,
      ...loading,
      open: true,
    }
  }
}

export const setStateAfterChannelBatch = (state, data) => {
  const notloading = hideLoader();

  return {
    channelslist: {
      ...state.channelslist,
      ...notloading,
      channels: data.channels,
      currentPage: data.current_page,
      totalPages: data.pages,
    }
  }
}

export const setStateOnChannelList = (state, value) => {
  return {
    channelslist: {
      ...state.channelslist,
      currentPage: (+state.channelslist.currentPage) + (+value)
    }
  }
}

export const setStateOnChannelSearchSubmit = (state, value) => {
  return {
    channelslist: {
      ...state.channelslist,
      open: false,
      filterKeyword: ''
    }
  }
}

export const setStateOnPerPageChange = (state, value) => {
  return {
    channelslist: {
      ...state.channelslist,
      perPage: value
    }
  }
}

export const setStateOnChanFilterChange = (state, value) => {
  return {
    channelslist: {
      ...state.channelslist,
      filterKeyword: value.toString(),
      filterOn: 'channel_title'
    }
  }
}

export const setStateOnPanelToggle = (state, channel) => {
  return {
    results:{
      ...state.results,

      [channel]: {
        ...state.results[channel],
        open: !state.results[channel].open
      }
    }
  }
}

export const setStateOnHideChannels = (state) => {
  const notloading = hideLoader();

  return {
    ...notloading,
    channelslist: {
      ...state.channelslist,
      channels: [],
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      sortBy: 'channel_title',
      ascending: true,
      filterKeyword: '',
      filterOn: '',
      open: false,
      loading: false
    }
  }
}

export const setStateOnReset = (state) => {
  return {
    channelslist: {
      channels: [],
      currentPage: 1,
      perPage: VIDEOS_PER_PAGE,
      totalPages: 0,
      sortBy: 'channel_title',
      ascending: true,
      filterKeyword: '',
      filterOn: ''
    },
    results: {},
    channelsearch: [],
    criteria: {
      order: 'viewCount'
    }
  }
}

export const setStateOnCloseVideo = (state) => {
  return {
    videoinfo: {
      open: false,
      videoData: {}
    }
  }
}

export const setStateOnSetChannelGroup = (state, value) => {

  const notloading = hideLoader();

  // Returns the group whose channels we want to search.
  const group = state.groups.data.find((item) => item.group_id == value);

  let channels = [];
  if(group && Object.hasOwn(group, 'channelList')) {
    channels = group.channelList;
  }

  return {
    channelsearch: channels,
    ...notloading
  }
}

export const toggleAdvancedOptions = (state) => {
  return {
    advancedOpen: !state.advancedOpen
  }
}

export const showAdvancedOptions = (state) => {
  return {
    advancedOpen: true
  }
}

export const hideAdvancedOptions = (state) => {
  return {
    advancedOpen: false
  }
}

export const setStateOnSetRegion = (state, regionCode) => {
  const region = state.regions.find((r) => regionCode === r.value);
  const flagObj = REGION_FLAGS.find((f) => f.region.indexOf(region.label) > -1);

  const authData = {...getAuthData()};

  const newState = {
    international: {
      ...authData.international,
      region: {
        code: region.value,
        flag: flagObj,
        name: region.label
      }
    }
  }

  setAuthData(Object.assign({ ...authData}, newState));
  return newState;
}

export const setStateOnSetLanguage = (state, languageCode) => {
  const language = state.languages.find((l) => languageCode === l.value);
  const authData = {...getAuthData()};

  const newState = {
    international: {
      ...authData.international,
      language: {
        name: language.label,
        code: language.value
      }
    }
  }
  setAuthData(Object.assign({ ...authData}, newState));
  return newState;
}

export const setStateOnVideoBlockSelect = (state, response) => {
  return {
    videoinfo: {
      ...state.videoinfo,
      selectedSet: {
        ...state.videoinfo.selectedSet,
        videoblockid: response.videosetblocks.value
      }
    }
  };
}

export const setStateAfterSubmittingVideoBlock = (state, response) => {

  if( !response.success ) {
    window.alert( MESSAGE_GENERIC_ERROR );
    return;
  }

  window.alert( MESSAGE_VIDEO_ADDED_TO_SET );
}

// Save search criteria to the auth data on componentWillUnmount
export const saveSearchCriteria = ( stateData ) => {

  const user = getAuthData();
  const toSave = {
    ...user,
    userSearch: stateData
  };

  setAuthData( toSave );
}

export const setStateIfSearchCriteria = ( state ) => {

  const userSearch = getAuthData('userSearch');
  const update = {
    ...state,
    criteria: {
      ...state.criteria,
      ...userSearch
    }
  };

  return update;
}

export const setStateOnSearchReset = ( state ) => {
  removeAuthData('userSearch');

  return {
    channelslist: {
      channels: [],
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      sortBy: 'channel_title',
      ascending: true,
      filterKeyword: '',
      filterOn: '',
    },
    results: {},
    criteria: {
      order: 'viewCount',
      channelsearch: [],
      channelSearchGroup: 0,
    },
    international: {
      region: {
        code: 'YTE',
        name: 'All Regions',
        flag: {
          flag: '🌐',
          region: ''
        }
      },
      language: {
        code: 'YTE',
        name: 'All Languages'
      }
    }
  };
}

export const setStateOnModalClose = (state) => {
  return {
    videoinfo: {
      ...state.videoinfo,
      open: false,
      videoData: {}
    }
  }
}

export const setStateAfterRatingsChange = (state, searchRes, response) => {
  return {
    results: searchRes,
    videoinfo: {
      ...state.videoinfo,
      videoData: {
        ...state.videoinfo.videoData,
        rating: response.rating
      }
    }
  }
}

export const setStateAfterMEOWChange = (state, searchRes, response) => {
  return {
    results: searchRes,
    videoinfo: {
      ...state.videoinfo,
      videoData: {
        ...state.videoinfo.videoData,
        meow: response.meow
      }
    }
  }
}

export const setStateAfterCRTChange = (state, searchRes, response) => {
  return {
    results: searchRes,
    videoinfo: {
      ...state.videoinfo,
      videoData: {
        ...state.videoinfo.videoData,
        crt: response.crt
      }
    }
  }
}



