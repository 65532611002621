/**
 * ImportSpreadsheet.js
 * Enables users to import data into AVA from GoogleSheet.
 */

import React from 'react';
import classNames from 'classnames';

import LoaderSimple from '../subcomponents/LoaderSimple';
import { ImportSpreadsheetFields } from './ImportSpreadsheetFields';

import {
  API_IMPORT_EMAIL
} from '../../js/Configuration';

import {
  getAuthData,
  apirequest
}  from '../../js/Utilities';

import './css/ImportSpreadsheet.css';


export default class ImportSpreadsheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: '' };
    this.onSubmit = this.onSubmit.bind(this);
    this.fetchController = new AbortController();
  }

  componentDidMount() {
    const fd = new FormData();
    fd.append('token', getAuthData('token'));

    const signal = this.fetchController.signal;

    apirequest(API_IMPORT_EMAIL, {body: fd, signal}, (response) => {
      this.setState({email: response.email});
    })
  }

  componentWillUnmount() {
    this.fetchController.abort();
  }

  updateState(domEvent) {
    this.setState({
      [domEvent.target.name]: domEvent.target.value
    });
  }

  onSubmit(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();

    if(this.props.onSubmit) {
      this.props.onSubmit(domEvent);
    }
  }

  render() {
    let body;
    const cssClasses = classNames({
      'spreadsheet__import': true,
      'spreadsheet__import__disabled': !this.state.email
    }, this.props.className);

    if(this.props.loading) {
      body = <LoaderSimple open={true} />;
    } else {
      const emailStateAndProps = Object.assign({...this.state}, {...this.props});

      body = (
        <form action="#" method="post" id="tools_import_sheet" onSubmit={this.onSubmit} className={cssClasses}>
          <ImportSpreadsheetFields {...emailStateAndProps}  />
          <fieldset hidden={false} disabled={!this.state.email} onChange={this.props.onChange}>
            <div>
              <legend>What do you want to do?</legend>
                <p className="fancy__radio">
                  <input type="radio" name="operation" id="averages" value="averages" selected={this.props.operation === 'averages'} />
                  <label htmlFor="averages">Calculate average and total duration</label>
                </p>
                <p className="fancy__radio">
                  <input type="radio" name="operation" id="durations" value="durations" selected={this.props.operation === 'durations'} />
                  <label htmlFor="durations">Count videos by duration</label>
                </p>
            </div>
          </fieldset>
          <button disabled={!this.state.email || !this.props.operation || !this.props.url } type="submit" className="btn btn--action">Import</button>
        </form>
      );
    }
    return body;
  }
}
