import React from 'react';
import { Link } from 'react-router-dom';

export default class Error extends React.Component {   
  render() {
    return (
      <div id="content" className="error404page">
        <div>
          <h1>No such content!</h1>
          <p>Sorry. Couldn't find that URL. Are you sure it's correct?</p>
          <Link to="/">Try the home page</Link>
        </div>
      </div>
    );
  }
};
