/**
 * AmzSongsReorder.js
 * An input field for reordering songs in a list.
 */
import React from 'react';

export const AmzSongsReorder = (props) => {  
  return (
    <div className="AmzSongsReorder" style={{'display': 'flex'}}>
      <label htmlFor={props.ASIN} className="form__access__label">Change position of {props['Song Title']}</label>
      <input type="number" step="1" min="0" max="1000" id={props.ASIN} name={props.ASIN} value={props.ordinal} onChange={props.onChange} />
    </div>
  );
}
