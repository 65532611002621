/**
 * AddChannelsByURL.js
 * Used to add channels by URL. In terms of layout, it's the same as AddVideosByURL.
 */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SelectMenuControlled from '../subcomponents/SelectMenuControlled';
import { IconApprovedRejected } from '../subcomponents/IconApprovedRejected';
import { CloseButton } from '../subcomponents/CloseButton';

import uniqueId from 'lodash.uniqueid';

import {
  MESSAGE_ERROR_TOO_MANY_URLS
} from '../../js/Configuration';

import '../Destinations/css/AddVideosByURL.css';

export default class AddChannelsByURL extends React.Component {
  constructor(props) {
    super(props);

    this.makeAddForm = this.makeAddForm.bind(this);
    this.makeDialogBody = this.makeDialogBody.bind(this);
    this.setFocus = this.setFocus.bind(this);
    this.makeListItem = this.makeListItem.bind(this);
    this.closeModal =  this.closeModal.bind(this);
    
    this.urlField = createRef();
    this.acbu = createRef();
  }

  componentDidUpdate() {
    if(this.props.open && this.acbu.hasOwnProperty('current')) {
      this.acbu.current.showModal();
    }
  }
  
  closeModal() {
    if(this.props.onClose) {
      this.props.onClose(this.acbu)
    } else {
      this.acbu.current.close()
    }
  }

  setFocus() {
    this.urlField.current.focus()
  }
  
  makeListItem( info = [] ) {
    let title;
    switch( info[1].message ) {
      case 'exists':
        title = 'Already in AVA';
        break;
      default:
        title = 'Added successfully';
    }

    /*
      In makeDialogBody, we convert this.props.response to an array from a Map.
      Each item that we receive here is an array in the form [channelid, statusobject]
     */
    return (
      <li key= { uniqueId() }>
        { info[1].title }
        
        <i className={`add_channels_message__${info[1].message}`}>{ title }</i>
        <b className={`add_channels_indicator__${info[1].message}`}>  
          <IconApprovedRejected approved={ info[1].message === 'success' } title="" />
        </b>
      </li>
    );

  }
  
  makeAddForm() {
    return (
      <div>
        <form method="dialog" id="add_channels_by_url_form" onSubmit={this.props.onSubmit}>
          <p>
            <label htmlFor="add_channels_by_url_urls">Enter URLs</label> <span className="form__instructions" id="add_channels_by_url_note">(Maximum 50 URLs or YouTube identifiers.)</span><br />
            <textarea className="form-control" id="add_channels_by_url_urls" aria-describedby="add_channels_by_url_note" name="channels" value={this.props.channels} onChange={this.props.onChange} rows="15" ref={this.urlField} required disabled={this.props.working}></textarea>
            <span className="form__instructions aria__description">Use valid YouTube URLs or channel IDs. URLs or IDs should be separated either by a comma (,) or new line. If using URLs, include the protocol for each, e.g.: <code>http://</code> or <code>https://</code>. Limit 50.</span>
          </p>     
          <div>
            <SelectMenuControlled options={this.props.options} labelText="Select a group" firstEmpty={true} name="group" cssClass="channels__group_menu" value={this.props.group} onChange={this.props.onChange} />
            <div id="group__labelled" className="form__instructions">(Optional)</div>
          </div>
        </form>
        <div>
          <i><span hidden={!this.props.max} className="form__instructions form__error"><b>{MESSAGE_ERROR_TOO_MANY_URLS[0]}</b> {MESSAGE_ERROR_TOO_MANY_URLS[1]}</span></i>
          <button type="submit" form="add_channels_by_url_form" disabled={this.props.max} className="btn btn--action">Add Channels</button>
        </div>
      </div>
    )
  }

  makeDialogBody() {
    let display = this.makeAddForm();

    /* Convert the map to an array */
    if( [...this.props.response].length ) {
      display = <ul className="multi_success multi_success--alt">{ Array.from( this.props.response ).map( this.makeListItem ) }</ul>;
    }
    
    return (
      <div id="add_channels_by_url">
        { display }       
      </div>
    )
  }

  render() {    
    const cssClass = classNames({
      'add__channels__by__url': true,
    }, this.props.cssClass);

    return (
      <dialog id="AddChannelsByURL" ref={this.acbu} className={cssClass} >
        <CloseButton color="#CBCBCB" className="dialog__body__close" onClick={this.closeModal}/>
        <h2>Add Channels By URL</h2>
        {this.makeDialogBody()}
      </dialog>
    );
  }
}

 
AddChannelsByURL.defaultProps = {
  open: false,
}

AddChannelsByURL.propTypes = {
  open: PropTypes.bool.isRequired,
  cssClass: PropTypes.string
};
