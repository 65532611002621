/**
 * AddUserForm.js
 * Form for adding a new user
 * See PropTypes definitions at the end of this file for parameters
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './css/AddUserForm.css';

import SelectMenuControlled from '../../subcomponents/SelectMenuControlled';
import { ActionMessageDisplay } from '../../subcomponents/ActionMessageDisplay';
import { UserAdminActivate } from './UserAdminActivate';
import { AddUserFormProjects } from './AddUserFormProjects';

import {
  ButtonResetPassword,
  ButtonRemoveUser
} from './UserAdminButtons';

const AddUserForm = (props) => {
  const showAction = () => {
    if( !Object.hasOwn(props, 'mode') ) return null;
    return  (
      <ActionMessageDisplay
        mode={props.mode.mode}
        hidden={props.mode.mode === null}
        message={props.mode.status}
        onClose={props.onMessageClose} />
    );
  }

  const showEditButtons = () => {
    if(!props.edit) return;
    return (
      <span className="user__edit__controls">
        <UserAdminActivate {...props.form} disabled={!props.form.enabled} onDeactivate={props.onDeactivate} onReactivate={props.onReactivate} />
        <ButtonRemoveUser {...props.form} onClick={props.onDeleteUser} />
        <ButtonResetPassword {...props.form} onClick={props.onRequestPassword} />
      </span>
    );
  }

  const cssClass = classNames(
    props.cssClass,
    {
      'useradmin__add__user_wrapper': true,
      'useradmin__add__user_wrapper--open': props.open
    }
  );

  let showCancel = null;

  if(Object.hasOwn(props, 'addUser')) {
    showCancel = <button type="reset" className="btn">Cancel</button>;
  }

  if(Object.hasOwn(props, 'addUser')) {
    showCancel = <button type="reset" className="btn">Cancel</button>;
  }

  return (
    <section className={cssClass}>
      <header>
        <h1>{props.title}</h1>
        <button hidden={props.edit} type="button" title="Toggle this panel" onClick={props.onToggle} className="button__toggle"><b>▶</b></button>
      </header>

      {showAction()}

      <form hidden={!props.open} action={props.action} method="post" onSubmit={props.onSubmit} onReset={props.onReset} className="useradmin__add__user">
        <p className="ua-au-a">
          <span className="required">A red asterisk (</span>) indicates a required field.
          <input type="hidden" name="uuid" id="uuid" value={props.form.uuid} />
          <input type="hidden" name="enabled" id="enabled" value={props.form.enabled} /> 
        </p>

        <p className="ua-au-b">
          <label htmlFor="user_name" className="required">Log In Name</label>
          <input
            name="username"
            id="user_name"
            className="form-control"
            type="text"
            minLength="2"
            maxLength="40"
            aria-describedby="user_name_direction"
            required
            onChange={props.onChange}
            value={props.form.username || props.form.user_name || ''}
            pattern="\w{2,40}" />
          <span id="user_name_direction" className="aria__description">This is the name the user will use to log in. It can contain letters, numbers, or underscores. It may not contain punctuation or spaces. It must be at least two, but no longer than forty characters long.</span>
        </p>

        <p className="ua-au-c">
          <label htmlFor="user_email" className="required">Email Address</label>
          <input
            name="email"
            id="user_email"
            className="form-control"
            type="email"
            required
            onChange={props.onChange}
            value={props.form.email || ''} />
        </p>

        <p className="ua-au-d">
          <label htmlFor="common_name" className="required">First Name</label>
          <input
            name="displayname_first"
            id="first_name"
            className="form-control"
            type="text"
            aria-describedby="first_name_hint"
            required
            maxLength="29"
            onChange={props.onChange}
            value={props.form.displayname_first || ''} />
          <span id="first_name_hint" className="aria__description">How the user will be greeted and identified to other producers, i.e. <q>Hello, Jane</q>. </span>
        </p>

        <p className="ua-au-e">
          <label htmlFor="common_name">Second Name</label>
          <input name="displayname_second"
            id="last_name"
            className="form-control"
            type="text"
            maxLength="30"
            onChange={props.onChange}
            value={props.form.displayname_second || ''} />
        </p>


        <fieldset className="ua-au-f">
          <div>
            <legend>Grant access to:</legend>
            <AddUserFormProjects
              projects={props.projects}
              userprojects={props.form.project_access}
              onChange={props.onChange} />
          </div>
        </fieldset>

        <div className="ua-au-g"> 
          <label htmlFor="user_region"></label>
          <SelectMenuControlled
            labelText="Set User Region"
            name="user_region"
            onChange={props.onChange}
            options={props.regions}
            isRequired={false}
            cssClass="user__admin__select"
            firstEmpty={false}
            value={props.form.user_region || '0'}
            describedBy="user_region_note" />
          <span id="user_region_note" className="aria__description">Optional field. Sets a default region search value for YouTube. The curator can change this setting.</span>
        </div>

        <div className="ua-au-h"> 
          <label htmlFor="user_language"></label>
          <SelectMenuControlled
            labelText="Set User Language"
            name="user_language"
            onChange={props.onChange}
            options={props.languages}
            firstEmpty={false}
            isRequired={false}
            cssClass="user__admin__select"
            value={props.form.user_language || '0'}
            describedBy="user_language_note" />
          <span id="user_language_note" className="aria__description">Optional field. Sets a default language search value for YouTube. The curator can change this setting.</span>
        </div>

        <p className="ua-au-i">
          <span className="button__group">
            <button type="submit" className="btn btn--action">Save</button>
            { showCancel }
          </span>
        </p>
      </form>
      {showEditButtons()}
    </section>
  );
}

AddUserForm.defaultProps = {
  edit: false
};

AddUserForm.propTypes = {
  action: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onChange: PropTypes.func
};

export default AddUserForm;
