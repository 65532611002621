/**
 * SeasonsEpsDisplay.js
 * Show the season and episode for a video set block.
 * See PropTypes definitions at the end of this file for parameters
 */  
import React from 'react';
import PropTypes from 'prop-types';

import {
  API_SAVE_SEASONEPS
} from '../../js/Configuration';

import './css/SeasonsEpsDisplay.css';

class SeasonsEpsDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'display'
    }

    this.onEdit = this.onEdit.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.makeInput = this.makeInput.bind(this);
  }

  onEdit() {
    this.setState({mode: 'edit'});
    if(this.props.onEdit) {
      this.props.onEdit();
    }
  }

  makeInput(id='season') {
    let inp;
    if(this.state.mode !== 'edit') {
      inp = this.props[id];
    } else {
      inp = <input type="number" id={id} name={id} value={this.props[id] || '' } onChange={this.props.onChange}  />
    }
    return inp;
  }

  makeLabel(text = "season") {
    let lbl;
    if(this.state.mode !== 'edit') {
      lbl = text;
    } else {
      lbl = <label htmlFor={text}>{text}</label>
    }
    return lbl;
  }

  onSave(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();

    this.setState({ mode: 'display'});
    if(this.props.onSave) {
      this.props.onSave();
    }
  }

  onCancel(domEvent) {
    this.setState({ mode: 'display'});
  }

  render() {
    return (
      <form action={API_SAVE_SEASONEPS} method="post" className="s__e__display" onSubmit={this.onSave}>
        <span>{this.makeLabel('season')}</span> {this.makeInput('season')}
        <span>{this.makeLabel('episode')}</span> {this.makeInput('episode')}
        <button type="button" className="btn__link btn" onClick={this.onEdit} hidden={!this.props.isAdmin || (this.state.mode === 'edit')}>Change</button>
        <button type="submit" className="btn btn--action" hidden={this.state.mode !== 'edit'}>Save</button>
        <button type="button" className="btn__link btn" onClick={this.onCancel} hidden={!this.props.isAdmin || (this.state.mode !== 'edit')}>Cancel</button>
      </form>
    );
  }
}


SeasonsEpsDisplay.defaultProps = {
  season: '—',
  episode: '—',
};


SeasonsEpsDisplay.propTypes = {
  season: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  episode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onChange: PropTypes.func
};


export default SeasonsEpsDisplay;
