/**
 * CRTStatus.js
 * Check for updates button.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LoaderSimple from '../subcomponents/LoaderSimple';

import './css/CRTStatus.css';

class CRTStatus extends React.Component { 
  render() { 
    const cssClass = classNames(
      {'destinations__crt_status': true},
      {'destinations__crt_status--approved': this.props.approved},
      {'destinations__crt_status--unsubmitted': /Not Submitted/.test(this.props.status)},
      {'destinations__crt_status--rejected': /Rejected/.test(this.props.status)}
    );
   
    const beh = this.props.loading ? <LoaderSimple open={true} cssClass="destinations__crt_status__loader" /> : this.props.status;
    
    return (
      <div className={cssClass}>
        <b><abbr title="Content Review Team">CRT</abbr> Status:</b> <span className="destinations__crt_status__message">{beh}</span>
      </div>
    );
  }
}

CRTStatus.defaultProps = {
  approved: false,
  status: '—'
}

CRTStatus.propTypes = {
  approved: PropTypes.bool.isRequired,
  status: PropTypes.string
};

export default CRTStatus
