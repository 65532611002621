/**
 * RunDate.js
 * Display the RunDate on the the Destinations/edit page
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  format,
  isValid
} from 'date-fns';
import 'moment-duration-format';

import {
  NULL_DATE_TEXT
} from '../../js/Configuration';


import './css/RunDate.css';

class RunDate extends React.Component {
  constructor(props) {
    super(props);
    this.onInput = this.onInput.bind(this);
  }

  onInput(domEvent) {
    domEvent.persist();
    this.props.onInputChange(domEvent)  
  }

  render() {
    const cssClass = classNames(
      {'destinations__run__date': true},
      this.props.className
    );
   
    const runDateFull = isValid( this.props.date ) ? format( new Date(this.props.date), 'PP' ) : NULL_DATE_TEXT;
   
    return (
      <div className={cssClass}>
        <p>
          <label htmlFor="destination_rundate">Set or Change Run Date: </label>
          <input type="date" id="destination_rundate" name="destination_rundate" value={this.props.date} onChange={this.onInput} />
        </p>
        <p className="destinations__run__date__full">{runDateFull}</p>
      </div>
    );
  }
}


RunDate.propTypes = {
  date: PropTypes.string,
  onInputChange: PropTypes.func,
  className: PropTypes.string
};

export default RunDate
