/**
 * AmzSongAddSongs.js
 */
import React from 'react';
import ModalDialog from '../subcomponents/ModalDialog';

import { AmzSongAddSongsForm } from './AmzSongAddSongsForm';
import AmzSongAddSongsSave from './AmzSongAddSongsSave';
import LoaderSimple from '../subcomponents/LoaderSimple';

const AmzSongAddSongs = (props) => {
  let body;
  const {
    working,
    mode,
    open,
    onClose,
  } = props;

  if (working) {
    body = <LoaderSimple open />;
  } else {
    body = (mode === 'save') ? <AmzSongAddSongsSave {...props} /> : <AmzSongAddSongsForm {...props} />;
  }

  return (
    <ModalDialog id="AmzSongAddSongs" title="Add Songs" open={open} onClose={onClose}>
      <h2>Add songs to cluster</h2>
      {body}
    </ModalDialog>
  );
}

export default AmzSongAddSongs;
