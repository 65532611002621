/**
 * ImportSpreadsheetToPlaylist.js
 * Enables users to import data into an AVA playlist from Google Sheet.
 */

import React, { createRef } from 'react';
import classNames from 'classnames';
import { CloseButton } from '../subcomponents/CloseButton';

import LoaderSimple from '../subcomponents/LoaderSimple';
import { ImportSpreadsheetFields } from './ImportSpreadsheetFields';
import ModalDialog from '../subcomponents/ModalDialog';
import ToggleSwitch from '../subcomponents/ToggleSwitch';

import {
  API_IMPORT_EMAIL,
} from '../../js/Configuration';

import {
  getAuthData,
  apirequest
}  from '../../js/Utilities';

export default class ImportSpreadsheetToPlaylist extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: '' }
    this.makeTitle = this.makeTitle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const fd = new FormData();
    fd.append('token', getAuthData('token'));

    apirequest(API_IMPORT_EMAIL, {body: fd}, (response) => {
      this.setState({email: response.email});
    })
  }

  onSubmit(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();
    if(this.props.onSubmit) {
      this.props.onSubmit(domEvent, this.importModal);
    }
  }

  makeTitle(title) {
    return <span>Importing videos to <q>{title}</q></span>;
  }

  render() {
    let body;
    const cssClasses = classNames({
      'spreadsheet__destinations': true,
      'spreadsheet__import__disabled': !this.state.email
    }, this.props.className);

    if(this.props.loading) {
      body = <LoaderSimple open={true} />;
    } else {

      /*
      This is a weird markup setup because ToggleSwitch is also a form and you can't nest forms.
      You can, however, associate a form control such as a button with a form by setting the `form`
      attribute as we've done here.
      */
      body = (
          <>
            <h2>{this.makeTitle(this.props.title)}</h2>
            <form id="tools_import_sheet" onSubmit={this.onSubmit} className={cssClasses}>
              <input type="hidden" name="playlist" id="playlist" value={this.props.vsb_id} onChange={this.props.onChange} />
              <ImportSpreadsheetFields {...this.state} {...this.props} />
            </form>
            <ToggleSwitch disabled={!this.state.email || !this.props.url } onChange={this.props.onChange} label="Add to beginning?" options={['Yes','No']} name="prepend" selectedIndex={+this.props.prepend} />
            <p className="text__right button__group">
              <button disabled={!this.state.email || !this.props.url } type="submit" className="btn btn--action" form="tools_import_sheet">Import</button>
              <button type="button" onClick={this.props.onClose} className="btn btn-light" form="tools_import_sheet">Cancel</button>
            </p>
          </>
      );
    }

    return (
      <ModalDialog id="ImportSpreadsheetToPlaylist" open={this.props.open} onClose={this.props.onClose}>{body}</ModalDialog>
    );
  }
}
