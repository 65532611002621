/**
 * AmzGenreImport.js
 */
import React, { forwardRef } from 'react';
import DialogModal from '../subcomponents/DialogModal';
import ActionMessageDisplay from '../subcomponents/ActionMessageDisplay';
import LoaderSimple from '../subcomponents/LoaderSimple';
import { CloseButton } from '../subcomponents/CloseButton';

import uniqueId from 'lodash.uniqueid';
import { getAuthData } from '../../js/Utilities';

import './css/AmzGenreAddNew.css';

const AddForm = (props) => {
  let body = <LoaderSimple open = {true} />;

  if( !props.loading && !props.completed.length ) {
    body = (
      <form onSubmit={ props.onSubmit }>
        <div><label htmlFor="url">Google Sheet URL</label></div>
        <div>
          <input type="hidden" id="parent" name="parent" value={props.parent} onChange={()=>{}} />
          <input disabled={!props.email} type="url" id="url" name="url" value={props.url} aria-describedby="url_note" onChange={props.onChange} required />
          <button type="submit" className="btn btn--action">Import</button>
        </div>
        <p id="url_note">Make sure that you've shared this spreadsheet with the email address <b className="nobreak">{props.email}</b>.</p>
      </form>
    );
  }

  if( !props.loading && props.completed.length ) {
    const li = props.completed.map((line) => <li key={uniqueId()}>{line}</li>);
    body = (
      <ul className="bulleted-disc">
        {li}
      </ul>
    );
  }

  return (
    <dialog id="AmzGenresImport" ref={props.modal} onClose={props.onClose}>
      <CloseButton onClick={props.onClose} />
      <h2>Import Genres</h2>
      { body }
    </dialog>
  )
};

const AmzGenreImport = forwardRef((props, ref) => {
  return (
    <div>
      <div style={{'textAlign':'right', 'marginLeft': '1rem'}}>
        <button type="button" className="btn btn--action" onClick={props.onOpen}>Import Genres</button>
      </div>
      <AddForm {...props} modal={ref} />
    </div>
  );
});

export default AmzGenreImport