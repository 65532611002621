/**
 * BadgeMEOWCRT.js
 *
 */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { isNumeric } from '../../js/Utilities';
import { MESSAGE_NOT_SUBMITTED } from '../../js/Configuration';

import './css/BadgeMEOWCRT.css';

export const BadgeMEOWCRT = (props) => {

  let label = props.label || MESSAGE_NOT_SUBMITTED;
  let css = {
    'video__badge': true,
    'video__badge--large': props.fullSize,
  };

  if( props.mode === 'meow' ) {
    css = classNames({
      ...css,
      'video__meow--okay': meowOK(props.id) === true, // Green badge
      'video__meow--notok': meowOK(props.id) === false, // Badge
      'video__meow--escalate': meowOK(props.id) === 'escalate' // Yellow badge
    });
  } else {
    css = classNames({
      ...css,
      'video__crt--okay': crtOK(props.id),
      'video__crt--notok': crtOK(props.id) === false,
      'video__crt--escalate': crtOK(props.id) === 'escalate'
    });
  }

  return <b className={css}>{ label }</b>;
}

const meowOK = (id) => {
  // Return this if it's not submitted.
  let okay = null;

  if(isNumeric(id)) {
    switch(id) {
      case 0:
        okay = true;
        break;
      case 16:
        okay = 'escalate';
        break;
      default:
        okay = false;
    }
  }
  return okay;
}

const crtOK = (id) => {
  let approved = false;
  const crtId = +id;

  if(crtId === 0) {
    approved = null;
  }

  if(crtId === 1) {
    approved = true;
  }

  if(crtId >= 10) {
    approved = 'escalate';
  }
  return approved;
}

BadgeMEOWCRT.defaultProps = {
  mode: 'meow',
  id: false,
  label: MESSAGE_NOT_SUBMITTED,
  fullSize: false
};

BadgeMEOWCRT.propTypes = {
  mode: PropTypes.oneOf(['meow', 'crt']).isRequired,
  label: PropTypes.string,
  fullSize: PropTypes.bool
};
