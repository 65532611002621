/**
 * RatingsMenu.js
 * Displays individual channels. Used on Videos page for individual video items.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PleaseAddToAva } from '../Videos/PleaseAddToAva';

export default class RatingsMenu extends React.Component {
  constructor( _props ) {
    super( _props );
    this.makeMenuItems = this.makeMenuItems.bind(this);
  }

  makeMenuItems(arrayOfItems=[]) {
    const defaultSelect = [<option key='selectrating_rating-1' value="">Select Rating</option>];
    let menu = null;

    if(arrayOfItems.length) {
      menu = arrayOfItems.map((item, index) => {
        return <option key={`selectrating_${index.toString()}`} value={index}>{item.label}</option>
      });
    }
    menu = defaultSelect.concat(menu);

    return menu;
  }

  render() {
    const cssClasses = classNames(
      this.props.cssClass,
      {
        'video__form__ratings': true,
        'video__info__field': true
      }
    );

    return (
      <form action={this.props.actionURL} method={this.props.method} className={cssClasses} onSubmit={this.props.onSubmitHandler}>
        <label htmlFor="video_rating">Assign a rating to this video:</label>
        <PleaseAddToAva hidden={ !this.props.disabled } />
        <span className="form__input_button_group">
          <select name="video_rating" id="video_rating" onChange={this.props.onChangeHandler} value={this.props.value} disabled={this.props.disabled}>
            {this.makeMenuItems(this.props.options)}
          </select>
          <button type="submit" disabled={this.props.disabled} className="btn btn--action">Save</button>
        </span>
      </form>
    );
  }
};

RatingsMenu.defaultProps = {
  actionURL: 'api/ratings',
  cssClass: null,
  method: 'POST',
  onSubmitHandler: ( domEvent )=>{ domEvent.preventDefault(); alert('default function! ')}
};

RatingsMenu.propTypes = {
  actionURL: PropTypes.string,
  method: PropTypes.string,
  cssClass: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  })).isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
};
