/**
 * AmzSongsReorderTrigger.js
 * Button to enter and exit reordering mode.
 */
import React from 'react';
import uniqueId from 'lodash.uniqueid';

export const AmzSongsReorderTrigger = (props) => {  
  return (
    <span className="AmzSongsReorderTrigger">
      <button type="button" className="btn btn--action" onClick={props.onClick} hidden={props.mode !== 'display'}>Re-order list</button>
      <button type="button" className="btn btn--action" onClick={props.onClick} hidden={props.mode !== 'reorder'}>Save changes</button>
    </span>
  );
}
