/**
 * GroupsChannelInfo.js
 * Displays a model on the GroupsPage info when the user clicks to get more
 * info about the channel.
 * 
 * This is similar to, but not the same as the ChannelInfo component. Needed to be able to both retain the
 * existing channel group data _and_ update it. Since that was significantly harder to do with a shared component
 * We're using a separate one.
 * 
 * Let's try to combine those components at some point in the future. In the goals of Gittin'er done, we're making two files. 
 * - webinista
 */

import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { CloseButton } from './CloseButton';

import { 
  isValid,
  format
} from 'date-fns';

import {
  API_GROUPS_ALLGROUPS,
} from '../../js/Configuration';

import { 
  apirequest,
  getAuthData,
  numberFormat
} from '../../js/Utilities';

import { getGroupNamesFromData } from '../../actions/ChannelsUtils';
import { setStateOnGetGroups } from '../../actions/GroupsUtils';

import '../../css/GroupsChannelInfo.css';

export default class GroupsChannelInfo extends React.Component {
  constructor(_props) {
    super(_props);
    
    this.state = {
      groupList: [],
    };

    this.getAllGroups = this.getAllGroups.bind(this);
    this.makeMenuItems = this.makeMenuItems.bind(this);
    this.showChannelPage = this.showChannelPage.bind(this);
    this.formatCategoryNames = this.formatCategoryNames.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.removeFromGroup = this.removeFromGroup.bind(this);
    this.onRemoveFromSystem = this.onRemoveFromSystem.bind(this);
    
    this.onEscapeDown = this.onEscapeDown.bind(this);

    this.DATE_FORMAT = 'PP';
    
    this.modal = createRef();
  }
  
  componentDidMount() {
    this.getAllGroups();
    
    window.addEventListener('keydown', this.onEscapeDown);
  }
  
  componentDidUpdate() {
    this.openModal()
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onEscapeDown);
  }
  
  onEscapeDown(domEvent) {
    const keyVal = Object.hasOwn(domEvent, 'key') ? domEvent.key : domEvent.keyCode;

    switch(keyVal) {
      case 27:
      case 'Escpae':
        this.closeModal(this.modal);
      default:
        return;
    }
  }
  
  onRemoveFromSystem(domEvent) {
    if(this.props.onRemoveFromSystem) {
      domEvent.preventDefault();
      domEvent.persist();
      this.props.onRemoveFromSystem(domEvent);
      this.closeModal(this.modal);
    }
  }

  openModal() {
    if(this.props.open && this.modal && Object.hasOwn(this.modal, 'current')) {
      this.modal.current.showModal();
    }
  }
  
  closeModal() {
    if(this.props.onClose) {
      this.props.onClose(this.modal)
    }
  }

  getAllGroups() {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_GROUPS_ALLGROUPS, {body: fd}, (data) => {
      this.setState((state) => setStateOnGetGroups(state, data), this.openModal);
    });
  }
  
  makeMenuItems(arrayOfItems=[]) {
    let menu = null;

    if(arrayOfItems.length) {
      menu = arrayOfItems.map((item, index) => {
        return <option key={index.toString()} value={item.group_id}>{item.group_name}</option>
      });
    }
    return menu;
  }

  showChannelPage() {
    const chann_id = this.props.youtubeid;
    window.open(`https://www.youtube.com/channel/${chann_id}/videos`, '_blank');
  }

  formatCategoryNames() {
    if(this.props.channel_category_names !== undefined) {
      return this.props.channel_category_names.sort().join(', ');
    }
  }

  removeFromGroup(domEvent) {
    domEvent.preventDefault();
    if(this.props.onRemoveFromGroup) {
      this.props.onRemoveFromGroup(
        domEvent.target.elements.groupID.value,
        domEvent.target.elements.channelID.value,
        this.modal
      )
    }
  }
  
  render() {
    const added   = new Date( this.props.channel_dateaddedtosystem );
    const updated = new Date( this.props.channel_datelastupdated );

    const hasAdded   = isValid( added )   ? format( added, this.DATE_FORMAT ) : null;
    const hasUpdated = isValid( updated ) ? format( updated, this.DATE_FORMAT ) : null;

    return (
      <dialog id="GroupsChannelInfoModal" ref={this.modal}>
        <CloseButton longForm={true} onClick={this.closeModal} title="Close" />
        <h2>{this.props.channel_title}</h2>
        <div className="GroupsChannelInfo__logo">
          <a href={`https://www.youtube.com/channel/${this.props.channel_youtubechannelid}/videos`} target="_blank" className="channel__thumb" rel="noopener noreferrer">
            <img src={ this.props.channel_thumbnail } alt={`${this.props.channel_title} Thumbnail`} />
          </a>
        </div>
  
        <section className="channel__meta_data">
          <h4>Channel Description:</h4>
          <div className="description" contentEditable="true" dangerouslySetInnerHTML={{ __html: this.props.channel_description }} />
        </section>
        
        <section className="channel__meta_data">
          <h4>Channel Stats:</h4>
          <ul>
            <li><span>Date Added to System:</span><span className="itemsBadge">{ hasAdded }</span></li>
            <li><span>Date Updated in System:</span><span className="itemsBadge">{ hasUpdated }</span></li>
            <li><span>Subscribers:</span><span className="itemsBadge">{ numberFormat( this.props.channel_subscribercount )}</span></li>
            <li><span>Video Count:</span><span className="itemsBadge">{ numberFormat( this.props.channel_videocount )}</span></li>
            <li><span>Curated Video Count:</span><span className="itemsBadge">{ numberFormat(this.props.curated_video_count )}</span></li>
            <li><span>Categories / Topics:</span><span className="itemsBadge">{ this.formatCategoryNames() }</span></li>
            <li><span>Tags:</span><div className="tags listItemScrollingTextBox">{ this.props.channel_customtags }</div></li>
            <li><span>Groups:</span><div className="tags listItemScrollingTextBox">{ getGroupNamesFromData( this.props ) }</div></li>
            <li><span>Keywords:</span><div className="tags listItemScrollingTextBox">{ this.props.channel_keywords }</div></li>
          </ul>
        </section>

        <form method="POST" action="api/channeldelete" className="form__action_control" onSubmit={this.onRemoveFromSystem}>
          <input type="hidden" name="id" id="delete_channel" value={this.props.channel_youtubechannelid} />
          <button type="submit" className="btn btn--action">Remove Channel From System</button>
        </form>

        <div className="form__action_control">
          <form method="post" onChange={this.props.onGroupChangeHandler}>
            <label htmlFor="modal_group_select">Remove Channel from Group:</label>
            <div>
              <select className="form-control" name="modal_group_select" id="modal_group_select" disabled value={+this.props.selectedGroup || ''}>
                {this.makeMenuItems(this.state.groupList)}
              </select>
            </div>
          </form>

          <div id="add_channel_button" className="centerTxt marginTop6px button__group">
            <form action="api/channelremovefromgroup" method="POST" onSubmit={this.removeFromGroup}>
              <input type="hidden" name="channelID" value={this.props.channel_youtubechannelid} />
              <input type="hidden" name="groupID" value={this.props.selectedGroup} />
              <button type="submit" className="btn btn--action" name="add_or_remove" value="add">Remove Channel from Group</button>
            </form>
          </div>
        </div>
        
        <form method="post" action="api/channelsettags" onSubmit={this.props.onUpdateTags} className="form__action_control">
          <div className="form__input_btn">
            <label htmlFor="channel_tags">Add Manual Tags:</label>
            <input id="channel_tags" type="text" name="tags" defaultValue={ this.props.channel_customtags || '' } placeholder="No Custom Tags, Add Comma Separated Tags" />
            <button type="submit" className="btn btn--action">Add Tags to Channel</button>
          </div>
        </form>
        <CloseButton longForm={true} onClick={this.closeModal} title="Close" />
      </dialog>
    )
  }
}


GroupsChannelInfo.propTypes = {
  channelData: PropTypes.any, // TODO: Change this to a shape
  open: PropTypes.bool,
  onCloseHandler: PropTypes.func,
  onRemoveFromGroup: PropTypes.func,
  onRemoveFromSystem: PropTypes.func,
  onGroupChangeHandler: PropTypes.func,
  onUpdateTags: PropTypes.func,
  onAddHandler: PropTypes.func,
  selectedGroup: PropTypes.any
};

