/**
 * StationsSeriesTable.js
 * Display table data for the stations series.
 */
import React from 'react';
import { Link } from 'react-router-dom'

import './css/StationsSeriesTable.css';

export default class StationsSeriesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stations: []
    };

    this.makeHeader = this.makeHeader.bind(this);
    this.makeRows = this.makeRows.bind(this);
    this.makeLink = this.makeLink.bind(this);
    this.makeControls = this.makeControls.bind(this);
  }

  makeHeader() {
    const ths = this.props.header.map((thd, idx) => {
      return <th key={idx}>{thd}</th>;
    });
    return <thead><tr>{ths}</tr></thead>;
  }
  
  makeLink(item) {
    let link;
    if(!item.hasOwnProperty('isSeries') || (item.hasOwnProperty('isSeries') && item.isSeries === true)) {
      link = <Link to={`/stations/series/${item.setID}`}>{item.setName}</Link>;
    } else {
      link = <Link to={`/stations/episode/${item.setID}`}>{item.setName}</Link>;
    }
    return link;
  }

  makeControls(item) {
    let controls;
    
    if(!item.hasOwnProperty('isSeries') || (item.hasOwnProperty('isSeries') && item.isSeries === true)) {
      controls = (
        <span>
          <button type="button" className="btn btn-sm btn-light stations__btn__editseries" data-episode={item.setID} data-setname={item.setName} onClick={this.props.onEditSeriesTitle}>Edit Series Title</button>            
          <button type="button" className="btn btn-sm btn--action" data-episode={item.setID} data-setname={item.setName} onClick={this.props.onViewEpisodes}>View Episodes</button>            
          <button type="button" className="btn btn-sm btn--action" data-series={item.setID} onClick={this.props.onAddEpisode}>Add Episode</button>
        </span>
      );
    } else {
      controls = <Link to={`/stations/episode/${item.setID}`}>View episode</Link>
    }
    return controls;
  }
  
  makeRows() {
    const trs = this.props.rows.map((trd, idx) => {
      return (
        <tr key={`row_${idx}`}>
          <td>{this.makeLink(trd)}</td>
          <td>
            {this.makeControls(trd)}
          </td>
        </tr>
      );
    });
    
    return <tbody>{trs}</tbody>;
  }
 
  render() {
    return (
      <table className="stations__series__table">
        {this.makeHeader()}
        {this.makeRows()}
      </table>
    );
  }
}
