import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NextPrevious from './NextPrevious';
import SearchResultItem from './SearchResultItem';
import LoaderSimple from '../subcomponents/LoaderSimple';

import './css/SearchResultsPanel.css';

import {
  SEARCH_NO_RESULTS
} from '../../js/Configuration';

export default class SearchResultsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.makeResultItems = this.makeResultItems.bind(this);
    this.makeResultCountDisplay = this.makeResultCountDisplay.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  makeResultItems(results) {
    let items;

    if( (results === undefined) || (results === null) ) {
      items = <LoaderSimple open={true} />
    } else if(results.length) {
      const vids = results.map((r) => {
        return (
          <SearchResultItem key={r.video_yt_id} id={r.video_yt_id} {...r} addVideoHandler={this.props.onAddVideo} playClickHandler={this.props.onPlayVideo} />
        );
      });

      items = (
        <div className="video__display video__display--grid search__results__results">
          <div className="List">{vids}</div>
        </div>
      );

    } else {
      items = <h3>{SEARCH_NO_RESULTS}</h3>;
    }

    return items;
  }

  makeResultCountDisplay() {
    const tooMany = (this.props.data.total_results >= 500) ? '> 500' : this.props.data.total_results;
    const results = (this.props.id === 'All_Channels') ? tooMany : this.props.data.total_results;
    return {count: (results || SEARCH_NO_RESULTS), showMessage: !(this.props.data.total_results >= 500)};
  }

  onToggle() {
    if(this.props.onToggle) {
      this.props.onToggle(this.props.data.channel_ytid);
    }
  }

  render() {
    const cssClass = classNames({
      'search__results__panel': true,
      'search__results__panel--open': this.props.open || false
    });

    return (
      <section className={cssClass} key={this.props.video_channel_yt_id}>
        <header>
          <div className="search__results__title">
            <h2>Results from <q>{this.props.title}</q></h2>
            <button hidden={this.props.singleChannel} disabled={!this.props.data.result_count} type="button" onClick={this.onToggle} className="button__toggle"><b>▶</b></button>
          </div>
          <NextPrevious next={this.props.next} prev={this.props.previous} {...this.props.data} className="search__channel__pagination" />
        </header>

        {this.makeResultItems(this.props.data.results)}
         
        <footer>
          <NextPrevious next={this.props.next} prev={this.props.previous} {...this.props.data} className="search__channel__pagination" />
        </footer>
      </section>
    )
  }
}

SearchResultsPanel.defaultProps = {
  open: true
}

SearchResultsPanel.propTypes = {
  title: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired
}
