/**
 * SearchText
 * Search form for Top Channels by Destination, Top Videos by Destination reports
 * Author: Webinista, Inc 3/24/18.
 */
   
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class SearchText extends React.Component
{
  constructor(props)
  {
    super(props);
    this.submitHandler = this.submitHandler.bind(this);
    this.makeDataList = this.makeDataList.bind(this);
  }

  submitHandler(domEvent) {
    if(this.props.onSubmit) {
      this.props.onSubmit(domEvent);
    }
  }

  makeDataList(arrayOfOptions) {
    return arrayOfOptions.map( (item, index) => {
      return <option value={item.title} key={index.toString()} />
    });
  }

  render() {
    const id = this.props.id || this.props.name;

    const cssClass = classNames(
      this.props.cssClass,
      {'reports__vidset__search': true}
    );

    let dataList, dataListAttr;

    if(this.props.datalist) {
      dataListAttr = `${id}_dl`;
      dataList = <datalist id={dataListAttr}>{this.makeDataList(this.props.datalist)}</datalist>;
    } else {
      dataListAttr = null;
      dataList = null;
    }
    
    
    return (
      <form id="reports_videoset_search" action={this.props.apiURL} method="POST" className={cssClass} onSubmit={this.submitHandler}>
        <input type="hidden" id="limit" name="limit" value={this.props.limit} />
        <label htmlFor={id}>{this.props.labelText}</label>
        <div>
          <input
            type="text"
            id={id}
            name={this.props.name}
            onChange={this.props.onInput}
            list={dataListAttr}
            required={true}
            autoComplete="off" />
            {dataList}
          <button type="submit" className="btn btn--action">Search</button>
        </div>
      </form>
    )
  }
}

SearchText.defaultProps = {
  apiURL: null,
  id: null,
  onChangeHandler: null,
  onInputHandler: null,
  onModalClickHandler: null,
  datalist: [],
  value: ''
};

SearchText.propTypes = {
  apiURL: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  onChangeHandler: PropTypes.func,
  onInputHandler: PropTypes.func,
  onSubmitHandler: PropTypes.func,
  destinations: PropTypes.arrayOf(PropTypes.shape({
    dID: PropTypes.string,
    dName: PropTypes.string
  })),
  value: PropTypes.string.isRequired,
  datalist: PropTypes.array
};

export default SearchText;
