import React, { useRef } from 'react';
import { CloseButton } from '../subcomponents/CloseButton';
import PropTypes from 'prop-types';
import TextInput from '../subcomponents/TextInput';

import {
  API_VIDEOSET_RENAME
} from '../../js/Configuration';

import { getAuthData } from '../../js/Utilities';

export const EditVideoSet = (props) => {
  const evs = useRef();
  
  if(props.open && evs.hasOwnProperty('current')) {
    evs.current.showModal()
  }
  
  const onClose = () => {
    if(props.onClose) {
      props.onClose(evs)
    }
  }
  
  const onSubmit = (domEvent) => {
    domEvent.preventDefault();
    domEvent.persist();

    if(props.onSubmit) {
      props.onSubmit(domEvent, evs)
    }
  }

  return (
    <dialog ref={evs} id="DestinationsEditVidset">
      <CloseButton onClick={onClose} longForm={true} />

      <h2>Rename this video set</h2>
      <form onSubmit={onSubmit} id="destination_create_vidset" action={API_VIDEOSET_RENAME} method="POST">
        <input type="hidden" name="token" value={getAuthData('token')} />
        <input type="hidden" name="id" value={props.item} onChange={() => {}} />
        <div className="form__label_btn_group">
        <TextInput
          cssClass="destinations__create-new"
          labelText="Enter a new name for this video set"
          name="title"
          id="edit"
          maxLength="50"
          onInputHandler={props.onChange}
          value={props.value} />
          <button type="submit" className="btn btn--action">Save</button>
        </div>
      </form>
    </dialog>
  );
}

EditVideoSet.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};



