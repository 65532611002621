import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { CloseButton } from './CloseButton';

import './css/ModalAlert.css';

export default class ModalAlert extends React.Component {
  constructor( _props ) {
    super( _props );
    this.dialog = createRef();
    this.closeModal = this.closeModal.bind(this);
    this.closeButton = this.closeButton.bind(this);
    this.makeTitle = this.makeTitle.bind(this);
  }

  componentDidMount() {
    if(this.props.open && Object.hasOwn(this.dialog, 'current')) {
      this.dialog.current.showModal();
    }
  }

  closeModal() {
    if(this.props.onClose) {
      this.props.onClose();
    }
    console.log( 'closeModal')
    this.dialog.current.close();
  }

  makeTitle() {
    if(this.props.title) {
      return <h2 id={`${this.props.id}_title`}>{this.props.title}</h2>;
    } else {
      return null;
    }
  }

  closeButton() {
    return (
      <CloseButton color="#CBCBCB" className="dialog__body__close" onClick={this.closeModal} />
    );
  }


  render() {
    return  (
      <dialog onClose={() => {console.log('dialog closed')}} ref={this.dialog} id={this.props.id} aria-labelledby={`${this.props.id}_title`}>
        <div className="dialog__body" role="document">
          {this.makeTitle()}
          {this.closeButton()}
          {this.props.body || this.props.children}
        </div>
      </dialog>
    )
  }
}

ModalAlert.defaultProps = {
  open: false,
  title: null,
  body: null
};

ModalAlert.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.any,
  body: PropTypes.any,
  onClose: PropTypes.func.isRequired
};
