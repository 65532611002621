export const setStateOnCreateVidsetResponse = (state, response, msg) => {
  return {
    create: {
      ...state.create,
      open: false,
      value: '',
      destination: {dName: '', dID: ''}
    },
    postCreate: {
      hidden: false,
      message: msg,
      mode: response.result
    }
  }
}

export const setStateOnEditVidsetResponse = (state, response) => {
  return {
    postCreate: {
      ...state.postCreate,
      hidden: false,
      message: response.message,
      mode: response.result === 'fail' ? 'failed' : response.result
    },
    edit: {
      ...state.edit,
      open: false,
      item: '',
      value: ''
    }
  };
}
