/**
 * YouTubeHeader.js
 * Manages the navigation display for the
 */

import React from 'react';
import {
  BrowserRouter as Router,
  NavLink
} from 'react-router-dom';

import { SwitchProjects } from './SwitchProjects';
import { SwitchProjectsMenu } from './SwitchProjectsMenu';

import { checkAccess } from '../../js/Utilities';

export default class YouTubeHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let switcher = this.props.canswitch ? <SwitchProjects onClick={this.props.onSwitch} /> : null;

    const activeClassName = ({isActive, isPending}) => {
      return isActive ? 'header__selected' : '';
    };

    const nav = (
      <div className="nav__project__wrap">
        <nav id="YouTubeHeader" className="nav__project" aria-label="YouTube Project Menu">
          <b className="nav__project_label">YouTube</b>
          <NavLink className={activeClassName} to="/search" key="search">Search</NavLink>
          <NavLink className={activeClassName} to="/videos" key="videos">Videos</NavLink>
          <NavLink className={activeClassName} to="/stations" key="stations">Stations</NavLink>
          <NavLink className={activeClassName} to="/destinations" key="linear">Destinations</NavLink>
          <NavLink className={activeClassName} to="/channels" key="channels">Channels</NavLink>
          <NavLink className={activeClassName} to="/groups" key="groups">Groups</NavLink>
          <NavLink className={activeClassName} to="/reports" key="reports">Reports</NavLink>
          <NavLink className={activeClassName} to="/tools" key="tools">Tools</NavLink>
        </nav>
        { switcher }
        <SwitchProjectsMenu open={this.props.menuopen} onClose={this.props.onSwitch} />
      </div>
    );
    return nav;
  }
}
