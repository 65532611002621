/**
 * SearchDates.js
 * Search values that are between a start and an end date
 * Author: Webinista, Inc 2018-09-04.
 */
  
import React from 'react';
import DateInput from '../subcomponents/DateInput';
import PropTypes from 'prop-types';

class SearchDates extends React.Component {

  render() {
    return (
      <div>
        <form id="reports_dropoff_search" action={this.props.apiURL} method="POST" className={this.props.cssClass} onSubmit={this.props.onSubmit}>
          <input type="hidden" id="limit" name="limit" value={this.props.limit} />
          <DateInput name="start" labelText="Start of date range:" isRequired={false} onChangeHandler={this.props.onChange} value={this.props.start} />
          <DateInput name="end" labelText="End of date range:" isRequired={false} onChangeHandler={this.props.onChange} value={this.props.end} />
          <button type="submit" className="btn btn--action">Search</button>
        </form>
      </div>
    )
  }
}

SearchDates.propTypes = {
  apiURL: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default SearchDates;
