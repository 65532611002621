/* 
* SelectPerPage.js 
* Creates a select menu for selecting the number of results to display 
* per screen. Used on Destinations, Search, and other pages.
* 
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isNumeric } from '../../js/Utilities';

class SelectPerPage extends React.Component {
  constructor( _props ) {
    super( _props );

    this.makeOptions = this.makeOptions.bind(this);
  };

  makeOptions() {
    const defaults = [10,25,30,50,100];
    let opts = defaults;

    if(this.props.replace) {
      opts = this.props.options;
    } else {
      opts = defaults.concat(this.props.options);
    }

    return opts.sort((a,b) => {return a - b;}).map((o,idx) => {     
      if(o === null) return null;
      if(isNumeric(o)) {
        return <option value={o} key={idx}>{o}</option>
      } else {
        // if a string was passed in, split it on a pipe.
        // Use first part for label, second for value.
        const v = o.split('|');
        return <option value={v[1]} key={idx}>{v[0]}</option>
      }
    });
  }

  render() {
    const cssClasses = classNames('input-select', this.props.cssClass);
    const name = this.props.name || this.props.id;
  
    return (
      <div className={cssClasses}>
        <label htmlFor={this.props.name}>Per page</label>
        <select name={name} id={this.props.id} value={this.props.value} onChange={this.props.onChangeHandler} required={this.props.isRequired} disabled={this.props.disabled}>
          {this.makeOptions()}
        </select>
      </div>
    );
  }
};

SelectPerPage.defaultProps = {
  cssClass: null,
  id: null,
  value: 0,
  isRequired: false,
  options: null,
  replace: false,
  disabled: false
};

SelectPerPage.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChangeHandler: PropTypes.func,
  cssClass: PropTypes.string,
  value: PropTypes.number,
  isRequired: PropTypes.bool,
  options: PropTypes.array,
  replace: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SelectPerPage;
