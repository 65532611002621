import React from 'react';
import classNames from 'classnames';

import SearchDropDown from './SearchDropDown';
import SearchInChannels from './SearchInChannels';
import SearchMoreOptions from './SearchMoreOptions';
import SearchDateRange from './SearchDateRange';

import { format } from 'date-fns';

import './css/SearchForm.css';

import {
  ORDER_BY,
  TOPIC_ID,
  VIDEO_CAT,
  EVENT_TYPE,
  VIDEO_DEF,
  VIDEO_DIM,
  VIDEO_DUR,
  VIDEO_TYPE,
  SAFE_SEARCH,
  VIDEO_LIC,
  LANGUAGES,
  REGIONS,
  VIDEO_EMBEDDABLE
} from '../../js/SearchConfiguration';

export const SearchForm = (props) => {
  const advanced = classNames({
    'search__form__advanced': true,
    'search__form__advanced--open': props.advancedOpen
  });

  const before = new Date(props.publishedBefore);
  const after  = new Date(props.publishedAfter);

  let rangeStart = '';
  if( !isNaN( after.getTime() )) {
    rangeStart = format( after.getTime(), 'yyyy-MM-dd' );
  }
  
  let rangeEnd = '';
  if( !isNaN( before.getTime() )) {
    rangeEnd = format( before.getTime(), 'yyyy-MM-dd' ) ;
  }
  
  return (
    <form action={props.action} method="POST" id="search_form" onSubmit={props.onSubmit} onReset={props.onReset}>
      <div className="form-group form__input_button_group">
        <label htmlFor="q">Enter Search Keywords</label>
        <span>
          <input
            type="search"
            className="bigSearchInputBox"
            name="q"
            id="q"
            value={props.q || ''}
            onChange={props.onChange} />
        </span>
      </div>

      <div className="search__form__shelf">
        <SearchDropDown label="Search in Group" options={props.groups} onChange={props.onChange} id="search_group" value={ props.channelSearchGroup || '' }/>
        <SearchInChannels onShowChannels={props.onShowChannels} />
        <SearchDropDown label="Order By" options={ORDER_BY} onChange={props.onChange} id="order" value={ props.order || ''} />
        <SearchDateRange onChange={props.onChange} afterDate={ rangeStart } beforeDate={ rangeEnd } />
        <SearchDropDown label="Topic ID" options={TOPIC_ID} onChange={props.onChange} id="topicId" value={ props.topicId || ''} />
       </div>

      <p className="search__form__chancount">Searching in <b>{props.channelsearch.length}</b> channels</p>
      <div className="search__form__shelf show__more">
        <SearchMoreOptions onClick={props.onShowOptions} open={props.advancedOpen} />
      </div>
      <div className={advanced}>
        <SearchDropDown label="Category Search" options={VIDEO_CAT} onChange={props.onChange} id="videoCategoryId" value={props.videoCategoryId || ''} />
        <SearchDropDown label="Video Definition" options={VIDEO_DEF} onChange={props.onChange} id="videoDefinition" value={props.videoDefinition || ''} />
        <SearchDropDown label="Video Dimensions" options={VIDEO_DIM} onChange={props.onChange} id="videoDimension" value={props.videoDimension || ''} />
        <SearchDropDown label="Video Duration" options={VIDEO_DUR} onChange={props.onChange} id="videoDuration" value={props.videoDuration || ''} />
        <SearchDropDown label="Video Type" options={VIDEO_TYPE} onChange={props.onChange} id="videoType" value={props.videoType || ''} />
        <SearchDropDown label="Video Embeddable" options={VIDEO_EMBEDDABLE} onChange={props.onChange} id="videoEmbeddable" value={props.videoEmbeddable || ''}/>
        <SearchDropDown label="Video License" options={VIDEO_LIC} onChange={props.onChange} id="videoLicense" value={props.videoLicense || ''}/>
        <SearchDropDown label="Live Event Type" options={EVENT_TYPE} onChange={props.onChange} id="eventType" value={ props.eventType || ''}/>
        <SearchDropDown label="Regions" options={props.regions} onChange={props.onChange} id="regionCode"  value={props.regionCode} />
        <SearchDropDown label="Language" options={props.languages} onChange={props.onChange} id="relevanceLanguage" value={props.relevanceLanguage} />
        <SearchDropDown label="Safe Search" options={SAFE_SEARCH} onChange={props.onChange} id="safeSearch" value={ props.safeSearch || ''}/>
      </div>
      <span className="search__form__shelf search__form__controls">
        <button type="reset" className="btn">Reset search</button>     
        <button type='submit' id="search_btn" className='btn btn--action'>Search</button>
      </span>
    </form>
  );
}

