/**
 * Created by Webinista, Inc 9/04/18.
 */

import React from 'react';
import SearchDates from './SearchDates';
import ReportsResults from './ReportsResults';
import LoaderSimple from '../subcomponents/LoaderSimple';
import { useLocation } from 'react-router-dom';

import {
  apirequest,
  getAuthData,
  yyyymmdd
} from '../../js/Utilities';


import {
  API_REPORTS_DROPOFFS,
  API_REPORTS_DROPOFFS_CSV,
  NULL_DATE_TEXT,
  NULL_VIDEOSET_TITLE_TEXT
} from '../../js/Configuration';

import '../../css/ReportsVideoDropOffs.css';

class ReportsVideoDropOffs extends React.Component
{
  constructor(props) {
    super(props);

    const start = new Date();

    this.state = {
      destination: '',
      destinations: [],
      end: yyyymmdd(new Date()),
      videos: [],
      isLoading: false,
      results: null, // Number of results returned
      start: yyyymmdd(new Date(start.setDate(-7))),
      limit: '500'
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.doSearch = this.doSearch.bind(this);
    this.massageResults = this.massageResults.bind(this);
  }

  massageResults(results){
    const res = results.data.map( (item) => {
      return {
        title: item['Video Title'],
        url: item.url,
        vs_title: item['Video Set'],
        episode: item.Episode  || NULL_VIDEOSET_TITLE_TEXT,
        rundate: item['Run Date'] || NULL_DATE_TEXT,
        frequency: yyyymmdd(new Date(item.video_datelastupdated))
      }
    });

    this.setState({videos: res, isLoading: false, results: res.length});
  }

  doSearch() {
    const fd = new FormData();
    fd.set('start', this.state.start);
    fd.set('end', this.state.end);
    fd.set('limit', this.state.limit);
    fd.set('token', getAuthData('token'));

    apirequest(API_REPORTS_DROPOFFS, {body: fd}, this.massageResults);
  }

  onChange(domEvent) {
    this.setState({
      [domEvent.target.name]: domEvent.target.value
    })
  }

  onSubmit(domEvent) {
    domEvent.preventDefault();
    this.doSearch();
  }

  componentDidMount() {
    this.setState({isLoading: true}, this.doSearch());
  }

  render() {
    let display;

    if(this.state.isLoading) {
      display = <LoaderSimple open={true} />
    } else {
       display = (
        <ReportsResults
          columnHeadings={['Video Title', 'Video Set', 'Video Set Title', 'Run Date', 'Date Found Inactive']}
          results={this.state.videos}
          count={this.state.results}
          destination={this.state.destination}
          downloadURL={API_REPORTS_DROPOFFS_CSV}
          start={this.state.start}
          end={this.state.end}
          limit={this.state.limit}
          cssClass="reports__table__videodropoffs" />
        );
    }

    return (
      <div className="reports__page">
        <h1>{this.props.params.state.title}</h1>
        <SearchDates
          apiURL={API_REPORTS_DROPOFFS}
          cssClass="reports__dropoffs__searchdates"
          destinations={this.state.destinations}
          id="destination"
          start={this.state.start}
          end={this.state.end}
          labelText="Select a destination:"
          limit={this.state.limit}
          name="destination"
          onChange={this.onChange}
          onSubmit={this.onSubmit} />
        {display}
      </div>
    );
  }
}

export default (props) => (
  <ReportsVideoDropOffs
      {...props}
      params={useLocation()}
  />
);