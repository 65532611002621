/*
DestinationsPlaylistUtils.js

Functions that are used in DestinationsPlaylist/Stations component.
*/
import uniqueId from 'lodash.uniqueid';

import {
  apirequest,
  getAuthData,
  shuffle,
  expandShortYouTubeUrl,
  extractYouTubeIdFromUrl,
  makeYouTubeUrl
} from '../js/Utilities';

import {
  tooManyURLs
} from './DestinationsUtils';

export const setStateOnChangePerPage = (state, value) => {
  return {
    ...state,
    addBySearch: {
      ...state.addBySearch,
      perPage: +value
    }
  }
}

export const setStateOnShuffle = (state) => {
  const toshuffle = [...state.list];
  return {
    ...state,
    list: shuffle(toshuffle)
  }
}

export const setStatePostSaveNewOrder = (state, response) => {
  const videos = normalizeVids(response.returnData);
  const neworder = {
    ...state.addBySearch,
    open: false,
    currentPage: 1,
    // selected: [...state.list],
    filterKeyword: '',
    filterOn: '',
    loading: true
  };

  return {
    ...state,
    addBySearch: neworder,
    list: videos
  }
}

export const setStateCloseVideo = (state) => {
  return {
    ...state,
    showVideo: false
  }
}

export const setStateOnPageRequest = (state, value) => {
  let curPage = state.addBySearch.currentPage + (+value);
  return {
    ...state,
    addBySearch: {
      ...state.addBySearch,
      currentPage: curPage
    }
  };
}

export const setStateGroups = (state, data) => {
  return {videoGroups: data};
}

export const setStateOnPreGetVideoListBatch = (state) => {
  return {
    ...state,
    addBySearch: {
      ...state.addBySearch,
      loading: true
    }
  };
}

export const normalizeVids = (videolist) => {
  const callback = ( item, index ) => {
    return {
      name: item.Video_Name,
      ordinal: index,
      id: item.video_id,
      ytID: item.Video_ID,
      thumb: item.videoext_thumbnail,
      duration: +item.Duration,
      is_interstitial: item.is_interstitial,
      crt: item.crt,
      meow: item.meow,
      active: item.active,
      position: +item.order
    };
  };
  return videolist.map(callback);
}

export const setStatePostVideoListBatch = (state, data) => {
  // add a checked property so we don't get undefined errors
  const { videos } = data;  
  videos.map((v) => {
    v.checked = false;
    return v;
  })
  
  const newState = {
    ...state.addBySearch,
    list: videos,
    currentPage: data.current_page,
    totalPages: data.pages,
    numResults: data.num_results,
    loading: false
  };

  return {
    ...state,
    addBySearch: newState
  };
}

export const setStatePostGetRatings = (state, data) => {
  const ratingsData = data.map((item, index) => {
    return {
      value: index,
      label: item.label
    };
  });
  return {
    ...state,
    ratings: ratingsData
  };
}

export const setStatePostRatingsSubmit = (state, data) => {
  return {
    ...state,
    showVideo: false,
    selectedRating: '',
    currentVideoData: {}
  }
}

export const setStateOnRatingsChange = (state, value) => {
  return {
    ...state,
    selectedRating: value,
    ratingsSubmitHandler: ''
  };
}

export const setStateIsDupe = (state, data) => {
  return {
    ...state,
    duplicated: data.is_dupe
  };
}

export const setStateCloseVideoList = (state) => {
  const newState = {
    ...state.addBySearch,
    open: false,
    currentPage: 1,
    totalPages: 0,
    numResults: 0,
    sortBy: 'video_addeddate',
    ascending: false,
    filterKeyword: '',
    filterOn: '',
    loading: true
  };
  return {
    ...state,
    addBySearch: newState
  };
}

export const setStateCloseCheckDupes = (state) => {
  return {
    ...state,
    checkForDupes: {
      ...state.checkForDupes,
      open: false,
      loading: true
    }
  }
}

export const setStateOpenCheckDupes = (state) => {
  return {
    ...state,
    checkForDupes: {
      ...state.checkForDupes,
      open: true,
      loading: true,
      mode: 'checking'
    }
  }
}

export const setStateSetDupes = (state) => {
  return {
    ...state,
    checkForDupes: {
      ...state.checkForDupes,
      dupes: checkDupes([...state.list]),
      loading: false
    }
  }
}


export const setStateShowAddByURLModal = (state) => {
  return {
    ...state,
    addByURL: {
      ...state.addByURL,
      open: true,
      value: '',
      working: false
    }
  }
}

export const setStateOnAddByUrlSubmit = (state) => {
  return {
    ...state,
    addByURL: {
      ...state.addByURL,
      working: true
    }
  }
}

export const setStateOnShowVids = (state) => {
  return {
    ...state,
    addBySearch: {
      ...state.addBySearch,
      open: true
    }
  }
}

export const setStateOnAddByUrlChange = (state, value) => {
  return {
    ...state,
    addByURL: {
      ...state.addByURL,
      open: true,
      value: value,
      maxIds: tooManyURLs(value)
    }
  };
}

export const setStateFoundDupes = (state, found) => {
  return {
    ...state,
    checkForDupes: {
      ...state.checkForDupes,
      dupes: found,
      open: true,
      loading: false,
      mode: 'adding'
    },
    addByURL: {
      ...state.addByURL,
      open: false
    }
  };
}

export const setStateCancelCheckDupes = (state) => {
  return {
    ...state,
    checkForDupes: {
      ...state.checkForDupes,
      open: false
    },
    addByURL: {
      ...state.addByURL,
      open: true,
      working: false
    }
  }
}

export const setStateOnAddByUrlClose = (state, arg2) => {
  return {
    ...state,
    addByURL: {
      ...state.addByURL,
      open: false
    }
  }
}

export const setStateOnSearchDirectionChange = (state) => {
  return {
    ...state,
    addBySearch: {
      ...state.addBySearch,
      ascending: !state.addBySearch.ascending
    }
  }
}

export const setStateOnSearchFilterClear = (state) => {
  return {
    ...state,
    ...state.addBySearch,
    filterOn: '',
    filterKeyword: ''
  };
}

export const setStateOnRemoveInstance = (state, title) => {
  return {
    ...state,
    checkForDupes: {
      ...state.checkForDupes,
      working: true,
      message: `I deleted that instance of <q>${title}</q>.`
    }
  }
}

export const setStateOnDupeExclude = (state, value={}) => {
  const foundDupes = [...state.checkForDupes.dupes];
  const index = foundDupes.findIndex((d) => d.id === value.id);

  foundDupes[index]['checked'] = value.checked;

  return {
    ...state,
    checkForDupes: {
      ...state.checkForDupes,
      dupes: foundDupes
    },
    addByURL: {
      ...state.addByURL,
      value: excludeUrlFromValue(state.addByURL.value, value)
    }
  }
}

// Technique for finding duplicates in an array. See: https://stackoverflow.com/a/24968449
const count = (list) => list.reduce((accumulator, currentvalue) => ({ ...accumulator, [currentvalue]: (accumulator[currentvalue] || 0) + 1 }), {});
export const findDupes = (arry) => {
  const counts = count(arry);
  return Object.keys(counts).filter((a) => counts[a] > 1);
}

export const checkDupes = (videolist = []) => {
  const vidIds = videolist.map((video) => {
    if(video.hasOwnProperty('ytID')) {
      return video.ytID;
    }
  });
  return findDupes(vidIds);
}

export const checkUrlListForDupes = (toAdd, currentPlaylist) => {
  const list =  toAdd
    .trim() // Eliiminates leading, trailing spaces.
    .split(/[,\s]/); // Splits by commas, whitespace.

  if(!list.length) return [];

  const dupes = list
    .filter((url) => url != false) // Eliminate blank lines.
    .map((url) => {
      if(url.indexOf('youtu') > -1) {
        const u = expandShortYouTubeUrl(url);
        return extractYouTubeIdFromUrl(u);
      } else {
        return url;
      }
    }) // Expand short URLs, get the IDs from the long URL.
    .map((i) => indicesOfDupes(currentPlaylist, i)) // Find dupes for each ID.
    .filter((i) => i.indexes.length); // Only return those IDs with dupes.

  return dupes;
}

export const indicesOfDupes = (array, value) => {
  const reducer = (acc, current, index) => {
    if(current.ytID === value) {
      acc.push(index);
    }
    return acc;
  }

  // Find the indices of the video in the video list array.
  const indices = array.reduce(reducer, []);
  return {
    id: value,
    indexes: indices,
    checked: true
  };
}

// Take a list of URLs, and process them to remove the ones we've excluded
// from the list of duplicates.
export const excludeUrlFromValue = (urls = '', video = {}) => {
  // Splits by commas, or whitespace. filters empties.
  const list = urls.split(/[,\s]/).filter(line => line !== '');
  if(!list.length) return;

  let include = '';

  if(!video.checked) {
    include = list
    .filter(line => line.indexOf(video.id) === -1) // Exclude the line containing the ID submitted
    .join('\n');
  } else {
    list[list.length] = makeYouTubeUrl(video.id);
    include = list.join('\n');
  }
  return include;
}

export const setStateOnDupesMessageClose = (state) => {
  return {
    ...state,
    checkForDupes: {
      ...state.checkForDupes,
      mode: 'checking',
      message: ''
    }
  }
}

// Called when there's an error adding items to the database.
export const setStateResetModal = ( state ) => {
  return {
    ...state,
    loading: false,
    addBySearch: {
      ...state.addBySearch,
      open: false
    },
    addByURL: {
      ...state.addByURL,
      open: false,
      value: '',
      working: false
    },
    checkForDupes: {
      ...state.checkForDupes,
      open: false,
      working: false
    }
  }
}

export const setStateAfterGrid = ( state, response = {}, closeAfter = false ) => {
  if( !response.hasOwnProperty('videoList') ) return state;

  return {
    ...state,
    loading: false,
    list: normalizeVids( response.videoList.returnData ),
    addBySearch: {
      ...state.addBySearch,
      open: false
    },
    currentVideoBlock: response,
    curated: {
        by: response.Curated_By || '',
        date: response.Curated_Date || false,
        isOkayed: response.Curated === 'y'
      },
    approved: {
      by: response.Approved_By || '',
      date: response.Approved_Date || false,
      isOkayed: response.Approved === 'y'
    },
    proofed: {
      by: response.Proofed_By || '',
      date: response.Proofed_Date || false,
      isOkayed: response.Proofed === 'y'
    },
    addByURL: {
      ...state.addByURL,
      open: false,
      value: '',
      working: false
    },
    checkForDupes: {
      ...state.checkForDupes,
      open: !closeAfter,
      working: false
    }
  }
}

const updateSelectedInList = ( list, target ) => {
  let loadedRows = [...list];
  const update = loadedRows.findIndex((item) => target.value === item.video_youtubeID );
  
  loadedRows[ update ] = {
    ...loadedRows[ update ],
    checked: !loadedRows[ update ].checked,
  };

  return loadedRows;
}

export const setStateOnVideoSelect = (state, target) => {
  const updatedSet = new Set( [...state.addBySearch.selected] );

  if( !target.checked ) {
    updatedSet.delete( target.value )
  } else {
    updatedSet.add( target.value );
  }

  const boo = {
    addBySearch: {
      ...state.addBySearch,
      selected: updatedSet,
      list: [...updateSelectedInList( state.addBySearch.list, target )]
    },
    addByURL: {
      ...state.addByURL,
      value: [...updatedSet].join('\n')
    }
  }

  return boo;
}
