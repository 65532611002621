import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import DialogModal from '../subcomponents/DialogModal';
import { CloseButton } from '../subcomponents/CloseButton';

import { Link } from 'react-router-dom';

import { API_VIDEOSET_ADDNEW } from '../../js/Configuration';
import { getAuthData } from '../../js/Utilities';

export const CreateRunDate = (props) => {
  const dcnrd = useRef();

  let body;
  if(props.mode === 'add') {
    body = (
      <section id="DestinationsCreateNewRunDate__add">
        <h2>Create a new playlist in <q>{props.title}</q></h2>
        <form onSubmit={props.onSubmit} id="destination_create_rundate" className="destination_create_rundate--modal" action={API_VIDEOSET_ADDNEW} method="POST">
          <input type="hidden" name="token" value={getAuthData('token')} />
          <input type="hidden" name="videoset" value={props.videoset} />
          <p>
            <label htmlFor="destination_rundate">Set run date:</label>
            <span className="playlist__dupemod_btns">
              <input type="date" id="destination_rundate" name="destination_rundate" value={props.date} onChange={props.onChange} />
              <button type="submit" className="btn btn--action" disabled={!props.date}>Save</button>
              <button type="button" className="btn btn-light" onClick={props.onChange} value="">I'll set one later</button>
            </span>
          </p>
        </form>
      </section>
    );
  } else if(props.mode === 'success') {
    body = (
      <section id="DestinationsCreateNewRunDate__success">
        <h2>Success!</h2> 
        <p>I've added a new playlist to the videoset, <q>{props.title}</q>.</p>
        <div>
          <Link to={`/destinations/playlist/${props.playlistID}`} className="btn btn--action">Edit this playlist</Link>
        </div>
      </section>
    );
  } else {
      body = (
        <section id="DestinationsCreateNewRunDate__error">
          <h2>Error! Something went wrong</h2>
          <p>Sorry. I could not add a new play list to the videoset,
          <q>{props.title}</q>. This is probably a bug. Please report it to an
          administrator.</p> 
        </section>
      )
  }
  
  if( dcnrd.hasOwnProperty('current') && props.open ) {
    dcnrd.current.showModal();
  }
  
  return (
    <dialog ref={dcnrd} id="DestinationsCreateNewRunDate">
      <CloseButton longForm={true} onClick={() => { props.onClose(dcnrd) } } />
      {body}
    </dialog>
  );
}

CreateRunDate.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};


