/**
 * ChannelListTable.js
 * A no-framework table component, specifically for the ChannelListComponent
 * display. Used on the Search page.
 * See PropTypes definitions at the end of this file for parameters
 * TODO: Consolidate this with VideoListChannel.js
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ChannelListTable extends React.Component {
  constructor(props) {
    super(props);
    this.makeHeaders = this.makeHeaders.bind(this);
    this.makeRows = this.makeRows.bind(this);
    this.makeOneRow = this.makeOneRow.bind(this);
  }

  makeHeaders() {
    let retval;

    if(this.props.headers) {
      let hdrs = [];

      hdrs = this.props.headers.map((h, idx) => {
        return <th key={idx.toString()}>{h}</th>
      });

      retval = (
        <thead>
          <tr>
            {hdrs}
          </tr>
        </thead>
      );
      
    } else {
      retval = null
    }
    return retval;
  }

  makeRows() {
    let retval;
    
    if(this.props.rows) {
      let rws = [];

      rws = this.props.rows.map((h,idx) => {
        return this.makeOneRow(h, idx);
      });

      retval = (
        <tbody>
         {rws}
        </tbody>
      );
      
    } else {
      retval = null
    } 
    return retval;
  }

  makeOneRow(obj, index) {
    let tds = [];

    tds[0] = (
      <td key={obj.channel_youtubeID}>
        <input
          name="channels_to_add"
          type="checkbox"
          defaultChecked={obj.channel_selected}
          id={obj.channel_youtubeID}
          value={obj.channel_youtubeID}
          onChange={this.props.checkBoxChangeHandler} />
      </td>
    );

    tds[1] = (
      <td key={`${obj.channel_youtubeID}_1`}>
        <label htmlFor={`${obj.channel_youtubeID}`}>
          {obj.channel_title}
        </label>
      </td>
    );

    tds[2] = <td key={`${obj.channel_youtubeID}_count`}>{obj.video_count}</td>;

    const tr = (
      <tr key={index.toString()}>
        {tds}
      </tr>
    );

    return tr;
  }

  render() {
    const cssClasses = classNames('table-list', this.props.cssClass);
    return (
      <table className={cssClasses} id={this.props.id}>
        {this.makeHeaders()}
        {this.makeRows()}
      </table>
    );
  }
}

ChannelListTable.propTypes = {
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  cssClass: PropTypes.string,
  headers: PropTypes.array,
  rows: PropTypes.array,
  checkBoxChangeHandler: PropTypes.func
};

export default ChannelListTable;
