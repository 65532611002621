/**
 * AmzSongAddSongs.js
 */
import React from 'react';
import { MESSAGE_ERROR_TOO_MANY_URLS } from '../../js/Configuration';

export const AmzSongAddSongsForm = (props) => {
  return (
    <form id="AmzSongAddSongs__form" onSubmit={props.onSubmit}>
      <p hidden={!props.maxIds} className="form__instructions form__error">
        <i><b>{MESSAGE_ERROR_TOO_MANY_URLS[0]}</b> {MESSAGE_ERROR_TOO_MANY_URLS[1]} {MESSAGE_ERROR_TOO_MANY_URLS[2]}</i>
      </p>
      <p>
        <input type="hidden" id="clusterid" name="clusterid" value={props.cluster} onChange={()=>{}}/>
        <label htmlFor="name">Amazon ASIN IDs or Song URLs</label>
        <textarea id="name" name="name" className="form-control"
          aria-describedby="cluster_note" value={props.value}
          onChange={props.onChange}></textarea>
        <span className="form__instructions aria__description" id="cluster_note">
          Use valid Amazon URLs or song ASIN IDs. URLs or IDs should be 
          separated either by a comma (,) or new line. If using URLs, include 
          the protocol for each, e.g.: <code>http://</code> or
          <code>https://</code>. Limit 15.
        </span>
      </p>
      <div className="button__group">
        <button type="submit" className="btn btn--action">Save</button>
        <button type="button" className="btn btn--light" onClick={props.onClose}>Cancel</button>
      </div>
    </form>
  );
}



    
