/**
 * CheckForUpdatesProgress.js
 * Modal called when there's a request to check for video updates.
 */

import React, { createRef, useId } from 'react';
import PropTypes from 'prop-types';
import LoaderSimple from '../subcomponents/LoaderSimple';
import { CloseButton } from '../subcomponents/CloseButton';

import {
  API_DESTINATIONS_CHECKFORUPDATES_CSV
} from '../../js/Configuration';

import {
  csvrequest,
  getAuthData,
  forceDownload
} from '../../js/Utilities';

import './css/CheckForUpdatesProgress.css';

export default class CheckForUpdatesProgress extends React.Component {
  constructor( _props ) {
    super( _props );
    this.makeList = this.makeList.bind(this);
    this.getUpdatesCSV = this.getUpdatesCSV.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.checkForUpdatesModal = createRef();
  }
  
  componentDidUpdate() {
    if( this.props.open ) {
      this.checkForUpdatesModal.current.showModal()
    }
  }

  closeModal() {
    if(this.props.onClose) {
      this.props.onClose(this.checkForUpdatesModal)
    }
  }

  getUpdatesCSV(domEvent) {
    domEvent.preventDefault();

    const fd = new FormData();
    const vidIds = this.props.videos.map((vid) => vid.YouTube_ID).join('|');

    fd.set('videoset_id', this.props.videoset);
    fd.set('ids', vidIds);
    fd.set('token', getAuthData('token'));

    csvrequest(API_DESTINATIONS_CHECKFORUPDATES_CSV, {body: fd}, (response) => {
      forceDownload(response);
    });
  }

  makeList() {
    if(this.props.loading) {
      return <LoaderSimple open={true} />;
    }

    if(this.props.videos.length) {
      const items = this.props.videos.map((item) => {

        let url;
        if(!item.URL) {
          url = <td className="text__center">–</td>;
        } else {
          url = <td className="text__nowrap"><a href={item.URL} target="_blank" rel="noopener noreferrer">{item.URL}</a></td>
        }

        const deleted = item.deleted ? 'Yes' : '';
        const title = (item.Current_Title === item.Previous_Title) ? '–' : item.Current_Title;

        return (
          <tr key={item.YouTube_ID}>
            {item.Previous_Title ? <td>{item.Previous_Title}</td> : <td>–</td>}
            {item.Current_Title ? <td>{title}</td> : <td>–</td>}
            {url}
            <td>{deleted}</td>
          </tr>
        );
      });

      const ids = this.props.videos.map((item) => {
        return item.history_id;
      });

      return (
        <div className="destinations__checkupdated__results">
          <h2>Results</h2>
          <table>
            <thead>
              <tr>
                <th>Previous Title</th>
                <th>Current Title</th>
                <th>Video URL</th>
                <th>Deleted from YouTube</th>
              </tr>
            </thead>
            <tbody>
              {items}
            </tbody>
          </table>
          <form action={API_DESTINATIONS_CHECKFORUPDATES_CSV} method="POST" target="_blank" onSubmit={this.getUpdatesCSV}>
            <input type="hidden" name="videoset_id" id="videoset_id" value={this.props.videoset} onChange={()=>{}} />
            <input type="hidden" name="ids" id="ids" value={ids.join('|')} onChange={()=>{}} />
            <button type="submit" className="btn btn--action">Export updates as CSV</button>
          </form>
        </div>
      )
    } else {
      return <p className="destinations__checkupdated__noupdates">No updates</p>;
    }
  }

  render() {
    return (
      <dialog onClose={this.closeModal} className='CheckForUpdatesProgress' ref={this.checkForUpdatesModal}>
        <CloseButton title="Close" longForm={true} onClick={this.closeModal} />
        <h2>Checking for Title Updates</h2>
        {this.makeList()}
      </dialog>
    )
  }
}

CheckForUpdatesProgress.defaultProps = {
  onVideoBlockSelect: ()=>{},
};

CheckForUpdatesProgress.propTypes = {
  onVideoBlockSelect: PropTypes.func,
};
