/**
 * StationView.js
 * /stations page-level component
 */
import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../subcomponents/Breadcrumb';
import StationsSeriesTable from './StationsSeriesTable';
import StationsFilter from './StationsFilter';
import EpisodesList from './EpisodesList';
import LoaderSimple from '../subcomponents/LoaderSimple';
import VideoSetEdit from '../Destinations/VideoSetEdit';
import SeriesEdit from '../Destinations/SeriesEdit';
import EditModal from '../subcomponents/EditModal';


import {
  API_DESTINATIONS_ASSIGNMENTS,
  API_STATIONS,
  API_STATIONS_SERIES_EDIT,
  API_VIDEOSET_ADDNEW,
  API_VIDEOSETBLOCK_ADDNEW,
  API_VIDEOSET_DUPLICATE,
  API_VIDEOSET_ASSIGNMENTS,
  API_STATIONS_FIND_SERIES
} from '../../js/Configuration';

import {
  apirequest,
  debounce,
  getAuthData,
  isAdmin
} from '../../js/Utilities';

import {
  saveSeasonEp
} from '../../actions/StationsUtils';

import './css/StationView.css';

class StationView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      loading: true,
      filtered: [],
      breadcrumbs: [{
        path: '/stations',
        label: 'Stations'
      }],
      meta: {},
      episodes: {
        open: false,
        list: [],
        meta: {
          id: 0,
          title: null
        },
        mode: 'view'
      },
      // Add new episode
      addnew: {
        setId: null,
        blockId: null,
        title: '',
        open: false,
        error: null,
        mode: 'episode',
        modaltitle: 'Add a new episode',
        labelText: 'Enter a name for this episode'
      },

      // Tacked on later, for adding a new series.
      addnewseries: {
        setId: null,
        blockId: null,
        title: '',
        open: false,
        error: null,
        mode: 'series',
        modaltitle: 'Add a new series',
        labelText: 'Enter a name for this series'
      },
      editseries: {
        open: false,
        error: null,
        value: '',
        mode: 'series_title',
        series_id: null
      }
    };

    this.filterSeries = debounce(250, this.filterSeries.bind(this));

    this.onViewEpisodes = this.onViewEpisodes.bind(this);
    this.onEpisodesClose = this.onEpisodesClose.bind(this);
    this.onEpisodeDuplicate = this.onEpisodeDuplicate.bind(this);
    this.onBatchEdit = this.onBatchEdit.bind(this);
    this.onBatchSave = this.onBatchSave.bind(this);
    this.onAddEpisode = this.onAddEpisode.bind(this);
    this.onAddEpisodeChange = this.onAddEpisodeChange.bind(this);
    this.onAddEpisodeClose = this.onAddEpisodeClose.bind(this);
    this.onAddEpisodeSave = this.onAddEpisodeSave.bind(this);
    this.onAddSeries = this.onAddSeries.bind(this);
    this.onAddSeriesSave = this.onAddSeriesSave.bind(this);
    this.onAddSeriesInput = this.onAddSeriesInput.bind(this);
    this.onAddSeriesClose = this.onAddSeriesClose.bind(this);
    this.onEditSeriesTitle = this.onEditSeriesTitle.bind(this);
    this.onEditSeriesTitleSave = this.onEditSeriesTitleSave.bind(this);
    this.onEditSeriesTitleInput = this.onEditSeriesTitleInput.bind(this);
    this.onEditSeriesTitleClose = this.onEditSeriesTitleClose.bind(this);

    this.makeEpisodesModal = this.makeEpisodesModal.bind(this);
    this.makeAddNewModal = this.makeAddNewModal.bind(this);
    this.isLoaded = this.isLoaded.bind(this);
    this.makeNewSeriesButton = this.makeNewSeriesButton.bind(this);

    this.updateSeriesTitleInList = this.updateSeriesTitleInList.bind(this);

    this.showCreatedEpisode = this.showCreatedEpisode.bind(this);
    this.getStationMeta = this.getStationMeta.bind(this);
    this.getSeries = this.getSeries.bind(this);

    this.showCreatedSeries = this.showCreatedSeries.bind(this);

    this.fd = new FormData(); // We'll reuse the FormData object in this component.

    this.isAdmin = isAdmin(this.props.project);
  }
  
  componentDidMount() {
    this.fd.set('token', getAuthData('token')); // Set the user token.
    this.getStationMeta();
  }

  getSeries() {
    const fd = this.fd;
    fd.set('id', this.props.params.stationID);
    fd.set('isStation', 1);

    apirequest(API_DESTINATIONS_ASSIGNMENTS, {body: fd}, (data) => {
      this.setState({
        list: data,
        filtered: data,
        loading: false
      })
    })
  }
  
  getStationMeta() {
    const fd = this.fd;

    fd.set('id', this.props.params.stationID);

    apirequest(API_STATIONS, {body: fd}, (data) => {
      if(!data.length) return;
      const slice = [{
        path: `/stations/${data[0].id}`,
        label: data[0].station
      }];

      this.setState({
        breadcrumbs: [...this.state.breadcrumbs].concat(slice),
        meta: data[0]
      }, this.getSeries)
    });
  }
 
  filterSeries(domEvent) {
    if(domEvent.target.value === '') {
      this.setState({filtered: [...this.state.list]});
      return;
    }
    
    const fd = this.fd;
    fd.set('keyword', domEvent.target.value);
    fd.set('station', this.props.params.stationID);

    apirequest(API_STATIONS_FIND_SERIES, {body: fd}, (data) => {
      this.setState({filtered: data});
    });
  }

  onViewEpisodes(domEvent) {
    const epState = {...this.state.episodes};

    epState.open = true;
    epState.meta = {
      id: domEvent.target.dataset.episode,
      title: domEvent.target.dataset.setname
    }

    this.setState({episodes: epState});
  }

  onEpisodesClose(domEvent) {
    const epState = {
      open: false,
      list: [],
      meta: {
        id: 0,
        title: null
      },
      mode: 'view'
    }
    this.setState({episodes: epState});
  }

  onEpisodeDuplicate(domEvent) {
    domEvent.preventDefault();

    const fd = this.fd;
    fd.set('id', domEvent.target.elements.id.value);

    apirequest(API_VIDEOSET_DUPLICATE, {body: fd}, (data) => {
      window.location = `/stations/episode/${data}`;
    });
  }

  onBatchSave(episodesList){
    if(!episodesList.length) return;

    const epState = {
      open: false,
      list: [],
      meta: {
        id: 0,
        title: null
      },
      mode: 'view'
    }

    episodesList.forEach((ep) => {
      saveSeasonEp(ep.vsb_id, ep.season, ep.episode, (data) => {
        this.setState({episodes: epState});
      });
    });
  }

  onBatchEdit(domEvent) {
    const epState = {...this.state.episodes};
    this.setState({
      episodes: Object.assign(epState, {mode: 'edit'})
    });
  }

  onAddEpisode(domEvent) {
     this.setState((state) => {
      return {
        ...state,
          addnew: {
            ...state.addnew,
            setId: domEvent.target.dataset.series,
            open: true
          }
      }
     });
  }

  onAddEpisodeChange(domEvent) {
    this.setState({
      addnew: {...this.state.addnew, title: domEvent.target.value}
    });
  }

  onAddEpisodeSave(domEvent) {
    const fd = this.fd;
    fd.set('setID', this.state.addnew.setId);
    fd.set('title', this.state.addnew.title);

    apirequest(API_VIDEOSETBLOCK_ADDNEW, {body: fd}, (data) => {
      if(data.result === 'success') {
        const addNewState = {
          setId: null,
          blockId: null,
          title: '',
          open: false,
          error: null
        };

        this.setState({addnew: addNewState });
        this.showCreatedEpisode(data.blockID);
      } else {
        alert(data.result);
      }
    });
  }

  onAddEpisodeClose(dialogRef) {
    this.setState((state) => {
      return {
        addnew: {
          ...this.state.addnew,
          setId: null,
          blockId: null,
          title: '',
          open: false
        }
      }
    }, () => dialogRef.current.close() );
  }

  onAddSeries(domEvent) {
    this.setState((state) => {
      return {
        ...state,
        addnewseries: {
          ...state.addnewseries,
          open: true,
          mode: 'series',
          setId: domEvent.target.dataset.series
        }
      }
    });
  }

  onAddSeriesInput(domEvent) {
    this.setState((state) => {
      return {
        addnewseries: {
          ...this.state.addnewseries,
          title: domEvent.target.value
        }
      }
    });
  }

  onAddSeriesSave(domEvent) {
    const fd = this.fd;

    fd.set('name', this.state.addnewseries.title);
    fd.set('destination', this.props.params.stationID);

    apirequest(API_VIDEOSET_ADDNEW, {body: fd}, (data) => {
      if(data.result === 'success') {
        const addNewState = {
          title: '',
          open: false,
          error: null
        };

        this.setState({addnewseries: addNewState });
        this.showCreatedSeries(data);
      } else {
        alert(data.result);
      }
    });
  }

  onAddSeriesClose(dialogRef) {
    this.setState((state) => {
      return {
        addnewseries: {
          ...state.addnewseries,
          title: '',
          open: false
        }
      };
    }, () => { dialogRef.current.close(); } );
  }

  onEditSeriesTitle(domEvent) {

    this.setState((state) => {
      const newstate = {
        ...state.editseries,
        open: true,
        series_id: +domEvent.target.dataset.episode,
        value: domEvent.target.dataset.setname
      };

      return {
        editseries: newstate
      }
    });
  }

  onEditSeriesTitleInput(domEvent) {
    this.setState((state) => {
      const newstate = {
        ...this.state.editseries,
        value: domEvent.target.value
      };
      
      return {editseries: newstate};
    });
  }

  onEditSeriesTitleClose(dialogRef) {
    this.setState((state) => {
      const combinedstate = {
        ...this.state.editseries,
        error: null,
        value: '',
        series_id: null,
        open: false
      };

      return { editseries: combinedstate }
    }, () => { dialogRef.current.close(); } );
  }


  updateSeriesTitleInList(id, title) {
    const newlist = [...this.state.list];
    // Find the current object to update...

    const current = newlist.findIndex((item) => item.setID == id);
    newlist[current].setName = title;
 
    return newlist;
  }

  onEditSeriesTitleSave() {
    const newlist = this.updateSeriesTitleInList(this.state.editseries.series_id, this.state.editseries.value);

    const fd = this.fd;
    fd.set('id', this.state.editseries.series_id);
    fd.set('title', this.state.editseries.value);

    apirequest(API_STATIONS_SERIES_EDIT, {body: fd}, (response) => {
      this.setState({
        list: newlist,
        editseries: Object.assign({...this.state.editseries}, {open: false})
      });
    });
  }

  showCreatedEpisode(episodeid) {
    window.location = `/stations/episode/${episodeid}`;
  }

  showCreatedSeries(data) {
    // Instead of redirecting to the /series/:id view, 
    // Let's go directly to the blank episode.
    const fd = this.fd;
    fd.set('id', data.setID);

    apirequest(API_VIDEOSET_ASSIGNMENTS, {body: fd}, (resp) => {
      window.location = `/stations/episode/${resp[0].vsb_id}`;
    });
  }

  makeEpisodesModal() {
    if(!this.state.episodes.open) return;

    return (
      <EpisodesList
        {...this.state.episodes}
        onClose={this.onEpisodesClose}
        breadcrumbs={this.state.breadcrumbs}
        onDuplicateEpisode={this.onEpisodeDuplicate}
        onBatchEdit={this.onBatchEdit}
        onBatchSave={this.onBatchSave} />
    );
  }

  makeAddNewEpisodeModal() {
    return (
      <VideoSetEdit
        title={this.state.addnew.modaltitle}
        labelText={this.state.addnew.labelText}
        open={this.state.addnew.open}
        value={this.state.addnew.title}
        onInput={this.onAddEpisodeChange}
        onSubmit={this.onAddEpisodeSave}
        onClose={this.onAddEpisodeClose}
        error={this.state.addnew.error} />
    );
  }

  makeAddNewModal() {
    return (
      <SeriesEdit
        {...this.state.addnewseries}
        onInput={this.onAddSeriesInput}
        onSubmit={this.onAddSeriesSave}
        onClose={this.onAddSeriesClose} />
    );
  }

  makeEditSeriesModal() {
    return (
      <EditModal id="stations-editseries"
        open={this.state.editseries.open}
        onSubmit={this.onEditSeriesTitleSave}
        onClose={this.onEditSeriesTitleClose}
        onInput={this.onEditSeriesTitleInput}
        apiURL="/"
        labelText="Edit a new name for this series"
        title="Change series title"
        value={this.state.editseries.value} />
    );
  }

  isLoaded() {
    if(this.state.loading) {
      return <LoaderSimple open={true} />;
    } else {
      return (
        <div>
          <StationsFilter onFilterType={this.filterSeries} />
          <StationsSeriesTable
            header={['Series', 'Actions']}
            rows={this.state.filtered}
            onViewEpisodes={this.onViewEpisodes}
            onAddEpisode={this.onAddEpisode}
            onEditSeriesTitle={this.onEditSeriesTitle} />
        </div>
      );
    }
  }

  makeNewSeriesButton() {
    let btn = null;
    if(this.isAdmin){ 
      btn = (
        <div>
          <button type="button" className="btn btn--action" onClick={this.onAddSeries}>Add New Series</button>
        </div>
      );
    }
    return btn;
  }

  render() {

    return (
      <div className="stations__view stations__view--withbreadcrumb">
        <header>
          <Breadcrumb items={this.state.breadcrumbs} />
          <div>
            <h1>Series for <b>{this.state.meta.station}</b></h1>
            {this.makeNewSeriesButton()}
          </div>
        </header>
        {this.isLoaded()}
        {this.makeEpisodesModal()}
        {this.makeAddNewEpisodeModal()}
        {this.makeAddNewModal()}
        {this.makeEditSeriesModal()}
      </div>
    ); 
  }
}

export default (props) => (
    <StationView
        {...props}
        params={useParams()}
    />
);

