import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { CloseButton } from '../subcomponents/CloseButton';

import TextInput from '../subcomponents/TextInput';

import { API_VIDEOSET_ADDNEW } from '../../js/Configuration';
import { getAuthData } from '../../js/Utilities';

export const DestinationsRename = (props) => {
  const destRename = useRef();
  
  if(props.open && destRename.hasOwnProperty('current') ) {
    destRename.current.showModal();
  }

  const onSubmit = (domEvent) => { 
    if(props.onSubmit) props.onSubmit(domEvent, destRename);
  }

  const onClose = () => { if(props.onClose) props.onClose(destRename); }

  return (
    <dialog ref={destRename} id="DestinationsRename" onClose={() => { props.onClose(destRename) }}>
      <CloseButton onClick={onClose} longForm={true} />
      <h2>Rename <q>{props.destination.dName}</q></h2>
      <form onSubmit={onSubmit} id="destination_create_vidset" action={API_VIDEOSET_ADDNEW} method="POST">
        <input type="hidden" name="token" value={getAuthData('token')} />
        <input type="hidden" name="destination" value={props.destination.dID || ''} onChange={() => {}} />
        <div className="form__label_btn_group">
        <TextInput
          cssClass="destinations__create-new"
          labelText="Enter the new name for this destination"
          name="name"
          id="create"
          maxLength="50"
          onInputHandler={props.onChange}
          value={props.value} />
          <button type="submit" className="btn btn--action">Save</button>
        </div>
      </form>
    </dialog>
  );
  
  
}

DestinationsRename.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};


