import React from 'react';
import PropTypes from 'prop-types';

export default class SearchMoreOptions extends React.Component {
  render() {
    const txt = this.props.open ? 'Hide advanced options' : 'Show advanced options';
    return <button onClick={this.props.onClick} type="button" className="trigger__fauxlink">{txt}</button>;
  }
}

SearchMoreOptions.propTypes = {
  onClick: PropTypes.func.isRequired
}