/**
 * EpisodeMoveModal.js
 * Displays playlist URL and comments form fields.
 */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SelectMenu from '../subcomponents/SelectMenu';
import { CloseButton } from '../subcomponents/CloseButton';

import {
  apirequest,
  getAuthData
} from '../../js/Utilities';

import './css/EpisodeMoveModal.css';

export default class EpisodeMoveModal extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);

    
    this.epMoveModal = createRef();
  }

  makeOptions(item) {
    return {
      label: item.setName,
      value: item.setID
    }
  }
  
  onClose() {
    this.props.onClose(this.epMoveModal)
  }
 
  componentDidUpdate() {
    if( this.props.open ) {
      this.epMoveModal.current.showModal()
    }
  }

  onChange(domEvent) {
    if(!this.props.onChange) return;
    this.props.onChange(domEvent);
  }
  
  onSubmit(domEvent) {
    domEvent.preventDefault();
    domEvent.persist();
    this.props.onSubmit(domEvent);
    this.onClose()
  }

  render() {
    const cssClass = classNames({
      'stations__move__ep': true,
    }, this.props.cssClass);
        
    return (
      <dialog id="EpisodeMoveModal" ref={this.epMoveModal} className={cssClass}>
        <CloseButton title="Close" longForm={true} onClick={this.onClose} />
        <h2>Move this episode</h2>
        <form id="EpisodeMoveModal__menu" action="/" method="dialog" onSubmit={this.onSubmit}>
          <div>
            <SelectMenu
              labelText="Select a new series"
              name="stations_move_ep_list"
              onChangeHandler={this.onChange}
              options={this.props.series}
              size="20"
              firstEmpty={false}/>

          </div>
          <p>
            <button type="submit" className="btn btn--action">Save</button>
            <button type="reset" className="btn btn--light">Reset</button>
          </p>
        </form>
      </dialog>
    );
  }
}

 
EpisodeMoveModal.defaultProps = {
  open: false,
  block: null
}

EpisodeMoveModal.propTypes = {
  open: PropTypes.bool.isRequired,
  block: PropTypes.any, // For now, this can be an object that contains the block ID/vsb_id.
  cssClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
