export const setPageState = (state, value) => {
  let process;

  switch(value) {
    case 'first':
      process = {
        ...state,
        pagination: {...state.pagination,  page: 1}
      }
      break;
    case 'previous':
      process = {
        ...state,
        pagination: {...state.pagination,  page: +state.pagination.page - 1}
      }
      break;
    case 'next':
      process = {
        ...state,
        pagination: {...state.pagination,  page: +state.pagination.page + 1}
      }   
      break;
    case 'last':
      process = {
        ...state,
        pagination: {...state.pagination,  page: state.pagination.pages}
      }
      break;        
    default:
      process = {
        ...state,
        pagination: {...state.pagination,  page: +value}
      }
  }
  return process;
}

