/**
 * AmzSongAddSongsSave.js
 */
import React from 'react';
import uniqueId from 'lodash.uniqueid';

import { MESSAGE_ERROR_TOO_MANY_URLS } from '../../js/Configuration';
import { NoImageImg } from '../subcomponents/NoImageImg';

import '../subcomponents/css/fancy-checkboxes.css';

export default class AmzSongAddSongsSave extends React.Component {
  constructor(props) {
    super(props);
    this.makeHeader = this.makeHeader.bind(this);
    this.makeRow = this.makeRow.bind(this);
  }
  
  makeHeader() {
    return (
      <thead>
        <tr>
          <th scope="col" className="asin__table_order">Order</th>
          <th colSpan="2" scope="col">Title</th>
          <th scope="col">ASIN</th>
          <th scope="col">Artist</th>
          <th scope="col">Album</th>
          <th scope="col">
            Confirm<br />
            <span className="table__note">Uncheck to exclude</span>
          </th>
        </tr>
      </thead>
    );      
  }

  makeRow(obj){
    let artist_name, album_title, cover_img;

    // Property can exist, but be empty, so we need to check for both.
    if(!obj.hasOwnProperty('artist')) {
      artist_name = 'No Artist Info';
    } else {
      artist_name = obj.artist.name ? obj.artist.name : 'No Artist Info';
    }

    if(obj.hasOwnProperty('album')) {
      album_title = obj.album.title ? obj.album.title : 'No Album Info';
    }

    if(obj.hasOwnProperty('imgpath')) {
      cover_img = obj.imgpath ? <img src={obj.imgpath} alt={`"${obj.title}"" cover`} className="asin__thumb" /> : <NoImageImg height="75px" width="75px" />;
    } else {
      cover_img = <NoImageImg height="75px" width="75px" />;
    }

    return (
      <tr key={uniqueId()}>
        <td>
          <input size="4" type="number" id={`order[${obj.asin}]`}
            name={`order[${obj.asin}]`} onChange={this.props.onOrderChange}
            value={obj.ordinal} min="-1" max="125" step="0.5" />
        </td>
        <td>{cover_img}</td>
        <td><label htmlFor={`order[${obj.asin}]`}>{obj.title || 'No Title Info'}</label></td>
        <td>{obj.asin}</td>
        <td>{artist_name}</td>
        <td>{album_title}</td>
        <td>
          <span className="fancy__chex">
            <input type="checkbox" id={obj.asin} name={obj.asin} onChange={this.props.onExcludeToggle} checked={obj.include} />
            <label htmlFor={obj.asin} className="asin__label">
              <span className="form__access__label">{obj.asin}</span>
            </label>
          </span>
        </td>
      </tr>
    );      
  }
  
  render() {
    const sty = {'textAlign': 'right'};

    return (
      <form id="AmzSongAddSongs__save" onSubmit={this.props.onSave}>
        <div className="button__group" style={sty}>
          <button type="submit" className="btn btn--action">Save</button>
          <button type="button" className="btn btn--light" onClick={this.props.onClose}>Cancel</button>
        </div>
        <table className="asin__table_add">
          {this.makeHeader()}
          <tbody>
          {this.props.data.map(this.makeRow)}
          </tbody>
        </table>
        <div className="button__group" style={sty}>
          <button type="submit" className="btn btn--action">Save</button>
          <button type="button" className="btn btn--light" onClick={this.props.onClose}>Cancel</button>
        </div>
      </form>
    );

  }
}
    
