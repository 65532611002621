/*
DestinationsUtils.js

Functions that are shared between multiple views in Destinations and/or Stations
*/

import {
  apirequest,
  getAuthData,
  getUTCNormalizedDate
} from '../js/Utilities';

import { format } from 'date-fns';

import {
  API_DESTINATIONS_LIST,
  API_DESTINATIONS_ASSIGNMENTS,
  API_DESTINATION_DELETE,
  API_RATINGS_ALL,
  API_SERIES,
  API_VIDEOSET_DUPLICATE,
  API_VIDEOSET_ASSIGNMENTS,
  API_VIDEOSET_DELETE,
  API_VIDEOSET_RESTORE,
  MESSAGE_CLIPBOARD_OK,
  MESSAGE_CLIPBOARD_FAILED,
  MESSAGE_ERROR_GENERAL
} from '../js/Configuration';


export const playAllVideos = (videolist = []) => {
  const videoIds = videolist.map(( video ) => {
    return video.ytID;
  });
  window.open(`https://www.youtube.com/watch_videos?video_ids=${videoIds.join( ',' )}`, '_blank');
}

export const exportVideoData = (videolist = []) => {
  let items = videolist.map(item => item.ytID);

  let textArea = document.createElement( "textarea" );
  textArea.style.position = 'absolute';
  textArea.style.left = '-9999em';
  textArea.style.top = '0px';  
  textArea.value = items; 

  document.body.appendChild( textArea ); 

  textArea.select();

  try {
    let successful = document.execCommand( 'copy' );

    if(successful) {
      window.alert(MESSAGE_CLIPBOARD_OK);
    } else {
      window.alert(MESSAGE_CLIPBOARD_FAILED);
    }
  } catch (err) {
    window.alert(MESSAGE_CLIPBOARD_FAILED);
  }

  document.body.removeChild(textArea);
  textArea = null;
}

export const duplicatePlaylist = (setblockid, callback = null) => {
  const fd = new FormData();
  fd.set('id', +setblockid);
  fd.set('token', getAuthData('token'));
  apirequest(API_VIDEOSET_DUPLICATE, {body: fd}, callback);
}

export const tooManyURLs = (string, max=50) => {
  return string.split(/[,\s]/).length > max;
}

export const getDestinations = (callback = null, withstations = false, inactives = false) => {
  const fd = new FormData();
  fd.set('token', getAuthData('token'));
  if(withstations) {
    fd.set('withstations', 1);
  }
  if(inactives) {
    fd.set('inactives', 1);
  }
  apirequest(API_DESTINATIONS_LIST, {body: fd}, callback);
}

export const getRatings = (callback = null) => {
  const fd = new FormData();
  fd.set('token', getAuthData('token'));
  apirequest(API_RATINGS_ALL, {body: fd}, callback);
}

export const getVideoSets = (destid, callback = null) => {
  const fd = new FormData();
  fd.set('id', destid);
  fd.set('token', getAuthData('token'));
  apirequest(API_DESTINATIONS_ASSIGNMENTS, {body: fd}, callback);
}

export const getRunDates = (setid, callback = null) => {
  const fd = new FormData();
  fd.set('id', setid);
  fd.set('token', getAuthData('token'));
  apirequest(API_VIDEOSET_ASSIGNMENTS, {body: fd}, callback);
}

export const deleteDestination = (destinationId, callback = null) => {
  const fd = new FormData();
  fd.set('id', destinationId);
  fd.set('token', getAuthData('token'));
  apirequest(API_DESTINATION_DELETE, {body: fd}, callback);
}

export const getVideoSetBreadcrumbs = (videoSetId, callback = null) => {
  const fd = new FormData();
  fd.set('id', videoSetId);
  fd.set('token', getAuthData('token'));
  apirequest(API_SERIES, {body: fd}, callback);
}

export const restoreVideoSet = (setId, callback = null) => {
  const fd = new FormData();
  fd.set('id', setId);
  fd.set('token', getAuthData('token'));
  apirequest(API_VIDEOSET_RESTORE, {body: fd}, callback);
}

export const deleteVideoSet = (setId, callback = null) => {
  const fd = new FormData();
  fd.set('id', setId);
  fd.set('token', getAuthData('token'));
  apirequest(API_VIDEOSET_DELETE, {body: fd}, callback);
}

export const setDestinationsLoadedState = (state, response) => {
  return {
    destinations: {
      ...state.destinations,
      list: response
    },
    loading: false
  }
}

export const setSearchLoadingState = (state) => {
  return {
    search: {
      ...state.search,
      loading: true
    }
  }
}

export const setSearchState = (state, value) => {
  return {
    search: {
      ...state.search,
      keyword: value,
      results: value === '' ? [] : state.search.results
    },
    episodes: []
  };
}

export const setSearchResetState = () => {
  return {
    search: {
      loading: false,
      active: false,
      results: [],
      keyword: ''
    }
  }
}

export const setSearchResponseState = (state, response) => {
  return {
    search: {
      ...state.search,
      active: true,
      results: response,
      loading: false
    }
  }
}

export const setResetEpisodeState = (state) => {
  return {
    episodes: []
  };
}

export const setStatePostCreateDestination = (state, response) => {
  const new_destination = [{
    dID: response.d_id,
    dName: response.d_name,
    isNew: true
  }];

 return {
    create: {
      ...state.create,
      open: false,
    },
    destinations: {
      ...state.destinations,
      list: new_destination.concat([...state.destinations.list]),
    }
  } 
}

export const setStateCloseCreateDestModal = (state) => {
  return {
    create: {
      open: false,
      value: ''
    }
  };
}

export const setStateCreateVidsetClose = (state) => {
  return { 
    videoset: {
      ...state.videoset,
      open: false, value: '',
      destination: {
        dID: '',
        dName: ''
      }
    }
  }
}

export const setStateOnCloseMessage = (state) => {
  return {
    remove: {
      ...state.remove,
      hidden: true,
      message: '',
      mode: 'success'
    }
  };
}

export const setStateOnCreateChange = (state, form, input) => {
  let key;
  let current;

  switch(form) {
    case 'destination_create_vidset':
      key = 'videoset';
      current = { ...state.videoset, value: input };
      break;
    default:
      key = 'create';
      current = { ...state.create, value: input };
  }
  return {[key]: current};
}

export const setStateOnTogglePanel = (state, which) => {
  const update = {...state.destinations};
  update.setList[which] = {...update.setList[which], open: true, loading: true};
  return {
    destination: update
  };
}

export const setStatePostTogglePanel = (state, index, data) => {
  const update = {...state.destination};
 
  update.setList[index] = {
    ...update.setList[index],
    loading: false,
    blockList: data
  };

  return {
    destination: update
  };
}

export const setStateGetDestInfo = (state, response) => {
  return {
    destinations: Object.assign({...state.destinations}, response),
    loading: false,
    pagination: Object.assign({...state.pagination}, response.sets)
  }
}

export const setStateOnPerPage = (state, value) => {
  return {
    pagination: {
      ...state.pagination,
      perPage: value     
    }
  }
}

export const setLoading = (state) => {
  return {
    loading: true
  }
}

export const setStateOnCreateVidsetClose = (state) => {
  return {
    create: {
      ...state.create,
      open: false,
      destination: {
        dID: '',
        dName: ''
      },
      value: '',
    }
  }
}

export const setStateOnCreateVidsetFailure = (state, response) => {
  return {
    create: {
      ...state.create,
      open: false,
      value: '',
      destination: {dName: '', dID: ''}
    },
    postCreate: {
      hidden: false,
      message: response.message,
      mode: 'failed'
    }
  }
}

export const setStateOnCreateVidsetOpen = (state, destID) => {
  return {
    create: {
      ...state.create,
      open: true,
      destination: {
        dID: destID,
        dName: state.destinations.dName
      }
    }
  }
}

export const setStateDVSOnCreateChange = (state, targetID, valu) => {
  return {
    [targetID]: {...state[targetID], value: valu}
  }
}

export const setStateOnEditVidsetOpen = (state, videoset, setname) => {
  return {
    edit: {
      ...state.edit,
      open: true,
      item: videoset,
      value: setname
    }
  }
}

export const setStateOnEditVidsetClose = (state) => {
  return {
    edit: {
      ...state.edit,
      open: false,
      item: '',
      value: ''
    }
  }
}

export const setStateOnSortChange = (state, value) => {
  return {
    pagination: {
      ...state.pagination,
      sort: +value,
      ascending: +(value == 0) // Value == 0 will be true/false. The + converts it to a boolean
    }
  }
}

// Move an existing blockList to the newly ordered set.
export const mergeExistingSetList = (currentSetList = [], responseSetList = []) => {
  const updated = currentSetList.map((c) => {
    let set_ = c;
    const itemToMerge = responseSetList.find((s) => s.setID == c.setID);
    if(itemToMerge) {
      set_ = Object.assign(itemToMerge, c);
    }
    return set_;
  });
}

export const setStateGetInteractives = (state, value) => {
  return {
    destinations: {
      ...state.destinations,
      withinactives: +value
    }
  }
}

/* @action will be either create or videoset to match state. */
export const setStateOnCreateOpen = (state, action, dest = null) => {
  /*
    Note that this will add a create.destination property in state.
    That should not cause problems, but do note that there this property will
    be there.
  */
  return {
    [action]: {
      ...state[action],
      open: true,
      destination: dest
    }
  }
}

export const findDestinationById = (destinationsList = [], id = null) => {
  if(!id) return false;

  return destinationsList.find((dest) => {
    return +dest.dID === +id;
  });
}

export const setStateOnRenameOpen = (state, dest = {}) => {
  return {
    destinations: {
      ...state.destinations,
      renaming: {
        ...state.destinations.renaming,
        open: true,
        destination: {...dest}
      }
    }
  }
}

export const setStateOnRenameClose = (state, dest = {}) => {
  return {
    destinations: {
      ...state.destinations,
      renaming: {
        ...state.destinations.renaming,
        open: false,
        destination: {
          dID: '',
          dName: ''
        }
      }
    }
  }
}

export const setStateOnRenameChange = (state, input) => {
  return {
    destinations: {
      ...state.destinations,
      renaming: {
        ...state.destinations.renaming,
        value: input
      }
    }
  }
}


export const setStateAfterRenameChange = (state, response) => {
  let result = state;

  if(response.result) {
    let updates = [...state.destinations.list];
    let index = updates.findIndex((d) => +d.dID === +response.id);
    updates[index]['dName'] = response.destination;

    result = {
      ...state,
      destinations: {
        ...state.destinations,
        list: updates,
        renaming: {
          open: false,
          destination: '',
          value: ''
        }
      }
    }
  }
  return result;
}

export const setStateOnVideoSetSelect = (state, response) => {

  if( response.result !== 'success') {
    alert(response.message);
    return false;
  }

  return {
    videosets: {
      ...state.videosets,
      mode: 'select',
      value: `${response.setID}`,
      options: [
        ...state.videosets.options,
        {
          label: response.setName,
          value: `${response.setID}` // convert to a string for consistency with other objects
        }
      ] 
    }
  }
}

export const setStateOnDestinationSelect = (state, value) => {
  return {
    destinations: {
      ...state.destinations,
      value: value.value,
      mode: (value.value === 'create') ? 'create' : 'select'
    }
  }; 
}

export const setStateOnDateChange = ( state, date = null ) => {
  let dateStr = '';

  if( date ) {
    const utcDate = getUTCNormalizedDate( date );
    dateStr = format( new Date( utcDate ), 'yyyy-MM-dd');
  }

  return {
    currentVideoBlock: {
      ...state.currentVideoBlock,
      vsb_datetimetorun: dateStr
    },
  }
}

