import React from 'react';

import LoaderSimple from './subcomponents/LoaderSimple';

import FilterComponent from './Videos/FilterComponent';
import NextPrevious from './Search/NextPrevious';

import ChannelItem from './Channels/ChannelItem';
import AddChannelsByURL from './Channels/AddChannelsByURL';

import '../css/Channels.css';

import {
  apirequest,
  debounce,
  getAuthData,
  getSortDirection,
} from '../js/Utilities';

import {
  stringToArray,
  setStateOnGetBatch,
  setStateOnSortDirection,
  setStateOnFilterClear,
  setStateOnShowAddChannel,
  setStateOnAddChannelResponse,
  setStateOnCloseAddChannel,
  setStateOnSubmitAddChannel,
  setStateOnAddChannelChange,
  setStateOnGroupsResponse,
  setStateOnFilterChange,
} from '../actions/ChannelsUtils';


import {
  CHANNELS_PER_PAGE,
  API_CHANNEL_BATCH,
  API_CHANNEL_ADD_TO_SYSTEM,
  API_GROUPS_ALLGROUPS
} from '../js/Configuration';

export default class Channels extends React.Component {
  constructor( _props ) {
    super( _props );

    this.state = {
      channelList: [],
      isLoading: true,
      perPage: CHANNELS_PER_PAGE,
      page: 1,
      pageCount: 1,
      numResults: 0,
      sortBy: 'channel_title',
      ascending: true,
      filter: {
        filterOn: 'channel_title',
        hideCategories: true,
        itemsName: 'Channels',
        searchValue: '',
      },
      addChannels: {
        open: false,
        channels: '', // Input string
        group: '',
        options: [],
        mode: 'adding', // Should be either "adding", "working", "error," or "response"
        max: false,
        error: false,
        response: new Map()
      }
    };

    this.getGroups = this.getGroups.bind(this);
    this.getChannelBatch = this.getChannelBatch.bind(this);
    this.getGroups = this.getGroups.bind(this);

    this.getNextPage = this.getNextPage.bind(this);
    this.getPrevPage = this.getPrevPage.bind(this);

    this.onFilterChangeHandler = this.onFilterChangeHandler.bind(this);
    this.onSortChangeHandler = this.onSortChangeHandler.bind(this);
    this.onFilterClearHandler = this.onFilterClearHandler.bind(this);
    this.onDirectionChangeHandler = this.onDirectionChangeHandler.bind(this);

    this.onGroupsResponse = this.onGroupsResponse.bind(this);

    this.onAddChannelsChange = this.onAddChannelsChange.bind(this);
    this.onShowAddChannel = this.onShowAddChannel.bind(this);
    this.onCloseAddChannel = this.onCloseAddChannel.bind(this);
    this.onSubmitAddChannel = this.onSubmitAddChannel.bind(this);
    this.onAddChannelErrorClose = this.onAddChannelErrorClose.bind(this);

    this.addChannel = this.addChannel.bind(this);
    this.onSubmitAddChannelResponse = this.onSubmitAddChannelResponse.bind(this);
    this.makePageCount = this.makePageCount.bind(this);
  }

  componentDidMount() {
    this.getChannelBatch();
  }

  getChannelBatch() {
    const fd = new FormData();
    fd.set('perpage', this.state.perPage);
    fd.set('page', this.state.page);
    fd.set('sort_by', this.state.sortBy);
    fd.set('filter_by', this.state.filter.searchValue);
    fd.set('filter_on', this.state.filter.filterOn);
    fd.set('order', getSortDirection(this.state.ascending));
    fd.set('token', getAuthData('token'));

    apirequest(API_CHANNEL_BATCH, {body: fd}, (data) => {
      this.setState((state) => setStateOnGetBatch(state, data));
    });
  }

  getGroups() {
    if( this.state.addChannels.options.length ) return;
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_GROUPS_ALLGROUPS, {body: fd}, this.onGroupsResponse);
  }

  onGroupsResponse(data) {
    this.setState((state) => setStateOnGroupsResponse( state, data ), this.setFocus);
  }

  onFilterChangeHandler(domEvent) {
    const target = domEvent.target;
    this.setState((state) => setStateOnFilterChange( state, target ), this.getChannelBatch);
  }

  onSortChangeHandler(domEvent) {
    const sort_criteria = domEvent.target.value.split('|')[0];
    const newState = {
      sortBy: sort_criteria,
      page: 1
    }
    this.setState(newState, this.getChannelBatch);
  }

  onDirectionChangeHandler(domEvent) {
    this.setState(setStateOnSortDirection, this.getChannelBatch);
  }

  onFilterClearHandler(domEvent) {
    this.setState(setStateOnFilterClear, this.getChannelBatch);
  }

  onShowAddChannel(domEvent) {
    this.setState(setStateOnShowAddChannel, this.getGroups);
  }

  onCloseAddChannel(dialogRef=null) {

    const callback = () => {
      if(dialogRef && dialogRef.hasOwnProperty('current')) {
        dialogRef.current.close();
      }
    }
    this.setState(setStateOnCloseAddChannel, callback);
  }

  onSubmitAddChannel(domEvent) {
    domEvent.preventDefault();
    this.setState(setStateOnSubmitAddChannel, this.addChannel)
  }

  onSubmitAddChannelResponse(response) {
    this.setState( (state) => setStateOnAddChannelResponse(state, response) );
  }

  addChannel() {
    const fd = new FormData();
    fd.set('groupid', this.state.addChannels.group);
    fd.set('token', getAuthData('token'));

    const channels = stringToArray( this.state.addChannels.channels ).filter((ch) => ch !== '');
    channels.forEach( ( ch ) => {
      fd.set( 'id', ch ); // this.onSubmitAddChannelResponse
      apirequest(API_CHANNEL_ADD_TO_SYSTEM, {body: fd}, this.onSubmitAddChannelResponse);
    });
  }

  onAddChannelErrorClose() {
    this.setState((state) => {
      return state.addChannels.error = '';
    });
  }

  getPagingComp() {
    let props = {};
    if( +this.state.page > 1 ) {
      props.prev_page = +this.state.page > 1;
    }

    return (
      <NextPrevious
        {...props}
        next={this.getNextPage}
        prev={this.getPrevPage}
        per_page={+this.state.perPage}
        result_count={this.state.numResults}
        className="channels__pagination" />
    );
  }

  getNextPage( ) {
    this.setState((prevState, props) => {
      const page = +prevState.page;
      const count = +prevState.pageCount;

      // If page is greater than count, clamp to the value of count. Otherwise increment.
      const nextPage = ( page > count) ? count : page + 1;

      return {
        page: +nextPage,
        isLoading: true
      };
    }, this.getChannelBatch);
  }

  getPrevPage() {
    this.setState((prevState, props) => {
      const page =  +prevState.page;

      // If page is greater than count, clamp to 1. Otherwise decrement.
      const prevPage = ( page <= 0) ? 1 : page - 1;

      return {
        page: +prevPage,
        isLoading: true
      };

    }, this.getChannelBatch);
  }

  getCurrentGroupFromId(id) {
    return this.state.groupsOptions.find((item) => item.value == id);
  }

  /* When coming from SelectMenu, this may be a numeric value, not an Event object */
  onAddChannelsChange(domEvent) {
    const name = domEvent.target.name;
    const value = domEvent.target.value;
    this.setState((state) => setStateOnAddChannelChange( state, name, value ));
  }

  makePageCount() {
    let pgCount;
    if(this.state.channelList.length) {
      pgCount = `Page ${this.state.page} of ${this.state.pageCount} (${this.state.numResults} results)`;
    } else {
      pgCount = `Page – of – (0 results)`;
    }
    return <span className="channels__pgcount">{ pgCount }</span>;
  }

  render() {
    let channels;

    if( this.state.isLoading ) {
      channels = <LoaderSimple open={this.state.isLoading } />;
    } else {
      const items = this.state.channelList.map( (item, i) => {
        return (
          <ChannelItem key={i}
            channelData={ item }
            groupsList={this.state.groupsOptions} />
          );
      });

      channels = (
        <div className="video__display video__display--grid channels__list">
          <div className="List">{ items }</div>
        </div>
      );
    }

    return (
      <div className="ava__view__wrap">
        <FilterComponent
          { ...this.state.filter }
          itemsName="Channels"
          sortDefault={ this.state.sortBy }
          ascending={ this.state.ascending }
          sortData={[
            { value:'channel_title', name:'Sort by Name', type:'string' },
            { value:'channel_videocount', name:'Sort by Number of Videos', type:'num' },
            { value:'channel_dateadded', name:'Sort by Date Published', type:'num' },
            { value:'channel_datelastupdated', name:'Sort by Last Updated', type:'num' },
            { value:'curated_video_count', name:'Number of Curated Videos', type:'num' },
            { value:'channel_subscribercount', name:'Number of Subscribers', type:'num' },
            { value:'channel_category', name:'Sort by Category/Topic', type:'string' },
          ]}
          filterData={[
            { value:'channel_title', name:'Channel Title', type:'string'},
            { value:'channel_category', name:'Category/Topic', type:'string' },
            { value:'channel_keywords', name:'Keywords', type:'string' },
            { value:'channel_customtags', name:'Tags', type:'string' }
          ]}
          onSortChange={this.onSortChangeHandler}
          onFilterChange={this.onFilterChangeHandler}
          onFilterClear={this.onFilterClearHandler}
          onDirectionChange={this.onDirectionChangeHandler}
        />

        <button type="button" className="btn btn--action" onClick={this.onShowAddChannel}><b>Add Channels</b></button>

        <div className="channels__pagination_bar">
          { this.makePageCount() }
          { this.getPagingComp() }
        </div>

        { channels }
        { this.getPagingComp() }
        <AddChannelsByURL
          {...this.state.addChannels}
          onChange={this.onAddChannelsChange}
          onClose={this.onCloseAddChannel}
          onSubmit={this.onSubmitAddChannel}
          onErrorClose={this.onAddChannelErrorClose} />
     </div>
    )
  }
}
