/**
 * ToolsDurationMode.js
 * Enables users to update their own account page.
 */

import React from 'react';
import { ToolsAddToGoogleSheet } from './ToolsAddToGoogleSheet';
import { ToolsImportAnother } from './ToolsImportAnother';
import { ToolsSpreadsheetUpdated } from './ToolsSpreadsheetUpdated';
import { ToolsSpreadsheetFailed } from './ToolsSpreadsheetFailed';
import { ToolsSheetLoading } from './ToolsSheetLoading';


import {
  API_IMPORT_SPREADSHEET_FINDRANGES,
  MESSAGE_ERROR_GENERAL
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData,
  numberFormat,
  percentage,
  precision
}  from '../../js/Utilities';

import {
  download,
  setSpreadsheetData
}  from '../../actions/ToolsUtils';

// import '../css/ToolsDurationMode.css';
import '../subcomponents/css/fancy-radiobuttons.css';

export default class ToolsDurationMode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      results: {
        count: 0
      },
      mode: 'importing', // 'importing', 'imported', or 'pushed'
      exporting: {
        loading: false
      }
    }

    this.fetchCancel = new AbortController();

    this.requestModeRanges = this.requestModeRanges.bind(this);

    this.setData = this.setData.bind(this);
    this.makeResponseDisplay = this.makeResponseDisplay.bind(this);
    this.prepareData = this.prepareData.bind(this);
    this.ddownload = this.ddownload.bind(this);
  }

  componentDidMount() {
    this.setState({loading: true, mode: 'importing'}, this.requestModeRanges);
  }

  componentWillUnmount() {
    this.fetchCancel.abort();
  }

  requestModeRanges() {
    const signal = this.fetchCancel.signal;
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    fd.set('url', `https://docs.google.com/spreadsheets/d/${this.props.match.params.spreadsheetID}/`);
    fd.set('sheet', this.props.match.params.sheetName);

    apirequest(API_IMPORT_SPREADSHEET_FINDRANGES, {body: fd, signal}, (response) => {
      if(response.status != 200) { 
        this.setState({mode: 'failed'});
        return;
      }

      const reslts = Object.assign({...this.state.results}, response.results);
      const updatestate = {
        results: reslts,
        mode: 'imported',
        loading: false
      }
      this.setState(updatestate);
    });
  }

  prepareData() {
    const bands = Object.keys(this.state.results)
    .filter(b => b !== 'count')
    .map((b) => [`${b} minutes`, this.state.results[b], `${ precision(percentage(+this.state.results.count, +this.state.results[b]), 2)}%`]);

    let data = [
      [`Statistics for ${this.props.match.params.spreadsheetID} (${this.props.match.params.sheetName})`],
      [''], // empty line
      ['Number of videos', this.state.results.count],
      [''], // empty line
      ['Range','Number of videos','Percentage']
    ];
    return data.concat(bands)
  }

  ddownload() {
    const dta = this.prepareData();
    download(dta, this.props.match.params.spreadsheetID, this.props.match.params.sheetName);
  }

  makeResponseDisplay() {
    const bands = Object.keys(this.state.results).filter(key => key !== 'count');
    const results = bands.map((b, ind) => {
      let pctg = percentage(+this.state.results.count, +this.state.results[b]);
      return <li key={ind}><b>{b} minutes:</b> {this.state.results[b]} videos or {precision(pctg, 2)}%</li>;
    });

    return (
      <div className="tools__imported">
        <h1>Video count by duration for {this.props.match.params.sheetName}</h1>

        <ul>
          <li><b>Videos in sheet:</b> {numberFormat(this.state.results.count)}</li>
          {results}
        </ul>

        <p className="tools__imported__controls">
          <button type="button" onClick={this.ddownload} className="btn btn--action">Download Statistics</button>
          <ToolsAddToGoogleSheet onClick={this.setData} loading={this.state.exporting.loading} />
          <ToolsImportAnother />
        </p>
      </div>
    );
  }

  setData () {
    const data = this.prepareData();

    const cb = (response) => {
      if(response.spreadsheetId) {
        this.setState({exporting: {loading: false}, mode: 'pushed'});
      } else {
        alert(MESSAGE_ERROR_GENERAL);
      }
    };
    setSpreadsheetData(data, cb, this.props.match.params.spreadsheetID, this.props.match.params.sheetName, null, 'durations');
  }

  render() {
    let body;

    switch(this.state.mode) {
      case 'importing':
        body = <ToolsSheetLoading />;
        break;
      case 'failed':
        body = <ToolsSpreadsheetFailed />;
        break;
      case 'imported':
        body = this.makeResponseDisplay();
        break;
      case 'pushed':
        body = <ToolsSpreadsheetUpdated spreadsheetID={this.props.match.params.spreadsheetID} />
        break;
    }
    return body;
  }
}
