/**
 * VideoList.js
 * Displays a list of available videos to add.
 * Used in DestinationsPlaylist.js
 */

import React, { createRef } from 'react';
import { CloseButton } from './subcomponents/CloseButton';

import VideoListTable from './subcomponents/VideoListTable';
import LoaderSimple from './subcomponents/LoaderSimple';
import Pagination from './subcomponents/Pagination';
import SelectPerPage from './subcomponents/SelectPerPage';
import FilterComponent from './Videos/FilterComponent';
import ToggleSwitch from './subcomponents/ToggleSwitch';

import {
  API_VIDEOSET_REINIT,
} from '../js/Configuration';

export default class VideosList extends React.Component {
  constructor(_props) {
    super(_props);

    this.vlModal = createRef();

    this.state = {
      selectedVideos: [],
      selectAll: false,
      tableHeaders: [
        { name: '', sortable: false },
        { name: 'Occurrences', sortable: false },
        { name: 'Video Name', sortable: false },
        { name: 'Channel Name', sortable: false },
        { name: 'Duration', sortable: false },
        { name: 'Video Date', sortable: false },
      ],
    }

    this.submitVideos = this.submitVideos.bind(this);
    this.close = this.close.bind(this);

    this.changeHandler = this.changeHandler.bind(this);
    this.makeTableData = this.makeTableData.bind(this);
    this.makeVideoSearch = this.makeVideoSearch.bind(this);

    this.setIndeterminate = this.setIndeterminate.bind(this);
    this.makeToggle = this.makeToggle.bind(this);

    this.isSelected = this.isSelected.bind(this)
  }

  componentDidUpdate() {
    const { open } = this.props;

    if (open) {
      this.vlModal.current.showModal()
    }
  }

  setIndeterminate() {
    this.setState({ selectAll: !this.state.selectAll });
  }

  makeToggle() {
    let toggle = null;
    if (this.props.toggle) {
      toggle = (
        <ToggleSwitch
          onChange={this.props.onPrependChange}
          label="Add to beginning?"
          className="addvideos__toggle--inline"
          options={this.props.toggle.options}
          name="prepend"
          selectedIndex={+this.props.toggle.selected}
        />
      )
    }
    return toggle;
  }

  isSelected(id) {
    const { selected } = this.props;

    const videos = Array.isArray(selected) ? selected : [];

    const selectedVideo = videos.find((sv) => sv.video_youtubeID === id)
    return !!selectedVideo;
  }

  makeTableData() {
    let data = [];
    const curSet = this.props.currentSet || [];

    const { list } = this.props

    if (list.length) {
      data = list.map((item, index) => ({
        video_id: item.video_id,
        video_title: item.video_title,
        video_channel: item.channel_title,
        video_duration: +item.videoext_duration,
        video_pubdate: item.videoext_publishdatetime,
        video_youtubeID: item.video_youtubeID,
        occurrences: this.props.currentSet.filter((v) => item.video_youtubeID === v.ytID).length,
        checked: item.checked,
      }));
    }
    return data;
  }

  submitVideos(domEvent) {
    domEvent.preventDefault();
    this.props.onSubmit(domEvent);
  }

  getModalBody() {
    const vlt = (
      <VideoListTable
        headers={this.state.tableHeaders}
        rows={this.makeTableData()}
        cssClass="video__list__table"
        checkBoxChangeHandler={this.changeHandler}
      />
    );

    const body = this.props.loading ? <LoaderSimple open cssClass="destinations__video__list__loader" /> : vlt;
    return body;
  }

  changeHandler() {
    this.props.videosSelectedHandler(domEvent);
  }

  close() {
    if (this.props.closeHandler) {
      this.props.closeHandler(this.vlModal)
    }
  }

  makeVideoSearch() {
    return (
      <FilterComponent
        ascending={this.props.ascending}
        cssClass="reports__vidset__search"
        itemsName="Videos"
        filterDefault="video_title"
        filterData={[
          { value: 'video_title', name: 'Video Title', type: 'string' },
          { value: 'video_channel_title', name: 'Channel Name', type: 'string' },
          { value: 'video_addedbyname', name: 'User name', type: 'string' },
        ]}
        sortData={[
          { value: 'video_title', name: 'Video Title', type: 'string' },
          { value: 'video_updateddate', name: 'Video Last Updated', type: 'num' },
          { value: 'video_addeddate', name: 'Date Video Added to System', type: 'num' },
          { value: 'video_channel_title', name: 'Channel Name', type: 'string' },
          { value: 'video_duration_seconds', name: 'Video Duration', type: 'num' },
          { value: 'video_viewcount', name: 'Video View Count', type: 'num' },
          { value: 'video_likecount', name: 'Video Likes Count', type: 'string' },
          { value: 'video_setscount', name: 'Number of Video Sets', type: 'num' },
          { value: 'video_publishdate', name: 'Publish Date', type: 'num' },
          { value: 'video_channel_publishdate', name: 'Channel Date', type: 'date' },
          { value: 'video_rating', name: 'Rating', type: 'string' },
          { value: 'meow', name: 'MEOW Status' },
        ]}
        onSortChange={this.props.onSearchSortChange}
        onFilterChange={this.props.onSearchFilterChange}
        onFilterClear={this.props.onSearchFilterClear}
        onDirectionChange={this.props.onSearchDirectionChange}
        hideCategories
      />
    );
  }

  render() {
    return (
      <dialog ref={this.vlModal} id="VideosListModal" className="video__list__modal" onClose={this.props.closeHandler}>
        <CloseButton longForm onClick={this.close} />
        <h2>Select Videos</h2>
        {this.makeVideoSearch()}
        <div className="vlm__controls">
          {this.makeToggle()}
          <button type="submit" className="btn btn--action" form="add_to_videoset">Submit</button>
        </div>
        <form action={API_VIDEOSET_REINIT} method="post" onSubmit={this.submitVideos} id="add_to_videoset">
          <div className="table__list__controls">
            <SelectPerPage name="video_list_pages" cssClass="table__list__perpage" value={this.props.perPage} onChangeHandler={this.props.perPageChangeHandler} />
            <Pagination
              currentPage={this.props.currentPage}
              totalPages={this.props.totalPages}
              onClickHandler={this.props.onPaginationClick}
              disablePrevious={this.props.currentPage <= 1}
              disableNext={this.props.currentPage >= this.props.totalPages}
            />
          </div>
          { this.getModalBody() }
          <div className="table__list__controls">
            <b />
            <Pagination
              currentPage={this.props.currentPage}
              totalPages={this.props.totalPages}
              onClickHandler={this.props.onPaginationClick}
              disablePrevious={this.props.currentPage <= 1}
              disableNext={this.props.currentPage >= this.props.totalPages}
            />
          </div>
        </form>
        <div className="vlm__controls">
          {this.makeToggle()}
          <button type="submit" form="add_to_videoset" className="btn btn--action">Submit</button>
          <button type="button" form="add_to_videoset" className="btn btn-light" onClick={this.close}>Close</button>
        </div>
      </dialog>
    )
  }
}
