/**
 * ReportsVideoDestinations
 * Displays Top Videos by Destination report results.
 */


import React from 'react';
import {
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import ReportSearch from './ReportSearch';
import ReportsResults from './ReportsResults';
import LoaderSimple from '../subcomponents/LoaderSimple';

import {
  API_DESTINATIONS_LIST,
  API_REPORTS_VIDSBYDEST
} from '../../js/Configuration';

import {
  apirequest,
  getAuthData
} from '../../js/Utilities';

const ReportsVideoDestinations = ({params = null}) => {
  const limit = '500';
  let display;

  const [ destination, setDestination ] = useState('');
  const [ destinations, setDestinations ] = useState([]);
  const [ end, setEndDate ] = useState('');
  const [ videos, setVideos ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ results, setResults ] = useState(null);
  const [ start, setStartDate ] = useState('');

  const massageResults = (results) => {
    const res = results.map( (result) => {
      return {
        title: result['Video Title'],
        url: result.youtubeID,
        frequency: result.Frequency
      }
    });
    return res;
  }

  const onSearchSubmit = (domEvent) => {
    domEvent.preventDefault();

    setLoading(true);
    setDestination(domEvent.target.destination.value);
    setStartDate(domEvent.target.start.value);
    setStartDate(domEvent.target.end.value);

    const fd = new FormData(domEvent.target);
    fd.set('token', getAuthData('token'));

    apirequest(API_REPORTS_VIDSBYDEST, {body: fd}, (data) => {
      if(data) {
        setVideos(data?.data);
        setResults(data?.data.length);
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    if( destinations.length ) return;

    const fetchCancel = new AbortController();
    const signal = fetchCancel.signal;
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_DESTINATIONS_LIST, {body: fd, signal}, (data) => setDestinations( data ));

    return () => {
      if( destinations.length ) {
        fetchCancel.abort();
      }
    }
  });

  if(loading) {
    display = <LoaderSimple open={true} />
  } else {
    display = (
      <ReportsResults
        columnHeadings={['Video Title', 'Frequency']}
        results={massageResults(videos)}
        count={results}
        start={start}
        end={end}
        destination={destination}
        downloadURL={`${API_REPORTS_VIDSBYDEST}/csv`}
        limit={limit} />
    );
  }

  return (
    <div className="reports__page">
      <h1>{ params?.state?.title }</h1>
      <ReportSearch
        apiURL={API_REPORTS_VIDSBYDEST}
        destinations={ destinations }
        id="destination"
        labelText="Select a destination:"
        limit={limit}
        name="destination"
        onSubmitHandler={onSearchSubmit} />
      {display}
    </div>
  )
}

export default () => <ReportsVideoDestinations params={useLocation()} />;
