/**
 * RuntimeRatings.js
 * Display runtime and video set ratings on the Destinations page.
 * See PropTypes definitions at the end of this file for parameters
 */  
import React from 'react';
import PropTypes from 'prop-types';

import './css/RuntimeRatings.css';

class RuntimeRatings extends React.Component {
  constructor(props) {
    super(props);
    this.showOptionalMessage = this.showOptionalMessage.bind(this);
  }

  showOptionalMessage() {
    let msg = '';
    msg = this.props.isRequired ? '(Required)' : '(Optional)';
    return msg;
  }

  render() {
    return (
      <div className="ratings__runtime">
        <span className="ratings__runtime_rt">Runtime {this.props.runtime} </span>
        | <span className="ratings__runtime_vc">{this.props.videos} videos </span>
        | <span className="ratings__runtime_r">Rating: {this.props.rating}</span>
      </div>
    );
  }
}


RuntimeRatings.defaultProps = {
  runtime: 0,
  rating: '—',
  videos: 0
};


RuntimeRatings.propTypes = {
  runtime: PropTypes.string.isRequired,
  videos: PropTypes.number,
  rating: PropTypes.string
};


export default RuntimeRatings;
