import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { CloseButton } from '../subcomponents/CloseButton';
import DialogModal from '../subcomponents/DialogModal';
import TextInput from '../subcomponents/TextInput';

import { API_VIDEOSET_ADDNEW } from '../../js/Configuration';
import { getAuthData } from '../../js/Utilities';

export const CreateVideoSet = (props) => {

  const cvs = useRef();

  if(props.open && cvs.hasOwnProperty('current')) {
    cvs.current.showModal()
  }
  
  const submitter = (domEvent) => {
    domEvent.preventDefault();
    domEvent.persist();

    props.onSubmit(domEvent, cvs);
  }

  return (
    <dialog ref={cvs} id="DestinationsCreateNewVidset">
      <CloseButton longForm={true} onClick={() => { props.onClose(cvs) } } />
      <h2>Create a new videoset in <q>{props.destination.dName}</q></h2>
      <form onSubmit={submitter} id="destination_create_vidset" action={API_VIDEOSET_ADDNEW} method="POST">
        <input type="hidden" name="token" value={getAuthData('token')} />
        <input type="hidden" name="destination" value={props.destination.dID} onChange={() => {}} />
        <div className="form__label_btn_group">
        <TextInput
          cssClass="destinations__create-new"
          labelText="Enter the name for this video set"
          name="name"
          id="create"
          maxLength="50"
          isRequired={true}
          onInputHandler={props.onChange}
          value={props.value} />
          <button type="submit" className="btn btn--action">Save</button>
        </div>
      </form>
    </dialog>
  );
}

CreateVideoSet.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};


