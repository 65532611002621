/**
 * Project.js
 * Displays project name, link and icon.
 */
import React from 'react';
import { Link } from 'react-router-dom';

import './css/Project.css';

export const Project = (props) => {
  return (
    <Link to={props.path} className="project__link">
      {props.name}
      {props.icon}
    </Link>
  )
}
